/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import { bool } from 'prop-types';
import {
  Card,
  CardMedia,
  CardTitle,
  CardBody,
  CardActions,
  Typography,
  Button,
  Link,
  CardFooter,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames/bind';
import { akamaiImageResize } from './helper';
import styles from './promo-visual-navigation.scss';

export const PromoVisualNavigationCard = (itemDetails) => {
  const cx = classNames.bind(styles);

  const { item, position, lazyLoad } = itemDetails;
  let {
    altText, image, id: itemID, link, promotionalOffer, previewImage
  } = item || {};

  let { url: imageUrl } = image || {};

  const { ref, clickID } = useImpression({
    data: {
      id: itemID,
      component: 'PromoVisualNavigation',
      name: 'PromoVisualNavigation',
      type: 'content',
      position,
    },
  });

  if (previewImage) {
    let damDownloadedContentImage = previewImage?.damDownloadedContent;
    let damContentSelectorImage = previewImage?.damContentSelector?.assetData?.[0];

    if (damDownloadedContentImage?.url) {
      imageUrl = damDownloadedContentImage?.url;
    } else {
      imageUrl = damContentSelectorImage?.selectedImageUrl || imageUrl;
    }
  }

  imageUrl = akamaiImageResize(imageUrl, 328);

  const {
    eyebrow, shouldShowDollar, shouldShowOff, specialOfferHeadline, specialOfferSubhead, simpleOfferHeadline, simpleOfferSubhead
  } = promotionalOffer || {};

  const eyebrowSection = (
    <CardTitle subheader={(<Typography variant="h5" height="tight" weight="display">{eyebrow}</Typography>)} />
  );

  const dollarSection = (
    <Typography variant="h5" height="tight" weight="display"><sup className="sui-align-super sui-mr-[0.13rem] sui-text-lg sui-top-[0.1rem]">$</sup></Typography>
  );

  const offSection = (
    <Typography variant="h5" height="tight" weight="display"><sub className="sui-ml-[0.4rem] sui-text-lg sui-bottom-auto">Off</sub></Typography>
  );

  const titleSection = (
    <>
      {simpleOfferHeadline
        ? (
          <CardTitle header={<Typography variant="h3" weight="display" height="tight">{simpleOfferHeadline}</Typography>} />
        )
        : (
          <>
            {eyebrow && eyebrowSection}
            <div className="sui-flex sui-items-center">
              {shouldShowDollar && dollarSection}
              <CardTitle header={<Typography variant="h2" weight="display" height="tight">{specialOfferHeadline}</Typography>} />
              {shouldShowOff && offSection}
            </div>
          </>
        )}
    </>
  );

  const subTitleSection = (
    <>
      {simpleOfferSubhead
        ? <CardBody><Typography height="normal">{simpleOfferSubhead}</Typography></CardBody>
        : <CardBody><Typography height="normal">{specialOfferSubhead}</Typography></CardBody>}
    </>
  );

  altText = altText || simpleOfferSubhead || specialOfferSubhead;

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
      key={`card-position-${position}`}
      className="sui-flex sui-w-full"
    >
      <Card
        key={itemID}
        target="_blank"
        href={link}
        rel="noreferrer"
        className={cx('sui-w-full sui-grid link--hover-no-underline')}
      >
        <CardMedia
          alt={altText}
          title={altText}
          src={imageUrl}
          aspect="square"
          loading={lazyLoad ? 'lazy' : ''}
        />
        <div className="sui-flex-row sui-grow">
          {titleSection}
          {subTitleSection}
        </div>
      </Card>
    </div>
  );
};

const displayName = 'PromoVisualNavigationCard';

const propTypes = {
  lazyLoad: bool
};

const defaultProps = {
  lazyLoad: false
};

PromoVisualNavigationCard.displayName = displayName;
PromoVisualNavigationCard.propTypes = propTypes;
PromoVisualNavigationCard.defaultProps = defaultProps;
