import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

import helpers from '../helpers/countdownHelper';
import TimerInline from './partials/TimerInline';
import TimerBlock from './partials/TimerBlock';
import TimerNumsOverText from './partials/TimerNumsOverText';
import './countdown-timer.style.scss';

const CountdownTimer = ({
  endDate,
  timebombThreshold,
  daysLeftThreshold,
  dateDisplayThreshold,
  hours,
  minutes,
  shouldShowSecondsRemaining,
  shouldUseMessagingString,
  shouldIncludeSvg,
  shouldUseBlock,
  shouldUseClockDisplay,
  shouldUseDateDisplay,
  shouldUseDaysLeftDisplay,
  customMessagingString,
  isSBOTD,
  isPromotionTimer,
  showCondensedTimer,
  showOrangeTimerSvg,
  shouldShowTextBelow,
}) => {
  const [timerEndDate, setTimerEndDate] = useState(() => {
    return helpers.initializeEndDate(endDate, hours, minutes);
  });

  useEffect(() => {
    if (endDate) {
      setTimerEndDate(() => {
        return helpers.initializeEndDate(endDate, hours, minutes);
      });
    }

  }, [endDate]);
  const shouldUseNumsOverText = isPromotionTimer && shouldShowTextBelow;
  const calculateTimeLeft = useCallback(() => {
    if (!timerEndDate) {
      return null;
    }

    if (isSBOTD) {
      return helpers.calculateSbotdTimeLeft();
    }

    if (isPromotionTimer) {
      return helpers.calculatePromotionTimeLeft(timerEndDate);
    }

    const timeToExpiration = +new Date(timerEndDate) - +new Date();

    if (timeToExpiration > 0) {
      return {
        days: helpers.convertToDays(timeToExpiration),
        timerHours: helpers.convertToHours(timeToExpiration),
        timerMinutes: helpers.convertToMinutes(timeToExpiration),
        seconds: helpers.convertToSeconds(timeToExpiration),
        totalMilliseconds: timeToExpiration,
      };
    }

    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalMilliseconds: 0,
    };
  }, [isPromotionTimer, isSBOTD, timerEndDate]);

  const getTimeStringAndMessaging = useCallback((timeLeft) => {
    if (!timeLeft) {
      return { messagingString: null, timeString: null };
    }

    if (isSBOTD && shouldShowSecondsRemaining) {
      return {
        messagingString: null,
        timeString: helpers.getSBOTDTimer(timeLeft),
      };
    }

    if (shouldUseNumsOverText) {
      return {
        messagingString: customMessagingString || '',
        timeString: timeLeft,
      };
    }

    if (isPromotionTimer) {
      return {
        messagingString: customMessagingString || '',
        timeString: helpers.getPromotionTimer(timeLeft),
      };
    }

    if (!shouldUseClockDisplay && !shouldUseDateDisplay && !showCondensedTimer) {
      return {
        messagingString: customMessagingString || 'Ends in ',
        timeString: helpers.getTimeStringDaysHoursMinutes(
          timeLeft,
          shouldShowSecondsRemaining
        ),
      };
    }

    if (shouldUseClockDisplay && timeLeft.totalMilliseconds <= timebombThreshold) {
      return {
        messagingString: customMessagingString || 'Ends in ',
        timeString: helpers.getTimeStringDigitalClock(timeLeft),
      };
    }

    if (shouldUseDaysLeftDisplay && timeLeft.totalMilliseconds < daysLeftThreshold) {
      return {
        messagingString: customMessagingString || 'Ends in ',
        timeString: helpers.getTimeStringDaysLeft(timeLeft),
      };
    }

    if (shouldUseDateDisplay && timeLeft.totalMilliseconds < dateDisplayThreshold) {
      return {
        messagingString: customMessagingString || 'Ends on ',
        timeString: helpers.getTimeStringDate(timerEndDate),
      };
    }

    if (showCondensedTimer) {
      return {
        messagingString: customMessagingString || ' left to buy',
        timeString: helpers.getCondensedTimer(timeLeft),
      };
    }

    return { messagingString: null, timeString: null };
  }, [
    customMessagingString,
    dateDisplayThreshold,
    daysLeftThreshold,
    isPromotionTimer,
    isSBOTD,
    showCondensedTimer,
    shouldShowSecondsRemaining,
    shouldUseClockDisplay,
    shouldUseDateDisplay,
    shouldUseDaysLeftDisplay,
    timebombThreshold,
    timerEndDate,
    shouldUseNumsOverText,
  ]);

  const [timeStringAndMessaging, setTimeStringAndMessaging] = useState(() => {
    return getTimeStringAndMessaging(calculateTimeLeft());
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = calculateTimeLeft();

      setTimeStringAndMessaging((currentState) => {
        const newState = getTimeStringAndMessaging(timeLeft);

        // one render per messagingString or timeString change
        if (
          newState
          && Object.is(currentState.messagingString, newState.messagingString)
          && Object.is(currentState.timeString, newState.timeString)
        ) {
          return currentState;
        }

        return newState;
      });

      if (!timeLeft || timeLeft.totalMilliseconds <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [calculateTimeLeft, getTimeStringAndMessaging]);

  if (!timerEndDate || !timeStringAndMessaging?.timeString) {
    return null;
  }

  let Timer;
  if (shouldUseNumsOverText) {
    Timer = TimerNumsOverText;
  } else if (shouldUseBlock) {
    Timer = TimerBlock;
  } else {
    Timer = TimerInline;
  }

  return (
    <ErrorBoundary name="countdown-timer">
      <Timer
        timeStringAndMessaging={timeStringAndMessaging}
        shouldIncludeSvg={shouldIncludeSvg}
        shouldUseMessagingString={shouldUseMessagingString}
        showMessageOnRight={showCondensedTimer}
        showOrangeTimerSvg={showOrangeTimerSvg}
        shouldShowTextBelow={shouldShowTextBelow}
      />
    </ErrorBoundary>
  );
};

CountdownTimer.displayName = 'CountdownTimer';

CountdownTimer.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  timebombThreshold: PropTypes.number,
  daysLeftThreshold: PropTypes.number,
  dateDisplayThreshold: PropTypes.number,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldUseMessagingString: PropTypes.bool,
  shouldShowSecondsRemaining: PropTypes.bool,
  shouldIncludeSvg: PropTypes.bool,
  shouldUseBlock: PropTypes.bool,
  shouldUseClockDisplay: PropTypes.bool,
  shouldUseDateDisplay: PropTypes.bool,
  shouldUseDaysLeftDisplay: PropTypes.bool,
  customMessagingString: PropTypes.string,
  isSBOTD: PropTypes.bool,
  isPromotionTimer: PropTypes.bool,
  showCondensedTimer: PropTypes.bool,
  showOrangeTimerSvg: PropTypes.bool,
  shouldShowTextBelow: PropTypes.bool,
};

CountdownTimer.defaultProps = {
  endDate: null,
  hours: 0,
  minutes: 0,
  timebombThreshold: 0,
  daysLeftThreshold: 0,
  dateDisplayThreshold: 0,
  shouldUseMessagingString: false,
  shouldShowSecondsRemaining: false,
  shouldIncludeSvg: false,
  shouldUseBlock: false,
  shouldUseClockDisplay: false,
  shouldUseDateDisplay: false,
  shouldUseDaysLeftDisplay: false,
  customMessagingString: null,
  isSBOTD: false,
  isPromotionTimer: false,
  showCondensedTimer: false,
  showOrangeTimerSvg: false,
  shouldShowTextBelow: false,
};

export default CountdownTimer;
