import React, { useContext } from 'react';
import {
  bool, func, number, element, string
} from 'prop-types';
import classNames from 'classnames';

import { Col, Image } from '@thd-olt-component-react/core-ui';
import {
  QueryProvider
} from '@thd-nucleus/data-sources';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { SalientPoints } from '@thd-olt-component-react/salient-points';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductImage } from '../product-image/ProductImage';
import { ProductShipping } from '../product-shipping/ProductShipping';
import { ProductStore } from '../product-store/ProductStore';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductBadge } from '../product-badge/ProductBadge';
import { isATCEnabled } from '../../utils';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';

import './product-mini.scss';

export const ProductMini = (props) => {
  const {
    disabled,
    onAction,
    enableATC,
    silentATC,
    onlyShowIfFulfillable,
    inCartQuantity,
    callToActionSection,
    showBadge,
    showBundleDiscount,
    showKPF,
    ctaLocation,
    hideFulfillment,
    hideCaret,
    hideShippingInfo,
    hideStoreInfo,
    ctaNoPadding,
    hideHighlights
  } = props;

  const { product, isHDQCSku, channel } = useContext(ProductPodContext);

  if (onlyShowIfFulfillable && !isATCEnabled(product)) return null;

  const mainSectionClasses = classNames('product-pod-mini__main', {
    'product-pod-mini__main--withATC': enableATC
  });
  const minKpfs = 3;
  const isCTALocationPropBottom = ctaLocation === 'bottom';
  const isMobile = channel === 'mobile';
  const shouldCTALocationBeBottom = (isMobile && !isCTALocationPropBottom) || isCTALocationPropBottom;
  const isCTALocationRight = !isCTALocationPropBottom && !isMobile;
  const hasMinKpfs = !!product?.keyProductFeatures?.keyProductFeaturesItems?.[0]?.features?.length
  && product.keyProductFeatures.keyProductFeaturesItems[0].features.length >= minKpfs;
  const hasHighlights = !!(product?.details?.highlights?.length);
  const showKeyProductFeatures = showKPF && hasMinKpfs;
  const showSalientPoints = !showKeyProductFeatures && hasHighlights;
  const useCaret = isCTALocationPropBottom && hideFulfillment && !hideCaret;

  return (
    <div className="product-pod-mini">
      <div>
        {showBadge && (
          <div data-testid="product-pod-mini__badge">
            <ProductBadge badges={product.badges} />
          </div>
        )}
        <Col fallback={useCaret || isCTALocationRight ? '3' : '4'} className="product-pod-mini__image">
          <ProductImage inCartQuantity={inCartQuantity} />
        </Col>
        <Col fallback={isCTALocationRight ? '7' : '8'} className={mainSectionClasses}>
          <ProductHeader brand="inline" collection="hidden" onAction={onAction} />
          <ProductRating />
          { isHDQCSku ? (
            <ProductQuoteCenter />
          ) : (
            <>
              {showBundleDiscount && (
                <div className="bundle-discount">
                  <Image
                    className="bundle-discount__icon"
                    asset="savings-tag.svg"
                    alt="Savings Tag"
                    title="Savings Tag"
                  />
                  <span className="bundle-discount__text">
                    Eligible for Bundle Discount
                  </span>
                </div>
              )}
              <ProductPrice className="product-pod__pricing" large={false} hideBulkPrice />
              {enableATC && <ProductATC disabled={disabled} silent={silentATC} outline />}
            </>
          )}
          {!hideHighlights && (
            <>
              {showKeyProductFeatures && (
                <div data-testid="product-pod-mini__kpf" className="product-pod-mini__kpf">
                  <KeyProductFeatures
                    hideImage
                    itemId={product.itemId}
                    minfeatures={minKpfs}
                    maxfeatures={5}
                    simple
                    oneColumnGrid
                  />
                </div>
              )}
              {showSalientPoints && (
                <Col className="package-pod__salient-points">
                  <Col className="package-pod__salient-points__border">
                    <div data-testid="product-pod-mini__salient-points">
                      <SalientPoints itemId={product.itemId} max={3} />
                    </div>
                  </Col>
                </Col>
              )}
            </>
          )}
          {!hideFulfillment && (
            <div className="product-pod-mini__fulfillment--wrapper">
              {!hideStoreInfo && <ProductStore checkGeneric />}
              {!hideShippingInfo && <ProductShipping className="product-pod__fulfillment--shipping" />}
            </div>
          )}
        </Col>
        {!enableATC && isCTALocationRight && (
          <Col fallback="2" className="product-pod-mini__cta" nopadding={ctaNoPadding}>
            {callToActionSection}
          </Col>
        )}
        {useCaret && (
          <Col fallback="1" className="product-pod-mini__caret">
            {!isHDQCSku && (
              <Image
                onClick={onAction}
                className="product-pod-mini__caret__image"
                alt="Open Menu"
                asset="caret-orange.svg"
                width={9}
                height={15}
              />
            )}
          </Col>
        )}
      </div>
      {shouldCTALocationBeBottom && !enableATC && (
        <Col xs="12" nopadding={ctaNoPadding}>
          {callToActionSection}
        </Col>
      )}
    </div>
  );
};

ProductMini.displayName = 'ProductMini';

ProductMini.propTypes = {
  disabled: bool,
  onAction: func,
  enableATC: bool,
  silentATC: bool,
  onlyShowIfFulfillable: bool,
  inCartQuantity: number,
  callToActionSection: element,
  showBadge: bool,
  showBundleDiscount: bool,
  showKPF: bool,
  ctaLocation: string,
  hideFulfillment: bool,
  hideCaret: bool,
  hideShippingInfo: bool,
  hideStoreInfo: bool,
  ctaNoPadding: bool,
  hideHighlights: bool
};

ProductMini.defaultProps = {
  disabled: false,
  onAction: () => {},
  enableATC: true,
  silentATC: false,
  onlyShowIfFulfillable: false,
  inCartQuantity: 0,
  callToActionSection: null,
  showBadge: false,
  showBundleDiscount: false,
  showKPF: false,
  ctaLocation: 'bottom',
  hideFulfillment: true,
  hideCaret: false,
  hideShippingInfo: false,
  hideStoreInfo: false,
  ctaNoPadding: false,
  hideHighlights: false
};
