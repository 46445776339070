import React, { useContext } from 'react';
import { string, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import ProductShelfContainer from './ProductShelfContainer';

function ProductShelfRenderer({
  componentId,
  dataComponent,
  showTooFewProductsNotice,
}) {

  const { channel } = useContext(ExperienceContext);
  const {title, sortBy} = dataComponent || {};
  const itemIds = dataComponent?.productShelfData?.skus;
  const sortOrder = dataComponent?.sortOrderOptions;
  const navParam = dataComponent?.productShelfData?.navParam;
  const { defaultVariables } = useContext(QueryContext);
  const { isBrandPricingPolicyCompliant = false } = defaultVariables?.current;

  return (
    <>
      <QueryProvider
        cacheKey="product-shelf-cache"
        defaultVariables={{
          storeId: '121',
          zipCode: '30339',
          skipKPF: true,
          skipSubscribeAndSave: true,
          isBrandPricingPolicyCompliant
        }}
      >
          <ProductShelfContainer
            componentId={componentId}
            channel={channel}
            showTooFewProductsNotice={showTooFewProductsNotice}
            opts={{
              itemIds,
              navParam,
              sortBy,
              sortOrder,
              contentfulTitle: title
            }}
          />
      </QueryProvider>
    </>
  );
}

ProductShelfRenderer.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
  showTooFewProductsNotice: bool,
};

ProductShelfRenderer.defaultProps = {
  componentClass: 'ProductShelfRenderer',
  showTooFewProductsNotice: false
};

export { ProductShelfRenderer };
