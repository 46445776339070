class Base {

  constructor(props = {}) {
    this._props = props || {};
  }

  get(key, defaultsTo) {
    let prop = this._props[key];
    if (prop) return prop;

    prop = defaultsTo;
    return prop;
  }

  toJSON() {
    return this._props;
  }
}

export default Base;