import React, { useEffect, useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { string, object, bool, func } from 'prop-types';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { NoResultsFoundBanner } from '@thd-olt-component-react/no-results-found-banner';
import { PopularCategories } from '@thd-olt-component-react/related-search';
import { ShopByCategory } from '@thd-olt-component-react/shop-by-category';
import { SponsoredCarousel } from '@thd-olt-component-react/sponsored-content';
import { PlpRecsRenderPlaceholder } from '@thd-olt-component-react/thd-recs-containers';

const NoResultsFound = ({
  keyword,
  searchData,
  cpoOnly,
  onCpoClick,
  pageType,
  requestKey
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('no-results-found.ready'); }, []);
  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      LIFE_CYCLE_EVENT_BUS.trigger('no-results.loaded', { keyword });
    }
  }, []);

  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const crumbs = [
    { label: 'Text Search', url: '' },
    { label: keyword, url: `/s/${keyword}?NCNI-5` }
  ];

  return (
    <div data-component="NoResultsFound">
      <QueryProvider cacheKey="no-results-found">
        <div className="sui-grid sui-grid-cols-12">
          <div className="sui-col-span-12 md:sui-col-start-2 md:sui-col-span-10 ">
            <Breadcrumbs staticCrumbs={crumbs} hideOnNoCategory={isMobile} />
            <NoResultsFoundBanner keyword={keyword} cpoOnly={cpoOnly} onCpoClick={onCpoClick} />
            <div className="md:sui-pt-6">
              <PopularCategories popCatId="home" />
            </div>
            <PlpRecsRenderPlaceholder
              requestKey={requestKey}
              keyword={keyword}
            />
            <div className="md:sui-pt-6">
              <ShopByCategory
                componentId="6dMXpZOWCDgD4ZzahLfBpJ"
                componentClass="ShopByCategory"
              />
            </div>
            <div className="sui-pt-4 md:sui-pt-10" data-testid="sponsored-carousel-nrf">
              <SponsoredCarousel
                plaLocation="sponsoredCarouselNoResultsFoundPage"
                pageContext={{
                  schema: 'no_results_found_sponsored',
                  keyword,
                  data: {
                    searchModel: searchData?.searchModel,
                  },
                }}
                showSponsoredCarousel
                dynamic={{
                  pageType
                }}
                hydrator={{
                  className: 'grid',
                  scrollBuffer: 750
                }}
              />
            </div>
          </div>
        </div>
      </QueryProvider>
    </div>
  );
};

NoResultsFound.displayName = 'NoResultsFound';

NoResultsFound.propTypes = {
  keyword: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  searchData: object,
  cpoOnly: bool,
  onCpoClick: func,
  pageType: string,
  requestKey: string,
};

NoResultsFound.defaultProps = {
  searchData: undefined,
  cpoOnly: false,
  onCpoClick: () => {},
  pageType: '',
  requestKey: ''
};

NoResultsFound.dataModel = extend({}, PlpRecsRenderPlaceholder, Breadcrumbs, PopularCategories);

export { NoResultsFound };
