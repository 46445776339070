import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { Button } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ProductPodGroupContext } from '../../ProductPodGroupContext';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductSeeSimilarItems = ({
  showSpacer,
  showSimilarInStock
}) => {

  const {
    onSimilarItemsClick,
    hideSeeSimilarItems,
    hideSimilarItemLink,
    showSimilarItem
  } = useContext(ProductPodContext);

  const {
    handleProductPodExpansion
  } = useContext(ProductPodGroupContext);

  const {
    channel
  } = useContext(ExperienceContext);

  if (hideSeeSimilarItems) {
    return null;
  }
  const similarButtonEnabled = !showSimilarInStock;
  const isMobile = channel === 'mobile';
  const SimilarInStockButton = classNames({
    'product-pod__customs--margin': similarButtonEnabled && isMobile,
    'similar-items-link': !similarButtonEnabled,
    'similar-items-link__caret': showSimilarInStock
  });

  const isOutOfStock = !showSimilarItem && hideSimilarItemLink;
  const outOfStockAndNoShowSimilarInStock = !showSimilarInStock && (isOutOfStock || !handleProductPodExpansion);
  const notOutOfStockAndShowSimilarInStock = showSimilarInStock && (!isOutOfStock || !handleProductPodExpansion);
  if (outOfStockAndNoShowSimilarInStock || notOutOfStockAndShowSimilarInStock) {
    if (showSpacer) {
      return (<div className="product-pod__similar-items-link-spacer" />);
    }
    return null;
  }
  const similarItemBtnClick = () => onSimilarItemsClick(similarButtonEnabled || showSimilarInStock);
  const seeSimilarText = showSimilarInStock ? 'View' : 'See';

  return (
    <>
      <Button
        tag="a"
        className={SimilarInStockButton}
        link={!similarButtonEnabled}
        outline={similarButtonEnabled}
        small={similarButtonEnabled && isMobile}
        onClick={similarItemBtnClick}
        data-testid="similar-items-button"
      >
        {similarButtonEnabled ? 'View Similar in Stock' : `${seeSimilarText} similar items`}
      </Button>
    </>
  );
};

ProductSeeSimilarItems.propTypes = {
  showSimilarInStock: bool,
  showSpacer: bool
};
ProductSeeSimilarItems.defaultProps = {
  showSimilarInStock: false,
  showSpacer: false
};
ProductSeeSimilarItems.displayName = 'ProductSeeSimilarItems';
