import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, Pill } from '@one-thd/sui-atomic-components';
import { emtContentDataModel } from '../dataModels';
import './brand-categories.scss';

export const BrandCategories = ({ contentId, pageName }) => {
  const { loading, data, error } = useDataModel(
    'emtContent',
    {
      variables: {
        contentId,
        pageName
      }
    }
  );

  const brandCategories = data?.emtContent?.content?.brandLinking;

  if (!data || loading || error || !brandCategories || brandCategories?.length === 0) {
    return null;
  }
  return (
    <div className="sui-flex sui-flex-col sui-w-full sui-gap-4 sui-p-3" data-component="BrandCategories">
      <Typography variant="h2" weight="display">{brandCategories?.title}</Typography>
      <div className="sui-flex sui-flex-row sui-flex-wrap sui-leading-tight sui-gap-4">
        {brandCategories?.links.map((links, index) => {
          const { anchortext, url } = links;

          return (
            <div
              className="brand-categories sui-bg-primary sui-rounded-full"
              key={`brand-categories-${index}`}
            >
              <Pill
                label={anchortext}
                component="a"
                href={url}
                clickable
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

BrandCategories.propTypes = {
  contentId: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired
};

BrandCategories.displayName = 'BrandCategories';

BrandCategories.dataModel = emtContentDataModel;