import React, { memo } from 'react';
import { shape, string } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { click } from './events';
import { useImpression } from '@thd-olt-component-react/impression';
import './full-card.scss';

const FullCard = ({ type, header, details, componentPosition, category }) => {

  const { title, description: headerDescription } = header;
  const { description, imageUrl, link, recordType } = details;
  const specialSymbol = 'â€™';
  const detailsDescription = description?.replace(specialSymbol, '\'');
  const { ref, clickID } = useImpression({
    data: {
      id: "00DEFVALCC00",
      name: 'CategoryCards',
      component: 'CategoryCards',
      position: componentPosition,
      type: 'content',
      category: category
    }
  });

  return (
    <section className="full-card__zone col__12-12" ref={ref} clickid={clickID}>
      <div className="full-card__header-wrapper">
        <div className="full-card__header-block">
          <h2 className="full-card__header u__bold">{title}</h2>
          <p className="full-card__header-text full-card__header-text__truncate">
            {type === 'rental'
              ? `Rent a ${headerDescription}`
              : `${headerDescription}`}
          </p>
        </div>
        {type === 'rental' && (
          <div className="full-card-details__logo">Tool Rental</div>
        )}
        {type === 'install' && (
          <div className={recordType?.includes('Services:')
            ? 'full-card-details__serviceslogo'
            : 'full-card-details__pro-referral-logo'}
          />
        )}
      </div>
      <div className="full-card-details">
        <div className="full-card-details__image">
          <Image src={imageUrl} alt="" stretchy width="1" height="1" />
        </div>
        <div className="full-card-details__description">
          {type === 'rental'
            ? (
              <div className="full-card-details__text">
                Prefer to Rent?
                <br />
                Your store may have the right solution!
              </div>
            )
            : <div className="full-card-details__text full-card-details__text--truncate">{detailsDescription}</div>}
          <div className="full-card-details__actions">
            <a
              className="bttn-outline bttn-outline--primary bttn--inline full-card-details__btn"
              href={link}
              onClick={() => {
                click({
                  pageNumber: 1,
                  position: 'r1',
                  type: title
                });
              }}
            >
              {type === 'rental' && (
                <span className="bttn__content">View Rentals</span>
              )}
              {type === 'install' && (
                <span className="bttn__content">Learn More</span>
              )}
              {type === 'guide' && (
                <span className="bttn__content">Read Our Guide</span>
              )}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

FullCard.displayName = 'CategoryCards-FullCard';

FullCard.propTypes = {
  header: shape({
    title: string,
    description: string
  }).isRequired,
  details: shape({
    imageUrl: string,
    description: string,
    link: string
  }).isRequired,
  type: string.isRequired
};

const MemoFullCard = memo(FullCard);

export { MemoFullCard as FullCard };
