import {
  params, string, arrayOf, shape, bool, customType
} from '@thd-nucleus/data-sources';
import { BuyItAgainProductPod } from './BuyItAgainProductPod';

export const dataModel = {
  searchModel: params({
    navParam: string(),
    additionalSearchParams: customType('AdditionalParams').shape({
      svocID: string(),
    }),
    storeId: string()
  }).shape({
    buyitagain: params({ dataSource: string() })
      .arrayOf(shape((BuyItAgainProductPod.dataModel.product))).skip('skipBuyitagain', true)
  })
};
