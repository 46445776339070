import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { WallAreaCalculator } from '@thd-olt-component-react/calculator';
import {
  Button, Col, Heading, Image, Tabs, Tab
} from '@thd-olt-component-react/core-ui';
import { EasyEstimator } from './EasyEstimator/EasyEstimator';
import paintModals from '../../../data/paint-modals.json';
import styles from './ContainerSize.module.scss';

const { calculateSize } = paintModals;
const cx = classNames.bind(styles);

export const ContainerSize = () => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('containerSize.ready');
  }, []);
  const [index, setIndex] = useState(0);

  const handleTabChange = () => {
    setIndex(1);
  };

  const handleToggle = ({ index: labelIndex }) => {
    setIndex(labelIndex);
  };
  const tabLabels = ['Paint Calculator', 'Easy Estimator'];
  const introPane = (
    <>
      <Image
        className={cx('bucket-image')}
        src="https://assets.thdstatic.com/images/v1/paint/1-gallon-bucket.svg"
        alt="One Gallon Paint Bucket"
      />
      <div className={cx('instructions')}>
        Enter your room&apos;s measurements to get your results or check out the&nbsp;
        <Button
          inline
          onClick={handleTabChange}
          link
          className={cx('easy-estimate-link')}
          tag="a"
        >
          Easy Estimator
        </Button>&nbsp;for a more
        general estimate.
      </div>
    </>
  );

  const errorMessage = 'One or more fields have invalid input(s). Unable to calculate.';

  return (
    <div className={cx('container-size')} data-component="PaintModalContentContainerSize">
      <Heading
        title="Calculate or Estimate"
        className={cx('container-size__heading')}
      />
      <div className={cx('container-size__body')}>
        <Col sm="12" md="12" lg="12">
          <Tabs value={index} onChange={handleToggle} className="tab-group__wrapper">
            {tabLabels.map((label, labelIndex) => {
              return (
                <Tab
                  key={labelIndex}
                  label={label}
                  className="tab-group__item"
                />
              );
            })}
          </Tabs>
          {index === 0 && (
            <Col>
              <WallAreaCalculator
                className={cx('calculate-size-container')}
                errorMessage={errorMessage}
                introPane={introPane}
                disclaimer={calculateSize.disclaimer}
              />
            </Col>
          )}
          {index === 1 && (
            <Col>
              <EasyEstimator />
            </Col>
          )}
        </Col>
      </div>
    </div>
  );
};

ContainerSize.displayName = 'ContainerSize';
