import React, { useContext, useEffect, useState } from 'react';
import { Col } from '@thd-olt-component-react/core-ui';
import { SuperSku, SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductHeader } from '../product-header/ProductHeader';
import { CheckoutSection } from './checkout-section';
import { ProductImage } from '../product-image/ProductImage';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import './product-horizontal.style.scss';

export const ProductHorizontalDesktop = () => {
  const {
    itemId,
    onFavoriteChange,
    onChangeSuperSku,
    onHoverSuperSku,
    isHovered,
    previousItemId,
    storeId,
    showSSKUMiniSwatches,
    isHDQCSku
  } = useContext(ProductPodContext);

  const superSkuItemId = isHovered ? previousItemId : itemId;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Col xs="3">
        <ProductFavorites
          className="product-pod__favorite product-pod__favorite--top-right"
          position="topRight"
          showCircle
          onFavoriteChange={onFavoriteChange}
        />
        <ProductImage className="product-image" />
      </Col>
      <Col xs="6" className="title-rating" nopadding>
        <ProductHeader brand="hidden" collection="hidden" />
        {mounted && showSSKUMiniSwatches
          ? (
            <SuperSkuMiniSwatches
              className="product-options"
              itemId={superSkuItemId}
              storeId={storeId}
              onChange={onChangeSuperSku}
              onHover={onHoverSuperSku}
            />
          )
          : (
            <SuperSku
              className="product-options"
              itemId={superSkuItemId}
              onChange={onChangeSuperSku}
              onHover={onHoverSuperSku}
            />
          )}
      </Col>
      <Col className="checkout-section" xs="3">
        <ProductRating />
        { isHDQCSku ? (
          <ProductQuoteCenter />
        ) : (
          <CheckoutSection onFavoriteChange={onFavoriteChange} />
        )}
      </Col>
    </>
  );
};

ProductHorizontalDesktop.displayName = 'ProductHorizontalDesktop';
