import React, { useContext } from 'react';
import { shape, func, string } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import {
  getLocations,
  getPickupService,
  isSTH,
  productShape
} from '../../helpers/pod-fulfillment-utils';
import { getPickupStoreName, isMobile } from '../../../components/helper/utils';

export const CheckNearByStore = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { product, onCheckNearbyStoresClick, nearbyStoreName, excludedState } = props;
  const { storeId } = useStore();
  const backupStoreName = useStore()?.storeName;
  const storeName = getPickupStoreName(product) || backupStoreName;

  let host = 'https://www.homedepot.com';

  if (typeof window !== 'undefined') {
    host = window.location.origin.match(/local/g)
      ? 'https://www.homedepot.com'
      : window.location.origin;
  }

  const cartReqParams = {
    itemId: product.identifiers.itemId,
    host,
    keyword: storeId,
    channel,
    isShipToHomeEligible: isSTH(product),
    alterBrowserHistory: true
  };

  const excludedStateMwLinkCss = (excludedState && isMobile(channel)) ? 'store__mw-link' : '';
  const linkText = excludedState || nearbyStoreName || storeName;

  return (
    <span className="store__link">
      {/* eslint-disable-next-line */}
      <a
        className={'u__default-link store__store-name ' + excludedStateMwLinkCss}
        onClick={(event) => {
          if (onCheckNearbyStoresClick) {
            onCheckNearbyStoresClick();
          }
          window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
            cartReqParams
          });
        }}
      >
        {linkText}
      </a>
    </span>
  );
};

CheckNearByStore.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  nearbyStoreName: string,
  excludedState: string,
};

CheckNearByStore.defaultProps = {
  onCheckNearbyStoresClick: () => {},
  nearbyStoreName: null,
  excludedState: '',
};
