import { params,
  arrayOf as arrayOfType,
  shape as shapeType,
  string as stringType } from '@thd-nucleus/data-sources';

export const dataModel = {
  searchModel: params({
    keyword: stringType()
  }).shape({
    id: stringType(),
    orangeGraph: shapeType({
      universalSearchArray: arrayOfType({
        pods: arrayOfType({
          title: stringType(),
          description: stringType(),
          imageUrl: stringType(),
          link: stringType(),
          recordType: stringType()
        })
      }),
      intents: arrayOfType(stringType()),
      productTypes: arrayOfType(stringType())
    }),
    searchReport: shapeType({
      correctedKeyword: stringType()
    })
  })
};
