import React from 'react';
import { string } from 'prop-types';
import { InformationModal } from '@thd-olt-component-react/information-modal';
import { PaintModalContent } from '@thd-olt-component-react/paint-modal-content';
import { publish } from '../../publisher';
import './attribute-label.scss';

export const AttributeLabelModal = (props) => {
  const {
    attributeName,
    hexColor,
    brandKey,
    type
  } = props;

  const onModalClick = () => {
    const labels = {
      'Paint Type': 'Paint Types',
      Sheen: 'Sheen Options',
      'Brand Name': 'Brand Options',
      'Container Size': 'Calculate or Estimate'
    };
    const data = {
      target: 'Help me choose',
      component: labels[attributeName],
      section: 'super-sku'
    };

    publish('click-help-me-choose', data);
  };

  const paintType = attributeName === 'Paint Type';
  const sheen = attributeName === 'Sheen';
  const brand = attributeName === 'Brand Name';
  const size = attributeName === 'Container Size';

  const overlayProps = {
    fit: paintType || brand,
    medium: sheen || size
  };

  return (
    <>
      <InformationModal
        linkTitle="Help Me Choose"
        onClick={onModalClick}
        overlayProps={overlayProps}
      >
        { paintType && <PaintModalContent.PaintType /> }
        { brand && <PaintModalContent.Brand brandKey={brandKey} type={type} /> }
        { sheen && <PaintModalContent.Sheen colorHex={`#${hexColor}`} /> }
        { size && <PaintModalContent.ContainerSize /> }
      </InformationModal>
    </>
  );
};

AttributeLabelModal.displayName = 'AttributeLabelModal';

AttributeLabelModal.propTypes = {
  attributeName: string.isRequired,
  brandKey: string,
  hexColor: string,
  type: string,
};

AttributeLabelModal.defaultProps = {
  brandKey: '',
  hexColor: '',
  type: ''
};
