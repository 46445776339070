import React, { memo } from 'react';
import { bool, string, shape, number } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { click } from './events';
import { useImpression } from '@thd-olt-component-react/impression';
import './zone-card.scss';

const RentalZoneCard = ({ major, header, details, componentPosition, category }) => {

  const { title, description: headerDescription } = header;
  const { link, imageUrl } = details;

  const { ref, clickID } = useImpression({
    data: {
      id: "00DEFVALCC00",
      name: 'CategoryCards',
      component: 'CategoryCards',
      position: componentPosition,
      type: 'content',
      category: category
    }
  });

  return (
    <>
      {major
        ? (
          <section className="zone-card__zone1" ref={ref} clickid={clickID}>
            <div className="zone-card__header-wrapper">
              <h2 className="zone-card__header u__bold">{title}</h2>
              <p className="zone-card__header-text">Rent a {headerDescription}</p>
            </div>
            <div className="zone-card-details">
              <div className="zone-card-details__image">
                <Image src={imageUrl} alt="" stretchy width="1" height="1" />
              </div>
              <div className="zone-card-details__description">
                <div className="zone-card-details__logo">Tool Rental</div>
                <div className="zone-card-details__text">
                  Prefer to Rent?
                  <br />
                  Your store may have the right solution!
                </div>
                <div className="zone-card-details__actions">
                  <a
                    className="bttn-outline bttn-outline--primary bttn--inline zone-card-details__btn"
                    href={link}
                    onClick={() => {
                      click({
                        pageNumber: 1,
                        position: 'r1',
                        type: title
                      });
                    }}
                  >
                    <span className="bttn__content">View Rentals</span>
                  </a>
                </div>
              </div>
            </div>
          </section>
        )
        : (
          <section className="zone-card__zone2">
            <div className="zone-card__header-wrapper toolrental-headerwrapper">
              <h2 className="u__truncate zone-card__header u__bold">{title}</h2>
              <p className="zone-card__header-text">{headerDescription}</p>
              <div className="zone-card-details__logo" style={{ marginTop: 10 }}>Tool Rental</div>
            </div>
            <div className="zone-card-details">
              <div className="zone-card-details__image">
                <Image src={imageUrl} alt="" stretchy width="1" height="1" />
              </div>
            </div>
            <div className="zone-card-details__actions">
              <a
                className="bttn-outline bttn-outline--primary bttn--inline zone-card-details__btn"
                href={link}
                onClick={() => {
                  click({
                    pageNumber: 1,
                    position: 'r1',
                    type: title
                  });
                }}
              >
                <span className="bttn__content">View Rentals</span>
              </a>
            </div>
          </section>
        )}
    </>
  );
};

RentalZoneCard.displayName = 'CategoryCards-RentalZoneCard';

RentalZoneCard.propTypes = {
  header: shape({
    title: string,
    description: string
  }).isRequired,
  details: shape({
    imageUrl: string,
    description: string,
    link: string
  }).isRequired,
  major: bool
};

RentalZoneCard.defaultProps = {
  major: false
};

const MemoRentalZoneCard = memo(RentalZoneCard);

export { MemoRentalZoneCard as RentalZoneCard };
