import React, { useState, Suspense } from 'react';
import { Button, Placeholder } from '@thd-olt-component-react/core-ui';
import { bool, string, shape } from 'prop-types';
import {
  alias,
  arrayOf,
  params,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { publish } from './publisher';
import { useSizeGuide } from './useSizeGuide';
import './size-guide.scss';

// eslint-disable-next-line max-len
const SizeGuideOverlayContent = React.lazy(() => import(/* webpackChunkName: "size-guide-overlay" */ './components/size-guide-overlay-content/size-guide-overlay-content'));

export const SizeGuide = (props) => {
  const {
    itemId,
    isCollection,
    isSuperSku,
    productType,
    superSkuSizeAndFitDetail,
    itemSizeAndFitDetail,
    storeIdFromMock,
    channelFromMock
  } = props;

  const [open, setOpen] = useState(false);

  const { data: sizeGuideResponse, loading, error } = useSizeGuide({
    itemId, isCollection, isSuperSku, superSkuSizeAndFitDetail, itemSizeAndFitDetail, storeIdFromMock
  }) || {};

  if (error && !sizeGuideResponse) {
    return null;
  }
  if (loading) {
    return (
      <div style={{ width: '100px' }} data-component="SizeGuidePlaceholder">
        <Placeholder
          type="text"
          className="placeholder-text-row"
          showLoadingAnimation
        />
      </div>
    );
  }

  const handleSizeGuideClick = () => {
    setOpen(true);
    publish('size-guide.click', {});
  };

  if (!sizeGuideResponse) {
    return null;
  }

  return (
    <div className="size-guide-wrapper" data-component="SizeGuide">
      <Button
        link
        onClick={handleSizeGuideClick}
      >
        <span data-testid="size-guide-link">Size Guide</span>
        <span className="size-guide__info" data-testid="info-icon" />
      </Button>
      {open && (
        <Suspense fallback={(
          <div data-component="SizeGuideOverlayContentPlaceholder">
            <Placeholder
              type="text"
              className="placeholder-text-row"
              showLoadingAnimation
            />
          </div>
        )}
        >
          <SizeGuideOverlayContent
            productType={productType}
            sizeGuideResponse={sizeGuideResponse}
            channelFromMock={channelFromMock}
            onClose={() => setOpen(false)}
            open={open}
          />
        </Suspense>
      )}
    </div>
  );
};

SizeGuide.displayName = 'SizeGuide';

SizeGuide.propTypes = {
  channelFromMock: string,
  isCollection: bool,
  isSuperSku: bool,
  itemId: string.isRequired,
  itemSizeAndFitDetail: shape({}),
  productType: string,
  superSkuSizeAndFitDetail: shape({}),
  storeIdFromMock: string
};

SizeGuide.defaultProps = {
  channelFromMock: null,
  isCollection: false,
  isSuperSku: false,
  itemSizeAndFitDetail: {},
  productType: null,
  superSkuSizeAndFitDetail: {},
  storeIdFromMock: null
};

SizeGuide.dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    sizeAndFitDetail: shapeType({
      attributeGroups: arrayOf(shapeType({
        dimensionLabel: stringType(),
        attributes: arrayOf(shapeType({
          attributeName: stringType(),
          dimensions: stringType()
        })),
        productType: stringType()
      }))
    })
  }),
  collectionSizeAndFit: alias('collection').params({
    collectionId: stringType().isRequired(), storeId: stringType()
  }).shape({
    sizeAndFitDetail: shapeType({
      collectionName: stringType(),
      attributeGroups: arrayOf(shapeType({
        dimensionLabel: stringType(),
        attributes: arrayOf(shapeType({
          attributeName: stringType(),
          dimensions: stringType()
        })),
        productType: stringType()
      }))
    })
  })
};
