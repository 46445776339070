import React, { useContext, useEffect, useState } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { AutomotiveBanner } from '@thd-olt-component-react/car-picker';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { SubscribeAndSave } from '@thd-olt-component-react/subscribe-and-save';
import classNames from 'classnames';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductBadge } from '../product-badge/ProductBadge';
import { ProductCompare } from '../product-compare/ProductCompare';
import { ProductCustomOptions } from '../product-custom-options/ProductCustomOptions';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductImage } from '../product-image/ProductImage';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductServiceAddOns } from '../product-service-add-ons/ProductServiceAddOns';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import { ProductRemove } from '../product-remove/ProductRemove';
import { ProductSample } from '../product-sample/ProductSample';
import { ProductSeeSimilarItems } from '../product-see-similar-items/ProductSeeSimilarItems';
import { ProductShipping } from '../product-shipping/ProductShipping';
import { ProductSponsored } from '../product-sponsored/ProductSponsored';
import { ProductQuantity } from '../product-quantity/ProductQuantity';
import { shouldShowQuantityBox, isAppliance } from '../../utils';
import { ProductStore } from '../product-store/ProductStore';
import { ProductEditButton } from '../product-edit/ProductEditButton';
import { ProductPodGroupContext } from '../../ProductPodGroupContext';
import { ProductHddcMessage } from '../product-hddc-message/ProductHddcMessage';
import './ProductDefault.style.scss';
import { ProductIdentifier } from '../product-identifier/ProductIdentifier';
import { FULFILLMENT_METHOD_STH } from '../../constants';

export const ProductDefault = () => {
  const {
    automotiveFitmentObject,
    buyMoreSaveMore,
    canonicalUrl,
    channelFromTest,
    comparable,
    customEventObject,
    hideATC,
    hideBadges,
    hideBulkPrice,
    hideFulfillment,
    hideLimitPerOrder,
    hideRating,
    hideShippingInfo,
    hideSimilarItemLink,
    hideShopThisCollection,
    hideSponsored,
    hideStoreInfo,
    hover,
    isHDQCSku,
    isHDDCSku,
    isHovered,
    itemId,
    mobilePodType,
    onChangeSuperSku,
    onEdit,
    onFavoriteChange,
    onHoverSuperSku,
    position,
    previousItemId,
    product,
    publishCustomEvent,
    removable,
    showKPF,
    showSample,
    showModelNumber,
    showItemId,
    showSkuNumber,
    showQuantityBox,
    showSubscribeAndSave,
    showSeeMoreOptions,
    showShippingInfoInHover,
    showStoreInfo,
    staticQuantity,
    storeId,
    swatchCount,
    showCountdownTimer,
    showSimilarItemButton,
    showSimilarLinkInstock,
    showSimilarItem,
    target,
  } = useContext(ProductPodContext);
  const { badges, info, pricing, identifiers } = product || {};
  const { modelNumber } = identifiers || {};
  const hasMultipleOptions = info?.totalNumberOfOptions > 1;
  const promotionEndDate = pricing?.promotion?.dates?.end;
  const { hasSubscription } = info || {};
  const superSkuItemId = isHovered ? previousItemId : itemId;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile' || channelFromTest === 'mobile';
  const mobileDoubleColumn = mobilePodType === 'doubleColumn' && channel === 'mobile';
  const brandStyle = hover ? 'below' : 'inline';
  const isHoverNotMobileDouble = hover && !mobileDoubleColumn;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    handleProductPodExpansion
  } = useContext(ProductPodGroupContext);

  const [subscriptionInfo, setSubscriptionInfo] = useState({ frequency: null, isOptIn: false });

  const showShipping = !hideShippingInfo && !showShippingInfoInHover && !info?.isBrioSku;

  const showHoverBehavior = mounted
    && hover
    && !isMobile;
  const showStore = showStoreInfo && !info?.isBrioSku;
  const { isDiscontinued, isOutOfStockOnline, isSTH } = podFulFillmentUtils;
  const isUnfulfillable = isDiscontinued(product) || isOutOfStockOnline(product);
  const isOutOfStock = !(!showSimilarItem && hideSimilarItemLink) || !handleProductPodExpansion;
  const shouldRenderSubscribeAndSave = mounted && showSubscribeAndSave && hasSubscription && !hover;
  const fulfillmentMethod = isSTH(product) ? FULFILLMENT_METHOD_STH : '';
  const handleSubscription = ({ frequency, isOptIn }) => setSubscriptionInfo({ frequency, isOptIn });
  const shouldPublishCustomEvent = shouldRenderSubscribeAndSave;

  if (shouldPublishCustomEvent) {
    const { component, customEvent } = customEventObject || {};
    if (component && customEvent) publishCustomEvent({ component, customEvent });
  }

  const seeMoreOptionsLinkClasses = classNames('product-pod__see-more-options-link', {
    'product-pod__see-more-options-link--hidden': !hasMultipleOptions
  });

  const fulfillmentWrapperClasses = classNames('product-pod__fulfillment-wrapper', {
    'product-pod__fulfillment-wrapper--single-wrapper': isAppliance(product)
  });

  return (
    <>
      {
        onEdit && (<ProductEditButton onClick={onEdit} />)
      }
      {
        !hideBadges && (
          <div className="product-pod__badge--wrapper" data-testid="product-default-badge">
            <ProductBadge badges={badges} />
          </div>
        )
      }
      <ProductFavorites
        className="product-pod__inline__favorite"
        onFavoriteChange={onFavoriteChange}
        position="topRight"
        showCircle
      />
      <ProductImage buyMoreSaveMore={buyMoreSaveMore} />
      {Boolean(automotiveFitmentObject) && <AutomotiveBanner fitment={automotiveFitmentObject} />}
      <ProductCustomOptions />
      <div className="product-pod--padding">
        {showSeeMoreOptions && (
          <a
            href={canonicalUrl || ''}
            target={target || ''}
            className={seeMoreOptionsLinkClasses}
          >
            See More Options
          </a>
        )}
        {!showSeeMoreOptions && mounted && (
          <SuperSkuMiniSwatches
            itemId={superSkuItemId}
            canonicalUrl={canonicalUrl}
            storeId={storeId}
            onChange={onChangeSuperSku}
            onHover={onHoverSuperSku}
            numberOfMiniSwatches={swatchCount}
          />
        )}
        {showSample && <ProductSample />}
        { !hideSponsored && <ProductSponsored position={position} /> }

        <ProductHeader
          brand={brandStyle}
          disableShopThisCollection={hideShopThisCollection}
        />

        {(((showItemId || showModelNumber || showSkuNumber) && !!modelNumber) || !hideRating) && (
          <div className="ratings-and-model-number-container">
            <div className="product-pod-list__identifiers">
              {showItemId && <ProductIdentifier type="itemId" />}
              {showModelNumber && <ProductIdentifier type="model" />}
              {showSkuNumber && <ProductIdentifier type="sku" />}
            </div>
            <ProductRating />
          </div>
        )}
        {
          staticQuantity && (
            <div className="qty-text-wrapper">
              <ProductQuantity textOnly showQtyText smallText showQtyValueText />
            </div>
          )
        }
        {isHDQCSku ? (
          <ProductQuoteCenter />
        ) : (
          <>
            <ProductServiceAddOns />
            <ProductPrice
              className="product-pod__pricing"
              hideLimitPerOrder={hideLimitPerOrder}
              large={false}
              hideBulkPrice={hideBulkPrice}
            />
            {(showCountdownTimer && promotionEndDate) && (
              <div className="product-pod__countdown-timer">
                <CountdownTimer
                  endDate={promotionEndDate}
                  shouldIncludeSvg
                  shouldUseMessagingString
                  showCondensedTimer
                  showOrangeTimerSvg
                />
              </div>
            )}
            {showKPF && (
              <KeyProductFeatures
                hideImage
                storeId={storeId}
                itemId={itemId}
                maxfeatures={5}
                simple
                oneColumnGrid
              />
            )}

            {isHoverNotMobileDouble && !hideFulfillment && (
              <div className={fulfillmentWrapperClasses}>
                {!hideStoreInfo && !isAppliance(product) && <ProductStore checkGeneric />}
                {!hideShippingInfo && <ProductShipping className="product-pod__fulfillment--shipping" />}
              </div>
            )}

            {!mounted && (!hover || isMobile) && <TextBlock rows={6} color="#E0E0E0" />}

            {mounted && hover && !isMobile && showSimilarLinkInstock && <ProductSeeSimilarItems showSimilarInStock />}

            {showHoverBehavior && (
              <div className="product-pod__toggle">
                <div className="product-pod__toggle__wrapper">
                  {!isUnfulfillable ? (
                    <>
                      {mounted && <ProductSeeSimilarItems />}
                      <ProductATC className="product-pod__add-to-cart" outline checkGeneric />
                      {comparable && <ProductCompare />}
                    </>
                  ) : (<ProductSeeSimilarItems />)}
                </div>
              </div>
            )}

            {mounted && mobileDoubleColumn && (
              <>
                {!hideFulfillment && (
                  <>
                    {!hideStoreInfo && <ProductStore checkGeneric />}
                    {!hideShippingInfo && <ProductShipping className="product-pod__fulfillment--shipping" />}
                  </>
                )}
                {<ProductSeeSimilarItems />}
                <>
                  {showSubscribeAndSave && (
                    <div className="product-pod__subscribe-and-save-container">
                      {shouldRenderSubscribeAndSave && (
                        <div className="product-pod__subscribe-and-save-wrapper">
                          <SubscribeAndSave
                            itemId={itemId}
                            fulfillment={fulfillmentMethod}
                            onChange={handleSubscription}
                            lite
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <ProductATC
                    className="product-pod__add-to-cart--mobile"
                    checkGeneric
                    outline={!subscriptionInfo?.isOptIn}
                    subscriptionInfo={subscriptionInfo}
                  />
                </>
                {showSimilarLinkInstock && (
                  <div className="product-pod__similar-instock--link">
                    <ProductSeeSimilarItems showSimilarInStock />
                  </div>
                )}
                {comparable && <ProductCompare />}
                {removable && <ProductRemove />}
              </>
            )}

            {mounted && !mobileDoubleColumn && !hover && (
              <>
                {!hideFulfillment && (
                  <div className={fulfillmentWrapperClasses}>
                    {!hideStoreInfo && !isAppliance(product) && <ProductStore checkGeneric />}
                    {!hideShippingInfo && <ProductShipping className="product-pod__fulfillment--shipping" />}
                  </div>
                )}
                {isHDDCSku && <ProductHddcMessage />}
                {!hideATC && (
                  <div className="product-pod__add-to-cart-wrapper">
                    {<ProductSeeSimilarItems />}
                    {showQuantityBox && !staticQuantity && shouldShowQuantityBox(product) ? (
                      <div className="product-pod__add-to-cart-quantity__wrapper">
                        <ProductQuantity textOnly wideView showQtyText smallText shortHeight noRightMargin />
                        <div className="product-pod__add-to-cart-quantity__add-to-cart-wrapper">
                          <ProductATC
                            className="product-pod__add-to-cart"
                            checkGeneric
                            outline
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {showSubscribeAndSave && (
                          <div className="product-pod__subscribe-and-save-container">
                            {shouldRenderSubscribeAndSave && (
                              <div className="product-pod__subscribe-and-save-wrapper">
                                <SubscribeAndSave
                                  itemId={itemId}
                                  fulfillment={fulfillmentMethod}
                                  onChange={handleSubscription}
                                  lite
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <ProductATC
                          checkGeneric
                          className="product-pod__add-to-cart"
                          outline={!subscriptionInfo?.isOptIn}
                          subscriptionInfo={subscriptionInfo}
                        />
                      </>
                    )}
                  </div>
                )}
                {comparable && showSimilarLinkInstock && (
                  !isOutOfStock ? (
                    <div className="product-pod__similar-instock">
                      <div><ProductCompare /></div>
                      <div><ProductSeeSimilarItems showSimilarInStock /></div>
                    </div>
                  ) : <ProductCompare />
                )}
                {comparable && !showSimilarLinkInstock && (
                  <ProductCompare />
                )}
                {!comparable && showSimilarLinkInstock && (
                  <ProductSeeSimilarItems showSimilarInStock />
                )}
                {removable && <ProductRemove />}
              </>
            )}

          </>
        )}
      </div>
    </>
  );
};

ProductDefault.displayName = 'ProductPodDefault';
