import React from 'react';
import { DefaultShipping } from '../partials/shipping/DefaultShipping';
import { FULFILLMENT_METHODS } from '../../components/constants';
import { getLiveGoodsStock } from '../../components/helper/utils';
import {
  getShippingThreshold,
  isAppliance,
  isBackorderedWithDate,
  isBackordered,
  isBODFS,
  isDiscontinued,
  hasFreeShipping,
  isOutOfStockOnline,
  isSTH,
  shouldShowShippingThreshold,
  meetsApplianceDeliveryThreshold,
  applianceDeliveryThreshold,
  getApplianceInventory,
  getDisplayableInventory,
  getExcludedStateSth,
  isBodfsWithQuantityAvailable,
  isBodfsWithQuantityUnknown,
  isAvailableAppliance,
  isSthOutOfStock,
  isItemGCC
} from './pod-fulfillment-utils';
import { dataModel } from '../../components/dataModel';
import { EmptyShippingMessage } from '../partials/shipping/EmptyShippingMessage';

export const getShippingTemplate = ({ product, customer, isTwoTileEnabledFS, customRender }) => {
  const isGCC = isItemGCC(product);
  const getThresholdShipping = getShippingThreshold(product);

  if (!product || isGCC) {
    return () => (
      <EmptyShippingMessage
        isGCC={isGCC}
        customRender={customRender}
        product={product}
      />
    );
  }

  /* -----LiveGoods----- */
  if (getLiveGoodsStock(product?.fulfillment) === 0) {
    return () => (<EmptyShippingMessage customRender={customRender} product={product} />);
  }

  if (isDiscontinued(product)) {
    return () => (
      <DefaultShipping
        shippingMessage="No longer available"
        customRender={customRender}
        product={product}
      />
    );
  }

  /* -----Appliance----- */
  if (isAppliance(product)) {

    if (isAvailableAppliance(product)) {
      if (meetsApplianceDeliveryThreshold(product)) {
        return () => (
          <DefaultShipping
            iconType="success"
            shippingMessageStyle="shipping__success"
            shippingMessage="Free"
            customRender={customRender}
            applianceQuantity={getApplianceInventory(product)}
            product={product}
          />
        );
      }
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage={`Free for appliance orders $${applianceDeliveryThreshold()}+`}
          customRender={customRender}
          applianceQuantity={getApplianceInventory(product)}
          shippingThreshold={applianceDeliveryThreshold()}
          product={product}
        />
      );
    }
    return () => (<DefaultShipping shippingMessage="Unavailable" customRender={customRender} product={product} />);
  }

  /* -----STH Only----- */
  if (isSTH(product) && !isBODFS(product)) {
    const excludedState = getExcludedStateSth(product);
    if (excludedState) {
      return () => (<DefaultShipping excludedState={excludedState} customRender={customRender} product={product} />);
    }
    if (isBackorderedWithDate(product) || isBackordered(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessage="Standard"
          customRender={customRender}
          isTwoTileEnabledFS={isTwoTileEnabledFS}
          product={product}
        />
      );
    }
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Free"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
        />
      );
    }
    if (getThresholdShipping) {
      let shippingThreshold = shouldShowShippingThreshold(product, getThresholdShipping)
        ? getThresholdShipping : 0;
      let shippingMessage = shippingThreshold ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
        />
      );
    }

    if (isSthOutOfStock(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" customRender={customRender} product={product} />);
    }

    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        customRender={customRender}
        isTwoTileEnabledFS={isTwoTileEnabledFS}
        product={product}
      />
    );
  }

  /* -----BODFS only----- */
  if (isBODFS(product) && !isSTH(product)) {
    // TODO: temporarily bypass same/next-day messaging for covid response
    /*
    if (isSameDayDelivery(product)) {
      return () => (
        <DefaultShipping
          alertType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Same-day"
          displayQuantity={displayQuantity}
        />
      );
    }
    if (isNextDayDelivery(product)) {
      return () => (
        <DefaultShipping
          alertType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Next-day"
          displayQuantity={displayQuantity}
        />
      );
    }
    */
    if (isBodfsWithQuantityUnknown(product) || !isBodfsWithQuantityAvailable(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" customRender={customRender} product={product} />);
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Scheduled"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
        customRender={customRender}
        product={product}
      />
    );
  }

  /* -----STH and BODFS----- */
  if (isBODFS(product) && isSTH(product)) {
    const excludedState = getExcludedStateSth(product);
    if (excludedState) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessage="Scheduled"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
          customRender={customRender}
          product={product}
        />
      );
    }
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage="Free"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
        />
      );
    }
    if (getThresholdShipping) {
      let shippingThreshold = shouldShowShippingThreshold(product, getThresholdShipping)
        ? getThresholdShipping : 0;
      let shippingMessage = shippingThreshold ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="shipping__success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
        />
      );
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        customRender={customRender}
        isTwoTileEnabledFS={isTwoTileEnabledFS}
        product={product}
      />
    );
  }

  /* -----not STH and not BODFS means no delivery to customer directly----- */
  if (!isBODFS() && !isSTH()) {
    if (isDiscontinued(product)) {
      return () => (
        <EmptyShippingMessage
          customRender={customRender}
          product={product}
        />
      );
    }
    if (isOutOfStockOnline(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" customRender={customRender} product={product} />);
    }
    return () => (<DefaultShipping shippingMessage="Unavailable" customRender={customRender} product={product} />);
  }

  return () => (<EmptyShippingMessage customRender={customRender} product={product} />);
};

getShippingTemplate.dataModel = dataModel;
