import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import {
  Paper,
  CardContent,
  CardTitle,
  Image,
} from '@thd-olt-component-react/core-ui';
import CTARenderer from '../CTARenderer/CTARenderer.component';
import moduleStyles from './PromotionalNavigation2AcrossMobile.scss';
import { getVideoId } from '../../helpers/helpers';

const ActionIcon = (
  <Image src="https://assets.thdstatic.com/images/v1/caret-orange.svg" alt="" />
);

const styles = classNames.bind(moduleStyles);

function hasRequiredImageData({ image }) {
  const type = typeof image;

  if (type !== 'object') {
    return null;
  }

  if (
    (image === null || Array.isArray(image) || Object.keys(image).length === 0)
  ) {
    return null;
  }

  /**
   * I've often seen etch responses contain a string
   * with a single space in it instead of the empty string
   * or `null`.
   *
   * We don't want to render a card without an image URL.
   */
  if (!image.src || image.src === ' ') {
    return null;
  }

  return image;
}

function hasRequiredText({ heading }) {
  /**
   * Again, here we are handling the strange edge case where
   * we're often getting strings with a single space in them
   * in the response from Etch / the content query.
   */
  return typeof heading === 'string' && heading.length && heading !== ' ';
}

const PromotionalNavigation2AcrossMobile = (props) => {
  const { opts = {}, channel, schemaName } = props;
  const { cards } = opts;

  const schema = schemaName || null;

  if (!cards.length) return null;

  return (
    <div id={schema} className={styles('promotional-navigation')}>
      {cards.map((card) => {
        if (!hasRequiredImageData(card) || !hasRequiredText(card)) return null;

        const {
          id, link, heading, subheading, image = {}
        } = card;

        /**
         * I've often seen etch responses contain a string
         * with a single space in it instead of the empty string
         * or `null`.
         *
         * We don't want to include an alt text string that contains
         * a space in it. If there's no alt text provided, which
         * this space seems to indicate, we should just make it
         * the empty string.
         */
        let imageAlt = image.alt !== ' ' ? image.alt : '';

        return (
          <Paper key={id} className={styles('card')}>
            <CTARenderer videoStill={image.src} ctaLink={link}>
              <CardContent
                noPadding
              >
                <Image alt={imageAlt} src={image.src} />
              </CardContent>
            </CTARenderer>
            <Link href={link} underline="hover">
              <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>
                {heading}
              </Typography>
              <Typography variant="body-lg" weight="regular" height="snug" color="primary">
                {subheading}
              </Typography>
            </Link>
          </Paper>
        );
      })}
    </div>
  );
};

PromotionalNavigation2AcrossMobile.displayName = 'PromotionalNavigation2AcrossMobile';

PromotionalNavigation2AcrossMobile.propTypes = {
  opts: PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        id: PropTypes.string,
        heading: PropTypes.string,
        subheading: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        schemaName: PropTypes.string
      })
    ),
  }).isRequired,
  channel: PropTypes.string,
  schemaName: PropTypes.string
};

PromotionalNavigation2AcrossMobile.defaultProps = {
  channel: '',
  schemaName: ''
};

export default PromotionalNavigation2AcrossMobile;
