import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { Quantity } from '@thd-olt-component-react/quantity';
import className from 'classnames';
import { ProductPodContext } from '../../ProductPodContext';
import './ProductQuantity.style.scss';

export const ProductQuantity = (props) => {
  const {
    onQuantityChange,
    quantity: editableQuantity,
    podType,
    staticQuantity
  } = useContext(ProductPodContext);
  const quantity = staticQuantity || editableQuantity;
  const {
    showQtyText, textOnly, wideView, shortHeight, smallText, noRightMargin, showQtyValueText, showQtyValueTextWide
  } = props;

  const wrapperClassNames = className(
    'quantity-box__wrapper',
    {
      'quantity-box__list-wrapper': podType === 'list',
      'quantity-box__side-by-side-wrapper': podType === 'sidebyside'
    }
  );

  const inputWrapperClassNames = className(
    'quantity-box__input-wrapper',
    {
      'quantity-box__list-input-wrapper': podType === 'list'
    }
  );

  const quantityValueTextClassNames = className(
    {
      'quantity-box__text-quantity--wide': showQtyValueTextWide
    }
  );

  const quantityTextClassNames = className(
    'quantity-box-text',
    {
      'quantity-box-text--wide': showQtyValueTextWide
    }
  );

  return ((showQtyText ? (
    <div data-testid="quantity-box-wrapper" className={wrapperClassNames}>
      <div className={quantityTextClassNames}>
        <span>Qty: </span>
        {showQtyValueText && (<span className={quantityValueTextClassNames}>{ quantity }</span>)}
      </div>
      {
        !showQtyValueText && (
          <div className={inputWrapperClassNames}>
            <Quantity
              value={quantity}
              onChange={onQuantityChange}
              textOnly={textOnly}
              wideView={wideView}
              smallText={smallText}
              shortHeight={shortHeight}
              noRightMargin={noRightMargin}
            />
          </div>
        )
      }
    </div>
  ) : (
    <div data-testid="quantity-box-wrapper">
      <Quantity
        value={quantity}
        onChange={onQuantityChange}
        shortHeight={shortHeight}
        smallText={smallText}
        textOnly={textOnly}
        noRightMargin={noRightMargin}
        wideView={wideView}
      />
    </div>
  ))
  );
};

ProductQuantity.propTypes = {
  showQtyText: bool,
  showQtyValueText: bool,
  showQtyValueTextWide: bool,
  textOnly: bool,
  wideView: bool,
  shortHeight: bool,
  smallText: bool,
  noRightMargin: bool
};

ProductQuantity.defaultProps = {
  showQtyText: false,
  showQtyValueText: false,
  showQtyValueTextWide: false,
  textOnly: false,
  wideView: false,
  shortHeight: false,
  smallText: false,
  noRightMargin: false
};

ProductQuantity.displayName = 'ProductQuantity';
