import React, { useEffect, useState } from 'react';
import { useStore } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import { getDeliveryZip } from '../../helpers/utils';
import { DeliveryContentDrawerLoader } from './DeliveryContentDrawerLoader';
import { DeliveryDrawer } from './DeliveryDrawer';
import { EXPRESS_DELIVERY, SHIP_TO_HOME } from '../../helpers/constants';
import DeliveryContentDrawerError from './DeliveryContentDrawerError';

export const DeliveryContentDrawer = (props) => {

  const {
    itemId,
    quantity,
    isQuickViewOpen,
    setIsQuickViewOpen,
    channelDemo,
    useLoader, useError
  } = props;

  let {
    storeId,
    zipCode,
  } = props;

  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (!channelDemo) {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width <= 640 || screen.height <= 640) {
        setChannel('Mobile');
      } else {
        setChannel('Desktop');
      }
    }
  }, []);

  useEffect(() => {
    if (channelDemo !== null) {
      setChannel(channelDemo);
    }
  }, [channelDemo]);

  const storeDetails = useStore();
  const { storeZip } = storeDetails;
  if (!storeId) {
    storeId = storeDetails.storeId;
  }
  const productQueryProps = {
    variables: { itemId, storeId },
    ssr: false
  };
  const { data } = useDataModel('clientOnlyProduct', productQueryProps);
  const { value } = data?.product?.pricing || {};
  const deliveryZip = getDeliveryZip();
  zipCode = zipCode || deliveryZip || storeZip;

  const shippingQueryProps = {
    variables: {
      itemId,
      storeId,
      zipCode,
      quantity,
      price: parseInt((value * quantity).toFixed(2), 10)
    }
  };
  const [getShipping, shippingResponse] = useDataModel('shipping', shippingQueryProps, true);
  useEffect(() => {
    if (storeId !== '8119') {
      getShipping();
    }
  }, [value, quantity, storeId, zipCode]);

  if (!isQuickViewOpen) {
    return null;
  }

  if (!shippingResponse?.data || shippingResponse?.loading || useLoader) {
    return (
      <DeliveryContentDrawerLoader
        channel={channel || 'Desktop'}
        setIsQuickViewOpen={setIsQuickViewOpen}
      />
    );
  }

  if ((shippingResponse?.error && !shippingResponse?.data)
    || shippingResponse?.data?.shipping?.shipModes?.length === 0 || useError) {
    return (
      <DeliveryContentDrawerError
        channel={channel || 'Desktop'}
        setIsQuickViewOpen={setIsQuickViewOpen}
      />
    );
  }

  const shippingModel = shippingResponse?.data?.shipping;

  const sthServices = shippingModel?.services.filter((service) => {
    return service?.type?.toLowerCase() === SHIP_TO_HOME;
  });
  const bodfsService = shippingModel?.services.filter((service) => {
    return service?.type?.toLowerCase() === EXPRESS_DELIVERY;
  });

  return (
    <DeliveryDrawer
      sthServices={sthServices}
      bodfsService={bodfsService}
      isQuickViewOpen={isQuickViewOpen}
      setIsQuickViewOpen={setIsQuickViewOpen}
      channel={channel || 'Desktop'}
    />
  );
};

DeliveryContentDrawer.propTypes = {
  zipCode: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  isQuickViewOpen: PropTypes.bool,
  setIsQuickViewOpen: PropTypes.func,
  channelDemo: PropTypes.string,
  useLoader: PropTypes.bool,
  useError: PropTypes.bool
};

DeliveryContentDrawer.defaultProps = {
  isQuickViewOpen: false,
  setIsQuickViewOpen: () => {
  },
  channelDemo: null,
  useLoader: false,
  useError: false,
};
