import React from 'react';
import { string, number } from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import { ACTION, RENTAL, RENTAL_INTENT_POD_CHECK_AVAILABILITY } from '../../util/constants';
import { publish } from '../../analytics';
import styles from '../../styles/rental-intent-pod.module.scss';

const RentalIntentPodDesktop = ({
  index,
  imageUrl,
  link,
  title,
  description
}) => {

  const onClick = ({
    podPosition,
    podInteractionNumber
  }) => {
    publish(RENTAL_INTENT_POD_CHECK_AVAILABILITY, {
      podType: RENTAL,
      podAction: ACTION,
      podInteractionNumber,
      podPosition
    });
  };

  return (
    <div className={classNames(styles['rental-intent-pod'])}>
      <div className={classNames(styles['rental-intent-pod__imageWrapper'])}>
        <Image
          stretchy
          className={classNames(styles['rental-intent-pod__image'])}
          alt={title}
          src={imageUrl}
        />
      </div>
      <div className={classNames(styles['rental-intent-pod__contentWrapper'])}>
        <div className={classNames(styles['rental-intent-pod__content'])}>
          <div className={classNames(styles['rental-intent-pod__title'])}>{title}</div>
          <div className={classNames(styles['rental-intent-pod__description'])}>{description}</div>
        </div>
        <a
          className="bttn-outline bttn-outline--primary"
          href={link}
          onClick={() => {
            onClick({
              podPosition: `r${index + 1}`,
              podInteractionNumber: '1',
            });
          }}
        >
          <span className="bttn__content">Check Availability</span>
        </a>
      </div>
    </div>
  );
};

RentalIntentPodDesktop.displayName = 'RentalIntentPod';

RentalIntentPodDesktop.propTypes = {
  index: number,
  imageUrl: string,
  link: string,
  title: string,
  description: string
};

RentalIntentPodDesktop.defaultProps = {
  index: 0,
  imageUrl: '',
  link: '',
  title: '',
  description: ''
};

export { RentalIntentPodDesktop };
