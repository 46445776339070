import React from 'react';
import classNames from 'classnames/bind';
import {
  string, arrayOf, shape, bool, func, oneOfType, number
} from 'prop-types';
import styles from '../../styles/primary-filters-drawer.scss';

const DrawerRefinement = ({ refinement, dimension, onClick }) => {
  const cx = classNames.bind(styles);
  const handleOnClick = () => {
    onClick({ refinement, dimension });
  };

  return (
    <button
      type="button"
      className={cx('primary-filters-drawer__rerfinement')}
      onClick={handleOnClick}
    >
      {refinement.label}
    </button>
  );
};

DrawerRefinement.displayName = 'DrawerRefinement';
DrawerRefinement.propTypes = {
  dimension: shape({
    dimensionId: string,
    label: string,
    refinements: arrayOf(
      shape({})
    ),
  }).isRequired,
  refinement: shape({
    label: string,
    recordCount: oneOfType([string, number]),
    refinementKey: string,
    selected: bool,
    url: string
  }).isRequired,
  onClick: func.isRequired
};

export { DrawerRefinement };