import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search } from '@one-thd/sui-icons';
import { Link } from '~/@one-thd/sui-atomic-components';
import { CustomCatalogButton } from './subcomponets/CustomCatalogButton';
import { SuggestionsContainer } from './subcomponets/SuggestionsContainer';
import {
  CHECK_SPELLING_TITLE,
  CONTACT,
  EXACT_PHRASE_MOBILE,
  EXACT_PHRASE_TITLE,
  GET_HELP,
  LOCAL_STORE,
  ONLINE_ORDER_SUPPORT,
  REFINE_SEARCH,
  SHOP_CUSTOM_PRODUCTS,
  TRY_DIFFERENT_WORDS_TITLE
} from './constants';

const NoResultsFoundBanner = ({ keyword, cpoOnly, onCpoClick }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('no-results-found-banner.ready'); }, []);
  return (
    <>
      <div className="sui-grid sui-grid-cols-12 sui-gap-5" data-component="NoResultsFoundBanner">
        <div className="sui-col-span-12 sui-font-regular sui-text-lg md:sui-text-3xl">
          <div className="sui-flex sui-flex-col sui-items-center sui-my-4">
            <div
              className=" sui-w-full
              sui-flex sui-flex-col sui-justify-center md:sui-flex-row sui-text-4xl sui-leading-tight sui-font-normal
              "
            >
              <span className="sui-flex sui-gap-2 sui-justify-center sui-items-center">
                <Search size="large" />
                Hmm...we couldn&apos;t find&nbsp;
              </span>
              <span className="sui-font-w-bold sui-break-words sui-text-center">
                &quot;{keyword}&quot;
              </span>
            </div>
            {cpoOnly && (
              <CustomCatalogButton value="false" onCpoClick={onCpoClick} title={SHOP_CUSTOM_PRODUCTS} />
            )}
          </div>
        </div>
      </div>
      <div className="sui-flex sui-w-full sui-justify-center sui-flex-col md:sui-flex-row sui-pb-4 md:sui-pb-0">
        <SuggestionsContainer header={REFINE_SEARCH}>
          {TRY_DIFFERENT_WORDS_TITLE}
          {CHECK_SPELLING_TITLE}
          {`${EXACT_PHRASE_TITLE} ${EXACT_PHRASE_MOBILE}`}
        </SuggestionsContainer>
        <SuggestionsContainer header={GET_HELP}>
          <>
            <Link href="https://www.homedepot.com/l/search/">
              {CONTACT}
            </Link> {LOCAL_STORE}
          </>
          {ONLINE_ORDER_SUPPORT}
        </SuggestionsContainer>
      </div>
    </>
  );
};

NoResultsFoundBanner.displayName = 'NoResultsFoundBanner';
NoResultsFoundBanner.propTypes = {
  keyword: PropTypes.string.isRequired,
  cpoOnly: PropTypes.bool,
  onCpoClick: PropTypes.func,
};

NoResultsFoundBanner.defaultProps = {
  cpoOnly: false,
  onCpoClick: () => {},
};

export { NoResultsFoundBanner };
