import {
  arrayOf,
  bool,
  element,
  func,
  oneOfType,
  string,
  number,
} from 'prop-types';
import React, {
  createContext,
  useEffect,
  useState,
} from 'react';
import styles from './drawer.module.scss';
import { DrawerPosition } from './drawer-utils/DrawerPosition';
import { getDrawerPositionStyles } from './drawer-utils/utility';

export const DrawerContext = createContext({});

const Drawer = ({
  open,
  position,
  onClose,
  maxSize,
  children,
  initialItem,
  hideMask,
  isMobile,
  zIndex,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(open);
  const [drawerRoute, setDrawerRoute] = useState([initialItem]);
  const [sharedDrawer, setSharedDrawer] = useState({});

  /**
   * Helps to toggle the mask
   * Mask is displayed based on the hideMask prop
   */
  const toggleMask = () => {
    if (!hideMask) {
      // Checking if the mask exists
      // classList contains
      const existingMask = document.body.querySelector(`.${styles['drawer-mask']}`);
      const drawers = document.body.querySelectorAll('.thd-sliding-drawer');
      let isAnyDrawerOpen = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const drawer of drawers) {
        isAnyDrawerOpen = (drawer.style[position] === '0px');
        if (isAnyDrawerOpen) {
          break;
        }
      }
      if (!existingMask) {
        if (isAnyDrawerOpen) {
          // Adding the mask to body to cover the entire document
          const maskEl = document.createElement('div');
          maskEl.classList.add(styles['drawer-mask']);
          maskEl.setAttribute('data-testid', 'drawer-mask');
          maskEl.style.zIndex = (zIndex - 1);
          /* eslint-disable no-use-before-define */
          maskEl.addEventListener('click', (event) => {
            window.LIFE_CYCLE_EVENT_BUS.trigger('drawer.close');
          });
          document.body.appendChild(maskEl);
        }
      } else if (!isAnyDrawerOpen) {
        // Remove the mask
        document.body.removeChild(existingMask);
      }
    }
  };

  /**
   * Used for closing the entire drawer
   * This is also available in useDrawer hook for children to close
   * the drawer based on the business logic
   * @param {*} event
   */
  const closeDrawer = (event, fromCloseButton) => {
    if (event) {
      event.preventDefault();
    }
    document.body.style.overflow = 'auto';
    setIsDrawerOpen(false);
    if (onClose) {
      onClose(fromCloseButton);
    }
  };

  /**
   * Enabled navigation between the drawers.
   * Children can invoke this method from the useDrawer hook and navigate.
   * @param {*} drawer - This is the name of the drawer item
   */
  const setCurrentDrawer = (drawer) => {
    // This is to address when the re-render happens
    if (drawerRoute[drawerRoute.length - 1] !== drawer) {
      const newDrawerRoute = [...drawerRoute];
      newDrawerRoute.push(drawer);
      setDrawerRoute(newDrawerRoute);
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('drawer.close', () => {
      closeDrawer(null, true);
    });
  }, []);

  useEffect(() => {
    setIsDrawerOpen(open);
    setCurrentDrawer(initialItem);
    if (open) {
      // Page Scrollbar is removed for the drawer
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open, initialItem]);

  useEffect(() => {
    toggleMask();
  }, [isDrawerOpen]);

  useEffect(() => {
    window.addEventListener('orientationchange', closeDrawer);
  }, [initialItem]);

  // clear junk on unmount
  useEffect(() => {
    return () => {
      window.removeEventListener('orientationchange', closeDrawer);
      const existingMask = document.body.querySelector(`.${styles['drawer-mask']}`);
      if (existingMask) {
        document.body.removeChild(existingMask);
      }
      document.body.style.overflow = 'auto';
    };
  }, []);

  /**
   * This method will be invoked by the children to pass
   * data between the drawers. This is exposed using
   * the hook useDrawer
   * @param {*} data
   */
  const setSharedState = (data) => {
    const newState = {
      ...sharedDrawer,
      ...data
    };
    setSharedDrawer(newState);
  };

  /**
   * Method to route back to the previous drawer.
   * This will be used by the default DrawerNav
   */
  const setPreviousDrawer = () => {
    const newDrawerRoute = [...drawerRoute];
    newDrawerRoute.pop();
    setDrawerRoute(newDrawerRoute);
  };

  return (
    typeof window !== 'undefined' && (
      <DrawerContext.Provider
        value={{
          closeDrawer,
          currentDrawer: drawerRoute[drawerRoute.length - 1],
          setCurrentDrawer,
          maxSize,
          position,
          isDrawerOpen,
          isMobile,
          setSharedState,
          setPreviousDrawer
        }}
      >
        <aside
          className={`${styles.drawer} thd-sliding-drawer`}
          style={getDrawerPositionStyles({
            position,
            toggleFlag: isDrawerOpen,
            maxSize,
            isMobile,
            zIndex,
            screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            screenHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
          })}
          data-testid="drawer"
        >
          {children}
        </aside>
      </DrawerContext.Provider>
    ));
};

Drawer.displayName = 'Drawer';

export { Drawer };

Drawer.propTypes = {
  open: bool,
  position: string,
  onClose: func,
  maxSize: number,
  children: oneOfType([
    element,
    arrayOf(element)
  ]).isRequired,
  initialItem: string.isRequired,
  hideMask: bool,
  isMobile: bool,
  zIndex: number,
};

Drawer.defaultProps = {
  open: false,
  position: DrawerPosition.RIGHT,
  onClose: () => {},
  maxSize: 400,
  hideMask: false,
  isMobile: false,
  zIndex: 10001
};
