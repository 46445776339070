import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string } from 'prop-types';
import classnames from 'classnames';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { FulfillmentPodShipping } from '@thd-olt-component-react/fulfillment';
import { extend } from '@thd-nucleus/data-sources';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductShipping = ({ className }) => {
  const {
    isRequestAQuoteEligible,
    isScheduleAMeasureEligible,
    isScheduleAConsultationEligible,
    product
  } = useContext(ProductPodContext);
  const { channel } = useContext(ExperienceContext);

  const shippingClasses = classnames(
    'product-pod__fulfillment--shipping',
    className, {
      'product-pod__fulfillment--shipping--mobile': channel === 'mobile'
    }
  );

  if (isScheduleAMeasureEligible
    || isScheduleAConsultationEligible
    || isRequestAQuoteEligible) {
    return null;
  }
  if (!product?.fulfillment) return <TextBlock rows={2} color="#E0E0E0" className={shippingClasses} />;

  return (
    <div className={shippingClasses}>
      <FulfillmentPodShipping product={product} />
    </div>
  );
};

ProductShipping.propTypes = {
  className: string
};

ProductShipping.defaultProps = {
  className: null
};

ProductShipping.displayName = 'ProductShipping';

ProductShipping.dataModel = extend(
  FulfillmentPodShipping,
);
