import React, { useContext } from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { ProductPodContext } from '../../ProductPodContext';
import { CONFIGURABLE_BLINDS } from '../../constants';
import './product-custom-options.scss';

export const ProductCustomOptions = () => {
  const {
    product: {
      identifiers,
      info
    } = {},
    hover
  } = useContext(ProductPodContext);
  const {
    globalCustomConfigurator = {}
  } = info || {};
  const {
    productType
  } = identifiers || {};

  const customExperience = globalCustomConfigurator?.customExperience;
  const configureProduct = productType === CONFIGURABLE_BLINDS;

  if ((hover && (!customExperience || !configureProduct)) || !configureProduct) return null;

  return (
    <div className="product-pod__customs--wrapper">
      <Image
        className="product-pod__customs--image"
        alt="more-options-icon"
        src="https://assets.thdstatic.com/images/v1/more-options.svg"
        width={42}
        height={31}
      />
      <span> Custom Options Available </span>
    </div>
  );
};

ProductCustomOptions.displayName = 'ProductCustomOptions';
