import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { bool, func } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Button, Image } from '@thd-olt-component-react/core-ui';
import { ProductPodContext } from '../../ProductPodContext';
import styles from './ProductEditButton.module.scss';

const cx = classNames.bind(styles);
export const ProductEditButton = ({ nopadding, onClick }) => {
  const { product } = useContext(ProductPodContext);
  const [asset, setAsset] = useState('pencil-gray.svg');
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const handleButtonLeave = () => {
    if (isMobile) {
      return;
    }
    setAsset('pencil-gray.svg');
  };

  const handleButtonHover = () => {
    if (isMobile) {
      return;
    }
    setAsset('pencil-orange.svg');
  };

  const handleOnEditEvent = ({ output } = {}) => {
    const { active, itemId } = output || {};
    if (itemId !== product?.itemId) {
      return;
    }
    if (active) {
      handleButtonHover();
    } else {
      handleButtonLeave();
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('product-pod.edit', handleOnEditEvent);

    return () => {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('product-pod.edit', handleOnEditEvent);
    };
  });

  const productButtonWrapperClass = cx(
    'product-edit-button',
    {
      'product-edit-button--nopadding': nopadding
    }
  );

  const handleEditClick = () => {
    if (onClick) {
      onClick({ product });
    }
  };

  return (
    <div className={productButtonWrapperClass}>
      <Button
        data-testid="product-pod-edit-button"
        onMouseEnter={handleButtonHover}
        onMouseLeave={handleButtonLeave}
        onClick={handleEditClick}
        link
      >
        <Image alt="Edit" asset={asset} width={24} />
      </Button>

    </div>
  );
};

ProductEditButton.propTypes = {
  nopadding: bool,
  onClick: func
};

ProductEditButton.defaultProps = {
  nopadding: false,
  onClick: null
};

ProductEditButton.displayName = 'ProductEditButton';
