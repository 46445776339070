import React from 'react';
import { formatDate } from '@thd-olt-functional/utils';

const helpers = {
  initializeEndDate: (endDate, hours, minutes) => {
    if (endDate) {
      return new Date(endDate);
    }
    if (hours || minutes) {
      const tempDate = new Date();
      if (hours) {
        tempDate.setHours(tempDate.getHours() + +hours);
      }
      if (minutes) {
        tempDate.setMinutes(tempDate.getMinutes() + +minutes);
      }
      tempDate.setSeconds(59);
      return tempDate;
    }

    // if no endDate, hours, or minutes
    return null;
  },
  hmsToMilliseconds: (tempTime) => {
    const timeValue = tempTime.split(':');
    return 1000 * ((timeValue[0] * 3600) + (timeValue[1] * 60) + (+timeValue[2] || 0));
  },
  convertTo24h: (timeStr) => {
    const time = timeStr.match(/(\d+):(\d+):(\d+)\s?(\w)/);
    let hr = Number(time[1]);
    const mins = Number(time[2]);
    const seconds = Number(time[3]);
    const meridian = time[4].toLowerCase();
    let result;

    if (meridian === 'p' && hr < 12) {
      hr += 12;
    } else if (meridian === 'a' && hr === 12) {
      hr -= 12;
    }
    result = hr + ':' + mins + ':' + seconds;
    return result;
  },
  setAlaskaOffset: (date) => {
    let offSet;
    let alaska = date.toLocaleTimeString('en-US',
      { timeZone: 'America/Anchorage', timeZoneName: 'short' }).split(' ')[2];
    if (alaska === 'AKST') {
      offSet = '-09:00';
    } else { // AKDT
      offSet = '-08:00';
    }
    return offSet;

  },
  convertToDays: (timeToExpiration) => {
    return Math.floor(timeToExpiration / (1000 * 60 * 60 * 24));
  },
  convertToHours: (timeToExpiration) => {
    return Math.floor((timeToExpiration / (1000 * 60 * 60)) % 24);
  },
  convertToMinutes: (timeToExpiration) => {
    return Math.floor((timeToExpiration / 1000 / 60) % 60);
  },
  convertToSeconds: (timeToExpiration) => {
    return Math.floor((timeToExpiration / 1000) % 60);
  },
  calculateSbotdTimeLeft: () => {
    const usaTimeZone = new Date().toLocaleString('en-US', { timeZone: 'America/Anchorage' });
    const usaTime = usaTimeZone.split(',');
    const currentTime = usaTime[1];

    const convertedTime = helpers.convertTo24h(currentTime);
    const dayEndTime = '22:59:59';
    const timeToExpiration = helpers.hmsToMilliseconds(dayEndTime) - helpers.hmsToMilliseconds(convertedTime);

    if (!timeToExpiration || timeToExpiration <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalMilliseconds: 0
      };
    }

    return {
      days: helpers.convertToDays(timeToExpiration),
      hours: helpers.convertToHours(timeToExpiration),
      minutes: helpers.convertToMinutes(timeToExpiration),
      seconds: helpers.convertToSeconds(timeToExpiration),
      totalMilliseconds: timeToExpiration
    };
  },
  calculatePromotionTimeLeft: (promotionEndDate) => {
    const sanitizedPromotionEndDate = promotionEndDate.toISOString().split('T')[0];
    const alaskaEndOfDay = '22:59:59.999';
    const date = new Date();
    const offSet = helpers.setAlaskaOffset(date);
    // Example: 2080-01-27T22:59:59.999-09:00
    const fullEndDateString = `${sanitizedPromotionEndDate}T${alaskaEndOfDay}${offSet}`;
    const timeToExpiration = +new Date(fullEndDateString) - Date.now();

    if (!timeToExpiration || timeToExpiration <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalMilliseconds: 0
      };
    }

    return {
      days: helpers.convertToDays(timeToExpiration),
      hours: helpers.convertToHours(timeToExpiration),
      minutes: helpers.convertToMinutes(timeToExpiration),
      seconds: helpers.convertToSeconds(timeToExpiration),
      totalMilliseconds: timeToExpiration
    };
  },
  getTimeStringDate: (timerEndDate) => {
    return formatDate({ date: timerEndDate, shortMonth: true, dayMonthDateFormat: true });
  },
  getTimeStringDaysHoursMinutes: (timeLeft, shouldShowSecondsRemaining) => {

    const daySuffix = (timeLeft || {}).days === 1 ? ' day' : ' days';
    const hourSuffix = (timeLeft || {}).timerHours === 1 ? ' hr' : ' hours';
    const minuteSuffix = (timeLeft || {}).timerMinutes === 1 ? ' min' : ' mins';
    const secondSuffix = (timeLeft || {}).seconds === 1 ? ' sec' : ' secs';
    const days = timeLeft?.days ? `${timeLeft.days}${(daySuffix)}` : '';
    const timerHours = timeLeft?.timerHours ? ` ${timeLeft.timerHours}${(hourSuffix)}` : '';
    const timerMinutes = timeLeft?.timerMinutes ? ` ${timeLeft.timerMinutes}${(minuteSuffix)}` : '';
    const timerSeconds = shouldShowSecondsRemaining && timeLeft?.seconds ? ` ${timeLeft.seconds}${(secondSuffix)}` : '';

    return `${days}${timerHours}${timerMinutes}${timerSeconds}`;
  },
  getTimeStringDigitalClock: (timeLeft) => {

    let newTimeString = '';

    const { days, timerHours, timerMinutes, seconds } = timeLeft;
    /* eslint-disable max-len */
    newTimeString += `${((days * 24 + timerHours) >= 10 ? days * 24 + timerHours : '0'.concat(timerHours))}`;
    newTimeString += `:${(timerMinutes >= 10 ? timerMinutes : '0'.concat(timerMinutes))}`;
    newTimeString += `:${(seconds >= 10 ? seconds : '0'.concat(seconds))}`;
    /* eslint-enable max-len */
    return newTimeString;
  },
  getTimeStringDaysLeft: (timeLeft) => {
    return `${timeLeft.days}${(timeLeft.days === 1 ? ' day' : ' days')}`;
  },

  getTextBelow: (timeLeft) => {
    const timerDays = timeLeft.days === 0 ? '' : timeLeft.days.toString().padStart(2, '0');
    const timerHours = timeLeft.hours
      .toString()
      .padStart(timeLeft.days > 0 ? 2 : 1, '0');
    const timerMinutes = timeLeft.minutes.toString().padStart(2, '0');
    const timerSeconds = timeLeft.seconds.toString().padStart(2, '0');

    const daysText = timerDays === 1 ? 'Day' : 'Days';
    const hoursText = timeLeft.hours === 1 ? 'Hr' : 'Hrs';
    const minuteText = timeLeft.minutes === 1 ? 'Min' : 'Mins';
    const secondsText = 'Secs';
    return {
      timerDays,
      timerHours,
      timerMinutes,
      timerSeconds,
      daysText,
      hoursText,
      minuteText,
      secondsText,
    };
  },

  getPromotionTimer: (timeLeft) => {
    const timerDays = timeLeft.days === 0 ? '' : timeLeft.days.toString();
    const timerHours = timeLeft.hours.toString().padStart(timeLeft.days > 0 ? 2 : 1, '0');
    const timerMinutes = timeLeft.minutes.toString().padStart(2, '0');
    const timerSeconds = timeLeft.seconds.toString().padStart(2, '0');

    const daysText = <span className="countdown-timer-text">{timerDays === 1 ? 'day : ' : 'days : '}</span>;
    const hoursText = <span className="countdown-timer-text">{timeLeft.hours === 1 ? 'hr : ' : 'hrs : '}</span>;
    const minuteText = (
      <span className="countdown-timer-text">
        {timeLeft.minutes === 1 ? 'min' : 'mins'}{!timerDays ? ' : ' : ''}
      </span>
    );
    const secondsText = <span className="countdown-timer-text">secs</span>;

    const promotionMessage = timeLeft.totalMilliseconds >= 0 && (
      <>
        {timerDays}{timerDays ? daysText : ''}{timerHours}{hoursText}
        {timerMinutes}{minuteText}{!timerDays ? timerSeconds : ''}{!timerDays ? secondsText : ''}
      </>
    );

    return promotionMessage;
  },
  zvalue: (n) => { return (n < 10 ? '0' : '') + n; },
  getSBOTDTimer: (timeLeft) => {

    const hrs = timeLeft.hours + 'hr ';
    const mins = timeLeft.minutes + 'min ';
    const secs = helpers.zvalue(timeLeft.seconds) + 'secs';
    return hrs + mins + secs;
  },
  getCondensedTimer: (timeLeft = {}) => {
    if (timeLeft.days >= 1) {
      const daySuffix = timeLeft.days === 1 ? ' day' : ' days';
      return ` ${timeLeft.days}${daySuffix}`;
    }

    if (timeLeft.timerHours >= 1 && timeLeft.timerMinutes >= 1) {
      const hourSuffix = timeLeft.timerHours === 1 ? ' hr' : ' hrs';
      const minuteSuffix = timeLeft.timerMinutes === 1 ? ' min' : ' mins';
      return ` ${timeLeft.timerHours}${hourSuffix} ${timeLeft.timerMinutes}${minuteSuffix}`;
    }

    if (timeLeft.timerHours >= 1) {
      const hourSuffix = timeLeft.timerHours === 1 ? ' hr' : ' hrs';
      return ` ${timeLeft.timerHours}${hourSuffix}`;
    }

    if (timeLeft.timerMinutes >= 1) {
      const minuteSuffix = timeLeft.timerMinutes === 1 ? ' min' : ' mins';
      return ` ${timeLeft.timerMinutes}${minuteSuffix}`;
    }
    return null;
  }
};

export default helpers;
