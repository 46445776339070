import React, { useContext, useRef } from 'react';
import { string, shape } from 'prop-types';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import {
  useDataModel,
  params,
  extend,
  shape as dsShape,
  string as dsString,
  QueryProvider,
  number as dsNumber,
  bool as dsBool,
  arrayOf as dsArrayOf
} from '@thd-nucleus/data-sources';
import {
  triggerNewRelic,
  getSchema,
  isBrandFpn,
  getSkus,
  triggerNewRelicTimeLog
} from '../../utils/helpers';
import { initImpressionWatchers } from '../../utils/initImpressionWatchers';
import { bannerTypes, FORCE_KEVEL } from '../../constants/constants';
import { newRelicConstants } from '../../constants';
import { buildRevJetBanner } from '../../utils/revjet';
import { setAppId } from '../../utils/queryParamsHelpers';
import './SponsoredInGridBanner.scss';
import { determineAdServer } from '../../utils/utils';

const SponsoredInGridBannerComponent = ({ browserId, pageContext, schema }) => {
  const experienceContext = useContext(ExperienceContext);
  const { mcvisID } = useThdCustomer();
  const { storeId } = useStore({
    varnish: true,
    online: true
  });
  // TODO: Think about kevel migration
  // const forceKevel = useConfigService(FORCE_KEVEL) || false;

  // set variables
  const bannerType = bannerTypes.INGRID;
  const pageType = getSchema({ schema, pageContext });
  const marketingId = browserId || mcvisID || '';
  const schemaId = `${schema}__ingrid`;
  const channel = setAppId({ experienceContext });
  const customerType = experienceContext.customer?.type ? experienceContext.customer.type : 'b2c';
  const adType = newRelicConstants.INGRID_BANNER;
  const category = pageContext?.data?.searchModel?.metadata?.categoryID;
  const keyword = pageContext?.keyword;
  const adServer = 'PIQ';

  const requestConfig = {
    adServer,
    mcvisID,
    browserId,
    schema,
    schemaId,
    experienceContext,
    pageContext,
    bannerType,
  };

  const bannerReceivedRef = useRef(null);
  const skus = getSkus(pageContext);
  const productsToSend = skus && skus?.split(',')?.map((sku) => parseFloat(sku));

  const apiRequestTimestamp = new Date().getTime();
  const { data, loading, error } = useDataModel('sponsored', {
    skip: isBrandFpn({ experienceContext, pageContext }) || pageType === 'cat_sponsored',
    ssr: false,
    variables: {
      bannerType: bannerTypes.INGRID,
      matchProducts: productsToSend.length > 0 ? productsToSend : [],
      keyword,
      adServer: 'PIQ',
      marketingId,
      pageType,
      category,
      customerType,
      channel,
      storeId
    }
  });
  let apiResponseTimestamp;
  const showBanner = !loading && data?.sponsored?.banner;
  if (showBanner && bannerReceivedRef.current !== false) {
    apiResponseTimestamp = new Date().getTime();
    requestConfig.startTime = apiRequestTimestamp;
    bannerReceivedRef.current = !bannerReceivedRef.current;
  }

  if (error) triggerNewRelic(adType, 'BANNER-API-FAILURE');
  if (!loading && !error && !data) triggerNewRelic(adType, 'BANNER-API-204');

  if (bannerReceivedRef.current) {
    initImpressionWatchers();
    triggerNewRelic(adType, 'BANNER-API-200');
    const sponsoredData = data.sponsored;
    buildRevJetBanner({
      data: sponsoredData,
      requestConfig,
      adType,
      useKevelInnervate: false
    });
    const bannerApiAdServer = determineAdServer(data.sponsored);
    // SEND TIMESTAMPS TO CALCULATE API RESPONSE TIME
    triggerNewRelicTimeLog(newRelicConstants.INGRID_BANNER_API_RESPONSE, apiRequestTimestamp, apiResponseTimestamp, bannerApiAdServer);
    // SEND TIME FROM BANNER INITIATED TO BANNER WINNER RECEIVED
    triggerNewRelicTimeLog(newRelicConstants.INGRID_BANNER_AD_INIT_TO_WINNER, apiRequestTimestamp, apiResponseTimestamp, bannerApiAdServer);
  }

  return (showBanner && (
    <div
      data-component="SponsoredInGridBanner"
      className="sponsored_ingrid_banner_container"
    >
      <div id={schemaId} data-testid="sponsored-ingrid-banner" className="sponsored_ingrid_banner" />
    </div>
  ));
};
const propTypes = {
  browserId: string,
  pageContext: shape({
    label: string
  }),
  schema: string
};

const defaultProps = {
  browserId: '',
  pageContext: {},
  schema: ''
};

SponsoredInGridBannerComponent.propTypes = propTypes;
SponsoredInGridBannerComponent.defaultProps = defaultProps;
SponsoredInGridBannerComponent.dataModel = extend({
  sponsored: params({
    bannerType: dsString(),
    adServer: dsString(),
    matchProducts: dsArrayOf(dsNumber({ float: true })),
    keyword: dsString(),
    marketingId: dsString().isRequired(),
    pageType: dsString().isRequired(),
    customerType: dsString(),
    category: dsString(),
    channel: dsString().isRequired(),
    storeId: dsNumber()
  }).shape({
    banner: dsShape({
      bannerURL: dsString(),
      clickthruURL: dsString(),
      onClickBeacon: dsString(),
      clickConversionBeacon: dsString(),
      viewConversionBeacon: dsString(),
      onViewBeacon: dsString(),
      slotId: dsString(),
      campaignId: dsString(),
      placementId: dsString()
    })
  })
});

const QueryProvidedSponsoredInGridBanner = (props) => {

  const skipFn = ({ skip, queryName }) => {
    return skip;
  };

  return (
    <QueryProvider cacheKey="sponsored-ingrid-banner" skip={skipFn}>
      <SponsoredInGridBannerComponent {...props} />
    </QueryProvider>
  );
};
QueryProvidedSponsoredInGridBanner.propTypes = propTypes;

QueryProvidedSponsoredInGridBanner.defaultProps = defaultProps;
QueryProvidedSponsoredInGridBanner.dataModel = extend({}, SponsoredInGridBannerComponent);
const SponsoredInGridBanner = withErrorBoundary(
  withDynamicComponent(
    withHydrator(
      {
        id: 'hydrated-sponsored-ingrid-banner',
        className: 'sponsored-ingrid-banner-hydrator',
        scrollBuffer: 100
      },
      QueryProvidedSponsoredInGridBanner
    )
  )
);
SponsoredInGridBanner.displayName = 'SponsoredInGridBanner';
export { SponsoredInGridBanner };
