import React from 'react';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';

const smSlides = (slides) => slides?.map((group, groupKey) => (
  <div className="sui-flex sui-gap-y-4 sui-flex-col" key={groupKey}>
    {group?.map((card, cardKey) => (
      <div className="capability_card" key={cardKey + card.id}>
        <CapabilityCard
          headline={card.headline}
          richText={card.richTextContent}
          componentId={card.id}
          componentPosition={cardKey + 1}
        />
      </div>
    ))}
  </div>
)
);

export default smSlides;
