import React from 'react';
import PropTypes from 'prop-types';
import './delivery-options-drawer.scss';
import { Drawer, DrawerItem, DrawerNav } from '@thd-olt-component-react/drawer';
import {
  capitalizeFirstLetter,
  formatDateWithDayOfWeek,
  getDeliveryDateAvailability,
  isDeliveryTimelineTodayOrTomorrow
} from '../../helpers/utils';
import { DeliveryOptionCard } from './DeliveryOptionCard';
import {
  BODFS_DELIVERY,
  BUY_ONLINE_DELIVER_FROM_STORE,
  DRAWER_FOOTER,
  DRAWER_HEADER,
  SHIP_TO_HOME,
  STH_DELIVERY
} from '../../helpers/constants';

export const DeliveryDrawer = ({
  sthServices,
  bodfsService,
  isQuickViewOpen,
  setIsQuickViewOpen,
  channel
}) => {

  const sthDate = isDeliveryTimelineTodayOrTomorrow(sthServices[0]?.deliveryTimeline)
    ? capitalizeFirstLetter(getDeliveryDateAvailability(sthServices[0]?.deliveryTimeline)?.toLowerCase())
    : formatDateWithDayOfWeek(sthServices[0]?.deliveryDates?.startDate, sthServices[0]?.deliveryDates?.endDate);

  const bodfsDate = getDeliveryDateAvailability(bodfsService[0]?.deliveryTimeline)?.toLowerCase();

  return (
    <div className={`DeliveryDrawer__drawerWrapper ${channel}`}>
      <Drawer
        open={isQuickViewOpen}
        onClose={() => setIsQuickViewOpen(false)}
        initialItem="delivery-quick-view"
        position="right"
        maxSize={channel === 'Mobile' ? 100 : 500}
        isMobile={channel === 'Mobile'}
      >
        <DrawerItem name="delivery-quick-view">
          <div className={`DeliveryDrawer__wrapper ${channel}`}>
            <DrawerNav>
              <span className={`DeliveryDrawer__header ${channel}`}>{DRAWER_HEADER}</span>
            </DrawerNav>
            <div className={`DeliveryDrawer__divider ${channel}`} />
            <div className={`DeliveryDrawer__body ${channel}`}>

              {sthServices.length > 0 && (
                <div className={`DeliveryDrawer__deliveryType ${channel}`}>
                  <div className={`DeliveryDrawer__deliveryEst ${channel}`}>{STH_DELIVERY}&nbsp;
                    <span className={`DeliveryDrawer__date ${channel}`}>{sthDate}</span>
                  </div>
                  <div className={`DeliveryDrawer__cardsContainer ${channel}`}>
                    {
                      sthServices.map((deliveryOption, i) => {
                        return (
                          <DeliveryOptionCard
                            channel={channel}
                            deliveryType={SHIP_TO_HOME}
                            deliveryOption={deliveryOption}
                            key={i}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              )}

              {bodfsService.length > 0 && (
                <div className={`DeliveryDrawer__deliveryType ${channel}`}>
                  <div className={`DeliveryDrawer__deliveryEst ${channel}`}>{BODFS_DELIVERY}&nbsp;
                    <span
                      className={`DeliveryDrawer__date ${channel}`}
                    >{capitalizeFirstLetter(bodfsDate)}
                    </span>
                  </div>
                  <div className={`DeliveryDrawer__cardsContainer ${channel}`}>
                    {
                      bodfsService.map((deliveryOption, i) => {

                        const todayOrTomorrow = isDeliveryTimelineTodayOrTomorrow(deliveryOption.deliveryTimeline);
                        const dateAvailability = getDeliveryDateAvailability(deliveryOption.deliveryTimeline);

                        return (
                          <DeliveryOptionCard
                            channel={channel}
                            deliveryType={BUY_ONLINE_DELIVER_FROM_STORE}
                            deliveryOption={deliveryOption}
                            isDeliveryTimelineTodayOrTomorrow={todayOrTomorrow}
                            deliveryDateAvailability={dateAvailability}
                            key={i}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              )}

              <div className={`DeliveryDrawer__deliveryFooter ${channel}`}>
                <span className={`DeliveryDrawer__deliveryFooterText ${channel}`}>
                  {DRAWER_FOOTER}
                </span>
              </div>
            </div>
          </div>
        </DrawerItem>
      </Drawer>
    </div>
  );
};

DeliveryDrawer.propTypes = {
  isQuickViewOpen: PropTypes.bool,
  setIsQuickViewOpen: PropTypes.func,
  sthServices: PropTypes.instanceOf(Array).isRequired,
  bodfsService: PropTypes.instanceOf(Array).isRequired,
  channel: PropTypes.string
};

DeliveryDrawer.defaultProps = {
  isQuickViewOpen: false,
  setIsQuickViewOpen: () => {
  },
  channel: null,
};
