import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { ProductPodContext } from '../../ProductPodContext';
import { PRODUCT_RECORD_TYPE, MAX_KEYWORD_LENGTH } from '../../constants';
import './product-see-more-in-department.style.scss';

export const ProductSeeMoreInDepartment = (props) => {
  const { product } = useContext(ProductPodContext);

  let { keyword } = props;
  if (!keyword) {
    return null;
  }

  const { productDepartment, productDepartmentId } = product?.info;
  if (!(productDepartment && productDepartmentId)) {
    return null;
  }

  if (keyword.length > MAX_KEYWORD_LENGTH) {
    keyword = keyword.substring(0, MAX_KEYWORD_LENGTH);
  }
  keyword = encodeURIComponent(keyword);

  const encodedValue = `${parseInt(productDepartmentId, 10).toString(36)}Z${PRODUCT_RECORD_TYPE.toString(36)}`;
  const url = `/b/N-${encodedValue}/Ntt-${keyword}?NCNI-5`;
  const caretAssetUrl = 'https://assets.thdstatic.com/images/v1/caret-orange.svg';

  return (
    <div className="product-see-more-in-department" data-testid="list-pod__see-more-in">
      <div className="product-see-more-in-department__wrapper">
        <a className="product-see-more-in-department__text" href={url}>See More in {productDepartment}</a>
        <Image
          className="product-see-more-in-department__caret"
          src={caretAssetUrl}
          width={9}
          height={15}
          alt="orange caret"
        />
      </div>
    </div>
  );
};

ProductSeeMoreInDepartment.propTypes = {
  keyword: string
};

ProductSeeMoreInDepartment.defaultProps = {
  keyword: null
};

ProductSeeMoreInDepartment.displayName = 'ProductSeeMoreInDepartment';
