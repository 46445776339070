import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography
} from '@one-thd/sui-atomic-components';

import { RichText } from '@thd-olt-component-react/rich-text';
import './content-accordion.scss';

const ContentAccordionRenderer = ({
  componentId,
  componentClass,
  descriptionContent,
  data,
  isLivePreview
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-accordion.ready');
  }, []);

  const { title, subtitle } = data || {};
  return (
    <div className="content-accordion" data-component={componentClass}>
      <Accordion>
        <AccordionHeader aria-controls="accordion1-content" id="accordion1-header">
          <div className="sui-flex-auto sui-my-1 sui-block">
            <Typography
              component="div"
              variant="body-lg"
              weight="bold"
              height="tight"
              data-contentful-entry-id={componentId}
              data-contentful-field-id="title"
            >
              {title}
            </Typography>
            {subtitle && (
              <div className="sui-mt-1">
                <Typography
                  variant="body-base"
                  height="tight"
                  color="subtle"
                  data-contentful-entry-id={componentId}
                  data-contentful-field-id="subtitle"
                >
                  {subtitle}
                </Typography>
              </div>
            )}
          </div>
        </AccordionHeader>
        <AccordionBody
          data-contentful-entry-id={componentId}
          data-contentful-field-id="description"
        >
          <RichText content={descriptionContent} isLivePreview={isLivePreview} />
        </AccordionBody>
      </Accordion>
    </div>
  );
};

ContentAccordionRenderer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired,
  descriptionContent: PropTypes.shape({}),
  data: PropTypes.shape({}),
  isLivePreview: PropTypes.bool,
};

ContentAccordionRenderer.defaultProps = {
  descriptionContent: null,
  data: {},
  isLivePreview: false
};

export { ContentAccordionRenderer };
