/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@one-thd/sui-carousel';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { extend } from '@thd-nucleus/data-sources';
import { CustomProductPod } from './CustomProductPod';
import { dataModel as dpdSearchModel } from '../hooks/dpdDataModel';

const ProductShelfCarousel = ({
  skus,
  channel,
  carouselConfig,
  dpdNewTab,
  storeId,
  storeZip,
  linkType,
  showPlaceholder
}) => {

  if (showPlaceholder) {
    const memoizedTextPlaceholder = useMemo(() => {
      return ([...Array(12)].map((value, idx) => {
        let width = idx % 2 === 0 ? '100%' : '90%';
        return <LoadingPlaceholder
          type="textRow"
          ready={false}
          showLoadingAnimation
          color="#E0E0E0"
          key={idx}
          style={{
            width: width,
            height: '0.7em',
            marginTop: '0.5em'
          }}
        >
          Row Placeholder
        </LoadingPlaceholder>;
      }));
    });

    const productShelfPlaceholder = (
      <Carousel {...carouselConfig} >
        {[...Array(6)].map((value, index) => {
          return (
            <div className="product-shelf__placeholder sui-w-[230px] sui-h-full" key={`idx-${index}`}>
              <LoadingPlaceholder
                type="rect"
                ready={false}
                showLoadingAnimation
                color="#E0E0E0"
                style={{ height: "270px", width: "100%" }}
              >
                Product Shelf Placeholder
              </LoadingPlaceholder>
              {memoizedTextPlaceholder}
            </div>
          );
        })}
      </Carousel>
    );

    return productShelfPlaceholder;
  }

  return (
    <Carousel {...carouselConfig} >
      {skus.map((sku, index) => {
        const { identifiers } = sku;
        const itemId = identifiers?.itemId;
        const key = `shelf-pods-${index}`;
        const { preferredPriceFlag } = sku.pricing;

        if (!itemId) {
          return null;
        }
        return (
          <Fragment key={key} >
            <CustomProductPod
              itemId={itemId}
              storeId={storeId}
              idx={index}
              preferredPriceFlag={preferredPriceFlag}
            />
          </Fragment>
        );
      })}
    </Carousel>
  );
};

ProductShelfCarousel.dataModel = extend(dpdSearchModel, CustomProductPod);

ProductShelfCarousel.propTypes = {
  channel: PropTypes.string,
  dpdNewTab: PropTypes.bool,
  skus: PropTypes.instanceOf(Array),
  linkType: PropTypes.string,
  storeId: PropTypes.string,
  storeZip: PropTypes.string,
  showPlaceholder: PropTypes.bool
};

ProductShelfCarousel.defaultProps = {
  channel: 'DESKTOP',
  dpdNewTab: false,
  skus: [],
  linkType: '_self',
  storeId: undefined,
  storeZip: undefined,
  showPlaceholder: false
};

export default ProductShelfCarousel;
