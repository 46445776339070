import React, { useContext, useState, useEffect } from 'react';
import { Accordion } from '@thd-olt-component-react/accordion';
import { Col } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string } from 'prop-types';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { Promotion } from '@thd-olt-component-react/promotion';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductHeader } from '../product-header/ProductHeader';
import { CheckoutSection } from './checkout-section';
import { ProductImage } from '../product-image/ProductImage';
import { ProductLink } from '../product-link/ProductLink';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import './product-horizontal.style.scss';

export const ProductHorizontalMobile = ({ channelFromTest }) => {
  const {
    itemId,
    onFavoriteChange,
    onHoverSuperSku,
    onChangeSuperSku,
    isHovered,
    previousItemId,
    isHDQCSku,
    hideBulkPrice,
    hideAccordion,
    hideLimitPerOrder,
    hidePromotions,
    brand
  } = useContext(ProductPodContext);

  const {
    store: {
      storeId = '121'
    } = {}
  } = useContext(ExperienceContext);

  const { channel } = useContext(ExperienceContext);
  const superSkuItemId = isHovered ? previousItemId : itemId;

  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Col className="save-to-favorites-wrapper">
        <ProductFavorites showCircle />
      </Col>
      <Col fallback="4" className="product-image-wrapper">
        <Col nopadding>
          <ProductImage />
        </Col>
      </Col>
      <Col fallback="8" className="pod-details">
        { isHDQCSku ? (
          <ProductQuoteCenter />
        ) : (
          <>
            <Col nopadding>
              <ProductHeader
                brand={brand || 'hidden'}
                className="product-title"
                collection="hidden"
              />
            </Col>
            <Col nopadding>
              <ProductRating />
            </Col>
            <Col nopadding>
              <ProductPrice large={false} hideBulkPrice={hideBulkPrice} hideLimitPerOrder={hideLimitPerOrder} />
            </Col>
            { !hidePromotions && (
              <Promotion
                itemId={itemId}
                storeId={storeId}
                channel={channel}
              />
            )}
          </>
        )}
      </Col>
      { !isHDQCSku && !hideAccordion && (
        <Col className="accordian__wrapper">
          <div className="productlink__wrapper__mobile">
            <ProductLink
              text="See Full Details"
              nopadding
            />
          </div>
          <Accordion className="accordion__wrapper--medium">
            <Accordion.Item
              onChange={() => setOpen(true)}
              title="Choose Options"
              labelClass="horizontal-pod choose-options"
              className="accordion__wrapper__itemColor"
            >
              {open && mounted && (
                <>
                  <SuperSku
                    itemId={superSkuItemId}
                    onChange={onChangeSuperSku}
                    onHover={onHoverSuperSku}
                    disableMediaAndAvailability
                  />
                  <CheckoutSection onFavoriteChange={onFavoriteChange} channelFromTest={channelFromTest} />
                </>
              )}
            </Accordion.Item>
          </Accordion>
        </Col>
      )}
    </>
  );
};

ProductHorizontalMobile.displayName = 'ProductHorizontalMobile';

ProductHorizontalMobile.propTypes = {
  channelFromTest: string,
};

ProductHorizontalMobile.defaultProps = {
  channelFromTest: null
};
