import React, { useContext, useEffect, useState } from 'react';
import { string, func, number, bool } from 'prop-types';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  useDataModel,
  useLazyDataModel
} from '@thd-nucleus/data-sources';
import { SuperSkuAttributeValue } from '../super-sku-attribute-value/super-sku-attribute-value';
import { SuperSkuAttribute } from '../super-sku-attribute/super-sku-attribute';
import { MiniSwatchTooltipPerformance } from '../mini-swatch-tooltip/mini-swatch-tooltip';
import { MoreOptions } from '../more-options/more-options';
import {
  NUMBER_OF_MINI_SWATCHES_ON_DESKTOP,
  NUMBER_OF_MINI_SWATCHES_ON_MOBILE,
  NUMBER_OF_ATTRIBUTES_FOR_MORE_OPTIONS
} from '../../statics';
import {
  transformMetadataToSearchNav
} from '../../super-sku-util';
import { publish } from '../../publisher';

export const SuperSkuMiniSwatches = (props) => {
  const {
    channel = 'desktop',
  } = useContext(ExperienceContext);

  const { storeId: defaultStoreId } = useStoreId();
  const {
    itemId,
    onChange,
    onHover,
    numberOfMiniSwatches,
    storeId: storeIdFromProps,
    canonicalUrl,
    center
  } = props;

  const storeId = storeIdFromProps || defaultStoreId;

  const [state, setState] = useState({
    swatches: [],
    parentId: null,
    shouldSkip: false,
    toolTipOpened: false
  });

  const [makeMetadataCall, metadataResponse] = useLazyDataModel('metadata', {
    variables: { parentId: state?.parentId },
    fetchPolicy: 'cache-first',
    dataSource: 'catalog',
    skip: false,
    ssr: false
  });

  useEffect(() => {
    if (state?.parentId && state.toolTipOpened) {
      makeMetadataCall();
    }
  }, [state?.parentId, state.toolTipOpened]);

  const opts = {
    variables: {
      itemId
    },
    fetchPolicy: 'cache-first'
  };
  const { data } = useDataModel('product', opts);

  useEffect(() => {
    const {
      swatches = [],
      totalNumberOfOptions = 0
    } = data?.product?.info || {};
    const { parentId } = data?.product?.identifiers || {};

    if (swatches?.length && !state.shouldSkip) {
      setState({
        ...state,
        swatches,
        totalNumberOfOptions,
        shouldSkip: true,
        parentId
      });
    } else if (totalNumberOfOptions > 0) {
      setState({
        ...state,
        totalNumberOfOptions
      });
    }
  }, [data?.product?.info]);

  useEffect(() => {
    if (metadataResponse?.data?.metadata) {
      const superSkuData = metadataResponse?.data?.metadata;
      if (superSkuData?.attributes) {
        setState({
          ...state,
          swatches: transformMetadataToSearchNav({
            itemId,
            superSkuData,
            currentSwatches: state.swatches
          }),
          superSkuData
        });
      }
    }
  }, [metadataResponse?.data, metadataResponse]);

  let numberOfMiniSwatchesToDisplay = channel === 'desktop'
    ? NUMBER_OF_MINI_SWATCHES_ON_DESKTOP
    : NUMBER_OF_MINI_SWATCHES_ON_MOBILE;

  if (numberOfMiniSwatches) {
    numberOfMiniSwatchesToDisplay = numberOfMiniSwatches;
  }

  const handleAttributeValueClick = (event, clickedItemId) => {
    onChange({ itemId: clickedItemId });
    const newSwatches = state.swatches.map((swatch) => {
      return {
        ...swatch,
        isSelected: swatch?.itemId === clickedItemId
      };
    });
    setState({
      ...state,
      swatches: newSwatches
    });

    publish('click-swatch', {});
    const tippyPopper = document.querySelector('.tippy-popper');
    if (tippyPopper && tippyPopper._tippy) {
      tippyPopper._tippy.hide();
    }
  };

  const handleAttributeValueHover = (event, hoveredItemId) => {
    onHover({
      itemId: hoveredItemId,
      isHovered: true
    });
  };

  const handleResetHover = () => {
    const selectedSwatch = state.swatches.find((swatch) => swatch.isSelected);
    onHover({
      itemId: selectedSwatch?.itemId || itemId,
      isHovered: false
    });
  };

  const onTooltipOpen = () => {
    setState({
      ...state,
      toolTipOpened: true
    });
  };

  const {
    swatches = [],
    totalNumberOfOptions = 0
  } = state;

  if (!swatches?.length || swatches?.length < NUMBER_OF_ATTRIBUTES_FOR_MORE_OPTIONS) {
    return totalNumberOfOptions > NUMBER_OF_ATTRIBUTES_FOR_MORE_OPTIONS && canonicalUrl
      ? <MoreOptions canonicalUrl={canonicalUrl} />
      : null;
  }

  const toDisplay = (swatches || []).slice(0, numberOfMiniSwatchesToDisplay);

  return (
    <SuperSkuAttribute
      // eslint-disable-next-line
      attributeType="swatch"
      // eslint-disable-next-line
      miniSwatch
      // eslint-disable-next-line
      center={center}
      // eslint-disable-next-line
      attributeName="color"
    >
      {(toDisplay || []).map((swatch, index) => {
        const {
          isSelected,
          itemId: swatchItemId,
          label,
          swatchImgUrl
        } = swatch || {};
        if (!swatchImgUrl) return null;
        return (
          <SuperSkuAttributeValue
            attributeType="swatch"
            channel={channel}
            isAvailable
            isSelected={isSelected}
            key={`mini-${index}`}
            miniSwatch
            onClick={(event) => handleAttributeValueClick(event, swatchItemId)}
            onMouseEnter={((event) => handleAttributeValueHover(event, swatchItemId))}
            onMouseLeave={handleResetHover}
            swatchGuid={swatchImgUrl}
            value={label}
          />
        );
      })}
      {totalNumberOfOptions > numberOfMiniSwatchesToDisplay
        && swatches?.length >= numberOfMiniSwatchesToDisplay
        && (
          <MiniSwatchTooltipPerformance
            itemId={itemId}
            canonicalUrl={canonicalUrl}
            handleAttributeValueClick={handleAttributeValueClick}
            handleAttributeValueHover={handleAttributeValueHover}
            handleResetHover={handleResetHover}
            swatches={state.swatches}
            miniSwatch
            channel={channel}
            onTooltipOpen={onTooltipOpen}
            totalNumberOfOptions={totalNumberOfOptions}
          />
        )}
    </SuperSkuAttribute>
  );
};

SuperSkuMiniSwatches.propTypes = {
  itemId: string.isRequired,
  onChange: func.isRequired,
  onHover: func.isRequired,
  numberOfMiniSwatches: number,
  storeId: string.isRequired,
  canonicalUrl: string,
  center: bool
};

SuperSkuMiniSwatches.defaultProps = {
  numberOfMiniSwatches: NUMBER_OF_MINI_SWATCHES_ON_MOBILE,
  canonicalUrl: null,
  center: false
};

SuperSkuMiniSwatches.dataModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      parentId: stringType(),
    }),
    info: shapeType({
      swatches: arrayOfType(shapeType({
        isSelected: boolType(),
        itemId: stringType(),
        label: stringType(),
        swatchImgUrl: stringType(),
        url: stringType(),
        value: stringType(),
      })),
      totalNumberOfOptions: numberType()
    })
  }),
  metadata: params({ parentId: stringType().isRequired() }).shape({
    attributes: arrayOfType(shapeType({
      attributeName: stringType(),
      attributeValues: arrayOfType(shapeType({
        swatchGuid: stringType(),
        value: stringType()
      })),
      isSwatch: boolType()
    })),
    childItemsLookup: arrayOfType(shapeType({
      attributeCombination: stringType(),
      canonicalUrl: stringType(),
      isItemBackOrdered: boolType(),
      itemId: stringType(),
    }))// todo sizeAndFit and superDuperSku
  }) /// dont need for tootip, need for core ssku
};
