import React from 'react';
import { BOSS } from '../partials/store/BOSS';
import { InStock } from '../partials/store/InStock';
import { InStockNearby } from '../partials/store/InStockNearby';
import { LimitedAvailability } from '../partials/store/LimitedAvailability';
import { LimitedAvailabilityNearby } from '../partials/store/LimitedAvailabilityNearby';
import { NotLocalized } from '../partials/store/NotLocalized';
import { NoStoreOption } from '../partials/store/NoStoreOption';
import { LiveGoodsPod } from '../partials/store/LiveGoodsPod';
import { BopisOffMessage } from '../partials/store/BopisOffMessage';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';
import { dataModel } from '../../components/dataModel';
import {
  getLiveGoodsStock,
  getLiveGoodsStoreName
} from '../../components/helper/utils';
import { FULFILLMENT_METHODS } from '../../components/constants';
import {
  isBOPIS,
  isBOSS,
  isBrowseOnly,
  isBuyInStore,
  isDiscontinued,
  isLimitedStock,
  isInStockYourStore,
  isInStockNearby,
  isLimitedStockNearby,
  isOutOfStockYourStore,
  isAppliance,
  isOutOfStockBoss,
  isOutOfStockOnline,
  getDisplayableInventory,
  getExcludedStateBoss,
  isItemGCC,
  getSellableQuantityYourStore
} from './pod-fulfillment-utils';

export const getStoreTemplate = ({
  product, storeId, customer, isTwoTileEnabledFS, customRender
}) => {
  const isGCC = isItemGCC(product);
  const liveGoodsStock = getLiveGoodsStock(product?.fulfillment);
  if (!product || isGCC) {
    return () => (
      <EmptyStoreMessage isGCC={isGCC} customRender={customRender} product={product} />);
  }

  if (liveGoodsStock === 0) {
    return () => (
      <LiveGoodsPod
        storeName={getLiveGoodsStoreName(product)}
        seasonStatusEligible={product.fulfillment?.seasonStatusEligible}
        customRender={customRender}
      />
    );
  } if (liveGoodsStock > 0) {
    return () => (<InStock product={product} customRender={customRender} />);
  }

  if (storeId) {
    /* -----Browse Only----- */
    if (isBrowseOnly(product)) {
      if (isBOPIS(product) && (isTwoTileEnabledFS
        ? (!isOutOfStockYourStore(product) && getSellableQuantityYourStore(product) > 0) : true)) {
        return () => (
          <BopisOffMessage
            product={product}
            isTwoTileEnabledFS={isTwoTileEnabledFS}
            customRender={customRender}
          />
        );
      }
      return () => (
        <LimitedAvailability
          product={product}
          isTwoTileEnabledFS={isTwoTileEnabledFS}
          customRender={customRender}
        />
      );
    }

    /* -----BOPIS----- */
    if (isBOPIS(product)) {
      if (isBuyInStore(product) && !isOutOfStockYourStore(product)
        && (isTwoTileEnabledFS ? getSellableQuantityYourStore(product) > 0 : true)) {
        return () => (
          <BopisOffMessage
            product={product}
            isTwoTileEnabledFS={isTwoTileEnabledFS}
            customRender={customRender}
          />
        );
      }
      if (isLimitedStock(product)) {
        if (isInStockNearby(product)) {
          return () => (
            <InStockNearby
              product={product}
              customRender={customRender}
            />
          );
        }
        return () => (
          <LimitedAvailability
            product={product}
            isTwoTileEnabledFS={isTwoTileEnabledFS}
            customRender={customRender}
          />
        );
      }
      if (isInStockYourStore(product)) { return () => (<InStock product={product} customRender={customRender} />); }
      if (isInStockNearby(product)) { return () => (<InStockNearby product={product} customRender={customRender} />); }
      if (isLimitedStockNearby(product)) {
        return () => (
          <LimitedAvailabilityNearby
            product={product}
            isTwoTileEnabledFS={isTwoTileEnabledFS}
            customRender={customRender}
          />
        );
      }
    }

    /* -----BOSS----- */
    if (isBOSS(product)) {
      const excludedState = getExcludedStateBoss(product);
      if (excludedState) {
        return () => (
          <NoStoreOption
            storeMessage="Not available in "
            excludedState={excludedState}
            enableOverlayLink
            product={product}
            customRender={customRender}
          />
        );
      }
      if (isOutOfStockBoss(product)) {
        return () => (
          <NoStoreOption
            storeMessage="Unavailable"
            enableOverlayLink={false}
            product={product}
            customRender={customRender}
          />
        );
      }
      return () => (
        <BOSS
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BOSS)}
          customRender={customRender}
        />
      );
    }

    /* -----No BOSS or BOPIS----- */
    // @TODO: What is in stock?
    // if (isInStock(product) || hasZeroStock(product) || isLimitedStock(product)) {
    //   /* Not BOPIS but has qtyForSale */
    //   return <LimitedAvailability localizedStore={localizedStore} channel={channel} />;
    // }
    if (isAppliance(product) || isDiscontinued(product)) {
      return () => (<EmptyStoreMessage customRender={customRender} product={product} />);
    }
    if (isOutOfStockYourStore(product)) {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable at "
          enableOverlayLink
          product={product}
          customRender={customRender}
        />
      );
    }
    if (isOutOfStockOnline(product)) {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable"
          enableOverlayLink={false}
          product={product}
          customRender={customRender}
        />
      );
    }
    if (product?.availabilityType?.type === 'Shared') {
      return () => (
        <NoStoreOption
          storeMessage="Unavailable at "
          enableOverlayLink
          product={product}
          customRender={customRender}
        />
      );
    }
    /* Not Sold in Stores */
    return () => (
      <NoStoreOption
        storeMessage="Not sold in stores"
        enableOverlayLink={false}
        product={product}
        customRender={customRender}
      />
    );
  }
  return () => (<NotLocalized customRender={customRender} />);
};

getStoreTemplate.dataModel = dataModel;
