import React, { useContext } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { bool, string, oneOf } from 'prop-types';
import className from 'classnames';
import { ProductPodContext } from '../../ProductPodContext';
import { getCustomUrlWithAnalytics, isBuildAndBuyProduct } from '../../utils';
import { PRODUCT_POD_CLICK, BUILD_AND_BUY } from '../../constants';
import './ProductHeader.style.scss';

export const ProductHeader = (props) => {
  const {
    brand,
    collection,
    large,
    target,
    disableShopThisCollection
  } = props;

  const {
    channel,
    brandMaxLine,
    brandTitleMaxLine,
    hosts,
    hover,
    onHeaderClick,
    pageType,
    podType,
    product,
    publishAnalytics,
    target: contextTarget,
    titleMaxLine,
    onEdit,
    sponsoredValues
  } = useContext(ProductPodContext);

  const CustomHeaderTag = useConfigService('fs:specialBuyHeaderTag') ? 'div' : 'h3';
  const {
    details,
    identifiers,
    info,
    itemId
  } = product || {};

  const {
    collection: collectionObj
  } = details || {};

  const {
    name: collectionName,
    url: collectionUrl
  } = collectionObj || {};

  const {
    canonicalUrl,
    brandName,
    productType = '',
    productLabel
  } = identifiers || {};

  const showBrand = !!brandName && brandName !== 'Unbranded';

  if (!productLabel) return null;

  const isMobile = channel === 'mobile';
  const showShopThisCollectionLink = !disableShopThisCollection && collectionName
    && collectionUrl && collection === 'below' && podType !== 'grouped';
  const onBuildAndBuyImageClick = () => publishAnalytics(PRODUCT_POD_CLICK, BUILD_AND_BUY);
  const checkOnClick = isBuildAndBuyProduct(product, hover) ? onBuildAndBuyImageClick : onHeaderClick;

  const customExperience = info?.globalCustomConfigurator?.customExperience;
  const productURL = getCustomUrlWithAnalytics({
    customExperience,
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    info,
    sponsoredValues
  });
  const headerTarget = isMobile ? '' : target || contextTarget;

  const titleClasses = className('product-pod__title', {
    'product-pod__title__product': brand === 'inline',
    'product-pod__title--large': large,
    'product-pod__title__spacer': isMobile,
    'product-pod__title--full-width': onEdit
  });
  const labelClasses = className('product-pod__title__product', {
    'product-pod__title__product--twoline': pageType === 'dpd' || titleMaxLine === 2,
    'product-pod__title__product--mobiledpd': pageType === 'dpd' && isMobile
  });
  const brandClasses = className('product-pod__title__brand', {
    'product-pod__title__brand--oneline': pageType === 'dpd' || brandMaxLine === 1,
    'product-pod__title__brand--mobiledpd': pageType === 'dpd' && isMobile
  });
  const shopThisCollectionClasses = className('product-pod__title__collection', {
    'product-pod__title__collection--block': brand === 'inline'
      && (podType === 'default' || podType === 'list') && !hover
  });
  const brandTitleClasses = className('product-pod--ie-fix', {
    'product-pod__title-control': !brandTitleMaxLine,
    'product-pod__title--twoline': brandTitleMaxLine === 2,
    'product-pod__title--threeline': brandTitleMaxLine === 3,
    'product-pod__title--fourline': brandTitleMaxLine === 4
  });

  return (
    <>
      <div className={titleClasses}>
        {showBrand && brand === 'above'
          && (
            <p className={brandClasses}>
              <span className="product-pod__title__brand--bold"> {brandName}</span>
            </p>
          )}
        <a
          href={productURL}
          onClick={checkOnClick}
          target={headerTarget}
          className="header product-pod--ie-fix"
        >
          <div className={brandTitleClasses}>
            <CustomHeaderTag className={titleClasses}>
              {showBrand && brand === 'inline'
              && (
                <span className="product-pod__title__brand--bold">
                  {brandName}
                </span>
              )}
              <span className={labelClasses} id={`product-header_title_product-${itemId}`}>
                {productLabel}
              </span>
            </CustomHeaderTag>
          </div>
        </a>
        {showBrand && brand === 'below'
        && (
          <p className={brandClasses}>by
            <span className="product-pod__title__brand--bold"> {brandName}</span>
          </p>
        )}
      </div>
      {showShopThisCollectionLink && (
        <a className={shopThisCollectionClasses} href={collectionUrl} target={target || contextTarget}>
          Shop this Collection
        </a>
      )}
    </>
  );
};

ProductHeader.propTypes = {
  brand: oneOf(['above', 'below', 'hidden', 'inline']),
  collection: oneOf(['below', 'hidden']),
  disableShopThisCollection: bool,
  /** Larger text for title */
  large: bool,
  /** Target can be overridden but takes the context as default */
  target: string
};

ProductHeader.defaultProps = {
  brand: 'below',
  collection: 'below',
  disableShopThisCollection: false,
  large: false,
  target: null
};

ProductHeader.displayName = 'ProductHeader';

export default ProductHeader;
