import React, { useContext } from 'react';
import { string, node } from 'prop-types';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductLink = ({ target, text, children }) => {
  const {
    product: {
      identifiers: {
        canonicalUrl
      } = {}
    } = {},
    onProductLinkClick
  } = useContext(ProductPodContext);

  if (!children && !text) return null;
  return (
    <a
      onClick={(evt) => onProductLinkClick(evt, { canonicalUrl, target })}
      className="product-pod__detail"
      href={canonicalUrl}
      target={target}
    >
      {children || text}
    </a>
  );
};

ProductLink.propTypes = {
  target: string,
  text: string,
  children: node,
};

ProductLink.defaultProps = {
  target: '_self',
  text: null,
  children: null
};

ProductLink.displayName = 'ProductLink';
