import React from 'react';
import { string, bool, func } from 'prop-types';
import { isFunction } from '../../../components/helper/utils';
import { InStockIcon, UnavailableIcon } from '../../helpers/icon-utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';

const LiveGoodsPod = ({ storeName, seasonStatusEligible, customRender }) => {

  const iconSelector = seasonStatusEligible ? InStockIcon : UnavailableIcon;
  const pickupType = seasonStatusEligible ? 'Buy in Store' : 'Pickup';
  const seasonMessage = seasonStatusEligible ? 'In Season' : 'Out of Season';

  if (isFunction(customRender)) {
    return customRender(
      { icon: iconSelector,
        pickUpTemplate: PICKUP_TEMPLATES.LIVE_GOODS_POD,
        values: { pickupType, storeName, message: seasonMessage } });
  }

  return (
    <div className="store__message">
      <div className="store__icon">{iconSelector}</div>
      <div className="store__text-box">
        <div>{pickupType}</div>
        <div className="store__second-line">
          <span className="store__primary">{seasonMessage}</span>
          {storeName && (
            <div className="u__inline">
              <span className="store__primary"> at </span>
              <span className="u__bold">{storeName}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LiveGoodsPod.propTypes = {
  storeName: string,
  seasonStatusEligible: bool,
  customRender: func
};
LiveGoodsPod.defaultProps = {
  storeName: null,
  seasonStatusEligible: false,
  customRender: null
};

export { LiveGoodsPod };
