import React from 'react';
import PropTypes from 'prop-types';
import './timer-num-and-text.scss';

export const TimerNumAndText = ({ timerNums, timerText }) => (
  <div className="timer-num-and-text">
    {timerNums}
    <span className="timer-num-and-text__text">
      {timerText}
    </span>
  </div>
);

TimerNumAndText.displayName = 'TimerNumAndText';

TimerNumAndText.propTypes = {
  /**
   * To show the time number
   */
  timerNums: PropTypes.string.isRequired,
  /**
   * To show the time text
   */
  timerText: PropTypes.string.isRequired
};
