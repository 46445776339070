import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image as AtomicImage } from '@thd-olt-component-react/core-ui';
import './Image.scss';
import * as analytics from '../../analytics';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));
const VideoPlayer = React.lazy(() => (
  import(/* webpackChunkName: "video-player" */ '@thd-olt-component-react/video-player')
).then((module) => ({ default: module.VideoPlayer })));

const ImgDisplay = (props) => {
  if (!props) return null;
  const { options = {}, channel } = props;
  const { height, width, name, alt } = options;
  const imgClass = classNames({
    'img--block': height && width
  });
  return (
    <AtomicImage
      stretchy
      src={((channel === 'desktop') ? options.imageSrc.desktop : options.imageSrc.mobile) || options.imageSrc}
      className={`${options.alignment} ${imgClass}`}
      alt={alt || 'image'}
      title={alt}
      height={height}
      width={width}
      lazy
    />
  );
};

const ImageCompiler = (props) => {
  const { options, channel, id } = props;
  const [overlay, setOverlay] = useState(false);
  const onImageClick = (event) => {
    if (options?.videoID) {
      event.preventDefault();
      setOverlay(true);
    }
    analytics.track('click', {
      eventType: '', widgetId: id, options, component: 'image'
    });
  };
  if (options.URL) {
    return (
      <>
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */}
        <a
          className={classNames({
            triggerOverlay: options.triggerOverlay,
            'tip-Opener': options.isToolTip,
          })}
          data-overlay-type={options.relationship}
          href={(options.URL && options.URL.indexOf('playVideo') === -1) ? options.URL : '#'}
          target={(options.targetBlank) ? '_blank' : ''}
          rel={`${(options.externalLink) ? 'nofollow' : ''}${(options.targetBlank) ? ' noopener noreferrer' : ''}`}
          title={options.alt}
          data-video-id={options.videoID}
          onClick={onImageClick}
        >
          <ImgDisplay options={options} channel={channel} id={id} />
        </a>
        {/* eslint-enable jsx-a11y/control-has-associated-label */}
        {overlay && (
          <Suspense fallback={<div />}>
            <Overlay
              onClose={() => setOverlay(false)}
              open
              fit
              closeButton
              modalClass="etch-widgets__video-overlay__modal"
            >
              <div className="etch-widgets__video-overlay" data-testid="image-video-overlay">
                <VideoPlayer
                  videoId={options?.videoID}
                  borderColor="black"
                  autoPlay
                  className="etch-widgets__video-overlay__player"
                />
              </div>
            </Overlay>
          </Suspense>
        )}
      </>
    );
  }
  return (<ImgDisplay options={options} channel={channel} id={id} />);

};

/**
 * @group Components
 * Displays content image. Can include overlay, link, and video modal
 */

const Image = (props) => {
  const { channel, opts } = props;
  const { options = {}, id } = opts;
  const { imageSrc } = options;
  if (!imageSrc) {
    return null;
  }
  const styles = {
    width: (options.isStretchy) ? '100%' : 'auto',
  };

  return (
    <div className="content_image" style={styles}>
      <ImageCompiler options={options} channel={channel} id={id} />
    </div>
  );
};

ImgDisplay.propTypes = {
  options: PropTypes.shape({
    isStretchy: PropTypes.bool,
    videoID: PropTypes.string,
    externalLink: PropTypes.bool,
    targetBlank: PropTypes.bool,
    URL: PropTypes.string,
    relationship: PropTypes.string,
    isToolTip: PropTypes.bool,
    alt: PropTypes.string,
    spadClass: PropTypes.string,
    alignment: PropTypes.string,
    imageSrc: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string
    })
  }).isRequired,
  channel: PropTypes.string
};

ImgDisplay.defaultProps = {
  channel: 'desktop'
};

ImageCompiler.propTypes = {
  options: PropTypes.shape({
    isStretchy: PropTypes.bool,
    videoID: PropTypes.string,
    externalLink: PropTypes.bool,
    targetBlank: PropTypes.bool,
    URL: PropTypes.string,
    relationship: PropTypes.string,
    isToolTip: PropTypes.bool,
    alt: PropTypes.string,
    spadClass: PropTypes.string,
    triggerOverlay: PropTypes.bool,
  }).isRequired,
  channel: PropTypes.string,
  id: PropTypes.string.isRequired
};

ImageCompiler.defaultProps = {
  channel: 'desktop'
};

Image.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    channel: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  channel: PropTypes.string
};

Image.defaultProps = {
  channel: 'desktop'
};

export default Image;
