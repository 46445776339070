import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useThdCustomer } from '@thd-olt-functional/customer-information';

import { SponsoredSkyscraperBanner } from '@thd-olt-component-react/sponsored-content';
import { LayoutManager, LayoutGroup } from '@thd-olt-component-react/layout-manager';
import BackGroundColor from '../../helpers/backgroundColors';
import BackGroundStyles from '../../helpers/backgroundStyles';
import ContentAlignmentSelector from '../../helpers/contentAlignmentSelector';
import './GridColumn.style.scss';

const styleHelper = new BackGroundStyles();
const colorPicker = new BackGroundColor();
const contentAlignmentSelector = new ContentAlignmentSelector();

const getBackgroundStyle = (bg) => {
  const { image, imageOptions = {} } = bg;

  const isNotNoRepeat = imageOptions.isRepeat ? 'repeat' : 'initial';

  return {
    backgroundImage: image.replace(/"/g, '\''),
    backgroundSize: (imageOptions.isSize_Cover) ? 'cover' : '',
    backgroundRepeat: (imageOptions.isNoRepeat) ? 'no-repeat' : isNotNoRepeat,
    backgroundPosition: imageOptions.isSize_Cover ? 'center' : ''
  };
};

const getColumnSize = (opts, children, channel) => {
  const {
    options,
    isGridColumn1,
    isSectionColumn1,
    isSectionColumn2,
    isSectionColumn11,
    isSectionColumn12
  } = opts;
  let sizeString = '';

  if (channel === 'mobile') {
    if (typeof options.size !== 'undefined') {
      sizeString = [
        (options.size.defaultValue) ? ` col-${options.size.defaultValue}` : ' '
      ].join().replace(/,/g, '');
    }
  } else if (channel === 'desktop') {
    if (typeof options.size !== 'undefined') {
      if (isGridColumn1) {
        sizeString = [
          (options.size.defaultValue) ? ` col__${options.size.defaultValue}` : ' ',
        ].join().replace(/,/g, '');
      } else {
        sizeString = [
          (options.size.defaultValue) ? ` col__${options.size.defaultValue}` : ' ',
          (options.size.xs) ? ` col__${options.size.xs}--xs` : ' ',
          (options.size.sm) ? ` col__${options.size.sm}--sm` : ' ',
          (options.size.md) ? ` col__${options.size.md}--md` : ' ',
          (options.size.lg) ? ` col__${options.size.lg}--lg` : ' ',
        ].join().replace(/,/g, '');
      }
    }
  }
  return sizeString;
};
/**
 * @group Layout
 * Displays column component
 */

const GridColumn = (props) => {
  const {
    opts,
    children,
    channel,
    isCategory,
    bannerData = {},
  } = props;
  const { options, isSectionColumn11 } = opts;
  const thdCustomer = useThdCustomer();

  const isMobile = channel === 'mobile';
  if (isMobile && options.hideMobile) return null;

  const background = options.background || {};
  const styles = background.image ? getBackgroundStyle(background) : {};
  const columnSize = getColumnSize(opts, children, channel);
  const columnAlignment = contentAlignmentSelector.get(options.contentAlignment);
  const backgroundColor = background.color ? `${background.color}-bg` : '';
  let bgColor = (background.bgPicker) ? colorPicker.get(background.bgPicker) : backgroundColor;
  const isRail = opts?.options?.rail;
  const showBanner = !isMobile && isRail && bannerData?.isCategory;
  const gridClass = classNames(
    'grid-column',
    columnSize,
    {
      spad: options.spad,
      alpha: options.alpha,
      omega: options.omega,
      bottomBorder: options.bottomBorder,
      'Vertical-Align--center': (!options.contentAlignment && options.vAlignCenter),
      'no-children': children.length > 0 && children[0].props.opts.type === 'guidedNavigationSlot',
    },
    bgColor,
    columnAlignment,
    'recursive-content'
  );

  return (
    <div
      className={gridClass}
      style={styles}
      id={opts.id}
    >
      {children}
      {showBanner && (
        <LayoutManager name="sponsored-skyscraper-banner-wrapper" useContextForProps>
          <LayoutGroup name="sponsored-skyscraper-banner">
            <div className="content column clp-skyscraper-banner">
              <SponsoredSkyscraperBanner
                browserId={thdCustomer?.mcvisID || ''}
                pageContext={{ label: 'browse-search', ...bannerData }}
              />
            </div>
          </LayoutGroup>
        </LayoutManager>
      )}
    </div>
  );
};

GridColumn.propTypes = {
  bannerData: PropTypes.shape({}),
  children: PropTypes.instanceOf(Array),
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  isCategory: PropTypes.bool,
  opts: PropTypes.shape({
    id: PropTypes.string,
    /* This name comes from Etch */
    isSectionColumn11: PropTypes.bool,
    options: PropTypes.shape({
      hideMobile: PropTypes.bool,
      background: PropTypes.shape({}),
      contentAlignment: PropTypes.shape({}),
      rail: PropTypes.bool,
      spad: PropTypes.bool,
      alpha: PropTypes.string,
      omega: PropTypes.string,
      bottomBorder: PropTypes.string,
      vAlignCenter: PropTypes.bool
    })
  }).isRequired

};

GridColumn.defaultProps = {
  children: [],
  channel: 'desktop',
  isCategory: false,
  bannerData: {}
};

export default GridColumn;
