export const getParams = (props) => {
  const {
    opts, channel, storeId
  } = props;

  // TODO: see if we still need these.
  const NEW_ITEM_PARAM = '+4295117280';
  const NAV_FILTER_PARAM = '10000003+';
  const options = opts;
  const { sortBy, itemIds } = options;
  let { navParam } = options;
  let params = {};
  // TODO: make sure the query actually gives a valid response
  // when this is empty instead of undefined. This was the only way to get mocks working.
  params.itemIds = [];
  let orderField = 'TOP_SELLERS';
  // TODO: update cases to use what contentful is providing
  switch (sortBy) {
    // TODO: verify this. seems like it should be BEST_MATCH
  case 'Best Match':
    orderField = 'MOST_POPULAR';
    break;
  case 'Top Rated':
    orderField = 'TOP_RATED';
    break;
  case 'Product Name':
    orderField = 'PRODUCT_NAME';
    break;
  case 'Brand Name':
    orderField = 'BRAND_NAME';
    break;
  case 'Price':
    orderField = 'PRICE';
    break;
  case 'Most Popular':
    orderField = 'MOST_POPULAR';
    break;
  case 'New Items':
    orderField = 'NEW_ITEMS';
    navParam += NEW_ITEM_PARAM;
    break;
  default:
    orderField = 'TOP_SELLERS';
    break;
  }

  if (itemIds && itemIds.length > 0) {
    params.channel = channel;
    params.itemIds = itemIds;
  } else if (navParam) {
    params.channel = channel;
    params.navParam = navParam?.indexOf('10000003') === -1 ? `${NAV_FILTER_PARAM}${navParam}` : navParam;
  }
  params.storeId = storeId;
  params.pageSize = parseInt(options.pageSize, 10);
  params.startIndex = 0;
  params.filter = {
    storeFilter: 'ALL'
  };
  params.orderBy = {
    field: orderField,
    order: options.sortOrder === 'Descending'
      ? 'DESC'
      : 'ASC'
  };

  return params;
};
