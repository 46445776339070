import { encodeTo36 } from './product-table-view-helpers';

export const generateEncodedSubGroup = (subgroupIds) => {
  return subgroupIds ? `Z${encodeTo36(subgroupIds).join('Z')}` : '';
};

export const generateTableNavParam = (categoryNavParam, groupIds, encodedSubgroup) => {
  return `N-${categoryNavParam}Z${encodeTo36(groupIds).join('Z')}${encodedSubgroup}`;
};

export const getSortParams = (sortBy, sortOrder, columns) => {
  let sort = [];
  if (sortBy.length === sortOrder.length) {
    sortBy.forEach((id, index) => {
      const sortParams = {
        sortBy: id,
        sortOrder: sortOrder[index],
      };
      const isNum = columns.filter((header) => header.columnId === id);
      if (isNum.length > 0) sortParams.isNumber = isNum[0].isNum;
      sort.push(sortParams);
    });
  }
  return sort;
};