import React, { useContext, useState, useEffect } from 'react';
import {
  bool as boolType,
  any,
  string as stringType,
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  extend,
  string,
  QueryProvider,
  params
} from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';
import { BuyItAgainLoadingPlaceholder } from './BuyItAgainLoadingPlaceholder';
import { CarouselHeader } from '../core/CarouselHeader';
import '../../styles/thd-recs-containers.scss';
import { BuyItAgainProductPod } from './BuyItAgainProductPod';

// eslint-disable-next-line react/prop-types
const Wrapper = ({ children }) => children;
Wrapper.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string()
  })
}, BuyItAgainProductPod);
const BuyItAgainCarousel = (props) => {

  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const {
    data,
    title,
    hideTitle,
    loading,
    scheme,
    showLoading,
    storeId,
    subtitle,
    membershipInformation,
    zipCode,
    slidesPer,
    hideCarouselArrows,
    analyticsAnchorProductSku,
    brandTitleMaxLine,
    hideATC
  } = props;

  const defaultVariables = {
    storeId,
    zipCode,
    installationServices: false,
    loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null
  };

  const { buyitagain: products } = data || { buyitagain: [] };

  let breakpoints = ({});
  if (!slidesPer) {
    breakpoints = ({
      sm: {
        slidesPerView: 1,
        slidesPerGroup: 1
      },
      md: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      lg: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      xl: {
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    });
  } else if (slidesPer) {
    breakpoints = ({
      sm: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer,
      },
      md: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      lg: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      xl: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      }
    });
  }

  const isLoading = showLoading && loading;
  const isProductAvailable = !loading && Array.isArray(products) && products?.length !== 0;

  return (
    <>
      {isLoading && (
        <BuyItAgainLoadingPlaceholder title={title} txtPlaceholderLines={3} hideTitle={hideTitle} />
      )}
      {isProductAvailable && (
        <>
          <CarouselHeader key={title} title={title} subtitle={subtitle} />
          <div className="sui-grid sui-gap-4 sui-grid-cols-1">
            <div>
              <QueryProvider
                dataSource={scheme}
                cacheKey={`${scheme}-carousel`}
                defaultVariables={defaultVariables}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideCarouselArrows={hideCarouselArrows}
                  slidesPerView={1}
                >
                  {products.map((product, idx) => {
                    const { itemId } = product;
                    const { preferredPriceFlag } = product.pricing || {};

                    return (
                      <Wrapper key={idx}>
                        <BuyItAgainProductPod
                          itemId={itemId}
                          key={itemId}
                          position={idx}
                          storeId={storeId}
                          scheme={scheme}
                          anchorProduct={analyticsAnchorProductSku}
                          parent={`discovery-zones-${title.toLowerCase().replace(/\s/g, '')}`}
                          zoneName={title}
                          zonePosition="n/a"
                          podResults={products?.length}
                          hideATC={hideATC}
                          preferredPriceFlag={preferredPriceFlag}
                          brandTitleMaxLine={brandTitleMaxLine}
                          isMobile={isMobile}
                        />
                      </Wrapper>
                    );
                  }
                  )}
                </Carousel>
              </QueryProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

BuyItAgainCarousel.dataModel = extend(Wrapper, BuyItAgainProductPod);
BuyItAgainCarousel.displayName = 'BuyItAgainCarousel';
BuyItAgainCarousel.propTypes = {
  ...BuyItAgainProductPod.propTypes,
  title: stringType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  loading: boolType,
  hideTitle: boolType,
  scheme: stringType,
  showLoading: boolType,
  storeId: stringType.isRequired,
  subtitle: stringType,
  hideCarouselArrows: boolType,
  analyticsAnchorProductSku: stringType,
  hideATC: boolType,
  zipCode: stringType,
};

BuyItAgainCarousel.defaultProps = {
  hideTitle: false,
  scheme: 'buyitagain',
  showLoading: true,
  loading: false,
  data: null,
  subtitle: null,
  hideCarouselArrows: false,
  analyticsAnchorProductSku: '',
  hideATC: false,
  zipCode: stringType,
};

export { BuyItAgainCarousel };