import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './MessagingBanner.scss';

const MessagingBanner = (props) => {

  const { opts = {} } = props;

  const {
    backgroundImage = {},
    primaryMedia = {},
    cta,
    ctaLink,
    heading,
    subHeading
  } = opts || {};

  const { src, alt, height, width } = primaryMedia;
  const imgClass = classNames({
    'img--block': height && width
  });

  return (
    <div
      className="EtchEnterpriseMessagingBanner etch-analytics"
      style={{
        backgroundImage: `url(${backgroundImage.src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <a className="EtchEnterpriseMessagingBanner__link" href={ctaLink}>
        <span className="EtchEnterpriseMessagingBanner__foreground">
          <span className="EtchEnterpriseMessagingBanner__imageContainer">
            <Image
              stetchy
              src={src}
              alt={alt}
              height={height}
              width={width}
              className={imgClass}
            />
          </span>
          <span className="EtchEnterpriseMessagingBanner__textContainer">
            <span className="EtchEnterpriseMessagingBanner__textContainer__copy">
              <h2
                className="EtchEnterpriseMessagingBanner__textContainer__heading
                u__large u__bold u__display-font--black u--paddingBottom"
              >
                {heading}
              </h2>
              <h4 className="EtchEnterpriseMessagingBanner__textContainer__subheading u__medium u--paddingBottom">
                {subHeading}
              </h4>
            </span>
          </span>
          {cta && (
            <div className="EtchEnterpriseMessagingBanner__buttonContainer">
              <button
                className="EtchEnterpriseMessagingBanner__btn bttn bttn--primary bttn--inline"
                href={ctaLink}
                type="button"
                aria-label={`Visit ${heading}`}
              >
                <span className="EtchEnterpriseMessagingBanner__btn__content bttn__content">{cta}</span>
              </button>
            </div>

          )}
        </span>
      </a>
      {!cta && <div className="placeholder" />}
    </div>
  );
};

MessagingBanner.displayName = 'MessagingBanner';

MessagingBanner.propTypes = {
  opts: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      src: PropTypes.string
    }),
    primaryMedia: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    cta: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heading: PropTypes.string,
    subHeading: PropTypes.string
  }).isRequired
};

MessagingBanner.defaultProps = {
};

export default MessagingBanner;
