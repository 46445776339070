import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { ProductPodPropTypes, ProductPodDefaultProps } from './Helper';
import { dataModel } from './dataModel';
import { ProductPodDesktop } from './ProductPodDesktop';
import { ProductPodMobile } from './ProductPodMobile';

export const ProductPod = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { props: themeProps } = useTheme(ProductPod);
  const allProps = { ...props, ...themeProps };

  if (channel === 'mobile') {
    /* eslint-disable react/jsx-props-no-spreading */
    return (<ProductPodMobile {...allProps} />);
  }
  /* eslint-disable react/jsx-props-no-spreading */
  return (<ProductPodDesktop {...allProps} />);
};

ProductPod.propTypes = ProductPodPropTypes;

ProductPod.defaultProps = ProductPodDefaultProps;

ProductPod.dataModel = dataModel;

ProductPod.displayName = 'ProductPod';
