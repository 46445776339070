/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { shape, func, bool } from 'prop-types';
import { useStore, ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { getStoreTemplate } from '../helpers/store-helper';
import { dataModel } from '../../components/dataModel';
import { productShape, isGraphQlDataEqual } from '../helpers/pod-fulfillment-utils';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';
import './fulfillment-pod-store.scss';
import { isShowBoth, isTwoTile, isTwoTileOff } from '../../components/helper/utils';
import { renderPickupTemplates } from '../templates/pickUpTemplate';

export const FulfillmentPodStore = React.memo((
  { product, onCheckNearbyStoresClick, isTwoTileEnabledFS, useCustomTemplate }) => {
  const { storeId } = useStore();
  const { customer } = useContext(ExperienceContext);
  const isTwoTileEnabledGlobal = useConfigService('isTwoTileEnabled');
  const isTwoTileMessagingEnabled = useConfigService('fs-prop:product-pod__fulfillment--isTwoTileMessagingEnabled');
  if (!isTwoTileEnabledFS || ((isTwoTile() || isShowBoth()) && !isTwoTileOff())) {
    isTwoTileEnabledFS = (isTwoTileEnabledGlobal && isTwoTileMessagingEnabled)
      || ((isTwoTile() || isShowBoth()) && !isTwoTileOff());
  }
  const StoreComponent = getStoreTemplate(
    {
      product, storeId, customer, isTwoTileEnabledFS, customRender: useCustomTemplate ? renderPickupTemplates : null
    });

  if (!StoreComponent) { return EmptyStoreMessage; }

  return (
    <StoreComponent
      product={product}
      onCheckNearbyStoresClick={onCheckNearbyStoresClick}
      isTwoTileEnabledFS={isTwoTileEnabledFS}
    />
  );
}, isGraphQlDataEqual);

FulfillmentPodStore.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  useCustomTemplate: bool,
  isTwoTileEnabledFS: bool
};

FulfillmentPodStore.defaultProps = {
  onCheckNearbyStoresClick: () => {},
  useCustomTemplate: false,
  isTwoTileEnabledFS: false
};

FulfillmentPodStore.dataModel = dataModel;
