import React from 'react';
import { string, arrayOf, oneOfType, shape } from 'prop-types';

export const SuggestionsContainer = ({ children, header }) => {
  const key = header.replace(/\s/g, '-');
  return (
    <div className="sui-px-4 sui-pt-4 sui-bg-primary">
      <span className="sui-font-w-bold sui-text-3xl sui-leading-tight">{header}</span>
      <ul className="sui-mt-2 sui-ml-2 sui-mb-2 sui-p-0">
        {children.map((child, index) => {
          return (
            <li
              key={`${key}-${index}`}
              className="sui-ml-4 sui-text-base sui-font-normal"
              style={{ 'list-style': 'disc' }}
            >
              {child}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SuggestionsContainer.displayName = 'SuggestionsContainer';
SuggestionsContainer.propTypes = {
  children: arrayOf(oneOfType([shape({}), string])).isRequired,
  header: string.isRequired,
};
