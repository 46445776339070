import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@thd-olt-component-react/core-ui';
import {
  badgeShape,
  isBrioProduct,
  isCustomKitchenCabinetProduct,
  getModifiedBadges,
} from '../../utils';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductBadge = ({ badges: badgesProp }) => {

  const {
    isScheduleAMeasureEligible,
    product
  } = useContext(ProductPodContext);

  const badgeModels = {
    new: 'New',
    seasonal: 'New This Season',
    exclusive: 'Exclusive',
    trending: 'Trending',
    bestseller: 'Best Seller',
    'top rated': 'Top Rated',
    installed: 'Installed',
    custom: 'Custom',
    cpo: 'Custom Catalog',
  };

  let badges = badgesProp || [];

  let badgesColor = '#3e7697';

  if (isScheduleAMeasureEligible || isBrioProduct(product)) {
    const installedBadge = { name: 'installed' };
    badges = getModifiedBadges({ badgesProp, badge: installedBadge });
  }

  if (isCustomKitchenCabinetProduct(product)) {
    const customBadge = { name: 'custom' };
    badges = getModifiedBadges({ badgesProp, badge: customBadge });
  }

  if (!badges || !badges.length || !badges[0]) return null;

  const displayableBadge = Object.keys(badgeModels);
  const itemBadge = badges
    .filter((badge) => badge)
    .filter((badge) => displayableBadge?.indexOf(badge.name) > -1)
    .map((badge) => badgeModels[badge.name]);
  const badge = itemBadge.length ? itemBadge[0] : null;

  if (!badge) {
    return null;
  }

  return (
    <Badge type="text" className="product-pod__badge" text={badge} color={badgesColor} />
  );
};

ProductBadge.propTypes = {
  badges: PropTypes.arrayOf(badgeShape)
};

ProductBadge.defaultProps = {
  badges: []
};

ProductBadge.displayName = 'ProductBadge';
