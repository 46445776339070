import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import className from 'classnames';
import { string } from 'prop-types';
import { Col } from '@thd-olt-component-react/core-ui';
import { Promotion } from '@thd-olt-component-react/promotion';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductQuantity } from '../product-quantity/ProductQuantity';
import { ProductShipping } from '../product-shipping/ProductShipping';
import { ProductStore } from '../product-store/ProductStore';
import { ProductLink } from '../product-link/ProductLink';
import { isATCEnabled } from '../../utils';

export const CheckoutSection = ({ channelFromTest }) => {
  const {
    channel,
  } = useContext(ExperienceContext);

  const {
    product,
    isATCDisabled,
    itemId,
    storeId
  } = useContext(ProductPodContext);

  const isDisabled = isATCDisabled;
  const isMobile = channel === 'mobile' || channelFromTest === 'mobile';
  const productAtc = className({
    'product-atc-wrapper--mobile': isMobile,
    'product-atc-wrapper--desktop': !isMobile
  });

  return (
    <>
      {!isMobile && <ProductPrice large={false} />}
      <Promotion
        itemId={itemId}
        storeId={storeId}
        channel={channel}
      />
      <Col className="horizontal-fulfillment">
        <ProductStore />
        <ProductShipping className="product-pod__fulfillment--shipping-collection" />
      </Col>
      { isATCEnabled(product) && (
        <Col className="atc-wrapper" nopadding>
          <Col
            nopadding
            fallback={isMobile ? '2' : '3'}
          >
            <ProductQuantity
              className={className({ 'horizontal-mobile-unit': isMobile })}
              textOnly
            />
          </Col>

          <Col
            xs={isMobile ? '12' : '6'}
            nopadding
            fallback={isMobile ? '10' : '12'}
            className={productAtc}
          >
            <ProductATC
              outline
              disabled={isDisabled}
            />
          </Col>
        </Col>
      )}
      { !isMobile && (
        <Col className="productlink__wrapper__desktop">
          <ProductLink
            text="See Full Details"
            nopadding
          />
        </Col>
      )}
    </>
  );
};

CheckoutSection.displayName = 'HorizontalPodCheckoutSection';

CheckoutSection.propTypes = {
  channelFromTest: string
};

CheckoutSection.defaultProps = {
  channelFromTest: null
};
