import React, { useContext, useState, useEffect } from 'react';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { string, bool } from 'prop-types';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductBadge } from '../product-badge/ProductBadge';
import { ProductCustomOptions } from '../product-custom-options/ProductCustomOptions';
import { ProductImage } from '../product-image/ProductImage';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductShipping } from '../product-shipping/ProductShipping';
import { ProductCompare } from '../product-compare/ProductCompare';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductSponsored } from '../product-sponsored/ProductSponsored';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import { ProductStore } from '../product-store/ProductStore';
import { ProductQuantity } from '../product-quantity/ProductQuantity';
import { ProductSeeMoreInDepartment } from '../product-see-more-in-department/ProductSeeMoreInDepartment';
import { shouldShowQuantityBox } from '../../utils';
import { CONFIGURABLE_BLINDS } from '../../constants';
import './ProductList.style.scss';
import { ProductIdentifier } from '../product-identifier/ProductIdentifier';

export const ProductList = (props) => {
  const {
    product,
    showItemId,
    showSkuNumber,
    canonicalUrl,
    storeId,
    onChangeSuperSku,
    onHoverSuperSku,
    swatchCount,
    isHovered,
    previousItemId,
    itemId,
    position,
    isHDQCSku,
    staticQuantity
  } = useContext(ProductPodContext);

  const { keyword, hideCompareBox } = props;

  const isConfigurableBlinds = product?.identifiers?.productType === CONFIGURABLE_BLINDS;

  const { badges } = product || {};

  const superSkuItemId = isHovered ? previousItemId : itemId;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div data-testid={`list-pod-${itemId}`}>
      <Row>
        <Col lg="3" md="3" xs="3" className="product-pod-list__image-and-compare-column">
          { (!hideCompareBox && !isHDQCSku)
          && (
            <div className="product-pod-list__compare-container">
              <ProductCompare noMargin />
            </div>
          )}
          <div className="product-pod-list__image-container">
            <ProductImage />
          </div>
          <div className="product-pod-list__more-options-container">
            <ProductCustomOptions />
          </div>
          {mounted && (
            <div className="product-pod-list__ssku-swatches-container">
              <SuperSkuMiniSwatches
                itemId={superSkuItemId}
                canonicalUrl={canonicalUrl}
                storeId={storeId}
                onChange={onChangeSuperSku}
                onHover={onHoverSuperSku}
                numberOfMiniSwatches={swatchCount}
              />
            </div>
          )}
        </Col>
        <Col lg="5" md="5" xs="4">
          <div className="product-pod-list__save-to-list-container">
            {!isConfigurableBlinds && (
              <ProductFavorites label="Save to List" />
            )}
          </div>
          <ProductSponsored position={position} />
          <div>
            <span><ProductBadge badges={badges} /></span>
            <span><ProductHeader brand="inline" /></span>
          </div>
          <div className="product-pod-list__identifiers">
            {showItemId && <ProductIdentifier type="itemId" />}
            <ProductIdentifier type="model" />
            {showSkuNumber && <ProductIdentifier type="sku" />}
          </div>
          <ProductRating />
          <ProductPrice large={false} />
          <ProductSeeMoreInDepartment keyword={keyword} />
        </Col>
        <Col lg="4" md="4" xs="5">
          { isHDQCSku ? (
            <ProductQuoteCenter />
          ) : (
            <>
              <ProductStore />
              <ProductShipping />
              <div className="product-pod-list__add-to-cart-quantity__wrapper">
                {shouldShowQuantityBox(product)
                  && (
                    <ProductQuantity
                      wideView
                      showQtyText
                      noRightMargin
                      shortHeight
                      forListPod
                      showQtyValueText={Boolean(staticQuantity)}
                      showQtyValueTextWide={Boolean(staticQuantity)}
                    />
                  )}
                <div className="product-pod__add-to-cart-quantity__add-to-cart-wrapper">
                  <ProductATC outline checkGeneric />
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

ProductList.displayName = 'ProductPodList';

ProductList.propTypes = {
  hideCompareBox: bool,
  keyword: string,
};

ProductList.defaultProps = {
  hideCompareBox: false,
  keyword: null,
};
