import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classNames from 'classnames';
import { ProductPodContext } from '../../ProductPodContext';
import './product-identifier.scss';

export const ProductIdentifier = (props) => {
  const { type } = props;
  const { product } = useContext(ProductPodContext);
  const { channel } = useContext(ExperienceContext);

  const { info, identifiers } = product || {};
  const isMobile = channel === 'mobile';
  const { modelNumber, itemId, storeSkuNumber } = identifiers || {};

  if (!modelNumber) return null;

  const productIdentifierClassNames = classNames('product-identifier', {
    'product-identifier--none': type === 'itemId',
    'product-identifier--centered': info?.isBrioSku && isMobile,
    'product-identifier__model': type === 'model'
  });

  let label = '';
  if (type === 'model' && modelNumber) label = `Model# ${modelNumber}`;
  else if (type === 'sku' && storeSkuNumber) label = `StoreSKU# ${storeSkuNumber}`;
  else if (type === 'itemId' && itemId) label = `Internet# ${itemId}`;
  else return null;

  return <div className={productIdentifierClassNames}>{label}</div>;
};

ProductIdentifier.propTypes = {
  type: PropTypes.oneOf(['sku', 'model', 'itemId']).isRequired,
};

ProductIdentifier.displayName = 'ProductIdentifier';
