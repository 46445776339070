import {
  arrayOf as arrayOfType,
  bool as boolType, number as numberType,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources/react/dataModel/prop-types';
import {
  dataModel as deliveryDataModel,
  DeliveryOptions
} from '@thd-olt-component-react/delivery-options';
import { params, alias, client, extend } from '@thd-nucleus/data-sources';

const Service = shapeType({
  deliveryTimeline: stringType(),
  deliveryDates: shapeType({
    startDate: stringType(),
    endDate: stringType(),
  }),
  deliveryCharge: stringType(),
  dynamicEta: shapeType({
    hours: stringType(),
    minutes: stringType()
  }),
  hasFreeShipping: boolType(),
  freeDeliveryThreshold: numberType({ float: true }),
  locations: arrayOfType(shapeType({
    curbsidePickupFlag: boolType(),
    isBuyInStoreCheckNearBy: boolType(),
    distance: numberType({ float: true }),
    inventory: shapeType({
      isOutOfStock: boolType(),
      isInStock: boolType(),
      isLimitedQuantity: boolType(),
      isUnavailable: boolType(),
      quantity: numberType(),
      maxAllowedBopisQty: numberType(),
      minAllowedBopisQty: numberType()
    }),
    isAnchor: boolType(),
    locationId: stringType(),
    state: stringType(),
    storeName: stringType(),
    storePhone: stringType(),
    type: stringType(),
  })),
  type: stringType(),
  optimalFulfillment: boolType(),
  totalCharge: numberType({ float: true }),
});

export const dataModel = extend({
  clientOnlyProduct: alias('product')
    .params({
      itemId: stringType().isRequired()
    })
    .shape({
      itemId: stringType(),
      dataSources: stringType(),
      identifiers: shapeType({
        productType: stringType(),
      }),
      info: shapeType({
        classNumber: stringType(),
        hasSubscription: boolType(),
        isLiveGoodsProduct: boolType(),
        productDepartment: stringType(),
        globalCustomConfigurator: shapeType({
          customExperience: stringType(),
        }),
      }),
      pricing: params({ storeId: stringType() })
        .shape({
          value: numberType({ float: true })
        }),
      availabilityType: shapeType({
        buyable: boolType(),
        discontinued: boolType(),
        status: boolType(),
        type: stringType(),

      }),
      fulfillment: client(params({ storeId: stringType(), zipCode: stringType(), quantity: numberType() }).shape({
        anchorStoreStatus: boolType(),
        anchorStoreStatusType: stringType(),
        backordered: boolType(),
        backorderedShipDate: stringType(),
        bossExcludedShipStates: stringType(),
        sthExcludedShipState: stringType(),
        bossExcludedShipState: stringType(),
        excludedShipStates: stringType(),
        seasonStatusEligible: boolType(),
        fulfillmentOptions: arrayOfType(shapeType({
          type: stringType(),
          fulfillable: boolType(),
          services: arrayOfType(Service)
        })),
        onlineStoreStatus: boolType(),
        onlineStoreStatusType: stringType(),
      }))
    }),
  shipping: deliveryDataModel.shipping
}, DeliveryOptions);
