import React from 'react';
import { Row, Col } from '@thd-olt-component-react/grid';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductImage } from '../product-image/ProductImage';
import { ProductPrice } from '../product-price/ProductPrice';
import './product-simple.style.scss';

export const ProductSimple = () => {

  return (
    <Row className="product-pod-simple__wrapper">
      <Col fallback="4" xs="12" sm="12" className="product-pod-simple__wrapper__center-image">
        <div className="product-pod-simple__image">
          <ProductImage />
        </div>
      </Col>
      <Col fallback="8" xs="12" sm="12">
        <ProductHeader brand="inline" disableShopThisCollection />
        <ProductRating />
        <ProductPrice
          large={false}
          hideBadge
          hideBulkPrice
          hideLimitPerOrder
          hidePromotions
        />
      </Col>
    </Row>
  );
};

ProductSimple.displayName = 'ProductSimple';
