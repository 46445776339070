import React, { useEffect } from 'react';
import {
  shape,
  string,
  arrayOf,
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { AnchorPill } from './AnchorPill';
import './anchor-links.scss';

const AnchorLinksRenderer = ({
  componentId,
  linkList,
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('anchor-links.ready');
  }, []);

  return (
    <section className="sui-flex sui-flex-wrap sui-gap-4">
      <Typography variant="h3" weight="bold" component="span">Jump to Section:</Typography>
      <div
        className="sui-flex sui-gap-4 sui-overflow-auto sui-overflow-y-hidden"
        data-contentful-entry-id={componentId}
        data-contentful-field-id="linkList"
      >
        {linkList.map((link, currentPosition) => (
          <AnchorPill
            key={componentId}
            componentId={componentId}
            componentPosition={currentPosition + 1}
            link={link}
          />
        ))}
      </div>
    </section>
  );
};

AnchorLinksRenderer.propTypes = {
  componentId: string.isRequired,
  linkList: arrayOf(shape({}))
};
AnchorLinksRenderer.defaultProps = {
  linkList: [],
};

export { AnchorLinksRenderer };
