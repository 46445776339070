import React, { useContext, useEffect, useState } from 'react';
import { bool, oneOf } from 'prop-types';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { AisleBayPod } from '@thd-olt-component-react/aisle-bay';
import { AutomotiveBanner } from '@thd-olt-component-react/car-picker';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductBadge } from '../product-badge/ProductBadge';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductImage } from '../product-image/ProductImage';
import { ProductServiceAddOns } from '../product-service-add-ons/ProductServiceAddOns';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductShipping } from '../product-shipping/ProductShipping';
import { ProductStore } from '../product-store/ProductStore';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductCustomOptions } from '../product-custom-options/ProductCustomOptions';
import { ProductSponsored } from '../product-sponsored/ProductSponsored';
import { ProductCompare } from '../product-compare/ProductCompare';
import { ProductMobileDpd } from './ProductMobileDpd';
import { ProductSeeSimilarItems } from '../product-see-similar-items/ProductSeeSimilarItems';
import { ProductIdentifier } from '../product-identifier/ProductIdentifier';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import { ProductHddcMessage } from '../product-hddc-message/ProductHddcMessage';
import {
  isCustomKitchenCabinetProduct,
  isSellableAtSelectedStore,
  isAppliance
} from '../../utils';
import './ProductMobile.style.scss';

export const ProductMobileSingleColumn = ({ isEtchOne, showDpdPod, collection }) => {
  const {
    automotiveFitmentObject,
    comparable,
    product,
    position,
    onFavoriteChange,
    buyMoreSaveMore,
    isHDQCSku,
    isHDDCSku,
    isHovered,
    previousItemId,
    itemId,
    storeId,
    onChangeSuperSku,
    onHoverSuperSku,
    target,
    canonicalUrl,
    showAisleAndBay,
    showCountdownTimer,
    showKPF,
    showSimilarLinkInstock,
    showModelNumber,
    showItemId,
    showSkuNumber,
    showSeeMoreOptions
  } = useContext(ProductPodContext);

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (showDpdPod) return <ProductMobileDpd isEtchOne={isEtchOne} />;

  const {
    details,
    info,
    badges,
    identifiers,
    pricing
  } = product || {};
  const promotionEndDate = pricing?.promotion?.dates?.end;
  const hasMultipleOptions = info?.totalNumberOfOptions > 1;
  const { storeSkuNumber } = identifiers || {};
  const {
    collection: collectionObj
  } = details || {};
  const {
    name: collectionName,
    url: collectionUrl
  } = collectionObj || {};

  const superSkuItemId = isHovered ? previousItemId : itemId;
  const showShopThisCollectionLink = collectionName && collectionUrl && collection === 'below' && !isHDDCSku;
  const productPodRightSideColWrapper = classNames('product-pod-right-side-col-wrapper', {
    'product-pod-right-side-col-wrapper--brio': info?.isBrioSku
  });
  const productPodLeftSideColWrapper = classNames('product-pod__left-side', {
    'product-pod__left-side--brio': info?.isBrioSku
  });

  const showAisleBayForProduct = showAisleAndBay && storeSkuNumber && isSellableAtSelectedStore({ product });

  const showProductStore = !info?.isBrioSku && !isHDDCSku && !isAppliance(product);

  const showATCButtonAtTheBottom = isCustomKitchenCabinetProduct(product)
    && typeof window !== 'undefined' && window?.outerWidth === 320;

  const seeMoreOptionsLinkClasses = classNames('product-pod__see-more-options-link', {
    'product-pod__see-more-options-link--hidden': !hasMultipleOptions
  });

  return (
    <>
      <ProductFavorites
        className="product-pod__inline__favorite"
        onFavoriteChange={onFavoriteChange}
        position="topRight"
        showCircle
      />
      <div className="product-pod__badges">
        <ProductBadge badges={badges} />
      </div>
      <div className="pod-header-wrapper">
        <ProductSponsored position={position} />
        <ProductHeader brand="inline" disableShopThisCollection />
        {info?.isBrioSku && (
          <div className="ratings-and-model-container-brio">
            <ProductRating />
            <ProductIdentifier type="model" />
          </div>
        )}
      </div>
      <Row>
        <div className="pod-mid-elements-wrapper">
          <Col fallback="6" className={productPodLeftSideColWrapper}>
            <ProductImage buyMoreSaveMore={buyMoreSaveMore} dualImageSwipe />
            {showSeeMoreOptions && (
              <a
                href={canonicalUrl || ''}
                target={target || ''}
                className={seeMoreOptionsLinkClasses}
              >
                See More Options
              </a>
            )}
            {!showSeeMoreOptions && mounted && (
              <SuperSkuMiniSwatches
                itemId={superSkuItemId}
                canonicalUrl={canonicalUrl}
                storeId={storeId}
                onChange={onChangeSuperSku}
                onHover={onHoverSuperSku}
                center
              />
            )}
            <ProductCustomOptions />
          </Col>
          <Col className={productPodRightSideColWrapper} fallback="6">
            {showShopThisCollectionLink && (
              <a className="product-pod__title__collection" href={collectionUrl} target={target}>
                Shop this Collection
              </a>
            )}

            {!info?.isBrioSku && (
              <>
                <div className="product-pod-list__identifiers">
                  {showItemId && <ProductIdentifier type="itemId" />}
                  {showModelNumber && <ProductIdentifier type="model" />}
                  {showSkuNumber && <ProductIdentifier type="sku" />}
                </div>
                <ProductRating />
              </>
            )}
            { isHDQCSku ? (
              <ProductQuoteCenter />
            ) : (
              <>
                <ProductServiceAddOns />
                <ProductPrice large={false} className="product-pod__pricing" />
                {(showCountdownTimer && promotionEndDate) && (
                  <div className="product-pod__countdown-timer">
                    <CountdownTimer
                      endDate={promotionEndDate}
                      shouldIncludeSvg
                      shouldUseMessagingString
                      showCondensedTimer
                      showOrangeTimerSvg
                    />
                  </div>
                )}
                {showKPF && (
                  <KeyProductFeatures
                    hideImage
                    storeId={storeId}
                    itemId={itemId}
                    maxfeatures={4}
                    simple
                    oneColumnGrid
                    className="product-pod__mw-kpf"
                  />
                )}

                {showProductStore && <ProductStore className="no-margin" checkGeneric />}
                {!info?.isBrioSku && !isHDDCSku && <ProductShipping className="product-pod__fulfillment--shipping" />}
                {isHDDCSku && <ProductHddcMessage />}
                {showAisleBayForProduct && <AisleBayPod storeId={storeId} storeSkuNumber={storeSkuNumber} />}
                {!showATCButtonAtTheBottom && <ProductSeeSimilarItems />}
                {!showATCButtonAtTheBottom && <ProductATC className="product-pod__add-to-cart" outline checkGeneric />}
                {showSimilarLinkInstock && (
                  <div className="product-pod__similar-instock--link"><ProductSeeSimilarItems showSimilarInStock />
                  </div>
                )}
              </>
            )}
          </Col>
          {!isHDQCSku && showATCButtonAtTheBottom
            && <ProductATC className="product-pod__add-to-cart" outline checkGeneric />}
        </div>
        {Boolean(automotiveFitmentObject) && (
          <Col nopadding className="product-pod__automotive-banner">
            <AutomotiveBanner fitment={automotiveFitmentObject} />
          </Col>
        )}
      </Row>
      {!isHDQCSku && comparable
      && (
        <Row className="product-pod__compare-row">
          <Col fallback="12" flatten>
            <ProductCompare />
          </Col>
        </Row>
      )}
    </>

  );
};

ProductMobileSingleColumn.propTypes = {
  collection: oneOf(['below', 'hidden']),
  isEtchOne: bool,
  showDpdPod: bool
};

ProductMobileSingleColumn.defaultProps = {
  collection: 'below',
  isEtchOne: false,
  showDpdPod: false
};

ProductMobileSingleColumn.displayName = 'ProductMobileSingleColumn';
