import React from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';
import styles from './product-quote-center.style.scss';

const cx = classNames.bind(styles);

export const ProductQuoteCenter = () => {
  return (
    <div className={cx('product-quote-center')}>
      <div>
        <Image
          className={cx('product-quote-center__warning-image')}
          src="https://assets.thdstatic.com/images/v1/advisory.svg"
          alt=""
          height="11"
          width="11"
        />
        <span className={cx('product-quote-center__header')}>Special Order</span>
      </div>
      <div className={cx('product-quote-center__message')}>
        Please visit your local Pro Desk or call our Pro Support Team to discuss pricing and availability.
      </div>
      <div>
        Call
        <a className={cx('product-quote-center__phone-number')} href="tel:1-844-776-7768">1-844-776-7768</a>
      </div>
    </div>
  );
};
