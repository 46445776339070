/* eslint-disable max-len */
import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { AisleBayPodsContext } from './AisleBayPodsContext';
import './aisle-bay-pod.scss';

export const AisleBayPod = ({ storeId, storeSkuNumber }) => {
  const { getAisleBayForPod, showAisleBayPods } = useContext(AisleBayPodsContext);
  if (!(showAisleBayPods && storeId && storeSkuNumber)) {
    return null;
  }

  const aisleBaySku = getAisleBayForPod({ storeIdNum: storeId, storeSku: storeSkuNumber });
  if (!aisleBaySku) {
    return null;
  }

  const { aisle, bay, invLocDesc } = aisleBaySku;

  const showAisle = !!aisle;
  const showDescriptionNotAisle = !!invLocDesc && !aisle;
  const showBay = !!bay && (!!invLocDesc || showAisle);

  if (!(showAisle || showDescriptionNotAisle)) {
    return null;
  }

  const baseName = 'aisle-bay-pod';
  const pointOfInterestSvgWrapClass = classNames(`${baseName}__poi-img-wrapper`);
  const pointOfInterestSvgClass = classNames(`${baseName}__poi-img`);
  const aisleBayPodRowClasses = classNames(baseName, `${baseName}__row`);
  const messageClass = classNames(`${baseName}__message`);
  const boldClass = classNames(`${baseName}__message--bold`);
  const leftClass = classNames(`${baseName}__message--left`);

  return (
    <div className={aisleBayPodRowClasses}>
      <div className={pointOfInterestSvgWrapClass}>
        <Image src="https://assets.thdstatic.com/images/v1/poi-outline.svg" alt="Location Pin" className={pointOfInterestSvgClass} />
      </div>
      <div className={messageClass}>
        <span className={leftClass}>
          { showAisle && (
            <span><span className={boldClass}>Aisle:</span>{`${Number(aisle) || aisle}`}</span>
          )}
          { showDescriptionNotAisle && (
            <span>{`${invLocDesc.toString().split(',')[0]}`}</span>
          )}
        </span>
        { showBay && (
          <span><span className={boldClass}>Bay:</span>{`${Number(bay) || bay}`}</span>
        )}
      </div>
    </div>
  );
};

AisleBayPod.displayName = 'AisleBayPod';

AisleBayPod.propTypes = {
  storeId: string,
  storeSkuNumber: string
};

AisleBayPod.defaultProps = {
  storeId: '',
  storeSkuNumber: ''
};
