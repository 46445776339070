/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Button } from '@thd-olt-component-react/button';
import { Col } from '@thd-olt-component-react/grid';
import { getClearAllWithSort } from '../../product-results-helpers';
import { ResultsSortBy } from '../ResultsSortBy';
import { StandardDimension } from '../Dimensions/StandardDimension';
import { LinkDimension } from '../Dimensions/LinkDimension';
import { RatingRadioDimension } from '../Dimensions/RatingRadioDimension';
import { VisualDimension } from '../Dimensions/VisualDimension';
import { NumericRangeDimension } from '../Dimensions/NumericRangeDimension';
import { ColorSwatchDimension } from '../Dimensions/ColorSwatchDimension';

import '../../styles/drawer.scss';

export const Drawer = ({
  appliedDimensions,
  breadCrumbs,
  dimensionLabel,
  isOpen,
  loading,
  metadata,
  normalizedDimensions,
  onSortByChange,
  onRefinementChange,
  searchReport,
  toggleDrawer,
  onDisplayData,
  enableMultiStore,
  onMultiStoreSelectionChange,
  multiStoreSelection,
  isTableView
}) => {
  const CLOSE_BUTTON = 'https://assets.thdstatic.com/images/v1/close-symbol-orange.svg';
  const [collapse, setCollapse] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    setCollapse(true);
    return () => {
      document.body.classList.remove('filter-and-sort--no-scroll');
    };
  }, []);

  if (isMounted && isOpen) {
    document.body.classList.add('filter-and-sort--no-scroll');
  }

  const dimensionApplied = (breadcrumbs = []) => {
    return breadcrumbs.filter((breadcrumb) => (breadcrumb.deselectUrl || '')).length !== 0;
  };

  const handleClick = useCallback((event) => {
    const url = getClearAllWithSort({ metadata, searchReport });
    const refinement = {
      url,
      clear: true
    };
    event.preventDefault();
    onRefinementChange({ refinement });
  }, [metadata, onRefinementChange, searchReport]);

  const closeDrawer = () => {
    document.body.classList.remove('filter-and-sort--no-scroll');
    toggleDrawer(true);
    if (typeof window !== 'undefined') {
      const page = window.history?.state?.page;
      const scrollY = window.history?.state?.scrollY || 0;
      if (page === 'FILTER_SORT_DRAWER') window.scroll(0, scrollY);
    }
  };

  const getAppliedRefinements = (label) => {
    let refinementStr = null;
    if (!appliedDimensions.length) return null;
    const selectedDimension = appliedDimensions.find((dim) => dim.label === label);
    if (selectedDimension?.refinements?.length) {
      const { refinements } = selectedDimension;
      refinementStr = '';
      refinements.forEach((refinement, index) => {
        refinementStr += refinement.label;
        if (index < refinements.length - 1) {
          refinementStr += ', ';
        }
      });
    }
    return refinementStr;
  };

  const clickOnFitCompatibility = () => {
    if (isOpen) {
      closeDrawer();
    }
  };

  const generateDrawerDimensions = () => {
    return (
      <>
        {normalizedDimensions.map((dimension) => {
          const {
            label, dimensionId, isVisualDimension, isNumericFilter, isColorSwatch,
          } = dimension;

          const hasFilter = ['BRAND', 'COLOR FAMILY', 'PATTERN TYPE', 'THEME', 'FEATURED KEYWORDS',
            'CUSTOMER SEARCHED KEYWORDS', 'POPULAR KEYWORDS'].indexOf(label.toUpperCase()) > -1;
          const hasFitCompatibility = [
            'DEPTH (EXCLUDING HANDLES) (IN.)',
            'HEIGHT TO TOP OF DOOR HINGE (IN.)',
            'HEIGHT TO TOP OF REFRIGERATOR (IN.)',
            'INSTALLATION DEPTH',
            'REFRIGERATOR FIT WIDTH',
            'TOTAL CAPACITY (CU. FT.)',
            'CAPACITY (CU. FT.) - REFRIGERATORS',
            'REFRIGERATOR CAPACITY (CU. FT.)',
            'DEPTH (INCLUDING HANDLES) (IN.)'
          ].includes(label.toUpperCase());
          const isCollapse = dimensionLabel ? label !== dimensionLabel : collapse;
          const refinementStr = getAppliedRefinements(label);

          if (isVisualDimension) {
            return (
              <VisualDimension
                drawer
                key={dimensionId}
                collapsed={collapse}
                appliedRefinements={refinementStr}
                onChange={onRefinementChange}
                dimension={dimension}
              />
            );
          }

          if (isNumericFilter) {
            return (
              <NumericRangeDimension
                filter={hasFilter}
                collapsed={isCollapse}
                drawer
                dimension={dimension}
                metadata={metadata}
                key={dimensionId}
                onChange={onRefinementChange}
                onDisplayData={onDisplayData}
                appliedRefinements={refinementStr}
                enableMultiStore={enableMultiStore}
                onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                multiStoreSelection={multiStoreSelection}
              />
            );
          }

          if (isColorSwatch) {
            return (
              <ColorSwatchDimension
                collapsed={collapse}
                drawer
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementChange}
              />
            );
          }

          switch (label) {
          case 'Category':
            return (
              <LinkDimension
                breadCrumbs={breadCrumbs}
                drawer
                collapsed={collapse}
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementChange}
              />
            );
          case 'Review Rating':
            return (
              <RatingRadioDimension
                collapsed
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementChange}
                appliedRefinements={refinementStr}
              />
            );
          default:
            return (
              <StandardDimension
                filter={hasFilter}
                fitCompatibility={hasFitCompatibility}
                onFitCompatibilityClick={clickOnFitCompatibility}
                priceRange={label.toUpperCase() === 'PRICE'}
                toggleSwitch={label.toUpperCase() === 'AVAILABILITY'}
                collapsed={isCollapse}
                drawer
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementChange}
                onDisplayData={onDisplayData}
                appliedRefinements={refinementStr}
                enableMultiStore={enableMultiStore}
                onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                multiStoreSelection={multiStoreSelection}
              />
            );
          }
        })}
      </>
    );
  };

  const { totalProducts = 0 } = searchReport;
  const resultsWithCount = (
    <div>
      View Results <span className="drawer__sticky-nav--count">({totalProducts})</span>
    </div>
  );

  const drawerClasses = classNames('drawer', {
    // eslint-disable-next-line
    'grid': isOpen,
    'drawer--display-none': !isOpen
  });

  const fadeClasses = classNames('drawer__shadow', {
    'drawer__shadow--fade': loading
  });

  return (
    <div className={drawerClasses}>
      <div className="drawer__content">
        <div className="drawer__header">
          <div className="drawer__title">Sort & Filter</div>
          <button className="drawer__close" type="button" onClick={closeDrawer}>
            <img alt="close-button" className="drawer__close--size" src={CLOSE_BUTTON} height="20" width="20" />
          </button>
        </div>
        <Col>
          {!isTableView && (
            <div className="drawer__sort-by">
              <ResultsSortBy
                drawer
                onSortChange={onSortByChange}
                oldDesign
              />
            </div>
          )}
          <div className="drawer__filters">
            <div className="filter__title">Filters</div>
            {dimensionApplied(breadCrumbs) && (
              <a className="filter__clear-all--blue" href="#" onClick={handleClick}>Clear All</a>
            )}
            {!dimensionApplied(breadCrumbs) && (
              <span className="filter__clear-all--grey">Clear All</span>
            )}
          </div>
          <div className="dimensions">
            {generateDrawerDimensions()}
          </div>
        </Col>
        <div className="grid drawer__sticky-nav">
          <div className="drawer__sticky-nav--results-button">
            <Button onClick={closeDrawer}>
              {dimensionApplied(breadCrumbs)
                ? resultsWithCount
                : <span>View Results</span>}
            </Button>
          </div>
        </div>
      </div>
      <div className={fadeClasses} />
    </div>
  );
};

Drawer.propTypes = {
  appliedDimensions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      refinements: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({})
  ),
  dimensionLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  metadata: PropTypes.shape({}),
  normalizedDimensions: PropTypes.arrayOf(
    PropTypes.shape({})
  ),
  onRefinementChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  searchReport: PropTypes.shape({
    totalProducts: PropTypes.number
  }),
  toggleDrawer: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    itemCount: PropTypes.string
  }),
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.arrayOf(PropTypes.shape({ })),
  onMultiStoreSelectionChange: PropTypes.func,
  isTableView: PropTypes.bool
};

Drawer.defaultProps = {
  breadCrumbs: [],
  dimensionLabel: null,
  isOpen: false,
  loading: false,
  metadata: null,
  normalizedDimensions: [],
  searchReport: null,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  enableMultiStore: false,
  multiStoreSelection: null,
  onMultiStoreSelectionChange: null,
  isTableView: false
};

Drawer.displayName = 'Drawer';

Drawer.dataModel = extend(StandardDimension);
