import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { oneOf } from 'prop-types';

import { Image, Heading } from '@thd-olt-component-react/core-ui';

import paintModals from '../../../data/paint-modals.json';
import sharedStyles from '../paint-modal-content.module.scss';
import styles from './Brand.module.scss';

const { brands } = paintModals;
const cx = classNames.bind({ ...styles, ...sharedStyles });

export const Brand = ({ brandKey, type }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('brands.ready'); }, []);

  const brandData = brands[brandKey][type];
  const dataClassnames = cx('table__data', 'table__data--brand');

  const isInteriorPaint = type === 'Interior Paint';
  const isGliddenBrand = brandKey === 'PPG - Glidden';

  const PERFORMANCE_LABEL = 'PERFORMANCE';
  const COVERAGE_LABEL = 'COVERAGE';
  const COVERAGE_PER_GALLON_LABEL = 'Coverage per gallon';
  const DURABILITY_LABEL = 'DURABILITY';
  const EASY_TO_CLEAN_LABEL = 'EASY TO CLEAN';
  const STAIN_BLOCKIN_LABEL = 'STAIN BLOCKING';
  const RESISTS_FADING_CRACKING_PEELING_LABEL = 'RESISTS FADING, CRACKING, AND PEELING';
  const MOLD_MILDEW_RESISTANT_LABEL = 'MOLD/MILDEW RESISTANT';
  const VOC_LABEL = 'Odor & VOC Conteent';

  return (
    <div className={cx('brand-modal', 'paint-modal-content')} data-component="PaintModalContentBrand">
      <Heading
        underline
        className={cx('brand-modal__header')}
        title={`Brand Options (${type})`}
      />
      <p className={cx('brand-modal__description')}>
        Our top paint brands side by side. Compare performance,
        durability, value, and more.
      </p>

      <div className={cx('table')}>
        <div className={cx('table__attributes')}>
          <div className={cx('table__row')}>{PERFORMANCE_LABEL}</div>
          <div className={cx('table__row')}>{COVERAGE_LABEL}</div>
          {isGliddenBrand && <div className={cx('table__row')}>{COVERAGE_PER_GALLON_LABEL}</div>}
          <div className={cx('table__row', 'table__row--rating-label')}>{DURABILITY_LABEL}</div>
          <div className={cx('table__row', 'table__row--rating-label')}>
            {isInteriorPaint && isGliddenBrand ? EASY_TO_CLEAN_LABEL : MOLD_MILDEW_RESISTANT_LABEL}
          </div>
          <div className={cx('table__row', 'table__row--rating-label')}>
            {isInteriorPaint && isGliddenBrand ? STAIN_BLOCKIN_LABEL : RESISTS_FADING_CRACKING_PEELING_LABEL}
          </div>
          {isInteriorPaint && <div className={cx('table__row')}>{VOC_LABEL}</div>}
        </div>
        <div className={cx('table__body')}>
          <div className={cx('table__row')}>
            {brandData.map((brand) => {
              const {
                image,
                name
              } = brand;
              return (
                <Image
                  className={cx('brand__image')}
                  src={image}
                  alt={name}
                  key={name}
                  width={100}
                  height={50}
                />
              );
            })}
          </div>
          <div className={cx('table__row', 'table__row--shaded')}>
            {brandData.map((brand) => {
              const { name, performance } = brand;
              return (
                <div className={dataClassnames} key={`${name}-performance`}>{performance}</div>
              );
            })}
          </div>
          <div className={cx('table__row')}>
            {brandData.map((brand) => {
              const { name, coverage } = brand;
              return (
                <div className={dataClassnames} key={`${name}-coverage`}>{coverage}</div>
              );
            })}
          </div>
          {isGliddenBrand
            && (
              <div className={cx('table__row', 'table__row--shaded')}>
                {brandData.map((brand) => {
                  const { name, coveragePerGallon } = brand;
                  return (
                    <div className={dataClassnames} key={`${name}-coveragePerGallon`}>{coveragePerGallon}</div>
                  );
                })}
              </div>
            )}
          <div className={cx('table__row', !isGliddenBrand && 'table__row--shaded', 'table__row--rating')}>
            {brandData.map((brand) => {
              const { name, durability } = brand;
              return (
                <div className={dataClassnames}>
                  <div className={dataClassnames} key={`${name}-durability`}>{durability}</div>
                </div>
              );
            })}
          </div>
          <div className={cx('table__row', isGliddenBrand && 'table__row--shaded', 'table__row--rating')}>
            {brandData.map((brand) => {
              const { name, easyToClean, moldMildewResistant, washability } = brand;
              return (
                <div className={dataClassnames}>
                  {isGliddenBrand
                    ? (
                      <div className={dataClassnames} key={`${name}-easyToClean`}>
                        {isInteriorPaint ? easyToClean : moldMildewResistant}
                      </div>
                    )
                    : <div className={dataClassnames} key={`${name}-easyToClean`}>{washability}</div>}
                </div>
              );
            })}
          </div>
          <div className={cx('table__row', !isGliddenBrand && 'table__row--shaded', 'table__row--rating')}>
            {brandData.map((brand) => {
              const { name, stainBlocking, resistsFadingCrackingPeeling } = brand;
              return (
                <div className={dataClassnames}>
                  {isGliddenBrand
                    ? (
                      <div className={dataClassnames} key={`${name}-stainBlocking`}>
                        {isInteriorPaint ? stainBlocking : resistsFadingCrackingPeeling}
                      </div>
                    )
                    : <div className={dataClassnames} key={`${name}-stainBlocking`}>{stainBlocking}</div>}
                </div>
              );
            })}
          </div>
          {isInteriorPaint
            && (
              <div className={cx('table__row')}>
                {brandData.map((brand) => {
                  const { name, odorVOCContent } = brand;
                  return (
                    <div className={dataClassnames} key={`${name}-odorVOCContent`}>{odorVOCContent}</div>
                  );
                })}
              </div>
            )}
        </div>
      </div>
      <div className={cx('brand-modal__disclaimer')}>
        * Colorants added to this base may increase voc level significantly depending on color
        choices
      </div>
    </div>
  );
};

Brand.propTypes = {
  brandKey: oneOf(['Behr', 'PPG - Glidden']),
  type: oneOf((['Interior Paint', 'Exterior Paint']))
};

Brand.defaultProps = {
  brandKey: 'Behr',
  type: 'Interior Paint',
};

Brand.displayName = 'PaintModalContent.Brand';
