import React, { useState, useEffect, Suspense } from 'react';
import classNames from 'classnames/bind';
import {
  bool,
  string,
  shape,
  node,
  func,
} from 'prop-types';

import { Button } from '@thd-olt-component-react/button';

import styles from './information-modal.module.scss';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));

const cx = classNames.bind(styles);
const doNothingFunction = () => {};

const InformationModal = ({
  linkTitle,
  children,
  onClick,
  onClose,
  overlayProps,
  showInfoIcon
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('information-modal.ready'); }, []);

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
    onClick();
  };

  const propsForOverlay = {
    ...InformationModal.defaultProps.overlayProps,
    ...overlayProps,
  };

  const handleOverlayClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div className="information-modal">
      <Button
        link
        onClick={handleButtonClick}
      >
        <span data-testid="size-guide-link">{linkTitle}</span>
        {showInfoIcon && (
          <span className={cx('information-modal__icon', 'information-modal__icon--info')} data-testid="info-icon" />
        )}
      </Button>
      { open && (
        <Suspense fallback={<div>Loading...</div>}>
          <Overlay
            {...propsForOverlay} // eslint-disable-line  react/jsx-props-no-spreading
            onClose={handleOverlayClose}
            open
          >
            { children }
          </Overlay>
        </Suspense>
      )}
    </div>
  );
};

InformationModal.propTypes = {
  children: node.isRequired,
  linkTitle: string.isRequired,
  onClick: func,
  onClose: func,
  overlayProps: shape({
    closeButton: bool,
    onClose: func,
    positionedCloseButton: bool
  }),
  showInfoIcon: bool
};

InformationModal.defaultProps = {
  onClick: doNothingFunction,
  onClose: doNothingFunction,
  overlayProps: {
    closeButton: true,
    positionedCloseButton: true
  },
  showInfoIcon: false,
};

InformationModal.displayName = 'InformationModal';

export { InformationModal };
