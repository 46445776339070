import React, { useState, useContext, Suspense } from 'react';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string, func, bool } from 'prop-types';
import { Button } from '@thd-olt-component-react/core-ui';
import './product-atc.style.scss';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/core-ui')
  .then((module) => ({ default: module.Overlay })));

export const ServicesButton = (props) => {
  const {
    name,
    servicesURL,
    onClick,
    showLargeButtonOnMobile
  } = props;

  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const [open, setOpen] = useState(false);
  const onServicesPodClick = () => {
    onClick();
    setOpen(true);
  };

  const servicesIframeWrapper = classNames('services-iframe', {
    'services-iframe--desktop': !isMobile
  });

  return (name && servicesURL
    && (
      <>
        <Button
          dark
          outline
          onClick={onServicesPodClick}
          small={isMobile && !showLargeButtonOnMobile}
        >
          {name}
        </Button>
        { open && (
          <Suspense fallback={<div>Loading...</div>}>
            <Overlay
              closeButton
              medium
              open={open}
              onClose={() => { setOpen(false); }}
              positionedCloseButton={!isMobile}
            >
              <div className={servicesIframeWrapper}>
                <iframe
                  width="100%"
                  height="100%"
                  title="Services"
                  src={servicesURL}
                />
              </div>
            </Overlay>
          </Suspense>
        )}
      </>
    )
  );
};

ServicesButton.displayName = 'Services Button';

ServicesButton.propTypes = {
  name: string,
  servicesURL: string,
  onClick: func,
  showLargeButtonOnMobile: bool
};

ServicesButton.defaultProps = {
  name: '',
  servicesURL: '',
  onClick: () => { },
  showLargeButtonOnMobile: false
};
