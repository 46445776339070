import React, { useEffect } from 'react';
import {
  array,
  bool,
  string,
  oneOfType,
  shape,
  func,
  number,
  node
} from 'prop-types';
import { useDomPath } from '@thd-olt-functional/utils';
import { Image } from '@thd-olt-component-react/core-ui';
// TODO: convert this to core-ui button once they implement a way to use refs with it
import { Button } from '@thd-olt-component-react/button';
import '../add-to-cart.scss';
import { AddToCartPlaceholder } from './AddToCartPlaceholder';

const AddToCart = ({
  cartReqParams,
  cartOptions,
  disabled,
  loading,
  onAddToCartClick,
  children,
  outline,
  className,
  cta,
  specialty,
  link,
  showBOPISOverlay,
  onClick,
  onClickPassThrough,
  onSuccess,
  onFail,
  onError,
  small,
  showIcon
}) => {
  useEffect(() => {
    if (!loading) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-cart.ready');
    }
  }, []);

  const [path, ref] = useDomPath();

  if (loading) {
    return <AddToCartPlaceholder />;
  }

  return (
    <div>
      <Button
        ref={ref}
        disabled={disabled}
        outline={outline}
        small={small}
        className={className}
        cta={cta}
        specialty={specialty}
        link={link}
        onClick={(event) => {
          if (disabled && onError) {
            onError();
          } else if (onClickPassThrough) {
            onClickPassThrough();
          } else if (!disabled) {
            if (onClick) onClick();
            if (showBOPISOverlay) {
              window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
                cartReqParams
              });
            } else {
              window.LIFE_CYCLE_EVENT_BUS.trigger('NATIVE_APP.ADD_TO_CART', {
                quantity: cartReqParams.quantity
              });
              window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
                cartReqParams,
                cartOptions,
                onAddToCartClick,
                onSuccess,
                onFail,
                path
              });
            }
          }
        }}
      >
        {showIcon && (
          <Image
            alt="add to cart icon"
            className="add-to-cart__icon"
            src="https://assets.thdstatic.com/images/v1/add-to-cart-icon--hover-filled-white.svg"
          />
        )}
        {children}
      </Button>
    </div>
  );
};

AddToCart.dataModel = {};
AddToCart.displayName = 'AddToCart';

AddToCart.propTypes = {
  cartOptions: shape({
    directCheckout: bool,
    host: string,
    misship: bool,
    paypal: bool
  }),
  cartReqParams: oneOfType([
    array,
    shape({
      attachedLabor: shape({
        attachedLaborSku: string
      }),
      fulfillmentLocation: string,
      fulfillmentMethod: string,
      itemId: string,
      quantity: number,
      service: shape({
        selected: bool,
        type: string
      }),
      subscriptions: shape({
        frequency: string
      }),
      warrantyItem: shape({
        itemId: string
      }),
    })
  ]),
  children: oneOfType([string, node]).isRequired,
  className: string,
  cta: bool,
  disabled: bool,
  loading: bool,
  onAddToCartClick: func,
  onClick: func,
  onClickPassThrough: func,
  onError: func,
  onFail: func,
  onSuccess: func,
  outline: bool,
  specialty: bool,
  showBOPISOverlay: bool,
  showIcon: bool,
  link: bool,
  small: bool
};

AddToCart.defaultProps = {
  cartOptions: {
    channel: 'desktop',
    host: 'https://www.homedepot.com',
    misship: false,
    paypal: false
  },
  cartReqParams: {
    itemId: null,
    quantity: 1
  },
  className: null,
  cta: false,
  disabled: false,
  loading: false,
  onAddToCartClick: () => {},
  onClick: () => {},
  onClickPassThrough: null,
  onError: null,
  onFail: () => {},
  onSuccess: () => {},
  outline: false,
  showBOPISOverlay: false,
  specialty: false,
  link: false,
  small: false,
  showIcon: false
};

export { AddToCart };
