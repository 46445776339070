/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useContext, useRef } from 'react';
import classNames from 'classnames';
import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  bool, string, arrayOf, shape, number
} from 'prop-types';
import {
  Col,
  Accordion,
  AccordionTitle,
  Heading,
  Row,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@thd-olt-component-react/core-ui';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import {
  arrangeHeaderByColumnOrder,
  isNumber,
  tableViewCustomSort,
  disableSort,
  defaultSortParams
} from '../utils/product-table-view-helpers';
import {
  COLUMNAME,
  cloneArray,
  FIXED_COLUMN_ORDER,
  SORT_TYPE,
  DEFAULT_ACCORDION_ICON,
  DEFAULT_ROWS_VISIBLE,
  CHANNEL,
  DEFAULTGROUPIMAGEURL,
  NO_VALUE_INDICATOR
} from '../constants/appConstant';
import './product-table-view.scss';
import ProductGrid from './productGrid';

const TableView = ({
  columns,
  subGroup,
  rows,
  activeProductDetails,
  setActiveProductDetails,
  uniqueId,
  numberOfRowsVisible = DEFAULT_ROWS_VISIBLE,
  showGroupTitle,
  groupTitle,
  topSellerImageURLs,
  storeId,
  customerType,
  primaryFilter,
  isResolved
}) => {
  const { channel } = useContext(ExperienceContext);
  const { title = '' } = subGroup;
  const orderedHeader = arrangeHeaderByColumnOrder(columns);

  const [sku, setSku] = useState(rows || []);
  const [sortType, setSortType] = useState(defaultSortParams(primaryFilter));
  const [toggleAccordion, setAccordionTogle] = useState(false);
  const [expandedProduct, setExpandedProduct] = useState({});
  const [showNoOfRows, setShowNumberOfRows] = useState(DEFAULT_ROWS_VISIBLE);
  const [productContainerStyle, setProductContainerStyle] = useState({});
  const [showGradient, setShowGradient] = useState({ leftGradient: false, rightGradient: true });
  const tableScrollRef = useRef(null);

  let tableElmentRef;
  const isServer = typeof window === 'undefined';

  const setScrollPosition = () => {
    const containerWidth = tableScrollRef?.current?.clientWidth;
    const element = tableScrollRef?.current?.querySelector('table');
    const scrollLeftValue = element?.scrollLeft;
    const maxWidth = `calc(${containerWidth || 0}px - ${channel === CHANNEL.DESKTOP ? 2 : 1}%)`;
    setProductContainerStyle({ marginLeft: `${scrollLeftValue || 0}px`, maxWidth });
  };

  const isAccordionExpanded = (index) => {
    return (
      activeProductDetails?.index === index
      && activeProductDetails?.subgroupTitle === title
      && activeProductDetails?.uniqueId === uniqueId
    );
  };

  const resetAccordion = () => {
    setAccordionTogle(false);
    setActiveProductDetails({});
    setScrollPosition();
  };

  useEffect(() => {
    const collapseAccordion = toggleAccordion
    && (activeProductDetails.uniqueId !== expandedProduct.uniqueId
      || activeProductDetails.subgroupId !== expandedProduct.subgroupId);
    if (collapseAccordion) {
      setAccordionTogle(toggleAccordion);
    }
  }, [activeProductDetails]);

  useEffect(() => {
    setSku(rows);
  }, [rows.length, isResolved]);

  useEffect(() => {
    if (sortType.order && sortType.type) {
      let clonedSku = cloneArray(sku);

      const sortedRows = clonedSku.map((element, index) => {
        const pickupText = tableScrollRef.current.querySelectorAll('.store__message')?.[index]?.innerText;
        const deliveryText = tableScrollRef.current.querySelectorAll('.shipping__message')?.[index]?.innerText;
        return { ...element, pickupText, deliveryText };

      }).sort(tableViewCustomSort(sortType, () => {}));
      setSku(sortedRows);
      resetAccordion();
    }
  }, [sortType]);

  if (sku.length === 0) return null;

  const onColumnClick = (selectedColumnType) => {
    let newSortType = {
      type: selectedColumnType,
      order: SORT_TYPE.ASCENDING,
    };

    if (sortType.type === selectedColumnType) {
      newSortType.order = sortType.order === SORT_TYPE.ASCENDING ? SORT_TYPE.DESCENDING : SORT_TYPE.ASCENDING;
    } else {
      newSortType.type = selectedColumnType;
    }

    setSortType(newSortType);
  };

  const renderSortIcon = (sortId) => {
    const isAscending = sortType.order === SORT_TYPE.ASCENDING;
    const sortClassName = classNames('table-results-view__sortIcon', {
      'table-results-view__sortIcon__up': isAscending,
      'table-results-view__sortIcon__down': !isAscending,
    });

    return sortId === sortType.type ? (
      <span className={sortClassName}>{' '}</span>
    ) : (
      <span className="table-results-view__sortIcon--desc" />
    );
  };

  const tableCell = (columnName, id, showIcon = false, order, index) => {
    if (!uniqueId || !id) return null;
    const applySort = showIcon && !disableSort(id);
    const cell = order === FIXED_COLUMN_ORDER ? (
      <span className="table-results-view__col-left-shift">{columnName}</span>
    ) : (
      columnName
    );
    let cellInfo = columnName;
    const { brandName, productLabel, canonicalUrl } = columnName;

    if (id === 'ITEMDESC' && typeof (cellInfo) === 'object') {
      const brandInfo = <span><b>{brandName !== 'Unbranded' ? `${brandName} ` : ''}</b></span>;
      cellInfo = <a href={`${canonicalUrl}`}>{brandInfo}<span>{`${productLabel}`}</span></a>;
    } else if ((isServer || !isResolved) && id === 'PRICE') {
      cellInfo = <TextBlock rows={1} color="#E0E0E0" className="table-results-view__price" />;
    }

    const isExpanded = isAccordionExpanded(index);

    const cellClasses = classNames({
      'table-results-view__table-cell': !canonicalUrl,
      'table-results-view__info--ellipsis': id !== 'PRICE'
    });
    return (
      <TableCell className="table-results-view__table-cell-border" key={`row-table-cell-${uniqueId}-${id}`}>
        {applySort ? (
          <div
            className="table-results-view__sort-caret"
            onClick={() => onColumnClick(id)}
            aria-hidden
          >
            {cell}
            {renderSortIcon(id)}
          </div>
        ) : (
          <div
            className={cellClasses}
            onClick={!canonicalUrl ? () => {
              setAccordionTogle(!isExpanded);
              setActiveProductDetails(title, index, uniqueId);
              setScrollPosition();
            } : undefined}
            aria-hidden
          >{cellInfo}
          </div>
        )}
      </TableCell>
    );
  };

  const renderProductAccordion = (index, attributeValue) => {
    const isExpanded = isAccordionExpanded(index);
    return (
      <TableCell
        key={`header-table-cell-${uniqueId}-${attributeValue}`}
        className="table-results-view__table--border"
      >
        <Accordion
          className="table-view__acc"
          expanded={isExpanded}
          icon={DEFAULT_ACCORDION_ICON}
          onChange={() => {
            setAccordionTogle(!isExpanded);
            setActiveProductDetails(title, index, uniqueId);
            setScrollPosition();
          }}
        >
          <>
            <AccordionTitle icon={DEFAULT_ACCORDION_ICON} className="table-view__acc">
              <span>{attributeValue}</span>
            </AccordionTitle>
          </>
        </Accordion>
      </TableCell>
    );
  };

  const renderTableHeader = (header = []) => {
    return header.map((data, index) => {
      const { columnName, columnId, sortable, order = index } = data;
      return tableCell(columnName, columnId, sortable, order);
    });
  };

  const getAvailabilityCell = (fulfillment, id, columnId) => {
    if (!fulfillment || !isResolved) {
      return <TextBlock rows={1} color="#E0E0E0" className="table-results-view__fulfillment" />;
    }
    const fulfillmentData = { fulfillment, identifiers: { itemId: id } };
    return (
      <>
        {columnId === 'INSTOREAVAILABILITY' ? (
          <div key={id}>
            <QueryProvider cacheKey="fulfillment-pod-store">
              <FulfillmentPodStore itemId={id} storeId={storeId} directData={fulfillmentData} useCustomTemplate />
            </QueryProvider>
          </div>

        ) : (
          <div key={id}>
            <QueryProvider cacheKey="fulfillment-pod-shipping">
              <FulfillmentPodShipping itemId={id} storeId={storeId} directData={fulfillmentData} useCustomTemplate />
            </QueryProvider>
          </div>
        )}
      </>
    );
  };

  const valueFormatter = (rawValue, columnName, preferredPriceFlag, unitOfMeasure) => {
    const columnMetaData = COLUMNAME[columnName];
    let value = rawValue;
    if (columnMetaData) {
      if (!value) {
        value = NO_VALUE_INDICATOR;
      }
      const price = isNumber(value) ? value.split('.') : value.toString().split('.');
      return (
        <div className="table-results-view__price" data-testid="price-container">
          {price[0] !== NO_VALUE_INDICATOR && (
            <span className="table-results-view__price-format" data-testid="currency">{columnMetaData.symbol}</span>
          )}
          <span data-testid={`price${price[0]}.${price[1]}`}>{price[0]}
          </span>
          <span hidden>.</span>
          <span className="table-results-view__price-format" data-testid={`cent${price[0]}.${price[1]}`}>
            {price[1]}
          </span>
          <span className="table-results-view__unit-format" data-testid={`unit${price[0]}.${price[1]}`}>
            /{unitOfMeasure}
          </span>
          { preferredPriceFlag && <span className="table-results-view__preferredPriceFlag" />}
        </div>
      );
    }
    return value;
  };

  const renderTableCell = (productAttribute, index) => {
    const { attributes, pricing, fulfillment, id } = productAttribute;
    let rowData = {};
    return orderedHeader.map((header) => {
      const { order, columnName, columnId } = header;
      if (columnId === COLUMNAME.Price.id) {
        rowData = { ...pricing, attributeId: columnId, attributeValue: pricing?.value?.toFixed(2) };
      } else if (columnId === 'INSTOREAVAILABILITY' || columnId === 'ONLINEAVAILABILITY') {
        rowData = { attributeId: columnId, attributeValue: getAvailabilityCell(fulfillment, id, columnId) };
      } else if (columnId === 'ITEMDESC') {
        rowData = { attributeId: columnId, attributeValue: productAttribute };
      } else {
        rowData = attributes?.filter((product) => columnId === product.attributeId);
        rowData = rowData.length > 0 ? rowData[0] : {};
      }
      const { attributeId = columnId, attributeValue = NO_VALUE_INDICATOR,
        preferredPriceFlag, unitOfMeasure } = rowData;
      return parseInt(order, 10) === FIXED_COLUMN_ORDER
        ? renderProductAccordion(index, attributeValue)
        : tableCell(valueFormatter(attributeValue, columnName, preferredPriceFlag, unitOfMeasure),
          attributeId, null, null, index);
    });
  };

  const renderProductPod = (index, id) => {
    if (!isAccordionExpanded(index)) {
      return null;
    }
    return <ProductGrid itemId={id} storeId={storeId} channel={channel} customerType={customerType} />;
  };

  const renderRows = (list = [], threshold) => {
    return list.slice(0, threshold);
  };

  const viewMoreHandler = () => {
    setShowNumberOfRows(sku.length);
  };

  const viewLessHandler = () => {
    setShowNumberOfRows(DEFAULT_ROWS_VISIBLE);
  };

  const viewMore = (
    <span className="table-results-view__viewMoreLess" onClick={viewMoreHandler} aria-hidden>
      {' '}
      + View All
    </span>
  );
  const viewLess = (
    <span className="table-results-view__viewMoreLess" onClick={viewLessHandler} aria-hidden>
      {' '}
      - View Less
    </span>
  );

  const renderViewMore = (list = [], threshold) => {
    const size = list.length;
    if (size > numberOfRowsVisible) {
      return (
        <Col className={classNames('table-results-view__view-more-less')}>
          { size > threshold ? viewMore : viewLess }
        </Col>
      );
    }
    return null;
  };

  const rightGradientDiv = <div className="table-results-view__right-gradient" />;

  const tableClassName = classNames({
    'table-results-view__noscroll': toggleAccordion,
    'table-results-view__scrolltab': !toggleAccordion
  });

  const tableContainerClassName = classNames('table-results-view__table-col', {
    'table-container--no-sub-group': !title
  });

  const isGradientApplicable = () => (channel === CHANNEL.MOBILE && !toggleAccordion);

  const renderLeftGradient = () => {
    const firstColumnWidth = tableScrollRef?.current
      ?.querySelector('table > tbody > tr > td:nth-child(1)')?.offsetWidth + 11;
    const leftGradientDiv = (
      <div
        className="table-results-view__left-gradient"
        style={{ left: `${firstColumnWidth}px` }}
      />
    );
    return showGradient?.leftGradient ? leftGradientDiv : null;
  };

  const renderRightGradient = () => {
    return showGradient?.rightGradient ? rightGradientDiv : null;
  };

  const setGradientFlagOnScroll = () => {
    tableElmentRef = tableScrollRef?.current?.querySelector('table');
    if (!tableElmentRef) return null;
    return tableElmentRef.addEventListener('scroll', () => {
      const scrollLeftValue = tableElmentRef.scrollLeft;
      const isNotScrolledLastColumn = (
        (tableElmentRef.scrollWidth - tableElmentRef.clientWidth) - scrollLeftValue) > 15;
      const isScrolledToFirstColumn = scrollLeftValue <= 1;
      setShowGradient({ leftGradient: !isScrolledToFirstColumn, rightGradient: isNotScrolledLastColumn });
    });
  };

  const renderTitleImage = (imageUrls = []) => {
    let [imageUrl] = DEFAULTGROUPIMAGEURL;
    if (Array.isArray(imageUrls) && imageUrls.length > 0) {
      [imageUrl] = imageUrls;
    }
    return (
      <img
        className="table-results-view__title-img"
        src={imageUrl?.url}
        width={`${imageUrl?.size}px`}
        height={`${imageUrl?.size}px`}
        alt={imageUrl?.url}
      />
    );
  };

  return (
    <Col className="table-results-view__padd-r0">
      <Row>
        {showGroupTitle && (
          <Row className="table-results-view__group-title-container">
            {renderTitleImage(topSellerImageURLs) }
            <Heading
              title={groupTitle}
              className="table-results-view__group-title"
            />
          </Row>
        )}
        {title && (
          <Row className="table-results-view__sub-group-container">
            <Heading
              title={title}
              className="table-results-view__title"
            />
          </Row>
        )}
        <Row>
          <Col xs="12" className={tableContainerClassName}>
            <div ref={tableScrollRef} onLoad={setGradientFlagOnScroll}>
              <Table className={tableClassName}>
                <TableBody className="table-results-view__scroll" key={`${uniqueId}-table`}>
                  { isGradientApplicable() && renderLeftGradient()}
                  { isGradientApplicable() && renderRightGradient()}
                  <TableRow className="table-results-view__table--bor">{renderTableHeader(orderedHeader)}</TableRow>
                  {renderRows(sku, showNoOfRows)?.map((product, index) => {
                    return (
                      <React.Fragment key={`table-row-${uniqueId}-${index}`}>
                        <TableRow className="table-results-view__rows">
                          {renderTableCell(product, index)}
                        </TableRow>
                        <TableRow
                          colSpan="100%"
                          className={
                            isAccordionExpanded(index)
                              ? classNames('table-results-view__show-product-pod')
                              : classNames('table-results-view__hide-product-pod')
                          }
                        >
                          <td colSpan={orderedHeader.length}>
                            <Row key={`product-pod-${uniqueId}-${index}`}>
                              <Row
                                id="product-container"
                                className={
                                  classNames(`table-results-view__product-container_${CHANNEL[
                                    channel ? channel.toUpperCase() : '']}`)
                                }
                                style={productContainerStyle}
                                key={uniqueId}
                              >
                                {renderProductPod(index, product.id)}
                              </Row>
                            </Row>
                          </td>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          {renderViewMore(sku, showNoOfRows)}
        </Row>
      </Row>
    </Col>
  );
};

TableView.displayName = 'TableView';
TableView.dataModel = extend({}, ProductGrid);

export default TableView;

TableView.propTypes = {
  isResolved: bool,
  columns: arrayOf(
    shape({
      order: number,
      columnId: string,
      columnName: string,
      sortable: bool,
    })
  ),
  subGroup: shape({
    title: string
  }),
  rows: arrayOf(shape({
    id: string
  })),
  activeProductDetails: shape({
    uniqueId: string
  }),
  setActiveProductDetails: () => { },
  uniqueId: string,
  numberOfRowsVisible: number,
  showGroupTitle: bool,
  groupTitle: string,
  topSellerImageURLs: arrayOf(shape({
    url: string,
    size: string
  })),
  storeId: string,
  customerType: string,
  primaryFilter: arrayOf(shape({
    sortBy: string,
    sortOrder: string
  }))
};

TableView.defaultProps = {
  isResolved: false,
  columns: [],
  subGroup: [],
  rows: [],
  activeProductDetails: [],
  setActiveProductDetails: () => { },
  uniqueId: '',
  numberOfRowsVisible: DEFAULT_ROWS_VISIBLE,
  showGroupTitle: false,
  groupTitle: '',
  topSellerImageURLs: [{ url: '', size: '' }],
  storeId: '121',
  customerType: 'B2C',
  primaryFilter: []
};
