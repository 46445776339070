import React, { useContext } from 'react';
import {
  shape, object, string, func
} from 'prop-types';
import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { DynamicRecsWrapper } from '@thd-olt-component-react/thd-recs-containers';
import classNames from 'classnames';
import { Card } from '@thd-olt-component-react/card';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import ZoneCard from './zone-card';
import './custom-product-pod-details.scss';

const CustomProductPodDetails = ({
  itemId,
  reference,
  scheme,
  setClosedState
}) => {
  const {
    channel,
    isServer,
    store
  } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  const currentPodContainer = reference?.current?.parentNode;
  const arrowPostion = currentPodContainer?.offsetLeft + (currentPodContainer?.offsetWidth / 2) - 32;
  const overlaySpace = classNames('custom-product-pod-details');
  if (isServer) return null;
  return (
    <div className={overlaySpace} data-component="CustomProductPodDetails">
      <span className="custom-product-pod-details--up-arrow" style={{ left: `${arrowPostion}px` }} />
      <button
        src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
        type="button"
        className="custom-product-pod-details__close-button"
        aria-label="close"
        onClick={() => { setClosedState(true); }}
      />
      {isMobile
        ? (
          <Card noHide>
            <Card.Content maxHeight="none" className="carousel-card">
              <QueryProvider cacheKey="dynamic-recs-wrapper-mobile">
                <DynamicRecsWrapper
                  schemaName={scheme}
                  anchorId={itemId}
                  hideFulfillment
                  errorBoundary
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {
                    ...(scheme === 'visuallysimilar_1_0' && { checkMinimumProducts: true, minimumProductCount: 4 })
                  }
                  hydrator={{
                    className: 'sui-grid',
                    tag: ZoneCard,
                    id: `${scheme}-mobile`,
                    delay: 1500,
                    scrollBuffer: 500
                  }}
                />
              </QueryProvider>
            </Card.Content>
          </Card>
        )
        : (
          <div>
            <QueryProvider cacheKey="dynamic-recs-wrapper-desktop">
              <DynamicRecsWrapper
                schemaName={scheme}
                anchorId={itemId}
                hideFulfillment
                errorBoundary
                // eslint-disable-next-line react/jsx-props-no-spreading
                {
                  ...(scheme === 'visuallysimilar_1_0' && { checkMinimumProducts: true, minimumProductCount: 4 })
                }
                hydrator={{
                  tag: ZoneCard,
                  id: `${scheme}-desktop`,
                  delay: 1500,
                  scrollBuffer: 500
                }}
              />
            </QueryProvider>
          </div>
        )}
    </div>
  );
};

CustomProductPodDetails.displayName = 'CustomProductPodDetails';

CustomProductPodDetails.propTypes = {
  itemId: string.isRequired,
  reference: shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: object
  }).isRequired,
  scheme: string,
  setClosedState: func.isRequired
};

CustomProductPodDetails.defaultProps = {
  scheme: 'PLP_Search_In_Stock',
};

CustomProductPodDetails.dataModel = extend({}, DynamicRecsWrapper);

export { CustomProductPodDetails };
