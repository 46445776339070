import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';
import styles from './emt-description.scss';

const cx = classNames.bind(styles);

export const EmtDescription = ({ title, description }) => {
  return (
    <div data-component="EmtDescription">
      <Typography variant="h3" color="primary" weight="bold">{title}</Typography>
      <div className={cx('emt-description__description-text')}>
        <ReactMarkdown>{description}</ReactMarkdown>
      </div>
    </div>
  );
};

EmtDescription.displayName = 'EmtDescription';
EmtDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};