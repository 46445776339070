import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params,
  shape,
  string as stringType,
  namedFragment,
  typename,
  customType,
  arrayOf,
  useDataModel,
} from '@thd-nucleus/data-sources';
import {
  Card,
  CardMedia,
  CardTitle,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

import { RichText } from '@thd-olt-component-react/rich-text';
import './capability_card.scss';

const CapabilityCard = ({
  componentId, componentClass, isHorizontal, headline, richText, componentPosition
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('capability_card.ready'); }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
    skip: (!!headline || !!richText),
  });

  const altText = data?.component?.altText;
  const headlineData = headline ?? data?.component?.headline;
  const hasImage = !!data?.component?.previewImage;
  const hasIcon = !!data?.component?.previewIcon;

  const richTextData = richText ?? data?.component?.richTextContent;

  let imageUrl;

  if (hasImage) {
    const damDownloadedContentImage = data?.component?.previewImage?.damDownloadedContent;
    const damContentSelectorImage = data?.component?.previewImage?.damContentSelector?.assetData?.[0];

    imageUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl || imageUrl;
  }

  let iconUrl;

  if (hasIcon) {
    const damDownloadedContentImage = data?.component?.previewIcon.damDownloadedContent;
    const damContentSelectorImage = data?.component?.previewIcon.damContentSelector?.assetData?.[0];

    iconUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl || iconUrl;
  }

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: 'CapabilityCard',
      component: 'CapabilityCard',
      position: componentPosition,
      type: 'content'
    }
  });

  return (
    <div
      className="capability-card"
      data-component="CapabilityCard"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      <Card>
        <div className={`sui-flex sui-gap-4 ${isHorizontal ? 'sui-flex-col sm:sui-flex-row' : 'sui-flex-col'}`}>
          {hasImage && imageUrl !== 'String' && (
            <div className="sui-w-full">
              <CardMedia
                src={imageUrl}
                aspect="square"
                alt={`${altText || headlineData}`}
                title={`${altText || headlineData}`}
              />
            </div>
          )}
          <div className="sui-flex sui-flex-col sui-space-y-4 sui-w-full">
            <CardTitle
              leadingIcon={hasIcon && iconUrl !== 'String' && (
                <img
                  width={24}
                  height={24}
                  src={iconUrl}
                  alt="icon"
                  className="sui-w-6 sui-h-6 sui-object-contain"
                />
              )}
              header={headlineData}
              headerWeight="bold"
            />
            {richTextData && (
              <div>
                <RichText content={richTextData} />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

CapabilityCard.propTypes = {
  componentId: PropTypes.string,
  componentClass: PropTypes.string,
  isHorizontal: PropTypes.bool,
  richText: PropTypes.string,
  headline: PropTypes.string,
  componentPosition: PropTypes.number
};

CapabilityCard.defaultProps = {
  componentId: undefined,
  componentClass: 'CapabilityCard',
  componentPosition: 1,
  isHorizontal: false,
  richText: undefined,
  headline: undefined,
};

CapabilityCard.displayName = 'CapabilityCard';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

CapabilityCard.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['CapabilityCard']).isRequired()
  }).shape({
    CapabilityCard: namedFragment({
      inline: true,
      fragmentType: 'CapabilityCard'
    }).shape({
      id: stringType(),
      altText: stringType(),
      previewIcon: DamMediaFragment,
      previewImage: DamMediaFragment,
      headline: stringType(),
      richTextContent: stringType(),
    })
  })
};

export { CapabilityCard };
