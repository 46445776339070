import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import '../countdown-timer.style.scss';
import classNames from 'classnames';

const TimerBlock = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg
}) => {

  const timerClasses = classNames('countdown-timer__wrapper', 'countdown-timer__wrapper--block');

  return (
    <div className={timerClasses}>
      {timeStringAndMessaging.messagingString && shouldUseMessagingString && (
        <div className="countdown-timer__clock-block">
          <span>
            {shouldIncludeSvg && (
              <Image src="https://assets.thdstatic.com/images/v1/clock-black.svg" alt="" width={1} height={1} />
            )}
            {timeStringAndMessaging.messagingString}
          </span>
        </div>
      )}
      {timeStringAndMessaging.timeString && (
        <div className="countdown-timer__time-block">
          {timeStringAndMessaging.timeString}
        </div>
      )}
    </div>
  );
};

TimerBlock.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show image clock
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show the message and time
   */
  timeStringAndMessaging: PropTypes.shape({
    messagingString: PropTypes.string,
    timeString: PropTypes.string
  })
};

TimerBlock.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerBlock;
