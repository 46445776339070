import { DrawerPosition } from './DrawerPosition';

const isPositionLeftOrRight = (position) => (
  position === DrawerPosition.LEFT || position === DrawerPosition.RIGHT
);

const formatMaxSize = (maxSize, suffix, prefix = '') => (prefix + maxSize + suffix);

const getMobileHidePosition = (isLeftOrRight, screenWidth, screenHeight) => (
  isLeftOrRight ? screenWidth : screenHeight
);

/**
 * Helps to create the styles for the drawer based on the position
 */
export const getDrawerPositionStyles = ({
  position,
  toggleFlag,
  maxSize,
  isMobile,
  zIndex,
  screenWidth,
  screenHeight,
}) => {
  const isLeftOrRight = isPositionLeftOrRight(position);
  const hidePositionSize = isMobile ? getMobileHidePosition(isLeftOrRight, screenWidth, screenHeight) : maxSize;
  const contentStyle = {
    transition: `${position} .3s ease-in`,
    [position]: toggleFlag ? 0 : `${formatMaxSize(hidePositionSize, 'px', '-')}`,
    zIndex,
    [
    isLeftOrRight ? 'width' : 'height'
    ]: formatMaxSize(maxSize, isMobile ? '%' : 'px'),
    [
    !isLeftOrRight ? 'width' : 'height'
    ]: '100%',
    [
    !isLeftOrRight ? 'left' : 'top'
    ]: 0
  };
  return contentStyle;
};

/**
 * Generate the style based on the currentDrawer and DrawerPosition
 */
export const getItemPositionStyles = ({
  toggleFlag,
  position,
  maxSize,
  isMobile,
  screenWidth,
  screenHeight,
}) => {
  const isLeftOrRight = isPositionLeftOrRight(position);
  const hidePositionSize = isMobile ? getMobileHidePosition(isLeftOrRight, screenWidth, screenHeight) : maxSize;
  const itemStyle = {
    transition: `${position} .3s ease-in`,
    [position]: toggleFlag ? 0 : `${formatMaxSize(hidePositionSize, 'px', '-')}`
  };
  if (isMobile) {
    if (isLeftOrRight) {
      itemStyle.width = '100%';
    } else {
      itemStyle.height = '100%';
    }
  }
  return itemStyle;
};