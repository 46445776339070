import React from 'react';
import PropTypes from 'prop-types';
import './delivery-options-drawer.scss';
import { Drawer, DrawerItem, DrawerNav } from '@thd-olt-component-react/drawer';
import { DRAWER_HEADER, ERROR_MESSAGE } from '../../helpers/constants';
import './alert.scss';

const DeliveryContentDrawerError = ({ channel, setIsQuickViewOpen }) => {

  return (
    <Drawer
      open
      onClose={() => setIsQuickViewOpen(false)}
      initialItem="delivery-quick-view"
      position="right"
      maxSize={channel === 'Mobile' ? 100 : 500}
      isMobile={channel === 'Mobile'}
    >
      <DrawerItem name="delivery-quick-view">
        <div className={`DeliveryDrawer__wrapper ${channel}`}>
          <DrawerNav>
            <span className={`DeliveryDrawer__header ${channel}`}>{DRAWER_HEADER}</span>
          </DrawerNav>
          <div className={`DeliveryDrawer__divider ${channel}`} />
          <div className={`DeliveryDrawer__body ${channel}`}>
            <div
              className="DeliveryDrawer__text-box alert-inline alert-inline--danger DeliveryDrawer__danger"
              style={{ margin: 10 }}
            >
              {ERROR_MESSAGE}
            </div>
          </div>
        </div>
      </DrawerItem>
    </Drawer>
  );
};

export default DeliveryContentDrawerError;

DeliveryContentDrawerError.propTypes = {
  channel: PropTypes.string,
  setIsQuickViewOpen: PropTypes.func
};

DeliveryContentDrawerError.defaultProps = {
  channel: null,
  setIsQuickViewOpen: () => {
  },
};
