import React from 'react';
import * as pt from 'prop-types';
import { List } from '@thd-olt-component-react/list';
import {
  params, string, shape, arrayOf, bool, useDataModel
} from '@thd-nucleus/data-sources';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { useHidePoints } from '../hooks/useHidePoints';
import { SalientListItem } from './SalientListItem';
import './salient-points.style.scss';

const SalientPoints = (props) => {
  const {
    children, fallbackToDescription, hideIfMultiVariant, itemId, max, title
  } = props;
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });
  const isSuperSku = !!data?.product?.identifiers?.isSuperSku;
  const parentId = data?.product?.identifiers?.parentId;

  const superSkuResponse = useDataModel('metadata', {
    variables: { parentId },
    ssr: false,
    skip: !parentId || !isSuperSku
  });
  const shouldHidePoints = useHidePoints(superSkuResponse);
  const hidePoints = hideIfMultiVariant ? shouldHidePoints : false;

  if ((error && !data) || !data) {
    return null;
  }
  const { product } = data || {};
  const { details } = product || {};

  if (loading && !details) {
    return (
      <LoadingPlaceholder
        type="text"
        ready={false}
        color="#E0E0E0"
        rows={4}
        showLoadingAnimation
      >
        Loading...
      </LoadingPlaceholder>
    );
  }
  const { description, highlights } = details || {};
  const points = (highlights || []).slice(0, max);
  const useFallback = !points.length && fallbackToDescription && description;

  if ((!useFallback && !points.length) || hidePoints) return null;

  return (
    <>
      {title && (
        <div className="salient-points__title" dangerouslySetInnerHTML={{ __html: title }} />
      )}
      { useFallback && (
        <div className="salient-points__description">
          {description}
        </div>
      )}
      <List className="salient-points">
        <>
          { points.map((point, index) => {
            return <SalientListItem key={index} point={point} />;
          })}
        </>
        { children }
      </List>
    </>
  );
};

SalientPoints.displayName = 'SalientPoints';

SalientPoints.propTypes = {
  // Additional salient points apart from productHighlights
  children: pt.node,
  // uses the description as a fallback value if no highlights
  fallbackToDescription: pt.bool,
  // Check whether to hide salient points if on desktop PEP page
  hideIfMultiVariant: pt.bool,
  // An Item that has product highlights
  itemId: pt.string.isRequired,
  // Limit the number of salient bullet points displayed
  max: pt.number,
  // list title
  title: pt.string
};

SalientPoints.defaultProps = {
  children: null,
  fallbackToDescription: false,
  hideIfMultiVariant: false,
  max: 3,
  title: null
};

SalientPoints.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      isSuperSku: bool(),
      parentId: string(),
    }),
    details: shape({
      description: string(),
      highlights: arrayOf(string())
    })
  }),
  metadata: params({ parentId: string().isRequired() }).shape({
    attributes: arrayOf(shape({
      attributeValues: arrayOf(shape({
        value: string()
      }))
    }))
  })
};

export { SalientPoints };
