import React, { useEffect } from 'react';
import { shape, bool } from 'prop-types';
import { Col, Row, Placeholder } from '@thd-olt-component-react/core-ui';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

import { hybridLayoutDataModel } from '../utils/DataModel';

const HybridContentfulWidgets = ({
  contentElements,
  loading,
  isBrowse
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hybrid.ready');
  }, []);
  return (
    <div style={{ backgroundColor: '#FFFFFF' }} className="sui-mt-5 sui-mb-5">
      {contentElements
        && !loading
        && (
          <div data-component="hybrid-upper-components">
            <Row data-testid="hero-carousel">
              <ErrorBoundary name="contentful-hero-carousel">
                <Col lg="12">
                  <contentElements.HeroCarousel />
                </Col>
              </ErrorBoundary>
            </Row>
            <div data-testid="dynamic-section">
              {contentElements.dynamicSections?.map((SubSection, key) => (
                <ErrorBoundary key={`dynamicSection-${key}`} name={`dynamicSection-${key}`}>
                  <div>
                    <Row>
                      <Col lg="12">
                        <SubSection />
                      </Col>
                    </Row>
                  </div>
                </ErrorBoundary>
              )
              )}
            </div>
          </div>
        )}
      {/* This will apply for PLP pages only */}
      {isBrowse && <div id="HybridPLP" />}
    </div>
  );
};
HybridContentfulWidgets.propTypes = {
  contentElements: shape({}).isRequired,
  loading: bool,
  isBrowse: bool
};

HybridContentfulWidgets.defaultProps = {
  loading: false,
  isBrowse: false
};

HybridContentfulWidgets.displayName = 'HybridContentfulWidgets';

HybridContentfulWidgets.dataModel = hybridLayoutDataModel;

export { HybridContentfulWidgets };
