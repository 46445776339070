import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ExperienceContext, useConfigService, useStore } from '@thd-nucleus/experience-context';
import {
  bool,
  params,
  shape,
  string,
  useDataModel
} from '@thd-nucleus/data-sources';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { shouldDisplay } from './util';

import * as analytics from './analytics/PromotionsAnalytics';
import './promotion.style.scss';

const Promotion = ({ itemId, hideConditionalPromoDescription }) => {
  const { channel } = useContext(ExperienceContext);
  const { storeId } = useStore();
  const hiddenPromosConfig = useConfigService('fs:pip-hidden-promotion-types');
  const hiddenPromos = hiddenPromosConfig
    ? JSON.parse(hiddenPromosConfig)
    : [];

  const { data } = useDataModel('product', { variables: { itemId, storeId } });

  const shortDesc = data?.product?.pricing?.promotion?.description?.shortDesc;
  const longDesc = data?.product?.pricing?.promotion?.description?.longDesc;
  const start = data?.product?.pricing?.promotion?.dates?.start;
  const end = data?.product?.pricing?.promotion?.dates?.end;

  const specialBuy = {
    'Online Exclusive Special Buy of the Day': {
      link: '/SpecialBuy/SpecialBuyOfTheDay',
      description: 'Shop All Special Buy of the Day',
    },
    'Online Exclusive Pro Special Buy of the Week': {
      link: '/SpecialBuy/ProSpecialBuyOfTheWeek',
      description: 'Shop All Pro Special Buy of the Week',
    },
    'Online Exclusive Decor Special Buy of the Week': {
      link: '/SpecialBuy/DecorSpecialBuyOfTheWeek',
      description: 'Shop All Decor Special Buy of the Week',
    }
  };

  const promotionClasses = classnames('promotion');

  if (!shouldDisplay({
    data,
    hiddenPromos,
    hideConditionalPromoDescription,
  })) {
    return null;
  }

  return (
    <div data-testid="promotion">
      {!specialBuy[shortDesc] && (
        <div className={promotionClasses}>
          <Tooltip
            channel={channel}
            content={(
              <div>
                <b>Promotion Details</b>
                <div className="promotion__tooltip">{`Price Valid: ${start} - ${end}`}</div>
                {longDesc && <div>{longDesc}</div>}
                {!longDesc && <div>{shortDesc}</div>}
              </div>
            )}
            closeButton
          >
            <button
              type="button"
              className="promotion__link"
              href="#"
              onClick={analytics.promoClickEvent}
            >
              {shortDesc}
            </button>
          </Tooltip>
        </div>
      )}
      {specialBuy[shortDesc] && (
        <div className="promotion__special-buy-wrapper">
          <div className="promotion__special-buy-icon" />
          <div>
            <div className=" promotion__special-buy-timer">Special Buy ends in&nbsp;
              <CountdownTimer
                isPromotionTimer
                endDate={end}
              />
            </div>
            <a className="promotion__special-buy-link" href={specialBuy[shortDesc].link}>
              {specialBuy[shortDesc].description}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

Promotion.displayName = 'Promotion';

Promotion.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    pricing: params({ storeId: string() }).shape({
      preferredPriceFlag: bool(),
      promotion: shape({
        dates: shape({
          start: string(),
          end: string()
        }),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
      })
    })
  })
};

Promotion.propTypes = {
  /**
  * To display the Item Id
  */
  itemId: PropTypes.string.isRequired,
  /**
  * To hide the promotion description
  */
  hideConditionalPromoDescription: PropTypes.bool
};

Promotion.defaultProps = {
  hideConditionalPromoDescription: false
};

export default Promotion;
