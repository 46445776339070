import React from 'react';
import { shape as shapeType, bool, string } from 'prop-types';
import classNames from 'classnames';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { getTarget } from './utils/getTarget';
import { getMediaUrl } from './utils/getMediaUrl';

const PiccolaComposableBannerRenderer = ({ data, componentId }) => {
  const {
    title, subtitle, smallMedia, altText, destinationUrl, legalCopy = {}
  } = data || {};

  const smallMediaUrl = getMediaUrl(smallMedia);
  const { legalText = '', legalUrl = '' } = legalCopy || {};

  const mainContent = (
    <div className={classNames(
      'sui-flex',
      'sui-items-center',
      'sui-paper-outlined',
      'sui-p-4',
      'sui-max-h-[148px]',
      'md:sui-max-h-[100px]',
      'lg:sui-max-h-[80px]',
      { 'hover:sui-shadow-lg': destinationUrl }
    )}
    >
      {/* Small Media container */}
      {smallMediaUrl && (
        <div
          className="sui-min-w-12 sui-min-h-12 sui-mr-4 sui-flex sui-items-center"
          data-contentful-field-id="smallMedia"
        >
          <img src={smallMediaUrl} alt={altText} width="48px" height="48px" data-contentful-field-id="altText" />
        </div>
      )}
      {/* Title & Subtitle container */}
      <div className="sui-grid sui-items-center lg:sui-grid-flow-col lg:sui-gap-4">
        <Typography
          variant="h3"
          height="tight"
          lineClamp={{
            default: '2', sm: '2', md: '1', lg: '1', xl: '1', '2xl': '1'
          }}
          data-contentful-field-id="title"
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            underline="none"
            variant="body-base"
            height="tight"
            lineClamp={{
              default: '3', sm: '3', md: '2', lg: '2', xl: '2', '2xl': '2'
            }}
            data-contentful-field-id="subtitle"
          >
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );

  return (
    <div
      data-contentful-entry-id={componentId}
      data-testid="piccola-composable-banner"
    >
      {/* Main Content */}
      {destinationUrl ? (
        <Link
          underline="none"
          href={destinationUrl}
          target={getTarget(destinationUrl)}
          data-contentful-field-id="destinationUrl"
        >
          {mainContent}
        </Link>
      ) : (
        mainContent
      )}
      {/* Legal Text */}
      {legalText && (
        <div className="sui-pt-2">
          {legalUrl ? (
            <Link href={legalUrl} target="_blank" variant="body-xs" data-contentful-field-id="legalCopy">
              {legalText}
            </Link>
          ) : (
            <Typography variant="body-xs" data-contentful-field-id="legalCopy">{legalText}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

PiccolaComposableBannerRenderer.propTypes = {
  data: shapeType({}),
  componentId: string
};

PiccolaComposableBannerRenderer.defaultProps = {
  data: {},
  componentId: ''
};

export { PiccolaComposableBannerRenderer };
