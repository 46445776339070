import { useEffect, useReducer } from 'react';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { ACTION_TYPES } from '../statics';
import { reducer } from '../reducer/reducer';
import { isItemOutOfStock, shouldDisplaySwatchCards, isItemUnavailableInStore } from '../super-sku-util';

export const initialState = {
  attributes: [],
  childItemsLookup: [],
  superDuperSku: {},
  isDiscontinued: false,
  expressDelivery: false,
  specificationGroup: [],
};

export const useSuperSku = ({
  disableMediaAndAvailability,
  itemId,
  storeId,
  isMiniSwatch
}) => {
  const opts = {
    ssr: false,
    variables: {
      itemId,
      storeId
    }
  };
  const { data } = useDataModel('product', opts);

  const {
    availabilityType,
    details,
    identifiers,
    sizeAndFitDetail,
    fulfillment,
    specificationGroup,
    info,
  } = data?.product || {};

  const { discontinued, type } = availabilityType || {};

  let isSuperSku = !!identifiers?.isSuperSku;
  let parentId = identifiers?.parentId;

  const collectionId = details?.collection?.collectionId;
  const itemSizeAndFitDetail = sizeAndFitDetail;

  const isDiscontinued = !!discontinued;
  const expressDelivery = podFulFillmentUtils?.isBODFS(data?.product);
  const isOutOfStock = isItemOutOfStock(fulfillment, type);
  const isUnavailableInStore = isItemUnavailableInStore(fulfillment, type);

  const [state, dispatch] = useReducer(reducer, initialState);
  const metadataOpts = {
    variables: {
      parentId
    },
    ssr: false
  };
  const [makeMetadataCall, superSkuData] = useLazyDataModel('metadata', metadataOpts);

  useEffect(() => {
    if (parentId && isSuperSku && state?.previousParentId !== parentId) {
      makeMetadataCall();
    }
  }, [parentId, isSuperSku, state?.previousParentId !== parentId]);

  useEffect(() => {
    if (!superSkuData?.called) {
      return;
    }

    if (superSkuData?.data) {
      const metaDataResponse = superSkuData?.data?.metadata;
      if (metaDataResponse) {
        dispatch({
          collectionId,
          disableMediaAndAvailability,
          isDiscontinued,
          isOutOfStock,
          expressDelivery,
          isUnavailableInStore,
          itemId,
          itemSizeAndFitDetail,
          isMiniSwatch,
          info,
          parentId,
          response: metaDataResponse,
          specificationGroup,
          type: ACTION_TYPES.LOAD_STATE
        });
      } else {
        dispatch({ type: ACTION_TYPES.NO_DATA_STATE });
      }
    } else if (superSkuData?.error && !superSkuData?.data) {
      dispatch({ type: ACTION_TYPES.ERROR_STATE });
    }
    if (superSkuData.loading) {
      dispatch({ type: ACTION_TYPES.LOADING_STATE });
    }
  }, [superSkuData?.data, superSkuData]);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.UPDATE_ALERT_VALUES,
      isDiscontinued,
      isOutOfStock,
      expressDelivery,
      isUnavailableInStore,
      disableMediaAndAvailability
    });
  }, [data?.product?.itemId]);

  return [state, dispatch];
};

export const getSelectedAttribute = (state, attributeLabel, attributeValues) => {
  const { selectedAttributes } = state;
  const currentAttribute = selectedAttributes.find((attribute) => {
    return attribute.attributeLabel === attributeLabel;
  }) || {};

  let swatchGuid = null;
  if (currentAttribute.attributeValue && currentAttribute.isSwatch) {
    swatchGuid = attributeValues?.find((attributeValue) => attributeValue?.value === currentAttribute.attributeValue)
      ?.swatchGuid;
  }

  return {
    attributeValue: currentAttribute.attributeValue || 'Please Select',
    swatchGuid
  };
};

export const getAttributeType = (
  state, channel, attribute, isOverflowSection, hideCards, disableMediaAndAvailability, large
) => {
  const { isSwatch } = attribute;
  const { childItemsLookup = [] } = state;
  const isCard = large || !!(isSwatch
    && !hideCards
    && shouldDisplaySwatchCards(state, channel)
    && childItemsLookup.length
    && !disableMediaAndAvailability
  );

  if (isOverflowSection) return 'dropdown';
  if (isCard) return 'card';
  if (isSwatch) return 'swatch';
  if (!isOverflowSection && !isSwatch) return 'tile';

  return null;
};

export const attributeValueFilter = ({ attribute, attributeValue, enabledTilesInAttribute }) => {
  return (attribute.isDominantAttribute
    || enabledTilesInAttribute.has(attributeValue.value)
    || enabledTilesInAttribute.size === 1);
};
