import React, { useContext, useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { QueryContext, QueryProvider } from '@thd-nucleus/data-sources';
import { RecentlyViewedWrapper } from './RecentlyViewedWrapper';
import ZoneCard from '../utils/zone-card';
import { rvDataModel } from '../dataModel';

const PlpRvRenderPlaceholder = ({
  isCategory,
  isSearch,
  showLoading
}) => {
  const { defaultVariables } = useContext(QueryContext) || {};

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  let schemeName;

  if (isCategory) {
    schemeName = 'rv_categorypages_rr';
  } else if (isSearch) {
    schemeName = 'rv_search_plp_rr';
  } else {
    schemeName = 'rv_nav_plp_rr';
  }
  if (!schemeName || !isMounted) return null;

  const rvDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <div data-component="PlpRvRenderPlaceholder" className="sui-w-full">
      <QueryProvider
        cacheKey="rv-plp-recs"
        defaultVariables={rvDefaultVariables}
        persist
      >
        <RecentlyViewedWrapper
          schemaName={schemeName}
          showLoading={showLoading}
          errorBoundary
          hydrator={{
            delay: 2000,
            className: 'sui-grid',
            tag: ZoneCard,
            id: `${schemeName}-rv`,
            scrollBuffer: 1200
          }}
        />
      </QueryProvider>
    </div>
  );
};

PlpRvRenderPlaceholder.displayName = 'PlpRvRenderPlaceholder';
PlpRvRenderPlaceholder.dataModel = rvDataModel;

PlpRvRenderPlaceholder.propTypes = {
  isCategory: bool,
  isSearch: bool,
  showLoading: bool
};

PlpRvRenderPlaceholder.defaultProps = {
  isCategory: false,
  isSearch: false,
  showLoading: false
};

export { PlpRvRenderPlaceholder };
