import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf,
  customType,
  fragment,
  params,
  shape,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  Button,
  Card,
  CardMedia,
  CardTitle
} from '@one-thd/sui-atomic-components';

import { publish } from '../../utils/publisher';
import { RichText } from './RichText';
import AnchorLink from './AnchorLink';
import HrefLink from './HrefLink';
import './spotlight_rich_text.scss';
import VideoPlayerWrap from './VideoPlayerWrap';

const SpotlightRichText = ({ componentId, componentClass, componentPosition = 1, value }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('spotlight_rich_text.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: 'SpotlightRichText',
      component: 'SpotlightRichText',
      position: componentPosition,
      type: 'content'
    }
  });

  const defaultValue = data?.component || {};

  const cta = value?.cta || defaultValue.cta;
  const link = value?.link || defaultValue.link;
  const richTextContent = value?.richTextContent || defaultValue.richTextContent;
  const linkList = value?.linkList || defaultValue.linkList;
  const title = value?.title || defaultValue.title;
  const previewImage = value?.previewImage || defaultValue.previewImage;
  const renderLinkList = linkList?.length > 0 && linkList[0].label;
  const videoUrl = value?.videoUrl || defaultValue.videoUrl;

  let richText;

  try {
    richText = JSON.parse(richTextContent);
    // eslint-disable-next-line no-empty
  } catch (error) { }

  let imageUrl;

  if (previewImage) {
    const damDownloadedContentImage = previewImage.damDownloadedContent;
    const damContentSelectorImage = previewImage.damContentSelector?.assetData?.[0];

    imageUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl || imageUrl;
  }

  const handleContentClick = () => {
    publish({
      widgetId: componentId,
    });
  };

  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component="SpotlightRichText"
      data-component-name="SpotlightRichText"
      data-component-position={componentPosition}
      className="sui-grid sui-h-full"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <Card className="sui-flex sui-w-full srt-card">
        {(videoUrl) ? (
          <VideoPlayerWrap videoUrl={videoUrl} />
        ) : (
          <CardMedia
            src={imageUrl}
            aspect="wide"
            alt={`Image for ${title}`}
            title={`Image for ${title}`}
          />
        )}
        <CardTitle header={title} grow={false} />
        <div className="sui-flex sui-flex-col sui-flex-1 sui-w-full">
          <div className="sui-flex-1 sui-w-full">
            {richText && <RichText content={richText} />}
          </div>
          <div className="sui-flex sui-flex-col sui-gap-2 sui-w-full">
            {renderLinkList ? (
              <div className="sui-flex sui-gap-2 sui-flex-col sui-mb-2 sui-mt-4">
                {linkList?.map((linkItem, key) => {
                  if (linkItem?.href?.includes('#')) {
                    return <AnchorLink linkItem={linkItem} key={key} />;
                  }
                  return <HrefLink linkItem={linkItem} key={key} />;
                }
                )}
              </div>
            ) : (
              <div className="sui-mb-2" />
            )}
            {link && cta
              && (
                <Button href={link} variant="secondary" onClick={handleContentClick} fullWidth>
                  {cta || ''}
                </Button>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};

const SpotlightRichTextPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  richTextContent: PropTypes.string.isRequired,
  eyebrow: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  previewImage: PropTypes.shape({
    damContentSelector: PropTypes.shape({
      assetData: PropTypes.arrayOf(
        PropTypes.shape({
          selectedImageUrl: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    damDownloadedContent: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  videoUrl: PropTypes.string,
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
});

SpotlightRichText.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  value: SpotlightRichTextPropTypes,
};

SpotlightRichText.defaultProps = {
  componentClass: 'SpotlightRichText',
  componentPosition: 1,
  value: undefined,
};

SpotlightRichText.displayName = 'SpotlightRichText';

SpotlightRichText.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['SpotlightRichText']).isRequired()
  }).shape({
    SpotlightRichText: fragment().shape({
      id: string(),
      title: string(),
      richTextContent: string(),
      eyebrow: string(),
      cta: string(),
      link: string(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      }),
      videoUrl: string(),
      linkList: arrayOf(shape({
        label: string(),
        href: string(),
      }))
    })
  })
};

export { SpotlightRichText };
