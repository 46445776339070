import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { shape } from 'prop-types';
import { Alert } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getAlertBannerDetails } from '../../car-picker-helpers';
import styles from './automotive-banner.module.scss';

const classes = classNames.bind(styles);

const AutomotiveBanner = ({ fitment }) => {

  const { message, type } = getAlertBannerDetails('browse', fitment);
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  let bannerClasses = classes('automotive-banner', {
    'automotive-banner--mobile': isMobile,
  });

  return (
    <div className={bannerClasses}>
      <Alert type={type} message={message} inline />
    </div>
  );
};

AutomotiveBanner.propTypes = {
  fitment: shape({})
};

AutomotiveBanner.defaultProps = {
  fitment: null
};

AutomotiveBanner.displayName = 'AutomotiveBanner';

export { AutomotiveBanner };
