import { parse } from 'query-string';
import { HTTP_CODE, ERROR_HANDLING_EXPERIENCE } from '../../utils/redirector-utils';

function containsBlacklistedQueryParams(originalPath) {
  const queryStr = originalPath.split('?')[1];
  if (!queryStr) return false;
  const BLACKLIST = ['articles', 'services'];
  const params = parse(queryStr);
  return (params.contentType && BLACKLIST.includes(params.contentType));
}

export function isSingleProductResult({ data, path, searchTypeParam }) {
  if (!data?.searchModel) return null;
  if (/^\/s\//.test(path)) {
    const { totalProducts, didYouMean, correctedKeyword } = data?.searchModel?.searchReport || {};
    const { universalSearch, relatedServices } = data?.searchModel?.relatedResults || {};
    const { identifiers } = data?.searchModel?.products?.[0] || {};

    if (totalProducts === 1
      && !relatedServices?.length
      && !universalSearch
      && !didYouMean
      && !correctedKeyword
      && !containsBlacklistedQueryParams(path)) {
      return {
        shouldRedirect: true,
        redirectPath: `${identifiers?.canonicalUrl}${searchTypeParam}`,
        httpCode: HTTP_CODE.TEMP_REDIRECT,
        description: 'Single product result resulted in redirect'
      };
    }
  }
  return null;
}

export function isInvalidRequest({ data, keyword, navParam, nttKeyword, error }) {

  if (!data) {
    return null;
  }

  if (((keyword || navParam || nttKeyword) && data?.searchModel === null)
    || (!data?.searchModel && !error && !keyword && !navParam && !nttKeyword)) {

    if (error?.networkError?.statusCode === HTTP_CODE.TIMEOUT) {
      return null;
    }

    return {
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: 'The request input parameters resulted in a 404',
      errorHandlingExperience: ERROR_HANDLING_EXPERIENCE.BROWSE
    };
  }

  return null;
}

export function isSearchRedirect({
  data,
  searchTypeParam,
  keyword,
  storeParam,
  storeSelectionParam
}) {
  const { searchRedirect, analytics } = data?.searchModel?.metadata || {};
  if (searchRedirect) {

    return {
      shouldRedirect: true,
      // eslint-disable-next-line max-len
      redirectPath: `${searchRedirect}&searchRedirect=${keyword}${searchTypeParam}&semanticToken=${encodeURIComponent(analytics?.semanticTokens)}${storeParam}${storeSelectionParam}`,
      httpCode: HTTP_CODE.PERM_REDIRECT,
      description: 'Searchnav directed redirect'
    };
  }

  return null;
}

export function isCanonicalRedirect({ data, path, searchTypeParam, navParam }) {

  if (!data) {
    return null;
  }

  const { canonicalUrl } = data?.searchModel?.metadata || {};
  const canonicalPath = (canonicalUrl || '').split('?')[0];

  if (decodeURI(path) !== decodeURI(canonicalPath)) {
    if (/Ntt-/.test(path)) {
      return null;
    }

    if (/^\/s/.test(path)) {
      return null;
    }

    if (/Hide-Unavailable-Products/.test(path) && RegExp(navParam).test(canonicalPath)) {
      return null;
    }

    if (!canonicalUrl) {
      return {
        shouldRedirect: false,
        isErrorStatus: true,
        httpCode: HTTP_CODE.NOT_FOUND,
        description: 'Page not found',
        errorHandlingExperience: ERROR_HANDLING_EXPERIENCE.BROWSE
      };
    }

    return {
      shouldRedirect: true,
      redirectPath: `${canonicalUrl}${searchTypeParam}`,
      httpCode: HTTP_CODE.PERM_REDIRECT,
      description: 'Canonical url directed redirect'
    };
  }

  return null;
}

export function isNavParamMissing({ data, navParam, nttKeyword, error }) {

  if (data?.searchModel === null && !navParam && !nttKeyword) {
    return {
      error,
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: 'Page not found',
      errorHandlingExperience: ERROR_HANDLING_EXPERIENCE.BROWSE
    };
  }

  return null;
}
