import React from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { func, string } from 'prop-types';

const CustomCatalogButton = ({ onCpoClick, title }) => {
  return (
    <div className="sui-mt-5">
      <Button variant="primary" onClick={onCpoClick}>
        {title}
      </Button>
    </div>
  );
};

CustomCatalogButton.displayName = 'CustomCatalogButton';

CustomCatalogButton.propTypes = {
  onCpoClick: func,
  title: string.isRequired,
};

CustomCatalogButton.defaultProps = {
  onCpoClick: () => {},
};

export { CustomCatalogButton };
