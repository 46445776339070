import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@one-thd/sui-carousel';
import { useDataModel } from '@thd-nucleus/data-sources';
import classNames from 'classnames/bind';
import { Button, Link, Typography } from '@one-thd/sui-atomic-components';
import { promoVisNavDataModel } from './dataModel';
import { PromoVisualNavigationCard } from './PromoVisualNavigationCard';
import styles from './promo-visual-navigation.scss';

const PromoVisualNavigation = ({
  componentId,
  componentClass,
  componentPosition,
  experienceHasSideNav,
  title,
  lazyLoad
}) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass: 'PromoVisualNavigation'
    }
  });

  if (!data || loading || error) {
    return null;
  }
  const { callToAction, destinationLink } = data?.component?.sectionCTAButton || {};

  const promoVisNavList = data?.component?.promoVisualNavigationList;
  const cardsArray = promoVisNavList.map((item, idx) => (
    <PromoVisualNavigationCard item={item} key={idx + 1} position={idx + 1} lazyLoad={lazyLoad} />
  ));
  const cardCount = promoVisNavList?.length;
  const desktopGridStylesVariations = {
    desktopWithSideNav: 'xl:sui-grid-cols-4',
    desktopWithOutSideNavAndFiveOrMoreCards: 'xl:sui-grid-cols-6',
    desktopWithOutSideNavAndFourOrLessCards: 'xl:sui-grid-cols-4'
  };
  const cx = classNames.bind(desktopGridStylesVariations);

  const tabletAndDesktopTemplate = (
    <div
      className={cx(
        'card-tile__collection',
        'sui-hidden',
        'md:sui-grid',
        'md:sui-grid-cols-4',
        'md:sui-gap-6',
        'xl:sui-gap-8',
        { desktopWithSideNav: experienceHasSideNav },
        { desktopWithOutSideNavAndFiveOrMoreCards: !experienceHasSideNav && cardCount >= 5 },
        { desktopWithOutSideNavAndFourOrLessCards: !experienceHasSideNav && cardCount <= 4 }
      )}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-testid="promo-visual-navigation"
      data-component-id={componentId}
      data-component-name={componentClass}
      data-component-position={componentPosition}
    >
      {cardsArray}
    </div>
  );

  const mobileTemplate = (
    <div className="sui-block md:sui-hidden">
      <Carousel
        slidesPerGroup={2}
        slidesPerView={2}
        breakpoints={{
          sm: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          md: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          lg: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          xl: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          }
        }}
      >
        {cardsArray.slice(0, 12)}
      </Carousel>
    </div>
  );

  const legalCopy = data?.component?.legalCopy;
  const cxLegal = classNames.bind(styles);

  const headerSection = (
    <>
      {title && (
        <div className="sui-flex sui-flex-col md:sui-flex-row sui-justify-between sui-pb-4">
          <div className="sui-pb-4 md:sui-p-0">
            <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>
              {title}
            </Typography>
          </div>
          {destinationLink && callToAction && (
            <div className="sui-w-fit">
              <Button href={destinationLink} variant="primary">
                {callToAction}
              </Button>
            </div>
          )}
        </div>
      )}
      {legalCopy && (
        <div className={cxLegal('promo-visual-navigation__legal-copy', 'sui-pb-4')}>
          {legalCopy?.legalUrl ? (
            <Link href={legalCopy?.legalUrl} target="_blank">
              {legalCopy?.legalText}
            </Link>
          ) : (
            <Typography>{legalCopy?.legalText}</Typography>
          )}
        </div>
      )}
    </>
  );

  return (
    <div>
      {headerSection}
      {mobileTemplate}
      {tabletAndDesktopTemplate}
    </div>
  );
};

PromoVisualNavigation.displayName = 'PromoVisualNavigation';
PromoVisualNavigation.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  experienceHasSideNav: PropTypes.bool,
  title: PropTypes.string,
  lazyLoad: PropTypes.bool
};

PromoVisualNavigation.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  experienceHasSideNav: false,
  title: '',
  lazyLoad: false
};

PromoVisualNavigation.dataModel = promoVisNavDataModel;

export { PromoVisualNavigation };
