import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import { Heading, Image } from '@thd-olt-component-react/core-ui';

import paintModals from '../../../data/paint-modals.json';
import styles from './PaintType.module.scss';

const { paintTypes } = paintModals;
const cx = classNames.bind(styles);

export const PaintType = () => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('paintType.ready'); }, []);

  const { description, types } = paintTypes;

  return (
    <div className={cx('type-modal')} data-component="PaintModalContentPaintType">
      <Heading
        underline
        className={cx('type-modal__heading')}
        title="Paint Types"
      />
      <div className={cx('type-modal__description')}>{description}</div>
      <div className={cx('type-modal__tiles')}>
        {types?.map((type) => {
          const { imageUrl, options, paintType } = type;
          return (
            <div className={cx('type-modal__tile')} key={paintType}>
              <Image className={cx('tile__image')} src={imageUrl} alt={paintType} />
              <div className={cx('options')}>
                <div className={cx('options-title')}>{paintType}</div>
                <ul className={cx('options-list')}>
                  {options.map((option, index) => {
                    return (
                      <li className={cx('option')} key={`option-${index + 1}`}>{option}</li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PaintType.displayName = 'PaintType';
