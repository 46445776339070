import React from 'react';
import {
  oneOf, oneOfType, string, bool, node
} from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import classNames from 'classnames';
import './message.scss';

const Message = ({
  channel, discount, disabled, linkText, messageText, className
}) => {

  const messageStyles = classNames(className, 'subscribe-and-save-message');

  const tooltip = (
    <div
      className="subscribe-and-save-tooltip"
      id="subscribe-and-save-tooltip"
      data-testid="subscribe-and-save-tooltip"
    >
      <div className="subscribe-and-save-tooltip__container">
        <div className="subscribe-and-save-tooltip__info">
          <div className="subscribe-and-save-tooltip__icon">
            <div className="subscribe-and-save-tooltip__icons subscribe-and-save-tooltip__icons--delivery" />
          </div>
          <div>{discount}% off subscription purchases plus free delivery</div>
        </div>
        <div className="subscribe-and-save-tooltip__info">
          <div className="subscribe-and-save-tooltip__icon">
            <div className="subscribe-and-save-tooltip__icons subscribe-and-save-tooltip__icons--deliverymanagement" />
          </div>
          <div>Pause or cancel your subscription anytime online</div>
        </div>
        <div className="subscribe-and-save-tooltip__info">
          <div className="subscribe-and-save-tooltip__icon">
            <div className="subscribe-and-save-tooltip__icons subscribe-and-save-tooltip__icons--nomembershipfee" />
          </div>
          <div className="subscribe-and-save-tooltip__message">No club or membership fees required</div>
        </div>
      </div>
      <br />
      <p className="subscribe-and-save-tooltip__footer">
        Subscribe to this product today to receive free delivery directly
        to your doorstop at your convenience. Just select your preferred
        delivery frequency from the drop down menu and checkout to start
        your subscription.
      </p>
    </div>
  );

  return disabled
    ? (
      <div className="subscribe-and-save-message subscribe-and-save-message--disabled">
        <span>Available for Ship to Home only.</span>
      </div>
    )
    : (
      <div className={messageStyles}>
        {messageText
          && <span>{messageText}</span>}
        <Tooltip channel={channel} placement="bottom" content={tooltip} closeButton>
          <button
            type="button"
            className="u__default-link subscribe-and-save-message__learn-more scrollTouch"
            id="subscribe-and-save-message__learn-more"
          >
            {linkText}
          </button>
        </Tooltip>
      </div>
    );
};

Message.displayName = 'SubscribeAndSave-Message';

Message.propTypes = {
  channel: oneOf(['mobile', 'desktop']),
  className: string,
  discount: string,
  disabled: bool,
  linkText: oneOfType([node, string]),
  messageText: oneOfType([node, string])
};

Message.defaultProps = {
  channel: 'desktop',
  className: null,
  discount: '5',
  disabled: false,
  linkText: 'Learn More',
  messageText: 'Schedule your product to be delivered for free when you need it most. '
};

export { Message };
