import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import classNames from 'classnames/bind';
import { Typography } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { visNavDataModel } from './dataModel';
import styles from './visual-navigation.scss';
import { VisualNavigationCard } from './VisualNavigationCard';

const VisualNavigation = ({
  componentId,
  componentClass,
  componentPosition,
  isInSection,
  lazyLoad,
  experienceHasSideNav
}) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass: 'VisualNavigation'
    }
  });

  if (!data || loading || error) {
    return null;
  }
  const cx = classNames.bind(styles);
  const visNavList = data?.component?.visualNavigationList;
  const cardCount = visNavList?.length;
  const title = data?.component?.title;

  const getMobileVariantByCardCount = (count) => {
    return '6';
  };

  const getDesktopVariantByCardCount = (count) => {
    if (count <= 4) return '3';

    return '2';
  };

  const mobileVariant = getMobileVariantByCardCount(cardCount);
  const tabletVariant = '3';
  const desktopVariant = getDesktopVariantByCardCount(cardCount);

  const cardCollection = (
    <>
      <div
        className={cx('card-tile__collection',
          'sui-grid',
          'sui-gap-1',
      `mobile-grid-cols-${mobileVariant}`,
      `tablet-grid-cols-${tabletVariant}`,
      `desktop-grid-cols-${desktopVariant}`
        )}
        id={`${componentClass}-${componentPosition}-${componentId}`}
        data-testid="visual-navigation"
        data-component-id={componentId}
        data-component-name={componentClass}
        data-component-position={componentPosition}
        data-component="VisualNavigation"
      >
        {visNavList.map((item, idx) => (
          <VisualNavigationCard
            item={item}
            key={idx + 1}
            position={idx + 1}
            lazyLoad={lazyLoad}
          />
        ))}
      </div>
    </>
  );

  return (
    isInSection ? cardCollection
      : (
        <>
          <div className={cx('sui-pb-4')}>
            <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>{title}</Typography>
          </div>
          <ImpressionProvider
            key={componentPosition}
            data={{
              id: 'componentId',
              component: 'Section',
              name: 'Section',
              type: 'content'
            }}
          >
            {cardCollection}
          </ImpressionProvider>

        </>
      )
  );
};

VisualNavigation.displayName = 'VisualNavigation';
VisualNavigation.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  isInSection: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  experienceHasSideNav: PropTypes.bool
};

VisualNavigation.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  isInSection: false,
  lazyLoad: false,
  experienceHasSideNav: false
};

VisualNavigation.dataModel = visNavDataModel;

export { VisualNavigation };