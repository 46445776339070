import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, SkeletonContent, SkeletonBlock, SkeletonLine } from '@one-thd/sui-atomic-components';

export const ProductPodPlaceholder = ({ podsCount, podType }) => {
  if (podType === 'list') {
    return (
      <div
        className="sui-w-full sui-grid"
        data-component="ProductPodListPlaceholder"
        data-testid="product-pod-list-placeholder"
      >
        {[...Array(podsCount)].map((n, i) => (
          <Skeleton key={`placeholder-${i}`} orientation="horizontal">
            <SkeletonContent density="loose" disablePadding="x" initialSize="2/4">
              <SkeletonBlock aspect="wide" style={{ height: '220px' }} />
            </SkeletonContent>
            <SkeletonContent density="loose" disablePadding="x" initialSize="2/4">
              <SkeletonLine variant="multi" numberOfLines={7} />
            </SkeletonContent>
            <SkeletonContent density="loose" disablePadding="x" initialSize="2/4">
              <SkeletonLine variant="multi" numberOfLines={7} />
            </SkeletonContent>
          </Skeleton>
        ))}
      </div>
    );
  }
  return (
    <div
      className="sui-w-full sui-grid sui-grid-cols-12"
      data-component="ProductPodPlaceholder"
      data-testid="product-pod-placeholder"
    >
      {[...Array(podsCount)].map((n, i) => (
        <Skeleton
          key={`placeholder-${i}`}
          data-component="ProductPodPlaceholder"
          className="sm:sui-col-span-12 md:sui-col-span-4 lg:sui-col-span-3 sui-col-span-12"
        >
          <SkeletonBlock aspect="square" style={{ height: '250px' }} />
          <SkeletonLine variant="multi" numberOfLines={7} />
        </Skeleton>
      ))}
    </div>
  );
};

ProductPodPlaceholder.propTypes = {
  podsCount: PropTypes.number,
  podType: PropTypes.string
};

ProductPodPlaceholder.defaultProps = {
  podsCount: 8,
  podType: null
};
