/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { CustomerContext } from '@thd-olt-functional/customer-information';

// eslint-disable-next-line import/no-cycle
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { QueryContext } from '@thd-nucleus/data-sources';
// eslint-disable-next-line import/no-cycle
import ContentComponent, { SpreadContent } from './Content.component';
import BoxHero from '../BoxHero';
import BlockHeader from '../BlockHeader';
import Column from '../Column';
import DecorHero from '../DecorHero';
import EtchRichText from '../EtchRichText';
import { EtchStoryCard } from '../EtchStoryCard';
import EtchMyStore from '../EtchMyStore';
import EtchStoryWithImage from '../EtchStoryWithImage';
import EtchVisualNav from '../EtchVisualNav';
import EtchCopyBlockHeader from '../EtchCopyBlockHeader';
import EtchCustomNavigation from '../EtchCustomNavigation';
import EtchVisualNav3AcrossMobile from '../EtchVisualNav3AcrossMobile';
import EtchVisualNavigationStory from '../EtchVisualNavigationStory';
import Grid from '../Grid';
import HeroModule from '../HeroModule';
import EtchEndCap from '../EtchEndCap';
import EtchCarousel from '../Carousel';
import EtchAnchorLinks from '../EtchAnchorLinks';
import EtchBlockHeaderStory from '../EtchBlockHeaderStory';
import EtchSubHeroModule from '../EtchSubHeroModule';
import EtchVideo from '../EtchVideo';
import BasicHeroWithOutButton from '../BasicHeroWithOutButton';
import RowHeader from '../RowHeader';
import LifeStyleBanner from '../LifeStyleBanner';
import MessagingBanner from '../MessagingBanner';
import DPD from '../DPD';
import Image from '../Image';
import SpecialBuyModule from '../SpecialBuyModule';
import * as analytics from '../../analytics';
import PromotionalNavigation2AcrossMobile from '../PromotionalNavigation2AcrossMobile';
import MillworksContent from '../MillworksContent';

const isValid = (props) => {
  if (!props || !props.opts) return false;
  return true;
};

const queryProviderDefaultVariables = {
  skipInstallServices: false,
  skipSpecificationGroup: false
};

const searchSkipFn = ({ skip, queryName, attributes }) => {
  if (queryName === 'searchStreamProduct') {
    if (!attributes?.searchModel?.products?._children?.fulfillment) return true;
  }
  return skip;
};

const ComponentType = (props) => {
  declareContexts([ExperienceContext, QueryContext, CustomerContext]);
  const { isDebugMode } = useContext(ExperienceContext);
  if (!isValid(props)) return null;
  const {
    channel, opts, opts: { type }, bannerData, schemaName, navParam, shouldPrioritizeCtaImageFetch
  } = props;
  switch (type) {
  case 'banner':
  case 'defaultVisualNav':
    return null;
  case 'Carousel':
    return (
      <EtchCarousel opts={props.opts}>
        {analytics.track('click', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.content?.map?.((cont, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              showFavoritesCount={props.showFavoritesCount}
              host={props.host}
              isCategory={props.isCategory}
              key={cont.id || i}
              linkType={props.linkType}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={cont}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </EtchCarousel>
    );
  case 'grid':
    return (
      <Grid opts={opts} channel={channel}>
        <SpreadContent {...props} />
      </Grid>
    );
  case 'column':
  case 'ColumnRail':
    return (
      <Column
        opts={opts}
        channel={channel}
        bannerData={bannerData}
      >
        <SpreadContent {...props} />
      </Column>
    );
  case 'DecorHero':
    return (<DecorHero opts={opts} />);
  case 'EtchAnchorLinks':
    return (
      <EtchAnchorLinks opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchAnchorLinks>
    );
  case 'EtchBoxHero':
    return (
      <BoxHero opts={opts} channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </BoxHero>
    );
  case 'EtchCopyBlockHeader':
    return (
      <EtchCopyBlockHeader opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchCopyBlockHeader>
    );
  case 'EtchCustomNavigation':
    return (
      <EtchCustomNavigation opts={opts} channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchCustomNavigation>
    );
  case 'EtchMyStore':
    return (
      <EtchMyStore opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchMyStore>
    );
  case 'EtchImage':
    return (<Image opts={opts} channel={channel} shouldPrioritizeCtaImageFetch={shouldPrioritizeCtaImageFetch} />);
  case 'EndCap':
    return (
      <EtchEndCap opts={opts} channel={channel} navParam={navParam}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchEndCap>
    );
  case 'topEventEndCap':
    return (
      <EtchEndCap opts={opts} channel={channel} navParam={navParam}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchEndCap>
    );
  case 'EtchRichText':
    return (<EtchRichText opts={opts} channel={channel} />);
  case 'EtchVisualNav':
    return (<EtchVisualNav opts={opts} />);
  case 'EtchVisualNav3AcrossMobile':
    return (<EtchVisualNav3AcrossMobile opts={opts} />);
  case 'EtchVisualNavigationStory':
    return (
      <EtchVisualNavigationStory opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchVisualNavigationStory>
    );
  case 'HeroModule':
    return (
      <HeroModule opts={opts} isHeroModule channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </HeroModule>
    );
  case 'HeroModule-White':
    return (
      <HeroModule opts={opts} isHeroModuleWhite channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </HeroModule>
    );
  case 'HeroModule-CC':
    return (
      <HeroModule opts={opts} isHeroModuleCC channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </HeroModule>
    );
  case 'HeroModule-BC':
    return (
      <HeroModule opts={opts} isHeroModuleBC channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </HeroModule>
    );
  case 'HeroModule-BL':
    return (
      <HeroModule opts={opts} isHeroModuleBL channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </HeroModule>
    );
  case 'HeroModuleWithoutButton':
  case 'HeroModuleWithoutButtonCC':
    return (
      <BasicHeroWithOutButton opts={opts} channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </BasicHeroWithOutButton>
    );
  case 'RowHeader':
    return (<RowHeader opts={opts} />);
  case 'LifeStyleBanner':
    return (
      <LifeStyleBanner opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </LifeStyleBanner>
    );
  case 'EtchDynamicProductDisplay':
    return (
      <DPD
        channel={props.channel}
        keyword={props.keyword}
        navParam={props.keyword}
        disableSwipeNavigation={props.disableSwipeNavigation}
        showFavoritesCount={props.showFavoritesCount}
        host={props.host}
        linkType={props.linkType}
        dpdNewTab={props.dpdNewTab}
        onFavoriteChange={props.onFavoriteChange}
        opts={opts}
        parentNavParam={props.parentNavParam}
        removeOOSSkus
        showArrows={props.showArrows}
        showDpdPod={props.showDpdPod}
        storeId={props.storeId}
      >
        {analytics.track('load', {
          eventType: 'load',
          widgetId: opts.id
        })}
      </DPD>
    );
  case 'EtchStoryCard':
    return (
      <EtchStoryCard opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchStoryCard>
    );
  case 'EtchStoryWithImage':
    return (
      <EtchStoryWithImage opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchStoryWithImage>
    );
  case 'EtchSubHeroModule':
    return (
      <EtchSubHeroModule opts={opts} channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchSubHeroModule>
    );
  case 'EtchVideo':
    return (
      <EtchVideo opts={opts}>
        {analytics.track('click', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchVideo>
    );
  case 'BlockHeader':
    return (
      <BlockHeader opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </BlockHeader>
    );
  case 'EtchCurationModule':
    return null;
  case 'EtchBlockHeaderStory':
  case 'EtchBlockHeaderStoryReverse':
    return (
      <EtchBlockHeaderStory opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchBlockHeaderStory>
    );
  case 'PromotionalMessagingBanner':
  case 'MessagingBanner':
    return (
      <MessagingBanner opts={opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </MessagingBanner>
    );
  case 'PromotionalNavigation':
    return (
      <PromotionalNavigation2AcrossMobile opts={opts} channel={channel} schemaName={schemaName}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </PromotionalNavigation2AcrossMobile>
    );
  case 'EtchSBOTD':
  case 'EtchDecorSBOTW':
  case 'EtchProSBOTW':
    return (
      <SpecialBuyModule opts={props.opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts?.id
        })}
      </SpecialBuyModule>
    );
  case 'MillworksContent':
    return (
      <MillworksContent opts={props.opts} />
    );
  default:
    if (isDebugMode) return (<div>component type not found: {props.opts.type}</div>);
    return null;
  }
};

ComponentType.propTypes = {
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  contentType: PropTypes.string,
  dimensions: PropTypes.instanceOf(Array),
  dpdNewTab: PropTypes.bool,
  env: PropTypes.oneOf([
    'local',
    'dev',
    'stage',
    'prod-beta',
    'prod'
  ]),
  /** Exclude content of these types */
  exclude: PropTypes.arrayOf(PropTypes.string),
  favorites: PropTypes.arrayOf(PropTypes.number),
  host: PropTypes.string,
  isCategory: PropTypes.bool,
  onFavoriteChange: PropTypes.func,
  onVisNavRender: PropTypes.func,
  opts: PropTypes.shape({
    content: PropTypes.instanceOf(Array),
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    options: PropTypes.shape({
      boost: PropTypes.instanceOf(Array),
      isDragDisabled: PropTypes.bool,
      listType: PropTypes.shape({})
    }),
    type: PropTypes.string
  }).isRequired,
  pageType: PropTypes.string,
  parentNavParam: PropTypes.string,
  partialTemplates: PropTypes.instanceOf(Array),
  plpBanner: PropTypes.bool,
  sku: PropTypes.instanceOf(Array),
  storeId: PropTypes.string,
  visualNavResults: PropTypes.shape({}),
  visualNavPromos: PropTypes.instanceOf(Array),
  showFavoritesCount: PropTypes.bool,
  shouldPrioritizeCtaImageFetch: PropTypes.bool,
  showDpdPod: PropTypes.bool,
  schemaName: PropTypes.string,
  bannerData: PropTypes.shape({}),
  deliveryZip: PropTypes.string,
  disableSwipeNavigation: PropTypes.bool,
  dpdResponse: PropTypes.string,
  linkType: PropTypes.string,
  showArrows: PropTypes.bool,
  keyword: PropTypes.string,
  navParam: PropTypes.string
};

ComponentType.defaultProps = {
  channel: 'desktop',
  contentType: 'categorypage',
  dimensions: [],
  dpdNewTab: false,
  env: 'prod',
  exclude: [],
  host: 'https://www.homedepot.com',
  isCategory: true,
  onFavoriteChange: null,
  onVisNavRender: () => {
  },
  pageType: null,
  parentNavParam: '',
  partialTemplates: [],
  plpBanner: false,
  sku: [],
  storeId: '121',
  visualNavResults: {},
  visualNavPromos: [],
  showFavoritesCount: false,
  shouldPrioritizeCtaImageFetch: false,
  showDpdPod: false,
  schemaName: null,
  favorites: null,
  bannerData: null,
  deliveryZip: null,
  disableSwipeNavigation: false,
  dpdResponse: null,
  keyword: null,
  navParam: '',
  linkType: null,
  showArrows: false
};

ComponentType.displayName = 'ComponentType';

export default ComponentType;
