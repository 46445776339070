export const TRY_DIFFERENT_WORDS_TITLE = 'Try different search words';
export const CHECK_SPELLING_TITLE = 'Check your spelling';
export const EXACT_PHRASE_TITLE = 'Use exact phrase';
export const EXACT_PHRASE_MOBILE = '(i.e. model # or brand name)';
export const GET_HELP = 'We Can Help';
export const ONLINE_ORDER_SUPPORT = `Call 1-800-430-3376 or
text 38698 for customer service`;
export const LOCAL_STORE = 'your Local Store';
export const CONTACT = 'Contact';
export const REFINE_SEARCH = 'Refine Your Search';
export const SHOP_CUSTOM_PRODUCTS = 'Shop Custom Catalog Products';
