import React from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import '../add-to-cart.scss';

const AddToCartPlaceholder = () => {
  return (
    <Placeholder
      type="rect"
      height="40px"
    />
  );
};

AddToCartPlaceholder.displayName = 'AddToCartPlaceholder';

export { AddToCartPlaceholder };