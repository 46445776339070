import React from 'react';
import {
  bool, func, oneOf, shape, string
} from 'prop-types';
import { Card } from '../card/card';
import { Dropdown } from '../dropdown/dropdown';
import { Swatch } from '../swatch/swatch';
import { Tile } from '../tile/tile';

export const SuperSkuAttributeValue = (props) => {
  const {
    attributeType,
    channel,
    centeredCards,
    displayInToolTip,
    hexColor,
    hoveredCombinationDetails,
    isAvailable,
    isDotComColorEligible,
    isFabricType,
    isSelected,
    isTooltipsEnabled,
    miniSwatch,
    onClick,
    onMouseEnter,
    onMouseLeave,
    swatchCardCombinationDetails,
    swatchGuid,
    value,
  } = props;

  const isCard = attributeType === 'card';
  const isDropdown = attributeType === 'dropdown';
  const isSwatch = attributeType === 'swatch';
  const isTile = attributeType === 'tile';

  return (
    <>
      {isSwatch
      && (
        <Swatch
          isSelected={isSelected}
          isAvailable={isAvailable}
          value={value}
          swatchGuid={swatchGuid}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isTooltipsEnabled={isTooltipsEnabled}
          hoveredCombinationDetails={hoveredCombinationDetails}
          channel={channel}
          miniSwatch={miniSwatch}
          displayInToolTip={displayInToolTip}
        />
      )}
      {isTile
      && (
        <Tile
          isSelected={isSelected}
          isAvailable={isAvailable}
          isFabricType={isFabricType}
          value={value}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isTooltipsEnabled={isTooltipsEnabled}
          hoveredCombinationDetails={hoveredCombinationDetails}
          channel={channel}
        />
      )}
      {isCard
      && (
        <Card
          isSelected={isSelected}
          isDotComColorEligible={isDotComColorEligible}
          attributeValue={value}
          onClick={onClick}
          swatchCardCombinationDetails={swatchCardCombinationDetails}
          centeredCards={centeredCards}
          hexColor={hexColor}
        />
      )}
      {isDropdown
      && (
        <Dropdown
          isAvailable={isAvailable}
          onClick={onClick}
          value={value}
          swatchGuid={swatchGuid}
        />
      )}
    </>
  );
};

SuperSkuAttributeValue.displayName = 'SuperSkuAttributeValue';

SuperSkuAttributeValue.propTypes = {
  attributeType: string.isRequired,
  channel: oneOf(['desktop', 'mobile']).isRequired,
  centeredCards: bool,
  displayInToolTip: bool,
  hexColor: string,
  hoveredCombinationDetails: shape({}),
  isAvailable: bool.isRequired,
  isDotComColorEligible: bool,
  isFabricType: bool,
  isSelected: bool.isRequired,
  isTooltipsEnabled: bool,
  miniSwatch: bool,
  onClick: func.isRequired,
  onMouseEnter: func,
  onMouseLeave: func,
  swatchCardCombinationDetails: shape({}),
  swatchGuid: string,
  value: string.isRequired
};

SuperSkuAttributeValue.defaultProps = {
  centeredCards: false,
  displayInToolTip: false,
  hexColor: '',
  hoveredCombinationDetails: {},
  isDotComColorEligible: false,
  isFabricType: false,
  isTooltipsEnabled: false,
  miniSwatch: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  swatchCardCombinationDetails: {},
  swatchGuid: ''
};
