import {
  params, string, shape, bool, client, arrayOf, extend, number
} from '@thd-nucleus/data-sources';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { Price } from '@thd-olt-component-react/price';
import { SuperSku, SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { Promotion } from '@thd-olt-component-react/promotion';
import { SubscribeAndSave } from '@thd-olt-component-react/subscribe-and-save';

const installServices = params({ storeId: string(), zipCode: string() }).shape({
  scheduleAMeasure: bool(),
  gccCarpetDesignAndOrderEligible: bool()
});
if (installServices.skip) {
  installServices.skip('skipInstallServices', true);
}

const product = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    availabilityType: shape({
      discontinued: bool(),
      type: string()
    }),
    badges: params({ storeId: string() }).arrayOf(shape({
      name: string(),
    })),
    details: shape({
      collection: shape({
        collectionId: string(),
        name: string(),
        url: string(),
      }),
      highlights: arrayOf(string())
    }),
    favoriteDetail: client(shape({
      count: number()
    })),
    fulfillment: client(params({ storeId: string(), zipCode: string() }).shape({
      backordered: bool(),
      backorderedShipDate: string(),
      bossExcludedShipStates: string(),
      excludedShipStates: string(),
      seasonStatusEligible: bool(),
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        fulfillable: bool(),
        services: arrayOf(shape({
          type: string(),
          hasFreeShipping: bool(),
          freeDeliveryThreshold: number({ float: true }),
          locations: arrayOf(shape({
            curbsidePickupFlag: bool(),
            isBuyInStoreCheckNearBy: bool(),
            distance: number({ float: true }),
            inventory: shape({
              isOutOfStock: bool(),
              isInStock: bool(),
              isLimitedQuantity: bool(),
              isUnavailable: bool(),
              quantity: number(),
              maxAllowedBopisQty: number(),
              minAllowedBopisQty: number()
            }),
            isAnchor: bool(),
            locationId: string(),
            storeName: string(),
            state: string(),
            type: string(),
          }))
        }))
      }))
    })),
    identifiers: shape({
      brandName: string(),
      canonicalUrl: string(),
      modelNumber: string(),
      productType: string(),
      productLabel: string(),
      itemId: string(),
      storeSkuNumber: string()
    }),
    info: shape({
      hasSubscription: bool(),
      isBuryProduct: bool(),
      isSponsored: bool(),
      isGenericProduct: bool(),
      isLiveGoodsProduct: bool(),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string()
      }),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
      globalCustomConfigurator: shape({
        customExperience: string()
      }),
      returnable: bool(),
      isCustomKitchenCabinet: bool().client(),
      isBrioSku: bool().client(),
      hidePrice: bool(),
      productSubType: shape({
        name: string(),
        link: string()
      }),
      categoryHierarchy: arrayOf(string()),
      samplesAvailable: bool(),
      customerSignal: shape({
        previouslyPurchased: bool()
      })
    }),
    installServices,
    media: shape({
      image: shape({
        url: string()
      }),
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string(),
        sizes: arrayOf(string())
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string(),
      })
    })
  })
};

export const dataModel = extend(
  product,
  {
    product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
      info: shape({
        hasSubscription: bool(),
        productDepartmentId: string(),
        productDepartment: string(),
        augmentedReality: bool()
      })
    })
  },
  Favorite,
  KeyProductFeatures,
  FulfillmentPodStore,
  FulfillmentPodShipping,
  Price,
  SubscribeAndSave,
  SuperSkuMiniSwatches,
  SuperSku,
  Promotion,
);

export const productPodDefaultDataModel = extend(
  product,
  Favorite,
  KeyProductFeatures,
  FulfillmentPodStore,
  FulfillmentPodShipping,
  Price,
  SubscribeAndSave,
  SuperSkuMiniSwatches,
  Promotion,
);
