import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { SizeGuide } from '@thd-olt-component-react/size-guide';
import { AttributeLabelModal } from './attribute-label-modal';
import './attribute-label.scss';

export const AttributeLabel = (props) => {
  const {
    attributeName,
    attributeLabelValue,
    hexColor,
    info,
    paintType,
    sizeGuideInfo,
  } = props;
  const {
    parentId,
    collectionId,
    itemId,
    superSkuSizeAndFitDetail = {},
    itemSizeAndFitDetail = {}
  } = sizeGuideInfo || {};

  const hasSizeGuideAttributeGroups = !!itemSizeAndFitDetail?.attributeGroups?.length;

  const hasSizeGuide = () => {
    return attributeName?.toLowerCase() === 'size' && hasSizeGuideAttributeGroups;
  };

  const productType = itemSizeAndFitDetail?.attributeGroups?.[0]?.productType;
  const superSkuIdOrItemId = hasSizeGuideAttributeGroups ? parentId : itemId;
  const sizeGuideItemId = collectionId || superSkuIdOrItemId;
  const attributeValue = attributeLabelValue && attributeLabelValue.split(',')[0];

  return (
    <div className="attribute__label">
      <div className="label">
        {attributeName}:
        {attributeValue && <span className="attribute-value"> {attributeValue}</span>}
      </div>
      {hasSizeGuide()
        && (
          <div className="size-guide-container">
            <SizeGuide
              itemId={sizeGuideItemId}
              productType={productType}
              isSuperSku={!collectionId}
              isCollection={!!collectionId}
              superSkuSizeAndFitDetail={superSkuSizeAndFitDetail}
              itemSizeAndFitDetail={itemSizeAndFitDetail}
            />
          </div>
        )}
      {info?.dotComColorEligible
        && (
          <AttributeLabelModal
            attributeName={attributeName}
            hexColor={hexColor}
            brandKey={info?.paintBrand}
            type={paintType}
          />
        )}
    </div>
  );
};

AttributeLabel.displayName = 'AttributeLabel';

AttributeLabel.propTypes = {
  attributeName: string.isRequired,
  attributeLabelValue: string,
  hexColor: string,
  info: shape({
    paintBrand: string,
  }),
  paintType: string,
  sizeGuideInfo: shape({
    collectionId: string,
    itemSizeAndFitDetail: shape({
      attributeGroups: arrayOf(shape({}))
    }),
    parentId: string,
    productType: string,
    superSkuSizeAndFitDetail: shape({
      attributeGroups: arrayOf(shape({}))
    })
  }),
};

AttributeLabel.defaultProps = {
  attributeLabelValue: '',
  hexColor: '',
  info: {
    paintBrand: '',
  },
  paintType: '',
  sizeGuideInfo: {
    collectionId: '',
    isSize: false,
    isTowelSize: false,
    parentId: '',
    productType: '',
    superSkuSizeAndFitDetail: {},
  },
};
