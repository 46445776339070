import {
  arrayOf as arrayType,
  params,
  shape as shapeType,
  string as stringType,
  bool as boolType
} from '@thd-nucleus/data-sources';

const pod = shapeType({
  title: stringType(),
  description: stringType(),
  imageUrl: stringType(),
  link: stringType(),
  isProContent: boolType(),
  recordType: stringType(),
});

const universalSearchWithPods = shapeType({
  pods: arrayType(pod),
  info: shapeType({
    title: stringType(),
  })
});

export const dataModel = {
  searchModel: params({ keyword: stringType(), navParam: stringType(), storeId: stringType() }).shape({
    orangeGraph: shapeType({
      universalSearchArray: arrayType(universalSearchWithPods),
      productTypes: arrayType(stringType())
    })
  })
};
