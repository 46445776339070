export const FULFILLMENT_METHODS = {
  STH: 'ShipToHome',
  BODFS: 'DeliverFromStore',
  BOPIS: 'BOPIS',
  STORE: 'ShipToStore',
  APPLIANCE: 'DirectDelivery',
  BOSS: 'ShipToStore'
};

export const CUSTOMER_TYPES = {
  B2B: 'B2B',
  B2C: 'B2C',
  B2X: 'B2X',
};

export const INVENTORY_SEARCH_ARRAY = {
  ShipToHome: ['delivery', 'sth'],
  DeliverFromStore: ['delivery', 'express delivery'],
  BOPIS: ['pickup', 'bopis'],
  ShipToStore: ['pickup', 'boss']
};

export const CHECK_NEARBY_STORES = 'Check Nearby Stores';
export const MAJOR_APPLIANCE = 'MAJOR_APPLIANCE';
export const APPLIANCE_DEPARTMENT = '29';
export const NON_MAJOR_APPLIANCE_CLASS = ['7', '8', '13', '15'];

export const MERCHANDISE = 'MERCHANDISE';
// external components are top level keys;
export const FOREIGN_LIFE_CYCLE_EVENTS = {
  productAddOns: {
    attachLaborChange: 'attach-labor.change'
  }
};
export const LIVE_GOODS_OUT_OF_SEASON = 'This plant is out of season.';
export const LIVE_GOODS_IN_SEASON = 'This plant is in season!';
export const COVID_19_CUTOFF_TIME = 14;
export const LIVE_GOODS_DEPARTMENT = '28';
export const LIVE_GOODS_CLASS = '8';

export const GCC_COUNTER_TOPS = 'custom-countertops';
