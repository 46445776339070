import React from 'react';
import { string } from 'prop-types';

export const Grid = (props) => {
  const { fill } = props;
  const gridPath = 'M32,5 L37,4.99937508 L37,0 L32,0 L32,5 L32,5 Z M40,5 L45,4.99937508 L45,0 L40,0 L40,5 L40,5 Z M48,'
  + '0 L48,5 L53,4.99937508 L53,0 L48,0 L48,0 Z M32,13 L37,12.9993751 L37,8 L32,8 L32,13 L32,13 Z M40,13 L45,12.9993751'
  + ' L45,8 L40,8 L40,13 L40,13 Z M48,13 L53,12.9993751 L53,8 L48,8 L48,13 L48,13 Z M32,21 L37,20.9993751 L37,16 L32,16'
  + ' L32,21 L32,21 Z M40,21 L45,20.9993751 L45,16 L40,16 L40,21 L40,21 Z M48,21 L53,20.9993751 L53,16 L48,16 L48,21 L'
  + '48,21 Z';
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Lists---1440" stroke="none">
        <g id="grid_icon" transform="translate(-1394.000000, -387.000000)" fill={fill}>
          <g transform="translate(1362.000000, 387.000000)">
            <path d={gridPath} id="Grid" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const List = (props) => {
  const { fill } = props;
  const listPath = 'M0,5 L5,4.99937508 L5,0 L0,0 L0,5 L0,5 Z M7,0 L7,5 L22,4.99937508 L22,0 L7,0 L7,0 Z M0,13 L5,12.99'
  + '93751 L5,8 L0,8 L0,13 L0,13 Z M7,13 L22,12.9993751 L22,8 L7,8 L7,13 L7,13 Z M0,21 L5,20.9993751 L5,16 L0,16 L0,21'
  + ' L0,21 Z M7,21 L22,20.9993751 L22,16 L7,16 L7,21 L7,21 Z';
  return (
    <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Lists---1440" stroke="none">
        <g id="list_icon" transform="translate(-1362.000000, -387.000000)" fill={fill}>
          <g id="Group-20" transform="translate(1362.000000, 387.000000)">
            <path d={listPath} id="Grid" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Table = (props) => {
  const { fill } = props;
  const tablePath = 'M0 0h21v2H0zm0 3.167h21v2H0zm0 3.166h21v2H0zM0 9.5h21v2H0zm0'
  + ' 3.167h21v2H0zm0 3.166h21v2H0zM0 19h21v2H0z';
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Table---1440" stroke="none">
        <g id="table_icon" transform="translate(-1362.000000, -387.000000)" fill={fill}>
          <g id="Group-20" transform="translate(1362.000000, 387.000000)">
            <path d={tablePath} id="Table" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Grid.propTypes = {
  fill: string.isRequired
};

List.propTypes = {
  fill: string.isRequired
};

Table.propTypes = {
  fill: string.isRequired
};
