/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { QueryContext, QueryProvider, extend } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { Hydrator } from '@thd-olt-component-react/hydrator';
import { EtchDynamicProduct } from '../EtchDynamicProduct/EtchDynamicProduct.component';
import Helpers from './DPDHelpers';
import { useDPD } from '../../../../hooks/useDPD';
import DPDCarousel from './DPDCarousel.component';

import './DPD.scss';

function getWidth(defaultWidth = 0) {
  if (typeof document === 'undefined') return defaultWidth;

  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}
/**
 * @group Components
 * Displays DPD component
 */

const DynamicProductDisplay = (props) => {
  const { channel } = useContext(ExperienceContext);
  const [startIndex, setStartIndex] = useState(0);
  const { isClientResolved, defaultVariables } = useContext(QueryContext);
  const { isBrandPricingPolicyCompliant = false } = defaultVariables?.current;
  const pageSize = 7;// channel === 'mobile' ? 3 : 6;
  const {
    skus = [],
    shopAllLink,
    categoryLabel,
    storeId,
    storeZip,
    totalProducts = 0,
  } = useDPD({
    ...props,
    pageSize,
    startIndex
  });

  const getLabel = useCallback(({ sortByName }) => {

    const displayLabel = Helpers.getDisplayLabel({ sortByName });
    const savingRegex = new RegExp('[A-Za-z]* Savings');
    let inLabel = '';
    if (displayLabel && categoryLabel) {
      inLabel = ' in ';
    }
    const curatedTitle = savingRegex.test(categoryLabel)
      ? categoryLabel
      : (
        <span>
          <span className="u__bold">{displayLabel}</span>{inLabel}{categoryLabel}
        </span>
      );
    return curatedTitle;
  }, [categoryLabel]);

  const {
    disableSwipeNavigation,
    // favorites,
    linkType,
    minDpdCount,
    // onFavoriteChange,
    opts,
    // parentNavParam,
    showArrows,
    showFavoritesCount,
    showDpdPod,
  } = props;
  const {
    Data = {},
    curationType = 'dynamic',
    cta,
    ctaLink,
    heading,
    subHeading,
    productDisplay = 'aisle',
    aisleBackgroundMobile,
    aisleBackgroundDesktop,
    aisleBackgroundColor = '#FFF'
  } = opts;
  const { sortBy = 'topsellers' } = Data;
  const isMobile = channel === 'mobile';
  const itemWidthPixelsMobile = 300;
  const carouselConfig = {
    itemClass: isMobile ? 'mobile-fixed-item' : 'fixed-item',
    multiItem: false,
    showArrows: isMobile ? false : showArrows,
    disableSwipeNavigation: isMobile ? true : disableSwipeNavigation,
    fixedItem: true,
    itemWidthMobile: '80%',
    itemWidthPixelsMobile,
    totalItems: totalProducts,
    onNextClick: (itemspershift, nextPosition) => {
      if (nextPosition < totalProducts) {
        if (nextPosition >= (skus.length - 2) && skus.length < totalProducts) {
          setStartIndex(skus.length + 1);
        }
      }
    }
  };
  const showDPD = totalProducts > minDpdCount;
  const width = isMobile ? getWidth(375) - 30 : null;
  const skipFn = ({ skip, attributes, queryName }) => {

    if (queryName !== 'product') return skip;
    const isResolved = isClientResolved({ queryName: 'dpdSearchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }

    return skip;
  };

  const showDynamicAisle = productDisplay === 'aisle' && curationType === 'dynamic';
  const showDynamicEndcap = productDisplay === 'endcap' && curationType === 'dynamic';
  const showManualAisle = productDisplay === 'aisle' && curationType === 'manual';
  const showManualEndcap = productDisplay === 'endcap' && curationType === 'manual';

  let aisleBackgroundStyle = {};

  if (isMobile) {
    aisleBackgroundStyle = {
      backgroundImage: aisleBackgroundMobile?.src ? `url('${aisleBackgroundMobile.src}')` : 'none',
      backgroundColor: aisleBackgroundColor
    };
  } else {
    aisleBackgroundStyle = {
      backgroundImage: aisleBackgroundDesktop?.src ? `url('${aisleBackgroundDesktop?.src}')` : 'none',
      backgroundColor: aisleBackgroundColor
    };
  }

  if (!showDPD) return null;

  return (
    <div className="dpd-etch2">
      <div className="dpd-etch2__label">
        {showDynamicAisle && (
          <>
            {getLabel({ sortByName: sortBy, categoryLabel })}
            {shopAllLink !== '' && (
              <a
                className="dpd-etch2__shopall--link"
                href={shopAllLink}
                aria-label={`Visit ${categoryLabel}`}
              >
                Shop All
              </a>
            )}
          </>
        )}
        {showManualAisle && (
          <>
            <span>
              <span className="u__bold">{heading}</span>{` in ${subHeading}`}
            </span>
            {ctaLink !== '' && (
              <a
                className="dpd-etch2__shopall--link"
                href={ctaLink}
                aria-label={`Visit ${heading}`}
              >
                {cta}
              </a>
            )}
          </>
        )}
      </div>
      <div className="dpd-etch2__dynamic_aisle" style={aisleBackgroundStyle}>
        {(showDynamicEndcap || showManualEndcap) && (
          <EtchDynamicProduct
            opts={opts}
            channel={channel}
            displayLabel={Helpers.getDisplayLabel({ sortByName: sortBy })}
            categoryLabel={categoryLabel}
            shopAllLink={shopAllLink}
            isManual={showManualEndcap}
          />
        )}
        {skus?.length > 0 && (
          <div className="dpd-etch2__carousel--wrapper" style={{ width }}>
            <QueryProvider
              dataSource="searchNav"
              cacheKey="dpdSearchNav"
              defaultVariables={{
                storeId,
                zipCode: storeZip,
                skipKPF: true,
                skipSubscribeAndSave: true,
                isBrandPricingPolicyCompliant
              }}
              skip={skipFn}
            >
              <DPDCarousel
                channel={channel}
                carouselConfig={carouselConfig}
                skus={skus}
                showFavoritesCount={showFavoritesCount}
                storeId={storeId}
                storeZip={storeZip}
              />
            </QueryProvider>
          </div>
        )}
      </div>
    </div>
  );
};

DynamicProductDisplay.displayName = 'DynamicProductDisplay';

DynamicProductDisplay.dataModel = extend(DPDCarousel);
// DynamicProductDisplay.dataModel = dpdSearchModel;

DynamicProductDisplay.propTypes = {
  disableSwipeNavigation: PropTypes.bool,
  linkType: PropTypes.string,
  minDpdCount: PropTypes.number,
  opts: PropTypes.shape({
    id: PropTypes.string,
    aisleBackgroundMobile: PropTypes.shape({
      src: PropTypes.string
    }),
    aisleBackgroundDesktop: PropTypes.shape({
      src: PropTypes.string
    }),
    Data: PropTypes.shape({
      activeRefmts: PropTypes.bool,
      navParam: PropTypes.string,
      featuredRecords: PropTypes.instanceOf(Array),
      sortBy: PropTypes.string,
      sortOrder: PropTypes.string,
      colNumber: PropTypes.number,
      recordLimit: PropTypes.string,
      pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isRefinement: PropTypes.bool,
      showNlpIcon: PropTypes.string,
      showAvailStatus: PropTypes.string,
      showRatings: PropTypes.string,
      sId: PropTypes.string,
      channel: PropTypes.string,
      navigationFilters: PropTypes.instanceOf(Array)
    }),
    type: PropTypes.string,
    isDynamicContent: PropTypes.bool,
    curationType: PropTypes.string,
    cta: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    productDisplay: PropTypes.string,
    aisleBackgroundColor: PropTypes.string
  }).isRequired,
  showArrows: PropTypes.bool,
  showFavoritesCount: PropTypes.bool,
  showDpdPod: PropTypes.bool
};

DynamicProductDisplay.defaultProps = {
  disableSwipeNavigation: false,
  linkType: '_self',
  minDpdCount: 4,
  showArrows: true,
  showFavoritesCount: false,
  showDpdPod: false
};

export default DynamicProductDisplay;
