import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import classNames from 'classnames';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductImage } from '../product-image/ProductImage';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductQuantity } from '../product-quantity/ProductQuantity';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductShipping } from '../product-shipping/ProductShipping';
import { ProductStore } from '../product-store/ProductStore';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import { ProductEditButton } from '../product-edit/ProductEditButton';
import { ProductIdentifier } from '../product-identifier/ProductIdentifier';
import { isATCEnabled, shouldShowQuantityBox } from '../../utils';
import './product-side-by-side.style.scss';

export const ProductSideBySide = () => {
  const {
    channel,
  } = useContext(ExperienceContext);
  const {
    hideLimitPerOrder,
    hideShippingInfo,
    hideStoreInfo,
    isATCDisabled,
    isHovered,
    itemId,
    onChangeSuperSku,
    onFavoriteChange,
    onHoverSuperSku,
    previousItemId,
    product,
    onEdit,
    storeId,
    isHDQCSku,
    showDisabledATC,
    showMobileQuantityBox,
    staticQuantity,
    showModelNumber,
    showSkuNumber,
  } = useContext(ProductPodContext);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const isDisabled = isATCDisabled;
  const superSkuItemId = isHovered ? previousItemId : itemId;
  const isMobile = channel === 'mobile';
  const showMobileQuantityBoxLayout = showMobileQuantityBox && isMobile;
  const showATCSection = isATCEnabled(product) || showDisabledATC;
  const atcColumnClassNames = classNames({
    'side-by-side__atc-qty-column': showMobileQuantityBoxLayout,
    'atc-wrapper': !showMobileQuantityBoxLayout
  });

  const quantityColumnClassnames = classNames('side-by-side__atc-qty-column', {
    'side-by-side__atc-qty-column--right-align': staticQuantity
  });

  const atcClassNames = classNames({
    'side-by-side__atc-column': showMobileQuantityBoxLayout,
  });

  const columnClassNames = classNames('flex-column', {
    'side-by-side__flex-column': showMobileQuantityBoxLayout,
  });

  return (

    <Row>

      <Col>
        {
          onEdit && (<ProductEditButton nopadding onClick={onEdit} />)
        }
        <div className="side-by-side__title-wrapper">
          <ProductHeader brand="inline" collection="hidden" />
        </div>
        <div className="side-by-side__identifiers">
          {showModelNumber && <ProductIdentifier type="model" />}
          {showSkuNumber && <ProductIdentifier type="sku" />}
        </div>
      </Col>
      <Col fallback="6" className={columnClassNames}>
        <ProductImage className="product-image" />
        <ProductFavorites
          className="product-pod__inline__favorite"
          onFavoriteChange={onFavoriteChange}
          position="topRight"
          showCircle
        />
        {showATCSection && showMobileQuantityBoxLayout && shouldShowQuantityBox(product) && (
          <Col className={quantityColumnClassnames} fallback="12" nopadding>
            <ProductQuantity
              wideView
              textOnly
              shortHeight
              smallText
              noRightMargin
              showQtyValueText={Boolean(staticQuantity)}
              showQtyValueTextWide={Boolean(staticQuantity)}
              showQtyText={Boolean(staticQuantity)}
            />
          </Col>
        )}
      </Col>
      <Col fallback="6" className={columnClassNames} nopadding>

        <ProductRating />

        {
          staticQuantity && !showMobileQuantityBoxLayout && (
            <div className="qty-text-wrapper">
              <ProductQuantity textOnly showQtyText smallText showQtyValueText />
            </div>
          )
        }

        { isHDQCSku ? (
          <ProductQuoteCenter />
        ) : (
          <>
            <ProductPrice hideLimitPerOrder={hideLimitPerOrder} large={false} />
            {mounted && (
              <SuperSkuMiniSwatches
                itemId={superSkuItemId}
                storeId={storeId}
                onChange={onChangeSuperSku}
                onHover={onHoverSuperSku}
              />
            )}
            { !hideStoreInfo && (<ProductStore />) }
            { !hideShippingInfo && (<ProductShipping className="product-pod__fulfillment--shipping-collection" />) }
            { showATCSection && (
              <Col
                className={atcColumnClassNames}
                nopadding
              >
                { !isMobile && !staticQuantity && !showMobileQuantityBoxLayout && (
                  <Col
                    nopadding
                    xs="3"
                  >
                    <ProductQuantity textOnly />
                  </Col>
                ) }
                <Col
                  xs={showMobileQuantityBoxLayout ? '12' : '7'}
                  nopadding={!showMobileQuantityBoxLayout}
                  className={atcClassNames}
                >
                  <ProductATC outline disabled={isDisabled} showLargeButtonOnMobile={showMobileQuantityBoxLayout} />
                </Col>
              </Col>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

ProductSideBySide.displayName = 'ProductPodSideBySide';
