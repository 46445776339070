/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { shape, func, bool } from 'prop-types';
import { getShippingTemplate } from '../helpers/shipping-helper';
import { productShape, isGraphQlDataEqual } from '../helpers/pod-fulfillment-utils';
import { dataModel } from '../../components/dataModel';
import { EmptyShippingMessage } from '../partials/shipping/EmptyShippingMessage';
import './fulfillment-pod-shipping.scss';
import { isShowBoth, isTwoTile, isTwoTileOff } from '../../components/helper/utils';
import { renderDeliveryTemplates } from '../templates/deliveryTemplate';

export const FulfillmentPodShipping = React.memo(({ product, isTwoTileEnabledFS, useCustomTemplate }) => {
  const { customer } = useContext(ExperienceContext);
  const isTwoTileEnabledGlobal = useConfigService('isTwoTileEnabled');
  const isTwoTileMessagingEnabled = useConfigService('fs-prop:product-pod__fulfillment--isTwoTileMessagingEnabled');

  if ((isTwoTile() || isShowBoth()) && !isTwoTileOff()) {
    isTwoTileEnabledFS = true;
  }
  if (!isTwoTileEnabledFS) {
    isTwoTileEnabledFS = isTwoTileEnabledGlobal && isTwoTileMessagingEnabled;
  }

  const ShippingComponent = getShippingTemplate(
    { product, customer, isTwoTileEnabledFS, customRender: useCustomTemplate ? renderDeliveryTemplates : null });

  if (!ShippingComponent) { return EmptyShippingMessage; }

  return (<ShippingComponent product={product} isTwoTileEnabledFS={isTwoTileEnabledFS} />);
}, isGraphQlDataEqual);

FulfillmentPodShipping.propTypes = {
  product: shape({ productShape }).isRequired,
  useCustomTemplate: bool,
  isTwoTileEnabledFS: bool
};

FulfillmentPodShipping.defaultProps = {
  useCustomTemplate: false,
  isTwoTileEnabledFS: false
};

FulfillmentPodShipping.dataModel = dataModel;
