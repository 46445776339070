import React, { useContext, useState, useEffect } from 'react';
import { Button, Checkbox } from '@thd-olt-component-react/core-ui';
import { bool } from 'prop-types';
import classnames from 'classnames';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductCompare = (props) => {
  const {
    onCompare,
    onSelectedForCompare,
    selectedForCompare,
    onCompareAnalytics,
    product
  } = useContext(ProductPodContext);

  const { noMargin } = props;

  const MAX_COMPARABLE = 4;
  const MIN_COMPARABLE = 2;
  const { itemId } = product || {};
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(selectedForCompare.includes(itemId));
  }, [selectedForCompare]);

  const onChange = () => {
    setChecked(!checked);
    onSelectedForCompare(itemId, !checked);
    onCompareAnalytics();
  };

  const compareBoxWrapperClasses = classnames(
    'product-pod__compare-action',
    {
      'product-pod__compare-action--no-margin': noMargin,
    }
  );

  return (
    <>
      <div className={compareBoxWrapperClasses}>
        <Checkbox
          id={`compare-${itemId}`}
          onChange={onChange}
          checked={checked}
          disabled={!checked && selectedForCompare.length >= MAX_COMPARABLE}
          value={`compare-${itemId}`}
          className="product-pod__compare-checkbox"
        >
          {(checked && selectedForCompare.length >= MIN_COMPARABLE)
            ? (
              <Button onClick={onCompare} cta>
                Compare Now
              </Button>
            )
            : <span>Compare</span>}
        </Checkbox>
      </div>
    </>
  );
};

ProductCompare.displayName = 'ProductCompare';

ProductCompare.propTypes = {
  noMargin: bool
};

ProductCompare.defaultProps = {
  noMargin: false
};
