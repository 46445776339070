import React, { useEffect } from 'react';
import { Pill } from '@one-thd/sui-atomic-components';
import {
  params,
  shape,
  string,
} from '@thd-nucleus/data-sources';
import { useImpression } from '~/@thd-olt-component-react/impression/dist/impression';
import './anchor-links.scss';

const AnchorPill = ({ componentId, componentPosition, link }) => {

  const category = link.label.replace(/ /g, '');

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'AnchorLinks',
      component: 'AnchorLinks',
      type: 'content',
      position: componentPosition,
      category
    }
  });
  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
      key={`card-position-${componentPosition}`}
      data-component="AnchorLink"
    >
      <Pill
        key={componentId}
        label={link.label}
        component="a"
        href={link.href}
        clickable
      />
    </div>
  );
};

AnchorPill.propTypes = {
  componentId: string.isRequired,
  componentPosition: string.isRequired,
  link: shape({}).isRequired
};

AnchorPill.displayName = 'AnchorPill';

AnchorPill.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: string().isRequired(),
  })
};
export { AnchorPill };
