import React from 'react';
import PropTypes from 'prop-types';
import CTARenderer from '../CTARenderer/CTARenderer.component';
import './DecorHero.scss';

const DecorHero = (props) => {
  const { opts = {} } = props;
  const {
    collectionLink,
    cornerCaption,
    cta,
    ctaLink,
    eyeBrow,
    heading,
    heroMedia = {},
    id,
    subHeading,
  } = opts;
  const HeroContent = () => (
    <div className="heroContent">
      {eyeBrow
        && (
          <>
            <div className="eyeBrow" />
            <span className="u__small u__light">
              {eyeBrow}
            </span>
          </>
        )}
      {!eyeBrow
        && <div />}
      <h2 className="u__xlarge u__bold u__black u--paddingBottom">{heading}</h2>
      <h4 className="u__medium u__light u--paddingBottom">{subHeading}</h4>
      {cta
      && (
        <button
          className="heroContent__button bttn bttn--primary bttn--inline"
          type="button"
          aria-label={`Visit ${heading}`}
        >
          <span className="bttn__content">{cta}</span>
        </button>
      )}
    </div>
  );
  const heroStyle = {
    backgroundImage: `url(${heroMedia.src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat'
  };
  return (
    <div id="EtchDecorHero" data-module-id={id}>
      <div className="heroContainer">
        {(collectionLink || ctaLink)
          && (
            <span className="heroImage" style={heroStyle}>
              <CTARenderer
                ctaLink={collectionLink || ctaLink}
                className="heroImage--block"
                aria-label={`Visit ${heading}`}
              >
                <div className="imageText">{cornerCaption}</div>
              </CTARenderer>
            </span>
          )}
        {!(collectionLink || ctaLink)
          && (
            <div className="heroImage" style={heroStyle}>
              <div className="imageText">{cornerCaption}</div>
            </div>
          )}
        {ctaLink
          && (
            <CTARenderer
              className="heroCard"
              ctaLink={ctaLink}
              aria-label={`Visit ${heading}`}
            >
              <HeroContent />
            </CTARenderer>
          )}
        {!ctaLink
          && (
            <div className="heroCard">
              <HeroContent />
            </div>
          )}
      </div>
    </div>
  );
};

DecorHero.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};
DecorHero.defaultProps = {
};

export default DecorHero;
