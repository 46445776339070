import React, { useState, useEffect, useContext, useRef } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';
import { useDataModel, QueryProvider } from '@thd-nucleus/data-sources';
import { Hydrator } from '@thd-olt-component-react/hydrator';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Row } from '@thd-olt-component-react/core-ui';
import { getBaseNavParam } from '../utils/product-table-view-helpers';
import { dataModel } from './dataModel';
import './product-table-view.scss';
import SubGroup from './subGroup';
import { CHANNEL, NRF_RESULT } from '../constants/appConstant';

const ProductTableView = ({ categoryNavParam }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-table-view.ready'); }, []);

  const [activeProductDetails, setActiveProductDetails] = useState({});
  let NRFStatus = useRef([]);
  const [showNrf, setShowNrf] = useState(false);

  const { channel, customer, store, deliveryZip: serverDeliveryZip } = useContext(ExperienceContext);
  const { storeId, storeZip } = useStore({ varnish: true, online: false });
  const customerType = customer?.type?.toLocaleUpperCase();
  const { data, error, loading } = useDataModel('tableViewModel', {
    variables: {
      channel: channel.toLocaleUpperCase(),
      navParam: categoryNavParam,
      customerType
    }
  });

  const containerClassName = classNames({
    'table-view__container': CHANNEL.DESKTOP === channel,
    'table-view__container--mobile': CHANNEL.DESKTOP !== channel,
  });

  if (!data && loading) {
    return (
      <div className={containerClassName} data-component="ProductTableViewPlaceholder">
        <TextBlock rows={30} color="#E0E0E0" />;
      </div>
    );
  }

  const tables = data?.tableViewModel?.tables || [];
  const { groups = [] } = tables[0] || {};

  const handleNRFStatus = (status) => {
    NRFStatus.current = [...NRFStatus.current, status];
    if ((groups.length === NRFStatus.current.length)
    && (!(NRFStatus.current.indexOf(1) > -1))) {
      setShowNrf(true);
    }
  };

  const showProductDetails = (subgroupTitle, index, uniqueId) => {
    if (activeProductDetails.subgroupTitle === subgroupTitle
      && activeProductDetails.index === index
      && activeProductDetails.uniqueId === uniqueId) {
      setActiveProductDetails({});
    } else {
      setActiveProductDetails({ subgroupTitle, index, uniqueId });
    }
  };

  if (groups.length === 0 || showNrf) {
    return (
      <span className="table-results-view__nrfpageTitle" data-component="ProductTableViewNRF">
        {NRF_RESULT}
      </span>
    );
  }

  const baseNavParam = getBaseNavParam(categoryNavParam, groups);

  return (
    <div data-component="ProductTableView" className="grid">
      {(groups || []).map((group, index) => {
        const {
          groupIds, subgroups, title: groupTitle, topSellerImageURLs
        } = group;
        const subGroupData = subgroups.length ? subgroups : [groupIds[0] || []];
        const joinedGroupIds = groupIds.join('');
        return (
          <div
            className={containerClassName}
            key={`${joinedGroupIds}-${index}`}
          >
            {subGroupData.map((subGroup, subGroupIndex) => {
              const { title: subGroupTitle } = subGroup;
              const showGroupTitle = subGroupIndex === 0;
              return (
                <Hydrator
                  id={`${joinedGroupIds}-${index}-${subGroupIndex}`}
                  key={`table-view-hydrator-${joinedGroupIds}-${index}-${subGroupIndex}`}
                  className="grid"
                  placeholder={(
                    <TextBlock rows={10} color="#E0E0E0" />
                  )}
                  scrollBuffer={750}
                >
                  <Row key={`sub-group-wrapper-${joinedGroupIds}-${index}-${subGroupIndex}`}>
                    <QueryProvider
                      key={`qp-${joinedGroupIds}-${index}-${subGroupIndex}`}
                      cacheKey="tableViewModel"
                    >
                      <SubGroup
                        key={`sub-group-${joinedGroupIds}-${index}-${subGroupIndex}`}
                        groupIds={groupIds}
                        subGroup={subGroupTitle ? subGroup : {}}
                        activeProductDetails={activeProductDetails}
                        setActiveProductDetails={showProductDetails}
                        handleNRFStatus={handleNRFStatus}
                        categoryNavParam={baseNavParam}
                        tableMetaData={data?.tableViewModel?.tables[0] || {}}
                        showGroupTitle={subGroupTitle ? showGroupTitle : true}
                        groupTitle={groupTitle}
                        topSellerImageURLs={topSellerImageURLs}
                        storeId={storeId}
                        customerType={customerType}
                        store={store}
                        channel={channel}
                        storeZip={storeZip}
                        serverDeliveryZip={serverDeliveryZip}
                      />
                    </QueryProvider>
                  </Row>
                </Hydrator>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

ProductTableView.propTypes = {
  categoryNavParam: string
};

ProductTableView.defaultProps = {
  categoryNavParam: ''
};

ProductTableView.displayName = 'ProductTableView';

ProductTableView.dataModel = dataModel;

export { ProductTableView };
