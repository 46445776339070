import {
  ACTION_TYPES,
  KEY_VALUE_DELIMITER,
  COMBINATION_DELIMITER,
  MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT,
  MAXIMUM_CALLS_FOR_PRICE_ENDPOINT
} from './statics';

export const createSelectedItemAttributes = (state, selectedItem) => {
  const currentlySelected = selectedItem.split(COMBINATION_DELIMITER);
  if (state.attributes[0]?.attributeName === 'Fabric Type') {
    const currentlySelectedFabric = currentlySelected.splice(1, 1);
    currentlySelected.unshift(currentlySelectedFabric[0]);
  }
  return currentlySelected.map((attributeValue, index) => {
    return {
      attributeLabel: (state.attributes[index] || {}).attributeName,
      attributeValue: (attributeValue.indexOf(KEY_VALUE_DELIMITER) >= 0
        ? attributeValue.split(KEY_VALUE_DELIMITER)[1]
        : attributeValue),
      isSwatch: (state.attributes[index] || {}).isSwatch
    };
  });
};

export const reorderAttributesForPatio = (attributeArr) => {
  return [attributeArr[1], attributeArr[0], ...attributeArr.slice(2, 4)];
};

export const determineSelectedAttributes = (state, selected) => {
  const { childItemsLookup } = state;

  if (selected) {
    return createSelectedItemAttributes(state, selected);
  }
  const selectedProduct = childItemsLookup.filter((item) => {
    return item.itemId === state.itemId;
  });

  if (!selectedProduct || !childItemsLookup || childItemsLookup.length === 0) {
    return [];
  }

  return createSelectedItemAttributes(state, selectedProduct[0]?.attributeCombination);
};

export const getMatchedAttributeCombinationDetails = (state) => {
  let currentCombination = state?.selectedAttributes?.map((data) => {
    return data.attributeValue;
  });
  currentCombination = state.attributes[0].attributeName === 'Fabric Type'
    ? reorderAttributesForPatio(currentCombination)
    : currentCombination;
  currentCombination = currentCombination.join(COMBINATION_DELIMITER);
  return state.childItemsLookup.find((childItem) => {
    return childItem.attributeCombination === currentCombination;
  });
};

export const getAttributeCombination = (selectedAttributes, currentTileInfo) => {
  return selectedAttributes?.map((attribute) => {
    if (attribute.attributeLabel === currentTileInfo.attributeLabel) {
      return {
        ...attribute,
        attributeValue: currentTileInfo.attributeValue,
      };
    }
    return {
      ...attribute,
    };
  });
};

const getCombinationsToRank = (state, clickedTile, childItemsLookupFromSdsku) => {
  if (!clickedTile.attributeLabel) {
    return childItemsLookupFromSdsku;
  }
  const availableCombinationsToRank = state.childItemsLookup.filter((childItem) => {
    return childItem.inventory?.isAvailable && (childItem[clickedTile.attributeLabel] === clickedTile.attributeValue);
  });

  if (availableCombinationsToRank.length) {
    return availableCombinationsToRank;
  }

  return state.childItemsLookup.filter((childItem) => {
    return childItem[clickedTile.attributeLabel] === clickedTile.attributeValue;
  });
};

export const getRankedCombinations = (state, combinationsToRank) => {
  let rankedCombinations = [];
  state.attributes.forEach(() => {
    rankedCombinations.push([]);
  });

  if (!state.selectedAttributes?.length && combinationsToRank?.length) {
    rankedCombinations[0].push(combinationsToRank[0]);
    return rankedCombinations;
  }

  const rankedCombos = combinationsToRank.reduce((rankedCombo, combination) => {
    const rank = state.selectedAttributes.reduce((currentRank, attribute) => {
      return combination[attribute.attributeLabel] === attribute.attributeValue
        ? currentRank + 1
        : currentRank;
    }, -1);

    if (rank !== -1) {
      (rankedCombo[rank] || []).push(combination);
    }
    return rankedCombo;
  }, rankedCombinations).reverse();

  if (rankedCombos.find((combo) => combo?.length)) {
    return rankedCombos;
  }
  return [[combinationsToRank[0]]];
};

const getHighestRankedCombination = (rankedCombos) => {
  const highestRankedCombo = rankedCombos.find((combo) => {
    return combo.length > 0;
  }) || [];
  const firstCombination = highestRankedCombo.pop() || {};
  return firstCombination;
};

export const findClosestSku = (state, clickedTile, childItemsLookupFromSdsku) => {
  const combinationsToRank = getCombinationsToRank(state, clickedTile, childItemsLookupFromSdsku);
  const rankedCombinations = getRankedCombinations(state, combinationsToRank);
  return getHighestRankedCombination(rankedCombinations);
};

export const getSelectedAttributesFromClosestSku = (state, clickedTile) => {
  const selected = findClosestSku(state, clickedTile).attributeCombination;
  return determineSelectedAttributes(state, selected);
};

export const getSelectedAttributes = (state, clickedTile) => {
  if (!getMatchedAttributeCombinationDetails(state)) {
    const selectedAttributes = getSelectedAttributesFromClosestSku(state, clickedTile);
    if (selectedAttributes.length === 0) {
      return {
        ...state,
        impossibleState: true
      };
    }
    const hasASingleAttribute = state?.attributes?.length === 1;
    return {
      ...state,
      jumpedState: !hasASingleAttribute,
      selectedAttributes
    };
  }

  return {
    ...state,
    jumpedState: false,
    impossibleState: false
  };
};

export const isLookupLengthLessThanMaximum = ({ childItemsLookup = [] }) => {
  return childItemsLookup.length <= MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT * MAXIMUM_CALLS_FOR_PRICE_ENDPOINT;
};

export const shouldDisplayInventory = (state) => {
  const { disableMediaAndAvailability } = state;
  return (isLookupLengthLessThanMaximum(state) && !disableMediaAndAvailability);
};

export const getProductInformation = (state) => {
  let productInformation = {};
  const isPatio = state?.selectedAttributes?.[0]?.attributeLabel === 'Fabric Type';
  const currentCombination = state.selectedAttributes.map((data) => {
    return data.attributeValue;
  }).join(COMBINATION_DELIMITER);
  state.childItemsLookup.forEach((childItem) => {
    let attributeCombinationJoin = childItem.attributeCombination;
    if (isPatio) {
      let childItemSplit = childItem.attributeCombination.split(COMBINATION_DELIMITER);
      attributeCombinationJoin = reorderAttributesForPatio(childItemSplit).join(COMBINATION_DELIMITER);
    }
    if (attributeCombinationJoin === currentCombination) {
      productInformation.itemId = childItem.itemId;
      productInformation.canonicalUrl = childItem.canonicalUrl;
      productInformation.isAvailable = shouldDisplayInventory(state) ? childItem.inventory?.isAvailable : true;
    }
  });
  return {
    ...productInformation
  };
};

const getNumberOfClicksToReachItem = (childItem) => {
  return (acc, attribute) => {
    return attribute.attributeValue === childItem[attribute.attributeLabel]
      ? acc + 1
      : acc;
  };
};

const itemsThatCanBeReachedInOneClick = (state) => {
  return (childItem) => {
    return (state.attributes.length - 1) <= state.selectedAttributes.reduce(getNumberOfClicksToReachItem(childItem), 0);
  };
};

const getEnabledOrAvailableAttributeValues = (state, allItemsThatCanBeReachedInOneClick, attributeValueType) => {
  const emptyAttributeValues = {};
  state.attributes.forEach(({ attributeName }) => {
    emptyAttributeValues[attributeName] = new Set();
  });
  const enabledOrAvailableAttributeValues = allItemsThatCanBeReachedInOneClick.reduce((attributeValues, item) => {
    const shouldAddAttributeValues = state.impossibleState
      || attributeValueType === 'enabled'
      || (attributeValueType === 'available'
      && item.inventory?.isAvailable);
    if (shouldAddAttributeValues) {
      state.attributes.forEach(({ attributeName }) => {
        attributeValues[attributeName].add(item[attributeName]);
      });
    }
    return attributeValues;
  }, emptyAttributeValues);
  return enabledOrAvailableAttributeValues;
};

export const getEnabledAndAvailableAttributeValues = (state) => {
  let allItemsThatCanBeReachedInOneClick;
  if (state.impossibleState) {
    allItemsThatCanBeReachedInOneClick = state.childItemsLookup;
  } else {
    allItemsThatCanBeReachedInOneClick = state.childItemsLookup.filter(itemsThatCanBeReachedInOneClick(state));
  }

  let enabledAndAvailableAttributeValues = {
    enabled: {},
    available: {}
  };
  enabledAndAvailableAttributeValues.enabled = getEnabledOrAvailableAttributeValues(
    state,
    allItemsThatCanBeReachedInOneClick,
    'enabled'
  );

  const containsInventory = state.childItemsLookup[0]?.inventory;
  if (shouldDisplayInventory(state) && containsInventory) {
    enabledAndAvailableAttributeValues.available = getEnabledOrAvailableAttributeValues(
      state,
      allItemsThatCanBeReachedInOneClick,
      'available');
  }

  return enabledAndAvailableAttributeValues;
};

export const getMinifiedString = (data) => {
  return data.replace(/ |;/gi, '_');
};

export const getUnMinifiedString = (data) => {
  return data.toString().replace(/_/gi, ' ');
};

const hasSwatchOnFirstAttribute = (attributes = []) => {
  return !!attributes[0]?.isSwatch;
};

export const isOverflowSection = (attribute = {}) => {
  return !!(attribute.scrollHeight > attribute.clientHeight);
};

export const shouldDisplaySwatchCards = (state, channel) => {
  const { attributes = [] } = state;
  return !!(channel === 'mobile'
    && isLookupLengthLessThanMaximum(state)
    && hasSwatchOnFirstAttribute(attributes)
  );
};

export const shouldDisplayTooltips = (state, channel) => {
  const isDesktop = channel === 'desktop';
  return (isDesktop
    && isLookupLengthLessThanMaximum(state)
  );
};

export const getProcessedInventoryData = (onlineInventory, storeInventory, expressDelivery, isItemBackOrdered) => {
  return {
    isAvailable: ((onlineInventory?.enableItem && onlineInventory?.totalQuantity > 0)
      || (storeInventory?.enableItem && storeInventory?.totalQuantity > 0)
      || expressDelivery || isItemBackOrdered)
  };
};

export const formatPrice = (rawPrice) => {
  // TODO: this should be a common/shared utility function https://github.homedepot.com/thd-olt-functional/utils
  const addCommas = () => parseFloat(rawPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const format = () => {
    let parsedPrice = parseFloat(rawPrice).toFixed(2).toString().split('.');
    let formattedPrice = '';
    let dollars = parsedPrice[0];
    let cents = parsedPrice[1];
    let lessThanDollar = false;

    if (dollars < 1) {
      lessThanDollar = true;
      formattedPrice = cents + '¢';
    } else {
      formattedPrice = '$' + addCommas();
    }

    return {
      formatted: formattedPrice,
      dollars,
      cents,
      lessThanDollar
    };
  };

  return {
    addCommas,
    format
  };
};

export const getProcessedPricingData = (pricing) => {
  const { value, original, message: pricingMessage, mapAboveOriginalPrice } = pricing || {};

  let message = value ? '' : 'Click Option to View Price';
  let rawPrice = value;
  const strikeThroughPriceForMap = !mapAboveOriginalPrice && /see final price in cart/i.test(pricingMessage);

  if (mapAboveOriginalPrice || strikeThroughPriceForMap) {
    message = 'See Price in Cart';
    rawPrice = original;
  }

  return {
    price: rawPrice && formatPrice(rawPrice)?.format(),
    isSpecialPrice: !!original,
    shouldHidePriceForMap: !!mapAboveOriginalPrice,
    message,
    strikeThroughPriceForMap,
  };
};

export const getFormattedChildItemsLookup = (attributes, childItemsLookup) => {
  const attributeNames = attributes.map((attribute) => attribute.attributeName);

  return childItemsLookup.map((item) => {
    let combination = item.attributeCombination.split(COMBINATION_DELIMITER);
    if (attributes[0] && attributes[0]?.attributeName === 'Fabric Type') {
      combination = reorderAttributesForPatio(combination);
    }
    const flattenedCombinations = combination.reduce((acc, combo, index) => {
      acc[attributeNames[index]] = combo;
      return acc;
    }, {});
    return {
      ...item,
      ...flattenedCombinations
    };
  });
};

export const getSwatchCardCombinationDetails = (state, attributeLabel, attributeValue) => {
  const currentCard = {
    attributeLabel,
    attributeValue
  };

  return findClosestSku(state, currentCard);
};

export const isItemOutOfStock = (fulfillment, type) => {
  const { isOutOfStockOnline } = fulfillment || {};
  return isOutOfStockOnline && type === 'Online';
};

export const isItemUnavailableInStore = (fulfillment, type) => {
  const { isOutOfStockOnline } = fulfillment || {};
  return isOutOfStockOnline && type === 'Store Only';
};

export const transformMetadataToSearchNav = ({ superSkuData, itemId, currentSwatches }) => {
  const { attributes, childItemsLookup: childItemsRaw } = superSkuData || {};
  const childItemsLookup = getFormattedChildItemsLookup(attributes, childItemsRaw);
  const selectedProduct = superSkuData.childItemsLookup?.find((item) => {
    return item?.itemId === itemId;
  });
  const initialState = {
    attributes,
    childItemsLookup,
    selectedProduct
  };
  if (selectedProduct) {
    const currentlySelected = selectedProduct.attributeCombination?.split(COMBINATION_DELIMITER);
    initialState.selectedAttributes = currentlySelected?.map((attributeValue, index) => {
      return {
        attributeLabel: attributes?.[index]?.attributeName,
        attributeValue: (attributeValue.indexOf(KEY_VALUE_DELIMITER) >= 0
          ? attributeValue.split(KEY_VALUE_DELIMITER)[1]
          : attributeValue),
        isSwatch: attributes?.[index]?.isSwatch
      };
    });
    initialState.itemId = selectedProduct.itemId;
    initialState.canonicalUrl = selectedProduct.canonicalUrl;
  }
  const { attributeName, attributeValues } = attributes[0] || {};

  const mappedItems = attributeValues?.map(({ value, swatchGuid }) => {
    let updatedState = {
      ...initialState
    };
    const clickedTileInformation = {
      attributeLabel: attributeName,
      attributeValue: value
    };
    updatedState.selectedAttributes = getAttributeCombination(updatedState.selectedAttributes, clickedTileInformation);

    const selectedAttributesInfo = getSelectedAttributes(updatedState, clickedTileInformation);
    const productInfo = getProductInformation(selectedAttributesInfo);
    return {
      value,
      swatchGuid,
      ...productInfo
    };
  });

  const filteredItems = mappedItems?.filter((item) => {
    return !currentSwatches?.find(({ label }) => {
      return label === item?.value;
    });
  });

  const newSwatches = filteredItems?.map((item) => {
    const {
      value,
      swatchGuid,
      itemId: swatchItemId
    } = item || {};
    return {
      itemId: swatchItemId,
      label: value,
      swatchImgUrl: swatchGuid,
      isSelected: swatchItemId === initialState?.itemId
    };
  });

  return [...currentSwatches, ...newSwatches];
};

export const syncAttributes = (state, dispatch, domPath) => {
  const collectionListPath = 'CollectionList[0]';
  if (domPath.indexOf(collectionListPath) !== -1
    && typeof window !== 'undefined'
    && window.LIFE_CYCLE_EVENT_BUS
  ) {
    window.LIFE_CYCLE_EVENT_BUS.on('super-sku.pass-to-collection', (data) => {
      const { parentId } = state;
      if (data?.output?.parentId === parentId) {
        const { output } = data;
        const { attributeLabel, attributeValue } = output;
        dispatch({
          type: ACTION_TYPES.SELECT, attributeLabel, attributeValue
        });
      }
    });
  }
};

export const shouldDisplayAlerts = (state) => {
  const {
    enabledAndAvailableAttributeValues = {},
    errorState = false,
    isMiniSwatch = false,
    jumpedState = false,
    impossibleState = false,
    unavailableState = false,
    isDiscontinued = false,
    isOutOfStock = false
  } = state || {};

  const availableAttributes = enabledAndAvailableAttributeValues?.available || {};
  const attributes = Object.values(availableAttributes);
  return (
    attributes.some((attribute) => attribute.size !== 0)
    && !errorState
    && !isMiniSwatch
    && (jumpedState || impossibleState || unavailableState || isDiscontinued || isOutOfStock)
  );
};

export const isSheenValue = (value) => {
  return ['Flat/Matte', 'Eggshell', 'Satin', 'Semi-Gloss', 'High-Gloss'].includes(value);
};

export const getPaintImageUrl = (attributeValue) => {
  let imagePath;
  switch (attributeValue) {
  case 'Interior Paint':
    imagePath = 'paint/paint-type-interior.svg';
    break;
  case 'Exterior Paint':
    imagePath = 'paint/paint-type-exterior.svg';
    break;
  case 'BEHR MARQUEE':
    imagePath = 'brand-logos/behr-marquee-black.svg';
    break;
  case 'BEHR ULTRA':
    imagePath = 'brand-logos/behr-ultra-blk.svg';
    break;
  case 'BEHR PREMIUM PLUS':
    imagePath = 'brand-logos/behr-premium-plus-black.svg';
    break;
  case 'BEHR DYNASTY':
    imagePath = 'brand-logos/behr-dynasty-blk.svg';
    break;
  case 'PPG TIMELESS':
    imagePath = 'brand-logos/ppg-timeless.svg';
    break;
  case 'PPG Diamond':
    imagePath = 'brand-logos/ppg-diamond.svg';
    break;
  case 'Glidden Diamond':
    imagePath = 'brand-logos/glidden-diamond.svg';
    break;
  case 'Glidden Premium':
    imagePath = 'brand-logos/glidden-premium.svg';
    break;
  case 'Glidden Essentials':
    imagePath = 'brand-logos/glidden-essentials.svg';
    break;
  case 'Flat/Matte':
    imagePath = 'paint/flat-matte-tile.png';
    break;
  case 'Eggshell':
    imagePath = 'paint/eggshell-tile.png';
    break;
  case 'Satin':
    imagePath = 'paint/satin-tile.png';
    break;
  case 'Semi-Gloss':
    imagePath = 'paint/semi-gloss-tile.png';
    break;
  case 'High-Gloss':
    imagePath = 'paint/high-gloss-tile.png';
    break;
  case '8 Ounce':
    imagePath = 'paint/sample-bucket.svg';
    break;
  case '1 Quart':
    imagePath = 'paint/quart-bucket.svg';
    break;
  case '1 Gallon':
    imagePath = 'paint/1-gallon-bucket.svg';
    break;
  case '5 Gallon':
    imagePath = 'paint/5-gallon-bucket.svg';
    break;
  default:
    break;
  }
  return imagePath ? `https://assets.thdstatic.com/images/v1/${imagePath}` : undefined;
};

export const getPaintContainerSize = (value) => {
  let containerSize;

  switch (value) {
  case '8 Ounce':
    containerSize = 'sample';
    break;
  case '1 Quart':
    containerSize = 'quart';
    break;
  case '1 Gallon':
    containerSize = 'gallon';
    break;
  case '5 Gallon':
    containerSize = '5gallon';
    break;
  default:
    break;
  }

  return containerSize;
};
