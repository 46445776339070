import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { node, string } from 'prop-types';

const ContentAccordionSection = ({
  title,
  children,
  anchorId
}) => {
  let contentAccordionContents = [];
  const sectionTitle = (
    <>
      {title && (
        <div className="sui-pb-4">
          <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>
            {title}
          </Typography>
        </div>
      )}
    </>
  );

  return (
    <section id={anchorId} className="sui-flex sui-flex-col sui-mt-2 sui-gap-1">
      {sectionTitle}
      {children}
    </section>
  );
};

ContentAccordionSection.propTypes = {
  title: string,
  anchorId: string,
  children: node.isRequired
};

ContentAccordionSection.defaultProps = {
  title: '',
  anchorId: ''
};

export { ContentAccordionSection };
