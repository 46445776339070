import React, { useContext } from 'react';
import { useHelmet, RedirectTag } from '@thd-nucleus/thd-helmet';
import { number, params, shape, string, arrayOf, useDataModel } from '@thd-nucleus/data-sources';
import { RedirectContext } from './RedirectContext';

import {
  isSingleProductResult,
  isInvalidRequest,
  isSearchRedirect,
  isCanonicalRedirect,
  isNavParamMissing
} from './rules/browse-search-redirect-rules';

import {
  ERROR_HANDLING_EXPERIENCE,
  getQueryStringValue,
  handleErrors
} from '../utils/redirector-utils';

const browseHandler = ({ data, error, props, history }) => {

  if (error) {
    const notOkError = handleErrors(error, ERROR_HANDLING_EXPERIENCE.BROWSE);
    if (notOkError) {
      return new RedirectTag(notOkError, props?.bypass, history);
    }
  }

  const [path, queryParams] = (props?.pathWithQueryParams || '').split('?');

  let searchTypeParam = '';
  let storeParam = '';
  let storeSelectionParam = '';

  const searchTypeValue = getQueryStringValue(queryParams, 'searchType');
  if (searchTypeValue && searchTypeValue === 'barcode') {
    searchTypeParam = `?searchType=${searchTypeValue}`;
  }

  const storeValue = getQueryStringValue(queryParams, 'store');
  if (storeValue) {
    storeParam = `&store=${storeValue}`;
  }

  const storeSelectionValue = getQueryStringValue(queryParams, 'storeSelection');
  if (storeSelectionValue) {
    storeSelectionParam = `&storeSelection=${storeSelectionValue}`;
  }

  const singleProduct = isSingleProductResult({ data, path, searchTypeParam });
  if (singleProduct) {
    return new RedirectTag(singleProduct, props?.bypass, history);
  }

  const {
    keyword,
    navParam,
    ntt: nttKeyword
  } = props;
  const invalidRequest = isInvalidRequest({ data, error, keyword, navParam, nttKeyword });
  if (invalidRequest) {
    return new RedirectTag(invalidRequest, props.bypass, history);
  }

  const navParamMissing = isNavParamMissing({ data, navParam, nttKeyword, error });
  if (navParamMissing) {
    return new RedirectTag(navParamMissing, props.bypass);
  }

  const searchRedirect = isSearchRedirect({
    data,
    keyword,
    searchTypeParam,
    storeParam,
    storeSelectionParam
  });
  if (searchRedirect) {
    return new RedirectTag(searchRedirect, props.bypass, history);
  }

  const canonicalRedirect = isCanonicalRedirect({ data, keyword, path, navParam, searchTypeParam });
  if (canonicalRedirect) {
    return new RedirectTag(canonicalRedirect, props.bypass, history);
  }

  return null;
};

// eslint-disable-next-line react/prop-types
export const BrowseSearchRedirector = ({ variables }) => {

  const {
    history,
    pathWithQueryParams,
    keyword,
    bypass,
    navParam,
    nttKeyword,
    redirectState,
    timeoutRecovery
  } = useContext(RedirectContext);

  const defaultOptions = {
    variables: {
      keyword: keyword || nttKeyword,
      navParam,
    },
    ssr: true
  };
  const options = variables
    ? { variables, ssr: true }
    : defaultOptions;
  const { data, error, loading } = useDataModel('searchModel', options);

  const props = {
    keyword,
    nttKeyword,
    navParam,
    error,
    pathWithQueryParams,
    bypass
  };
  useHelmet('browseRedirector', { data, error, props, history }, browseHandler, [data?.searchModel?.id]);

  return null;
};
BrowseSearchRedirector.displayName = 'BrowseSearchRedirector';
BrowseSearchRedirector.propTypes = {};

BrowseSearchRedirector.defaultProps = {};

BrowseSearchRedirector.dataModel = {
  searchModel: params({ keyword: string(), navParam: string() }).shape({
    id: string(),
    metadata: shape({
      canonicalUrl: string(),
      searchRedirect: string(),
      analytics: shape({
        semanticTokens: string()
      })
    }),
    searchReport: shape({
      totalProducts: number(),
      didYouMean: string(),
      correctedKeyword: string()
    }),
    relatedResults: shape({
      universalSearch: shape({
        title: string()
      }),
      relatedServices: arrayOf(shape({
        label: string()
      }))
    }),
    products: params({ pageSize: number() }).arrayOf(shape({
      itemId: string(),
      dataSources: string(),
      identifiers: shape({
        canonicalUrl: string()
      })
    }))
  })
};
