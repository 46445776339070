export const ACTION_TYPES = {
  LOAD_STATE: 'LOAD_STATE',
  ADD_MEDIA_PRICE_AND_INVENTORY: 'ADD_MEDIA_PRICE_AND_INVENTORY',
  HOVER: 'HOVER',
  HOVER_RESET: 'HOVER_RESET',
  ERROR_STATE: 'ERROR_STATE',
  NO_DATA_STATE: 'NO_DATA_STATE',
  LOADING_STATE: 'LOADING_STATE',
  SELECT: 'SELECT',
  UPDATE_OVERFLOW_VALUES: 'UPDATE_OVERFLOW_VALUES',
  RESET_OVERFLOW_VALUES: 'RESET_OVERFLOW_VALUES',
  SDSKU_SELECT: 'SDSKU_SELECT',
  UPDATE_ALERT_VALUES: 'UPDATE_ALERT_VALUES'
};
export const KEY_VALUE_DELIMITER = '::';
export const COMBINATION_DELIMITER = '~~';
export const MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT = 25;
export const MAXIMUM_CALLS_FOR_PRICE_ENDPOINT = 2;
export const NUMBER_OF_MINI_SWATCHES_ON_MOBILE = 4;
export const NUMBER_OF_MINI_SWATCHES_ON_DESKTOP = 5;
export const WIDTH_OF_TOOLTIP = 225;
export const NUMBER_OF_DROPDOWN_ITEMS_FOR_VIEW_MORE = 10;
export const NUMBER_OF_ATTRIBUTES_FOR_MORE_OPTIONS = 2;
