import React, { useState } from 'react';
import classnames from 'classnames';
import './swatch.scss';
import {
  bool, func, oneOf, shape, string
} from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { TooltipContent } from '../tooltip-content/tooltip-content';

export const Swatch = (props) => {
  const {
    channel,
    displayInToolTip,
    hoveredCombinationDetails,
    isAvailable,
    isSelected,
    isTooltipsEnabled,
    miniSwatch,
    onClick,
    onMouseEnter,
    onMouseLeave,
    swatchGuid,
    value
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const IMAGE_SIZE = miniSwatch ? '17' : '30';
  const isMobile = channel === 'mobile';

  const shouldShowTooltip = isHovered
    && isTooltipsEnabled && hoveredCombinationDetails?.combinationDetails && !miniSwatch;
  const title = shouldShowTooltip ? '' : value;

  const swatchClasses = classnames('super-sku__inline-swatch border-radius--medium', {
    'super-sku__inline-swatch--selected': isSelected,
    'super-sku__inline-swatch--disabled': !isSelected && !isAvailable && !miniSwatch,
    'super-sku__inline-swatch__mini-swatch': miniSwatch,
    'super-sku__inline-swatch__display-in-tooltip': displayInToolTip
  });

  const imageClasses = classnames('border-radius--medium', {
    'mini-image': miniSwatch,
    'mini-image--selected': displayInToolTip && isSelected
  });
  const mouseEnter = (event) => {
    if (!isMobile) {
      setIsHovered(true);
      onMouseEnter(event);
    }
  };
  const mouseLeave = (event) => {
    if (!isMobile) {
      setIsHovered(false);
      onMouseLeave(event);
    }
  };

  const swatchChild = (
    <button
      className={swatchClasses}
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      type="button"
    >
      <div className="swatch__image border-radius--medium">
        <Image
          alt={value}
          className={imageClasses}
          src={swatchGuid}
          title={title}
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          lazy
        />
      </div>
      {displayInToolTip && <div className="swatch__text">{title}</div>}
    </button>
  );

  return (shouldShowTooltip
    ? (
      <Tooltip
        channel={channel}
        content={<TooltipContent hoveredCombinationDetails={hoveredCombinationDetails} />}
        interactive
      >
        {swatchChild}
      </Tooltip>
    )
    : (swatchChild)
  );
};

Swatch.displayName = 'Swatch';

Swatch.propTypes = {
  channel: oneOf(['desktop', 'mobile']).isRequired,
  displayInToolTip: bool,
  hoveredCombinationDetails: shape({}),
  isAvailable: bool.isRequired,
  isSelected: bool.isRequired,
  isTooltipsEnabled: bool,
  miniSwatch: bool,
  onClick: func.isRequired,
  onMouseEnter: func.isRequired,
  onMouseLeave: func.isRequired,
  swatchGuid: string.isRequired,
  value: string.isRequired
};

Swatch.defaultProps = {
  displayInToolTip: false,
  hoveredCombinationDetails: {},
  miniSwatch: false,
  isTooltipsEnabled: false
};
