import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  customType, fragment, params, string as stringType, useDataModel
} from '@thd-nucleus/data-sources';
import './content-accordion.scss';
import { ContentAccordionRenderer } from './ContentAccordionRenderer';

const ContentAccordion = ({ componentId, componentClass }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-accordion.ready'); }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  return (
    <ContentAccordionRenderer
      componentId={componentId}
      componentClass={componentClass}
      descriptionContent={data?.component?.description}
      data={data?.component}
    />
  );
};

ContentAccordion.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired
};

ContentAccordion.displayName = 'ContentAccordion';

ContentAccordion.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['ContentAccordion']).isRequired()
  }).shape({
    ContentAccordion: fragment().shape({
      id: stringType(),
      title: stringType(),
      subtitle: stringType(),
      description: stringType()
    })
  })
};

export { ContentAccordion };
