import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { extend } from '@thd-nucleus/data-sources';
import { isGenericProduct } from '../../utils';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductStore = ({ checkGeneric, className }) => {
  const {
    doNotShowChooseYourOptions,
    product,
    isRequestAQuoteEligible,
    isScheduleAMeasureEligible,
    isScheduleAConsultationEligible,
    onCheckNearbyStoresClick
  } = useContext(ProductPodContext);

  const { channel } = useContext(ExperienceContext);
  if ((!doNotShowChooseYourOptions && checkGeneric && isGenericProduct(product))
    || isScheduleAMeasureEligible
    || isScheduleAConsultationEligible
    || isRequestAQuoteEligible) {
    return null;
  }
  const storeClasses = classNames('product-pod__fulfillment--store', className, {
    'product-pod__fulfillment--store--mobile': channel === 'mobile'
  });

  if (!product?.fulfillment) return <TextBlock rows={2} color="#E0E0E0" className={storeClasses} />;

  return (
    <div className={storeClasses}>
      <FulfillmentPodStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
      />
    </div>
  );
};

ProductStore.propTypes = {
  checkGeneric: bool,
  className: string
};

ProductStore.defaultProps = {
  checkGeneric: false,
  className: null
};

ProductStore.displayName = 'ProductStore';

ProductStore.dataModel = extend(
  FulfillmentPodStore,
);
