/* eslint-disable max-len */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { extend } from '@thd-nucleus/data-sources';
import { Sticky } from '@thd-olt-component-react/sticky';
import { ProductResultsContext } from './ProductResultsContext';
import { FilterAndSortPlaceholder } from './FilterAndSort/FilterAndSortPlaceholder';
import { PrimaryFilters } from './PrimaryFilters/PrimaryFilters';
import { publish } from '../publisher';
import { DrawerAndAppliedRefinements as Drawer } from './FilterAndSort/DrawerAndAppliedRefinements';
import { MainFilter } from './FilterAndSort/MainFilter';
import {
  getAppliedDimensions,
  getClearAllWithSort,
  getRefinementUrlWithSort,
  sortAscending,
  sortDimensions,
  sortRefinementKeys,
  sortSelectedDimensions,
  findCustomPriceRange,
  getDimensionsForUrl,
  getRefinemntsLabelsForUrl,
  ON_DISPLAY_ITEM,
  filtersCatStyleCheck,
  sortOrder,
  onMultiStoreSelectionChange
} from '../product-results-helpers';
import '../styles/filter-and-sort.scss';
import OnDisplayContext from './OnDisplayContext';

const ResultsFilterAndSortDrawer = ({
  onDimensionsChange,
  onRefinementSelected,
  onSortChange,
  stickyOffset,
  multiStoreSelection,
  enableMultiStore,
  onDisplay,
  onDisplayChange,
  disableStickyFilter,
  isTableView,
  hideViewAll,
  isGetItFastOnPlp
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const { data, loading } = useContext(ProductResultsContext);
  const [onDisplayClick, setOnDisplayClick] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const appliedSelection = useRef([]);
  const clearAllRefinement = useRef();

  const {
    appliedDimensions, taxonomy, dimensions, searchReport, metadata, primaryFilters
  } = data?.searchModel || {};
  const { canonicalUrl = '', clearAllRefinementsURL = '' } = metadata || {};

  let [selectedDimensions, setSelectedDimensions] = useState(
    appliedDimensions ? getAppliedDimensions(appliedDimensions) : []
  );
  const desktopSort = useRef('');
  const handleSortOrderChange = ({ output: result }) => {
    if (result?.sortby && result?.sortorder) {
      desktopSort.current = `${result.sortby}:${result.sortorder}`;
    }
  };
  useEffect(() => {
    setSelectedDimensions(getAppliedDimensions(appliedDimensions));
    window.LIFE_CYCLE_EVENT_BUS.on('sortorder.value', handleSortOrderChange);
    let _appliedDimensions = getAppliedDimensions(appliedDimensions);
    if (onDisplay === 'true') {
      setSelectedDimensions([..._appliedDimensions, ON_DISPLAY_ITEM]);
    } else {
      setSelectedDimensions(_appliedDimensions);
    }
  }, [appliedDimensions]);

  const isRefinementPresent = () => {
    return selectedDimensions.some((dimension) => dimension?.refinements.some((ref) => ref?.label === ON_DISPLAY_ITEM?.refinements[0]?.label));
  };

  // adding chips into drawer
  const addRefinment = () => {
    if (!isRefinementPresent()) {
      setSelectedDimensions([...selectedDimensions, { ...ON_DISPLAY_ITEM }]);
    }
  };

  // removing chip from drawer
  const removeRefinment = () => {
    if (isRefinementPresent() && selectedDimensions.length) {
      setSelectedDimensions(selectedDimensions.filter((item) => {
        return !item?.refinements.some((refinement) => refinement.refinementLabel === 'On Display');
      }));
    }
  };

  useEffect(() => {
    setOnDisplayClick(isRefinementPresent());
  }, [selectedDimensions]);

  const onDisplayData = {
    onDisplay,
    onDisplayChange,
    isStoreDisplay: data?.searchModel?.metadata?.isStoreDisplay || null,
    itemCount: data?.searchModel?.metadata?.productCount?.inStore
  };

  if (!dimensions) {
    return <FilterAndSortPlaceholder numOf={5} />;
  }
  if (dimensions.length === 0) {
    return null;
  }

  const { breadCrumbs = [] } = taxonomy || {};

  const keepCurrentSelection = () => {
    return getAppliedDimensions(selectedDimensions);
  };

  const toggleDrawer = (close) => {
    if (!close) {
      appliedSelection.current = keepCurrentSelection();
    }
    if (close) {
      setSelectedDimensions(appliedSelection.current);
    }
    setDrawerOpen(!drawerOpen);
    publish('change-secondary-filter', !drawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    document.body.classList.remove('filter-and-sort--no-scroll');
  };

  // builds url and call dimension changed
  const applyFilters = () => {
    document.body.classList.add('filter-and-sort--fade');
    setTimeout(() => {
      if (clearAllRefinement.current !== undefined) {
        appliedSelection.current = [];
        onDimensionsChange({ refinement: clearAllRefinement.current });
        closeDrawer();
        document.body.classList.remove('filter-and-sort--fade');
        return;
      }
      let sortedSelectedDimensions = sortSelectedDimensions(selectedDimensions);
      if (selectedDimensions && Object.keys(selectedDimensions).length > 0) {
        publish('change-filters-refinements-secondary', selectedDimensions);
      }
      // get refinement keys and sort them by id
      let refinementKeys = sortedSelectedDimensions
        .map((dim) => dim.refinements.map((ref) => ref.refinementKey)).flat(1);

      let sortedRefinementKeys = sortRefinementKeys(refinementKeys);

      // get dimensions that may go on the url
      let dimensionsIncludedInUrl = getDimensionsForUrl(sortedSelectedDimensions);

      // get refinements from the previous dimension list
      let refinementsIncludedInUrl = dimensionsIncludedInUrl?.map(
        (_dimension) => _dimension.refinements.sort(sortAscending)).flat(1);
      let length = refinementsIncludedInUrl && refinementsIncludedInUrl.length;
      let lastRefinement = refinementsIncludedInUrl && refinementsIncludedInUrl[length - 1];
      let urlParts = clearAllRefinementsURL?.split('?')[0].split('/');

      // get custom price range
      const priceDimension = selectedDimensions.find((_dimension) => _dimension.label === 'Price');
      const [lowerbound, upperbound] = findCustomPriceRange(priceDimension?.refinements);
      const canonicalUrlParts = canonicalUrl.split('?');
      let queryParams = canonicalUrlParts.length > 1 ? canonicalUrlParts[1] : '';
      if (lowerbound || upperbound) {
        let queries = queryParams.split('&')
          .filter((query) => !query.toLowerCase().includes('lowerbound') && !query.toLowerCase().includes('upperbound'));
        queryParams = queries.join('&');
        queryParams += `&lowerbound=${lowerbound}&upperbound=${upperbound}`;
      }

      queryParams = filtersCatStyleCheck(queryParams);

      queryParams = sortOrder({ desktopSort, queryParams });

      const nValue = urlParts.find((part) => part.indexOf('N-') !== -1) || '';
      let refinementsIds = nValue;
      if (sortedRefinementKeys.length > 0) {
        refinementsIds += 'Z' + sortedRefinementKeys.join('Z');
      }
      // get refinement labels for url
      const refinementsLablesForUrl = getRefinemntsLabelsForUrl(refinementsIncludedInUrl);
      urlParts.splice(urlParts.indexOf(nValue), 0, ...refinementsLablesForUrl);

      urlParts.splice(urlParts.indexOf(nValue), 1, refinementsIds);
      lastRefinement = { ...lastRefinement, url: urlParts.join('/') + '?' + queryParams };
      appliedSelection.current = [];
      // pass dimension
      if (lastRefinement?.refinementKey === 'onDisplay') {
        onDimensionsChange({ refinement: lastRefinement, onDisplay: true });
      } else {
        onDimensionsChange({ refinement: lastRefinement });
      }
      closeDrawer();
      document.body.classList.remove('filter-and-sort--fade');
    }, 0);
  };

  // update selected refinements list
  const updateSelectedRefinements = ({ refinement, dimension, radio, multiRefinements }) => {
    clearAllRefinement.current = undefined;
    let temp = selectedDimensions.filter((dim) => dim.label !== 'Clear');
    let index = selectedDimensions.findIndex((dim) => {
      return dim.label === dimension.label;
    });

    if (multiRefinements) {
      temp = selectedDimensions.slice();
      temp = [
        ...selectedDimensions,
        {
          label: dimension.label,
          refinements: [...multiRefinements]
        }
      ];
    }

    if (refinement && index >= 0) {
      temp = selectedDimensions.slice();
      let selectedRefinement = -1;
      if (dimension.dimensionId === 'custom_price') {
        let priceRangeList = temp[index].refinements.filter((ref) => ref.refinementKey !== refinement.refinementKey);
        temp[index].refinements = priceRangeList;
      }
      if (temp.length > 0) {
        selectedRefinement = temp[index].refinements.findIndex(
          (ref) => ref.refinementKey === refinement.refinementKey);
      }
      if (radio && selectedRefinement === -1) {
        temp[index].refinements = [];
      }
      if (selectedRefinement >= 0) {
        temp[index].refinements = temp[index].refinements
          .filter((_refinement) => (_refinement.refinementKey !== refinement.refinementKey) || radio);
        if (temp[index].refinements.length === 0) {
          temp.splice(index, 1);
        }
      } else {
        temp[index].refinements = temp[index].refinements.slice();
        temp[index].refinements.push(refinement);
      }
    } else if (refinement && refinement.clear) {
      return [];
    } else if (refinement) {
      temp = selectedDimensions.slice();
      temp.push({
        label: dimension.label,
        refinements: [refinement]
      });
    }
    return temp;
  };

  const selectRefinement = ({
    refinement, dimension, radio, multiRefinements, isChecked
  }) => {
    if (isChecked !== undefined
      && (refinement?.refinementKey === 'onDisplay' || refinement?.label === 'Pick Up Today')) {
      if (isChecked) {
        addRefinment();
      } else {
        removeRefinment();
      }
      return;
    }
    const updatedSelectedRefinements = updateSelectedRefinements({ refinement, dimension, radio, multiRefinements });
    setSelectedDimensions(updatedSelectedRefinements);
  };

  const handleClearAll = () => {
    const url = getClearAllWithSort({ metadata, searchReport });
    clearAllRefinement.current = {
      url,
      clear: true
    };
    removeRefinment();
    setOnDisplayClick(false);
    setSelectedDimensions([]);
  };

  ResultsFilterAndSortDrawer.toggleDrawer = toggleDrawer;
  const sortedDimensions = sortDimensions({ dimensions, searchReport, metadata });

  const onRefinementChange = ({ refinement, dimension }) => {
    document.body.classList.add('filter-and-sort--fade');
    setTimeout(() => {
      if (onDimensionsChange && dimension) {
        const updatedSelectedRefinements = updateSelectedRefinements({ refinement, dimension });
        setSelectedDimensions(updatedSelectedRefinements);
        const refinementUrl = getRefinementUrlWithSort({ dimension, metadata, refinement, searchReport });
        const { label: dimensionName } = dimension;
        const updatedRefinement = {
          ...refinement,
          url: refinementUrl,
          dimensionName
        };
        onDimensionsChange({ refinement: updatedRefinement });
      } else if (onDimensionsChange && !dimension) {
        onDimensionsChange({ refinement });
      }
      document.body.classList.remove('filter-and-sort--fade');
    }, 0);
  };

  const handleCloseDrawer = () => {
    closeDrawer();
  };

  return (
    <OnDisplayContext.Provider value={{ onDisplayClick, setOnDisplayClick }}>
      <div className="filter-and-sort" data-component="ResultsFilterAndSortDrawer">
        <Drawer
          appliedDimensions={selectedDimensions}
          breadCrumbs={breadCrumbs}
          isOpen={drawerOpen}
          onSortByChange={onSortChange}
          onRefinementChange={onRefinementChange}
          onRefinementSelected={onRefinementSelected || selectRefinement}
          metadata={metadata}
          normalizedDimensions={sortedDimensions}
          searchReport={searchReport}
          toggleDrawer={toggleDrawer}
          onDisplayData={onDisplayData}
          loading={loading}
          enableMultiStore={enableMultiStore}
          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
          multiStoreSelection={multiStoreSelection}
          onDimensionsChange={applyFilters}
          clearAll={handleClearAll}
          isTableView={isTableView}
        />
        {!disableStickyFilter ? (
          <div className="filter-and-sort__sticky-wrapper">
            <Sticky
              animation="slide"
              className="filter-and-sort--sticky"
              offsetMargin={stickyOffset}
              position="top"
              stickyType="showOnScrollUp"
              visibleByDefault
            >
              <PrimaryFilters
                appliedDimensions={selectedDimensions}
                dimensions={dimensions}
                primaryFilters={primaryFilters}
                isMobile={isMobile}
                isOpen={drawerOpen}
                handleCloseDrawer={handleCloseDrawer}
                onApply={applyFilters}
                onChange={selectRefinement}
                onRefinementChange={onRefinementChange}
                toggleSecondaryDrawer={toggleDrawer}
                canonicalUrl={canonicalUrl}
                baseUrl={clearAllRefinementsURL}
                hideViewAll={hideViewAll}
                desktopSort={desktopSort}
              />
            </Sticky>
          </div>
        ) : (
          <PrimaryFilters
            appliedDimensions={selectedDimensions}
            dimensions={dimensions}
            primaryFilters={primaryFilters}
            isMobile={isMobile}
            isOpen={drawerOpen}
            handleCloseDrawer={handleCloseDrawer}
            onApply={applyFilters}
            onChange={selectRefinement}
            onRefinementChange={onRefinementChange}
            toggleSecondaryDrawer={toggleDrawer}
            canonicalUrl={canonicalUrl}
            baseUrl={clearAllRefinementsURL}
            hideViewAll={hideViewAll}
            desktopSort={desktopSort}
          />
        )}
        {isMobile && !isGetItFastOnPlp && (
          <MainFilter
            dimensions={sortedDimensions}
            enableMultiStore={enableMultiStore}
            multiStoreSelection={multiStoreSelection}
            onChange={onDisplay === 'true' ? onDisplayChange : onRefinementChange}
            onDisplayData={onDisplayData}
            onMultiStoreSelectionChange={onMultiStoreSelectionChange}
            isPrimaryFilters
          />
        )}
      </div>
    </OnDisplayContext.Provider>
  );
};

ResultsFilterAndSortDrawer.propTypes = {
  onDimensionsChange: PropTypes.func.isRequired,
  onRefinementSelected: PropTypes.func,
  onSortChange: PropTypes.func.isRequired,
  stickyOffset: PropTypes.number,
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onDisplay: PropTypes.string,
  onDisplayChange: PropTypes.func,
  disableStickyFilter: PropTypes.bool,
  isTableView: PropTypes.bool,
  hideViewAll: PropTypes.bool,
  isGetItFastOnPlp: PropTypes.bool
};

ResultsFilterAndSortDrawer.defaultProps = {
  onRefinementSelected: null,
  stickyOffset: 0,
  enableMultiStore: false,
  multiStoreSelection: null,
  onDisplay: 'false',
  onDisplayChange: null,
  disableStickyFilter: false,
  isTableView: false,
  hideViewAll: false,
  isGetItFastOnPlp: false
};

ResultsFilterAndSortDrawer.displayName = 'ResultsFilterAndSortDrawer';

ResultsFilterAndSortDrawer.dataModel = extend(MainFilter, Drawer);

export { ResultsFilterAndSortDrawer };
