import {
  arrayOf, bool, element, func, number, oneOf, shape, string
} from 'prop-types';
import { getDomPath } from '@thd-olt-functional/utils';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import {
  CHECK_NEARBY_STORE,
  COMPARE,
  PRODUCT_DETAILS,
  PRODUCT_IMAGE,
  PRODUCT_POD_CLICK,
  PRODUCT_POD_COMPARE,
  PRODUCT_POD_SIMILAR,
  PRODUCT_POD_SWAP,
  RATING
} from './constants';
import { publish, publishAnalyticsEvent } from '../analytics';
import {
  checkScheduleAMeasureEligibility,
  checkGccCarpetDesignAndOrderEligibility,
  checkIsHDQCSku,
  checkIsHDDCSku,
  isCustomKitchenCabinetProduct,
  isBrioProduct,
} from './utils';

export const ProductPodPropTypes = {
  analyticsData: shape({}),
  anchorItem: string,
  automotiveFitmentObject: shape({}),
  brandMaxLine: number,
  brandTitleMaxLine: oneOf([2, 3, 4, null]),
  channelFromTest: string,
  className: string,
  comparable: bool,
  customEventObject: shape({}),
  // Prevents showing "Choose Your Options" button instead of ATC when product is generic
  doNotShowChooseYourOptions: bool,
  dpdNewTab: bool,
  hasInStoreFilter: bool,
  hideATC: bool,
  hideBadges: bool,
  hideFavorites: bool,
  hideFulfillment: bool,
  hideCaret: bool,
  hideLimitPerOrder: bool,
  hidePriceBadge: bool,
  hidePromotions: bool,
  hideRating: bool,
  hideRebates: bool,
  hideSavingsText: bool,
  hideSeeSimilarItems: bool,
  hideShippingInfo: bool,
  hideShopThisCollection: bool,
  hideSponsored: bool,
  hideStoreInfo: bool,
  hover: bool,
  hoverDelay: number,
  installationServices: bool,
  isEtchOne: bool,
  // this prop is deprecated, migrate to the anlyticsData prop
  irgSectionName: string,
  itemId: string,
  keyword: string,
  maxKeyFeatures: number,
  miniOptions: shape({
    disabled: bool,
    onAction: func,
    enableATC: bool,
    silentATC: bool,
    onlyShowIfFulfillable: bool,
    inCartQuantity: number,
    callToActionSection: element,
    ctaLocation: oneOf(['bottom', 'right']),
    hideFulfillment: bool,
    hideCaret: bool,
    ctaNoPadding: bool
  }),
  mobilePodType: oneOf(['singleColumn', 'doubleColumn', 'simple']),
  noATCFulfillment: bool,
  onCartFailure: func,
  onCartSuccess: func,
  onClick: func,
  onCompare: func,
  onEdit: func,
  onError: func,
  onFavoriteChange: func,
  onRemove: func,
  onHover: func,
  onHoverExit: func,
  onHoverSuperSku: func,
  onQuantity: func,
  onSelectedForCompare: func,
  onShowSimilarClick: func,
  onSuperSkuChange: func,
  onTouchEnd: func,
  onTouchStart: func,
  pageNumber: number,
  pageType: string,
  podCount: number,
  podIndex: number,
  podType: oneOf(['grouped', 'horizontal', 'horizontal-simple', 'sidebyside', 'default', 'mini', 'list', 'simple']),
  position: number,
  preFetchProductData: bool,
  recommendationVersion: string,
  removable: bool,
  selectedForCompare: arrayOf(string),
  showAisleAndBay: bool,
  showCountdownTimer: bool,
  showDpdPod: bool,
  showExactPriceOnLoad: bool,
  showFavoritesCount: bool,
  showModelNumber: bool,
  showItemId: bool,
  showSkuNumber: bool,
  showPreferredPricingBadge: bool,
  showPreferredPricingBadgeToolTip: bool,
  showSample: bool,
  showSeeMoreOptions: bool,
  showShippingInfoInHover: bool,
  showSimilarItem: bool,
  showQuickView: bool,
  showStoreInfo: bool,
  showSSKUMiniSwatches: bool,
  showDisabledATC: bool,
  showSoldOutMessage: bool,
  showQuantityBox: bool,
  showMobileQuantityBox: bool,
  showSubscribeAndSave: bool,
  startingQuantity: number,
  staticQuantity: number,
  stfTopRight: bool,
  storeId: string,
  swapItemPosition: number,
  // number of swatches to display when using the mini swatches (see ProductDefault)
  swatchCount: number,
  target: string,
  testId: string,
  titleMaxLine: number,
  zipCode: string,
  isBOLDCategory: bool,
  showKPF: bool,
  ignoreAnalytics: bool,
  // this prop is deprecated, migrate to the anlyticsData prop
  sharedSection: string,
  hideCompareBox: bool
};

export const ProductPodDefaultProps = {
  analyticsData: null,
  anchorItem: null,
  automotiveFitmentObject: null,
  brandMaxLine: null,
  brandTitleMaxLine: null,
  channelFromTest: null,
  className: null,
  comparable: false,
  customEventObject: null,
  dpdNewTab: false,
  doNotShowChooseYourOptions: false,
  hasInStoreFilter: false,
  hideATC: false,
  hideBadges: false,
  hideFavorites: false,
  hideFulfillment: false,
  hideCaret: false,
  hideLimitPerOrder: false,
  hidePriceBadge: false,
  hidePromotions: false,
  hideRating: false,
  hideRebates: false,
  hideSavingsText: false,
  hideSeeSimilarItems: false,
  hideShippingInfo: false,
  hideShopThisCollection: false,
  hideSponsored: false,
  hideStoreInfo: false,
  hover: false,
  hoverDelay: 200,
  installationServices: false,
  isEtchOne: false,
  irgSectionName: '',
  itemId: '',
  keyword: null,
  maxKeyFeatures: 0,
  miniOptions: {
    disabled: false,
    onAction: () => {},
    enableATC: true,
    silentATC: false,
    onlyShowIfFulfillable: false,
    inCartQuantity: 0,
    callToActionSection: null,
    ctaLocation: 'bottom',
    hideFulfillment: true,
    hideCaret: false,
    ctaNoPadding: false
  },
  mobilePodType: 'doubleColumn',
  noATCFulfillment: false,
  onCartFailure: () => {},
  onCartSuccess: () => {},
  onClick: null,
  onCompare: () => {},
  onEdit: null,
  onError: null,
  onFavoriteChange: null,
  onHover: null,
  onHoverExit: null,
  onHoverSuperSku: null,
  onRemove: () => {},
  onQuantity: () => {},
  onSelectedForCompare: () => {},
  onShowSimilarClick: null,
  onSuperSkuChange: null,
  onTouchEnd: null,
  onTouchStart: null,
  pageNumber: 1,
  pageType: 'browse',
  podCount: null,
  podIndex: 1,
  podType: 'default',
  position: 1,
  preFetchProductData: false,
  recommendationVersion: 'n/a',
  removable: false,
  selectedForCompare: [],
  showAisleAndBay: false,
  showCountdownTimer: false,
  showDisabledATC: false,
  showSoldOutMessage: false,
  showDpdPod: false,
  showExactPriceOnLoad: false,
  showFavoritesCount: false,
  showModelNumber: false,
  showItemId: false,
  showSkuNumber: false,
  showPreferredPricingBadge: false,
  showPreferredPricingBadgeToolTip: false,
  showSeeMoreOptions: false,
  showShippingInfoInHover: false,
  showSimilarItem: false,
  showQuickView: false,
  showStoreInfo: false,
  showSSKUMiniSwatches: false,
  showQuantityBox: false,
  showMobileQuantityBox: false,
  showSubscribeAndSave: false,
  startingQuantity: 1,
  staticQuantity: null,
  stfTopRight: false,
  storeId: '',
  swapItemPosition: 1,
  swatchCount: 0,
  target: null,
  testId: null,
  titleMaxLine: null,
  zipCode: undefined,
  isBOLDCategory: false,
  showKPF: false,
  showSample: false,
  ignoreAnalytics: false,
  sharedSection: '',
  hideCompareBox: false,
};

export const getSimilarInfo = () => {
  // @todo this is bad. should be prop driven and consider the cookie as an extrnal global
  let searchCookie = '';
  if (typeof window !== 'undefined') {
    searchCookie = window.cookieUtils?.readBrowserCookie('x-ttsearch') || '';
  }
  const searchOptions = searchCookie.split(',');
  const showSimilarLinkInstock = searchOptions.filter((opt) => opt === 'INSTOCK_link').length === 1;
  return {
    showSimilarLinkInstock
  };
};

export const getProductPodContextValue = ({
  props,
  channel,
  state,
  hosts,
  productRef,
  productPodRef,
  handleProductPodExpansion,
  handleProductQuickView,
  product,
  setState,
  setOverlayState
}) => {
  const {
    analyticsData,
    anchorItem,
    automotiveFitmentObject,
    brand,
    brandMaxLine,
    brandTitleMaxLine,
    comparable,
    customEventObject,
    doNotShowChooseYourOptions,
    dpdNewTab,
    hasInStoreFilter,
    hideAccordion,
    hideATC,
    hideBadges,
    hideBulkPrice,
    hideFavorites,
    hideFulfillment,
    hideCaret,
    hideLimitPerOrder,
    hidePriceBadge,
    hidePromotions,
    hideRating,
    hideRebates,
    hideSavingsText,
    hideSeeSimilarItems,
    hideShippingInfo,
    hideShopThisCollection,
    hideSponsored,
    hideStoreInfo,
    hover,
    irgSectionName,
    itemId,
    maxKeyFeatures,
    mobilePodType,
    noATCFulfillment,
    onCartFailure,
    onCartSuccess,
    onClick,
    onCompare,
    onEdit,
    onHoverSuperSku: onHoverSuperSkuProp,
    onFavoriteChange,
    onQuantity,
    onRemove,
    onSelectedForCompare,
    onShowSimilarClick,
    onSuperSkuChange,
    onTouchEnd,
    onTouchStart,
    pageNumber,
    pageType,
    podCount,
    podIndex,
    podType,
    position,
    recommendationVersion,
    removable,
    selectedForCompare,
    showAisleAndBay,
    showCountdownTimer,
    showDisabledATC,
    showSoldOutMessage,
    showFavoritesCount,
    showModelNumber,
    showItemId,
    showSkuNumber,
    showPreferredPricingBadge,
    showPreferredPricingBadgeToolTip,
    showSeeMoreOptions,
    showShippingInfoInHover,
    showSimilarItem,
    showQuickView,
    showStoreInfo,
    showSSKUMiniSwatches,
    showQuantityBox,
    showMobileQuantityBox,
    showSubscribeAndSave,
    staticQuantity,
    stfTopRight,
    storeId,
    swapItemPosition,
    swatchCount,
    target,
    titleMaxLine,
    isBOLDCategory,
    showKPF,
    showSample,
    ignoreAnalytics,
    sharedSection,
  } = props;

  const { showSimilarLinkInstock } = getSimilarInfo();
  const { isFulfillable } = podFulFillmentUtils;
  const { info } = product || {};
  const { isBuryProduct = false } = info || {};
  const hideSimilarItemLink = isFulfillable(product) || !isBuryProduct;

  const isHDQCSku = checkIsHDQCSku(product);
  const isHDDCSku = checkIsHDDCSku(product);
  const isScheduleAMeasureEligible = checkScheduleAMeasureEligibility(product);
  const isGccCarpetDesignAndOrderEligible = checkGccCarpetDesignAndOrderEligibility(product);
  const isScheduleAConsultationEligible = isCustomKitchenCabinetProduct(product);
  const isRequestAQuoteEligible = isBrioProduct(product);

  const publishAnalytics = (eventName, action) => {
    publishAnalyticsEvent({
      eventName,
      action,
      analyticsData,
      itemId,
      productPodRef,
      ignoreAnalytics,
      pageType,
      podType,
      position,
      product,
      pageNumber,
      podCount,
      state,
      target,
      irgSectionName,
      recommendationVersion,
      setState
    });
  };

  const onSavingsCenterToggle = () => {
    setState({
      ...state,
      buyMoreSaveMore: !state.buyMoreSaveMore
    });
  };

  const onChangeSuperSku = (sskuData) => {

    if (onSuperSkuChange) {
      onSuperSkuChange(sskuData);
    }
    if (setOverlayState) {
      setOverlayState(false);
    }
    setState({
      ...state,
      selectedItemId: sskuData?.itemId,
      showExactPrice: true,
      isHovered: false,
      fetchPolicy: 'cache-first',
      sponsoredValues: state.sponsoredValues
    });
  };

  const onHoverSuperSku = (sskuData) => {
    if (onHoverSuperSkuProp) {
      onHoverSuperSkuProp(sskuData);
    }
    setState({
      ...state,
      previousItemId: sskuData?.isHovered ? state.selectedItemId || itemId : null,
      selectedItemId: sskuData?.itemId,
      isHovered: sskuData?.isHovered,
      itemIdForSubComponents: state.selectedItemId || itemId,
      fetchPolicy: 'cache-first',
    });
  };

  const onProductLinkClick = (evt, opts) => {
    if (onClick) {
      onClick(evt, opts);
    }
  };

  const handleClick = (event, action) => {
    const customExperience = !!product?.info?.globalCustomConfigurator?.customExperience;
    if (!customExperience && onClick) {
      onClick(event, product, action, state.sponsoredValues);
    }
    if (anchorItem) {
      publish(PRODUCT_POD_SWAP, { anchorItem, swapItemPosition });
    }

    publishAnalytics(PRODUCT_POD_CLICK, action);
  };

  const onQuantityChange = ({ quantity }) => {
    if (onQuantity) {
      onQuantity(Number(quantity));
    }
    setState({
      ...state,
      quantity: Number(quantity)
    });
  };

  const chooseYourOptionsClick = (event) => {
    if (onClick) {
      onClick(event, product);
    }
  };

  const handleTouchStart = (event) => {
    if (onTouchStart) {
      onTouchStart(event);
    }
  };

  const handleTouchEnd = (event) => {
    if (onTouchEnd) {
      onTouchEnd(event);
    }
  };

  return {
    analyticsData,
    anchorItem,
    automotiveFitmentObject,
    brand,
    brandMaxLine,
    brandTitleMaxLine,
    buyMoreSaveMore: state?.buyMoreSaveMore,
    channel,
    chooseYourOptionsClick,
    comparable: comparable && !isHDDCSku,
    customEventObject,
    doNotShowChooseYourOptions,
    handleTouchEnd,
    handleTouchStart,
    hasInStoreFilter,
    hosts,
    hover,
    hideAccordion,
    hideATC,
    hideBadges,
    hideBulkPrice,
    hideFavorites,
    hideFulfillment: hideFulfillment || isHDDCSku,
    hideCaret,
    hideLimitPerOrder,
    hidePriceBadge,
    hidePromotions,
    hideRating: hideRating || isHDDCSku,
    hideRebates,
    hideSavingsText,
    hideSeeSimilarItems,
    hideSimilarItemLink,
    hideShippingInfo: hideShippingInfo || isHDDCSku,
    hideShopThisCollection: hideShopThisCollection || isHDDCSku,
    hideSponsored,
    hideStoreInfo: hideStoreInfo || isHDDCSku,
    itemId: productRef.current?.product?.itemId || state.itemIdForSubComponents,
    canonicalUrl: state.canonicalUrl,
    isHovered: state.isHovered,
    isHDQCSku,
    isHDDCSku,
    isRequestAQuoteEligible,
    isScheduleAMeasureEligible,
    isGccCarpetDesignAndOrderEligible,
    isScheduleAConsultationEligible,
    previousItemId: state.previousItemId,
    onCompare,
    onCompareAnalytics: () => {
      publishAnalytics(PRODUCT_POD_COMPARE, COMPARE);
    },
    onSavingsCenterToggle,
    onSelectedForCompare,
    onHeaderClick: (event) => handleClick(event, PRODUCT_DETAILS),
    onImageClick: (event) => handleClick(event, PRODUCT_IMAGE),
    onRatingClick: (event) => handleClick(event, RATING),
    onCheckNearbyStoresClick: () => {
      publishAnalytics(PRODUCT_POD_CLICK, CHECK_NEARBY_STORE);
    },
    onFavoriteClick: () => {},
    onSimilarItemsClick: (similarButtonEnabled) => {
      const path = getDomPath(productPodRef, { attributes: ['id'] });
      publish(PRODUCT_POD_SIMILAR, { path, product });
      handleProductPodExpansion(itemId, position, productPodRef, similarButtonEnabled);
    },
    onQuickViewClick: ({ isQuickViewOpen, setIsQuickViewOpen, }) => {
      handleProductQuickView(itemId, isQuickViewOpen, setIsQuickViewOpen);
    },
    publishCustomEvent: ({ component, customEvent }) => {
      const path = getDomPath(productPodRef, { attributes: ['id'] });
      const eventOriginatedFrom = (componentName) => {
        const reComponent = new RegExp(componentName, 'i');
        return path?.reverse()?.find((name) => name?.match(reComponent));
      };
      if (eventOriginatedFrom(component)) publish(customEvent, {});
    },
    dpdNewTab,
    onProductLinkClick,
    onShowSimilarClick,
    onChangeSuperSku,
    onHoverSuperSku,
    onFavoriteChange,
    onCartSuccess,
    onCartFailure,
    onQuantityChange,
    onRemove,
    quantity: state.quantity,
    maxKeyFeatures,
    mobilePodType,
    noATCFulfillment,
    onEdit,
    pageType,
    podType,
    podIndex,
    position,
    product,
    productPodRef,
    publishAnalytics,
    removable,
    selectedForCompare,
    selectedItemId: state.selectedItemId,
    showAisleAndBay,
    showCountdownTimer,
    showModelNumber,
    showItemId,
    showSkuNumber,
    showPreferredPricingBadge,
    showPreferredPricingBadgeToolTip,
    showStoreInfo,
    showExactPrice: state.showExactPrice,
    showSeeMoreOptions,
    showShippingInfoInHover,
    showSimilarItem,
    showQuickView,
    showSSKUMiniSwatches,
    showQuantityBox,
    showMobileQuantityBox,
    showSubscribeAndSave,
    sponsoredValues: state.sponsoredValues,
    target,
    titleMaxLine,
    showFavoritesCount,
    staticQuantity,
    stfTopRight,
    storeId,
    showDisabledATC,
    showSoldOutMessage,
    swapItemPosition,
    swatchCount,
    isBOLDCategory,
    showKPF,
    showSample,
    sharedSection,
    showSimilarLinkInstock
  };
};
