import React, { useContext, useEffect, useState } from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductBadge } from '../product-badge/ProductBadge';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductImage } from '../product-image/ProductImage';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductCustomOptions } from '../product-custom-options/ProductCustomOptions';
import { ProductSponsored } from '../product-sponsored/ProductSponsored';
import './ProductMobile.style.scss';

export const ProductMobileDpd = ({ isEtchOne }) => {
  const {
    product,
    position,
    onFavoriteChange,
    buyMoreSaveMore,
    isHovered,
    previousItemId,
    itemId,
    storeId,
    onChangeSuperSku,
    onHoverSuperSku
  } = useContext(ProductPodContext);

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const { badges } = product || {};
  const superSkuItemId = isHovered ? previousItemId : itemId;
  const dpdImageWrapperClasses = classnames({
    dpdPod__image: isEtchOne,
    'dpdPod__image--full': !isEtchOne
  });

  // TODO: https://github.homedepot.com/thd-olt-component-react/product-pod/issues/272
  //  it's rendering twice on mobile / carousels are jacked up
  if (!mounted) {
    return null;
  }

  return (
    <>
      <div className="dpdPod">
        <ProductSponsored position={position} />
        <ProductHeader brand="below" />
        <div className="dpdPod__ssku">
          {mounted && (
            <SuperSkuMiniSwatches
              itemId={superSkuItemId}
              onChange={onChangeSuperSku}
              onHover={onHoverSuperSku}
              storeId={storeId}
            />
          )}
        </div>
        <Row><ProductRating /></Row>
        <div className="dpdPricing">
          <ProductPrice large={false} className="product-pod__pricing" />
        </div>
      </div>
      <Row>
        <div className={dpdImageWrapperClasses}>
          <Col fallback="6">
            <ProductFavorites
              className="product-pod__inline__favorite"
              onFavoriteChange={onFavoriteChange}
              position="topRight"
              showCircle
            />
            <ProductImage buyMoreSaveMore={buyMoreSaveMore} dualImageSwipe>
              <div className="product-pod__badge-wrapper-hack">
                <div className="product-pod__badge--wrapper">
                  <ProductBadge badges={badges} />
                </div>
              </div>
            </ProductImage>
            <ProductCustomOptions />
          </Col>
        </div>
      </Row>
    </>

  );
};

ProductMobileDpd.propTypes = {
  isEtchOne: bool
};

ProductMobileDpd.defaultProps = {
  isEtchOne: false
};

ProductMobileDpd.displayName = 'ProductMobileDpd';
