import React, { useContext } from 'react';
import {
  bool, func, shape, string
} from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { getDomPath } from '@thd-olt-functional/utils';
import { Button } from '@thd-olt-component-react/core-ui';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { publish } from '../../../analytics';
import { ServicesButton } from './ServicesButton';
import { ProductPodContext } from '../../ProductPodContext';

import {
  ADD_TO_CART,
  PRODUCT_POD_IRG_ATC,
  PRODUCT_POD_ATC,
  PRODUCT_POD_CLICK,
  SCHEDULE_A_MEASURE,
  SCHEDULE_A_MEASURE_URL,
  GET_ESTIMATE,
  REQUEST_A_QUOTE,
  SCHEDULE_A_CONSULTATION,
  BUILD_AND_BUY,
  VIEW_DETAILS,
  CHOOSE_YOUR_OPTIONS,
  SERVICES_BUTTON_CLICK
} from '../../constants';
import {
  isATCEnabled,
  getReturnMessage,
  getCartOptions,
  getCartReqParams,
  isGenericProduct,
  isBuildAndBuyProduct,
  isLiveGoodsOOSProduct,
  isCustomKitchenCabinetProduct,
  isProductOutOfStockOnline,
  getCustomUrlWithAnalytics,
  getGccCarpetDesignAndOrderURL,
  getServicesFormName,
  getShowBopisOverlay,
  isAppliance,
  getMisship
} from '../../utils';

export const ProductATC = (props) => {
  const {
    channel,
    hosts
  } = useContext(ExperienceContext);

  const store = useStore();

  let deliveryZipCode;
  if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
    deliveryZipCode = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails()?.zipcode;
  }

  const {
    analyticsData,
    doNotShowChooseYourOptions,
    chooseYourOptionsClick,
    dpdNewTab,
    hasInStoreFilter,
    isRequestAQuoteEligible,
    isScheduleAMeasureEligible,
    isGccCarpetDesignAndOrderEligible,
    isScheduleAConsultationEligible,
    mobilePodType,
    noATCFulfillment,
    pageType,
    product,
    target,
    quantity: editableQuantity,
    publishAnalytics,
    onCartSuccess,
    onCartFailure,
    showDisabledATC,
    productPodRef,
    hideATC,
    hover,
    sharedSection,
    showSoldOutMessage,
    staticQuantity,
    gccExperience,
    sponsoredValues,
    isHDDCSku
  } = useContext(ProductPodContext);

  const {
    checkGeneric,
    className,
    disabled,
    onClick,
    outline,
    showReturnable,
    silent,
    subscriptionInfo,
    showLargeButtonOnMobile
  } = props;
  const quantity = staticQuantity || editableQuantity;

  if (hideATC) return null;

  const isDisabled = (!isAppliance(product) && !isATCEnabled(product)) || disabled;
  const showViewDetailsBtn = isLiveGoodsOOSProduct(product) || isHDDCSku;
  const showBuildAndBuyButton = isBuildAndBuyProduct(product, hover);
  const showRequestAQuoteButton = isRequestAQuoteEligible;
  const showScheduleAConsultationButton = isScheduleAConsultationEligible;
  const showChooseYourOptionsButton = !doNotShowChooseYourOptions && checkGeneric && isGenericProduct(product);

  const { isFulfillable } = podFulFillmentUtils;
  const { info, identifiers } = product || {};
  const { isBuryProduct = false } = info || {};

  const showSimilarButton = isBuryProduct && !isFulfillable(product);

  const shouldHide = !isScheduleAMeasureEligible
    && !showDisabledATC
    && isDisabled
    && !showBuildAndBuyButton
    && !showViewDetailsBtn
    && !showRequestAQuoteButton
    && !showScheduleAConsultationButton
    && showSimilarButton;

  if (shouldHide) return null;

  let cartOptionsSharedSection = analyticsData?.sharedSection || sharedSection;
  const returnMessage = getReturnMessage({ showReturnable, product });
  const cartOptions = getCartOptions({
    channel,
    hidden: !!silent,
    sharedSection: cartOptionsSharedSection,
    misship: getMisship({ product })
  });
  const cartReqParams = getCartReqParams({
    store, quantity, deliveryZipCode, product, channel, noATCFulfillment, hasInStoreFilter, subscriptionInfo
  });
  const showBopisOverlay = getShowBopisOverlay({ product, cartReqParams });
  const isMobile = channel === 'mobile';
  const { canonicalUrl, productType = '' } = identifiers || {};
  const customExperience = info?.globalCustomConfigurator?.customExperience;

  const getAtcLabel = () => {
    if (info?.customerSignal?.previouslyPurchased) return 'Buy It Again';
    if (isProductOutOfStockOnline(product) && !isAppliance(product) && !showSoldOutMessage) return 'Out Of Stock';
    if (isDisabled && showSoldOutMessage) return 'Sold Out';
    if (subscriptionInfo?.isOptIn) return 'Subscribe';
    return 'Add to Cart';
  };

  const atcLabel = getAtcLabel();
  const productUrl = getCustomUrlWithAnalytics({
    customExperience,
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    hover,
    info,
    sponsoredValues
  });

  const servicesURL = info?.productSubType?.link;

  const customBlindsButton = classNames({
    'bttn-outline': !isMobile,
    'product-pod__customs--margin bttn--small bttn-outline': isMobile,
  });

  const cartClicked = (event) => {
    const domPath = getDomPath(productPodRef, { attributes: ['id'] });
    let eventName = PRODUCT_POD_ATC;

    const eventOriginatedFrom = (componentName) => {
      const component = new RegExp(componentName, 'i');
      return domPath?.reverse()?.find((name) => name?.match(component));
    };

    if (eventOriginatedFrom('itemrelatedgroups\\[')) {
      eventName = PRODUCT_POD_IRG_ATC;
    }

    const action = ADD_TO_CART;
    if (pageType !== 'dpd' && pageType !== 'pip') {
      publishAnalytics(eventName, action);

      if (eventOriginatedFrom('resultswrapped\\[')) {
        publishAnalytics(PRODUCT_POD_CLICK, action);
      }
    }

    if (onClick) {
      onClick(event, product, action);
    }
  };

  const genericClicked = (event) => {
    if (chooseYourOptionsClick) chooseYourOptionsClick(event, product);
  };

  const onBuildAndBuyClick = () => { publishAnalytics(PRODUCT_POD_CLICK, BUILD_AND_BUY); };
  const onScheduleAMeasureClick = () => { publishAnalytics(PRODUCT_POD_CLICK, SCHEDULE_A_MEASURE.toLowerCase()); };
  const onGccCarpetDesignAndOrderClick = () => { publishAnalytics(PRODUCT_POD_CLICK, GET_ESTIMATE.toLowerCase()); };
  const onServicesButtonClick = (buttonName) => {
    publishAnalytics(PRODUCT_POD_CLICK, buttonName.toLowerCase());
    const eventData = {
      eventName: buttonName.toLowerCase(),
      formName: getServicesFormName(servicesURL)
    };
    publish(SERVICES_BUTTON_CLICK, eventData);
  };

  if (isScheduleAMeasureEligible && isGccCarpetDesignAndOrderEligible) {
    return (
      <Button
        href={getGccCarpetDesignAndOrderURL(product)}
        outline
        small={isMobile && !showLargeButtonOnMobile}
        tag="a"
        target="_blank"
        onClick={onGccCarpetDesignAndOrderClick}
      >
        {GET_ESTIMATE}
      </Button>
    );
  }

  if (isScheduleAMeasureEligible) {
    return (
      <Button
        href={SCHEDULE_A_MEASURE_URL}
        outline
        small={isMobile && !showLargeButtonOnMobile}
        tag="a"
        target="_blank"
        onClick={onScheduleAMeasureClick}
      >
        {SCHEDULE_A_MEASURE}
      </Button>
    );
  }

  if (showBuildAndBuyButton) {
    return (
      <Button
        className={customBlindsButton}
        href={productUrl}
        target={target}
        onClick={onBuildAndBuyClick}
        tag="a"
        outline
      >
        {BUILD_AND_BUY}
      </Button>
    );
  }

  if (showViewDetailsBtn) {
    return (
      <>
        <Button
          dark
          href={productUrl}
          outline
          small={isMobile && !showLargeButtonOnMobile}
          tag="a"
          target={target}
          data-testid="bttn__view-details"
        >
          {VIEW_DETAILS}
        </Button>
      </>
    );
  }

  if (showRequestAQuoteButton) {
    return (
      <ServicesButton
        name={REQUEST_A_QUOTE}
        servicesURL={servicesURL}
        showLargeButtonOnMobile={showLargeButtonOnMobile}
        onClick={() => onServicesButtonClick(REQUEST_A_QUOTE)}
      />
    );
  }

  if (showScheduleAConsultationButton) {
    return (
      <ServicesButton
        name={SCHEDULE_A_CONSULTATION}
        servicesURL={servicesURL}
        showLargeButtonOnMobile={showLargeButtonOnMobile}
        onClick={() => onServicesButtonClick(SCHEDULE_A_CONSULTATION)}
      />
    );
  }

  if (showChooseYourOptionsButton) {
    const newTab = dpdNewTab && !target ? '_blank' : target;
    return (
      <Button
        dark
        href={productUrl}
        onClick={genericClicked}
        outline
        small={isMobile}
        tag="a"
        target={newTab}
      >
        {CHOOSE_YOUR_OPTIONS}
      </Button>
    );
  }

  return ((
    <>
      <AddToCart
        className={className}
        checkGeneric={checkGeneric}
        showDisabledATC={showDisabledATC}
        showReturnable={showReturnable}
        cartOptions={cartOptions}
        cartReqParams={cartReqParams}
        disabled={isDisabled}
        onClick={cartClicked}
        outline={outline}
        showBOPISOverlay={showBopisOverlay}
        onSuccess={onCartSuccess}
        onFail={onCartFailure}
      >
        {atcLabel}
      </AddToCart>
      {returnMessage && (
        <div className="product-pod__return-messaging">
          {returnMessage}
        </div>
      )}
    </>
  ));
};

ProductATC.propTypes = {
  /** When true, checks if sku is generic and returns "Choose Your Options" button */
  checkGeneric: bool,
  className: string,
  disabled: bool,
  /** Method triggered when `checkGeneric` is true and "Choose Your Options" button clicked */
  onClick: func,
  /** "Add to Cart" Button styling */
  outline: bool,
  /** show return messaging */
  showReturnable: bool,
  showLargeButtonOnMobile: bool,
  /** Add to Cart without overlay */
  silent: bool,
  subscriptionInfo: shape({
    frequency: string,
    isOptIn: bool
  })
};

ProductATC.defaultProps = {
  checkGeneric: false,
  className: null,
  disabled: false,
  onClick: null,
  outline: false,
  showReturnable: false,
  showLargeButtonOnMobile: false,
  silent: false,
  subscriptionInfo: {}
};

ProductATC.displayName = 'ProductATC';
