import { useDataModel } from '@thd-nucleus/data-sources';
import {
  getContentfulTargetingIds,
  useVariationIds,
} from '@thd-olt-component-react/personalization-utils';
import { componentMapper } from '../utils/componentMapper';
import { parseContent, validateNValue } from '../utils/utils';

export const useHybridPLP = ({
  slug,
  customerType,
  instance,
  storeId,
  skip,
  isShowProducts
}) => {
  const { data, loading, error } = useDataModel('layouts', {
    variables: {
      slug: (isShowProducts) ? slug + '-ShowProducts' : slug,
      customerType,
      isHybrid: validateNValue(slug)
    },
    ssr: true,
    skip
  });

  const layouts = data?.layouts;
  const content = layouts?.content;

  const { mboxes, campaignIds } = getContentfulTargetingIds({ content });
  const isReady = mboxes.length > 0 || campaignIds.length > 0;
  const { variationIds, renderDefault } = useVariationIds({
    mboxes,
    campaignIds,
    isReady
  });

  const customProps = {
    ComponentSelector: (component) => ({
      componentMap: componentMapper.getComponentMap(),
      defaultComponent: component.defaultComponent,
      variations: component.variations,
      variationIds: variationIds || [],
      renderDefault,
      isSeoBot: instance.thdSEOBotDetection,
      targetingType: component.targetingType,
      customPropMap: {
        Spotlight: {
          showDescription: true
        },
        SpotlightContainer: {
          showDescription: true
        },
        EndCap: {
          storeId,
          customerType: customerType.toUpperCase(),
          pageUrl: `/${slug}`,
          narrowBottom: true
        }
      }
    }),
    Spotlight: () => ({
      showDescription: true
    }),
    SpotlightContainer: () => ({
      showDescription: true
    }),
    EndCap: () => ({
      storeId,
      customerType: customerType.toUpperCase(),
      pageUrl: `/${slug}`,
      narrowBottom: true
    })
  };

  // * Get the parsed data from contentful and set it in a state
  const getParsedContent = (_data) => {
    const { Content } = parseContent({
      data: _data,
      componentMapper,
      customProps
    });

    return Content;
  };

  const contentElements = data ? getParsedContent(data) : null;

  return { contentElements, data, loading, error };
};
