import React from 'react';
import { bool, shape as shapeType, string as stringType } from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import classNames from 'classnames';
import { getTarget } from './utils/getTarget';

export const retrieveUrl = (previewImage) => {
  const damDownloadedContentImage = previewImage?.damDownloadedContent?.url;
  const damContentSelectorImage = previewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl;

  return damDownloadedContentImage || damContentSelectorImage || '';
};

const BannerRenderer = ({
  data, desktopAspectRatio, tabletAspectRatio, mobileAspectRatio, lazyLoad, componentId
}) => {
  const {
    link,
    altText
  } = data || {};

  const bannerResponsiveClasses = classNames(
    mobileAspectRatio,
    tabletAspectRatio,
    desktopAspectRatio,
  );

  const isSSR = typeof window === 'undefined';

  const mobileUrl = retrieveUrl(data?.mobileImage);
  const tabletUrl = retrieveUrl(data?.tabletImage);
  const desktopUrl = retrieveUrl(data?.desktopImage);

  const pictureElement = (
    <picture data-contentful-entry-id={componentId} data-contentful-field-id="desktopImage">
      <source media="(max-width: 767px)" srcSet={mobileUrl} />
      <source media="(min-width: 768px) and (max-width: 1023px)" srcSet={tabletUrl} />
      <source media="(min-width: 1024px)" srcSet={desktopUrl} />
      <img loading={lazyLoad ? 'lazy' : ''} src={desktopUrl} alt={altText} height="100%" width="100%" />
    </picture>
  );

  return (
    <div data-testid="internal-marketing-banner" className={bannerResponsiveClasses}>
      <LoadingPlaceholder
        type="rect"
        color="#E0E0E0"
        className={bannerResponsiveClasses}
        showLoadingAnimation
        ready={!isSSR}
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        {link && (
          <>
            <a href={link} target={getTarget(link)}>
              {pictureElement}
            </a>
          </>
        )}
        {!link && (
          <>
            {pictureElement}
          </>
        )}
      </LoadingPlaceholder>
    </div>
  );
};

BannerRenderer.propTypes = {
  componentId: stringType.isRequired,
  desktopAspectRatio: stringType.isRequired,
  tabletAspectRatio: stringType.isRequired,
  mobileAspectRatio: stringType.isRequired,
  lazyLoad: bool,
  data: shapeType({}),
};

BannerRenderer.defaultProps = {
  data: {},
  lazyLoad: false
};

BannerRenderer.displayName = 'BannerRenderer';

export { BannerRenderer };
