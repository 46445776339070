import React, { memo } from 'react';
import { bool, string, shape } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { click } from './events';
import { useImpression } from '@thd-olt-component-react/impression';
import './zone-card.scss';

const GuideZoneCard = ({ major, header, details, componentPosition, category }) => {

  const { title, description: headerDescription } = header;
  const { description: detailsDescription, link, imageUrl } = details;

  const { ref, clickID } = useImpression({
    data: {
      id: "00DEFVALCC00",
      name: 'CategoryCards',
      component: 'CategoryCards',
      position: componentPosition,
      type: 'content',
      category: category
    }
  });

  return (
    <>
      {major
        ? (
          <section className="zone-card__zone1" ref={ref} clickid={clickID}>
            <div className="zone-card__header-wrapper">
              <h2 className="zone-card__header u__bold">{title}</h2>
              <p className="zone-card__header-text">{headerDescription}</p>
            </div>
            <div className="zone-card-details">
              <div className="zone-card-details__image">
                <Image src={imageUrl} alt="" stretchy width="1" height="1" />
              </div>
              <div className="zone-card-details__description">
                <div className="zone-card-details__text category-cards-details__text--truncate">
                  {detailsDescription}
                </div>
                <div className="zone-card-details__actions">
                  <a
                    className="bttn-outline bttn-outline--primary bttn--inline zone-card-details__btn"
                    href={link}
                    onClick={() => {
                      click({
                        pageNumber: 1,
                        position: 'r1',
                        type: title
                      });
                    }}
                  >
                    <span className="bttn__content">Read Our Guide</span>
                  </a>
                </div>
              </div>
            </div>
          </section>
        )
        : (
          <section className="zone-card__zone2">
            <div className="zone-card__header-wrapper">
              <h2 className="u__truncate zone-card__header u__bold">{title}</h2>
              <p className="zone-card__header-text">{headerDescription}</p>
            </div>
            <div className="zone-card__video-wrapper">
              <a className="zone-card__vidcap-link" href={link}>
                <div
                  className="zone-card-details__image zone-card-details__image--vidcap"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                  }}
                />
              </a>
            </div>
            <a
              className="zone-card__video-link"
              href={link}
              onClick={() => {
                click({
                  pageNumber: 1,
                  position: 'r1',
                  type: title
                });
              }}
            >
              See Our Tips
            </a>
          </section>
        )}
    </>
  );
};

GuideZoneCard.displayName = 'CategoryCards-GuideZoneCard';

GuideZoneCard.propTypes = {
  header: shape({
    title: string,
    description: string
  }).isRequired,
  details: shape({
    imageUrl: string,
    description: string,
    link: string
  }).isRequired,
  major: bool
};

GuideZoneCard.defaultProps = {
  major: false
};

const MemoGuideZoneCard = memo(GuideZoneCard);

export { MemoGuideZoneCard as GuideZoneCard };
