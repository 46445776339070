/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import {
  Card,
  CardMedia,
  CardTitle
} from '@one-thd/sui-atomic-components';
import { bool } from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames/bind';
import { akamaiImageResize } from './helper';
import styles from './visual-navigation.scss';

export const VisualNavigationCard = (itemDetails) => {
  const cx = classNames.bind(styles);

  const { item, position, lazyLoad } = itemDetails;
  let {
    altText, id: itemID, link, title, previewImage
  } = item || {};

  const { ref, clickID } = useImpression({
    data: {
      id: itemID,
      component: 'VisualNavigation',
      name: 'VisualNavigation',
      type: 'content',
      position,
    },
  });

  let damDownloadedContentImage = previewImage?.damDownloadedContent;
  let damContentSelectorImage = previewImage?.damContentSelector?.assetData?.[0];
  let imageUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl;

  imageUrl = akamaiImageResize(imageUrl, 703);

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
      key={`card-position-${position}`}
      className={`${!imageUrl ? 'sui-align-middle' : ''} sui-flex sui-h-full sui-w-full`}
      data-component="VisualNavigationCard"
    >
      <Card
        key={itemID}
        href={link}
        target="_blank"
        rel="noreferrer"
        className={cx('sui-w-full link--hover-no-underline')}
      >
        {imageUrl && (
          <CardMedia
            alt={`${altText || title}`}
            title={`${altText || title}`}
            src={imageUrl}
            aspect="square"
            loading={lazyLoad ? 'lazy' : ''}
          />
        )}
        {title && (
          <CardTitle
            actionPosition="center"
            header={title}
            headerVariant="h5"
          />
        )}
      </Card>
    </div>
  );
};

const displayName = 'VisualNavigationCard';

const propTypes = {
  lazyLoad: bool
};

const defaultProps = {
  lazyLoad: false
};

VisualNavigationCard.displayName = displayName;
VisualNavigationCard.propTypes = propTypes;
VisualNavigationCard.defaultProps = defaultProps;