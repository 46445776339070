import React, { useContext, useEffect } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { List } from '@one-thd/sui-atomic-components';
import {
  params, string as stringType, fragment, customType, useDataModel, arrayOf, bool
} from '@thd-nucleus/data-sources';
import { NavigationItem } from './NavigationItem';

const SideNavigation = ({ componentId, componentClass, componentPosition }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('side-navigation.ready'); }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  if (!data || error || loading) return null;

  const navs = data?.component?.navigationList;

  if (isMobile) return null;
  return (
    <ImpressionProvider data={{
      id: componentId,
      component: SideNavigation.displayName,
      name: SideNavigation.displayName,
      type: 'content',
      position: ''
    }}
    >
      <div
        data-component-id={componentId}
        id={`${componentClass}-${componentPosition}-${componentId}`}
        data-component-name={SideNavigation.displayName}
        data-component-position={componentPosition}
        data-testid="items-list"
        data-component={SideNavigation.displayName}
        className="side-navigation sui-bg-primary sui-font-sans sui-hidden lg:sui-inline"
      >
        <List type="plain">
          {navs?.map((nav, index) => (
            <NavigationItem
              key={nav.id}
              componentId={nav.id}
              componentPosition={index + 1}
              link={nav.link}
              title={nav.title}
              heading={nav.heading}
            />
          ))}
        </List>
      </div>
    </ImpressionProvider>
  );
};

SideNavigation.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number
};

SideNavigation.defaultProps = {
  componentPosition: 1,
  componentClass: ''
};

SideNavigation.displayName = 'SideNavigation';

SideNavigation.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['SideNavigation'])
      .isRequired()
  }).shape({
    SideNavigation: fragment().shape({
      id: stringType(),
      componentName: stringType(),
      navigationList: arrayOf({
        id: stringType(),
        title: stringType(),
        link: stringType(),
        heading: bool()
      })
    })
  })
};

export { SideNavigation };
