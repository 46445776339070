import React, { useState } from 'react';
import { string, node } from 'prop-types';
import classnames from 'classnames';
import { Input } from '@thd-olt-component-react/input';
import './wallAreaCalculator.scss';

const AVERAGE_DOOR_AREA = 21;
const AVERAGE_WINDOW_AREA = 12;
const SQUARE_FEET_PER_GALLON = 400;
const MIN_GALLONS = 2;

export const WallAreaCalculator = ({
  className,
  introPane,
  errorMessage,
  disclaimer
}) => {

  const defaultState = {
    height: '',
    length: '',
    width: '',
    doors: '',
    windows: ''
  };
  const [calculatorState, setCalculatorState] = useState(defaultState);

  const {
    height,
    length,
    width,
    doors,
    windows
  } = calculatorState;

  const totalSquareFeet = ((length * 2) + (width * 2)) * height;
  const getGallons = () => {
    return Math.ceil((totalSquareFeet - (AVERAGE_DOOR_AREA * doors) - (AVERAGE_WINDOW_AREA * windows))
    / SQUARE_FEET_PER_GALLON);
  };

  const gallons = (length && width && height) ? getGallons() : null;
  const amount = gallons < MIN_GALLONS ? '1 Gallon' : `${gallons} Gallons`;
  const doorsText = `${doors || 'no'} door${doors === '1' ? '' : 's'} `;
  const windowsText = `${windows || 'no'} window${windows === '1' ? '' : 's'}`;

  const calculatorClasses = classnames('wall-area-calculator', className);

  const validateInputText = (value) => value && /^0*[1-9]\d*$/.test(value);

  const showError = (height && !validateInputText(height))
    || (width && !validateInputText(width))
    || (length && !validateInputText(length))
    || (doors && !validateInputText(doors))
    || (windows && !validateInputText(windows));

  const showResult = gallons !== null && !showError;
  const unitOfMeasure = '(ft.)';
  const optionalLabel = '(optional)';

  // @TODO: We need a more generic message to display the results. It's too specific to paint.

  return (
    <div className={calculatorClasses}>
      <div className="wall-area-calculator__body">
        <div className="wall-area-calculator__input">
          <Input
            className="input-field"
            onChange={(value) => setCalculatorState({ ...calculatorState, height: value })}
            value={height}
            header={`Height ${unitOfMeasure}`}
            validation={validateInputText}
            errorText="Invalid Input"
            type="number"
            pattern="\d*"
          />
          <Input
            className="input-field"
            onChange={(value) => setCalculatorState({ ...calculatorState, width: value })}
            value={width}
            header={`Width ${unitOfMeasure}`}
            validation={validateInputText}
            errorText="Invalid Input"
            type="number"
            pattern="\d*"
          />
          <Input
            className="input-field"
            onChange={(value) => setCalculatorState({ ...calculatorState, length: value })}
            value={length}
            header={`Length ${unitOfMeasure}`}
            validation={validateInputText}
            errorText="Invalid Input"
            type="number"
            pattern="\d*"
          />
          <Input
            className="input-field"
            onChange={(value) => setCalculatorState({ ...calculatorState, doors: value })}
            value={doors}
            header={`# of Doors ${optionalLabel}`}
            validation={validateInputText}
            errorText="Invalid Input"
            type="number"
            pattern="\d*"
          />
          <Input
            className="input-field"
            onChange={(value) => setCalculatorState({ ...calculatorState, windows: value })}
            value={windows}
            header={`# of Windows ${optionalLabel}`}
            validation={validateInputText}
            errorText="Invalid Input"
            type="number"
            pattern="\d*"
          />
        </div>
        <div className="wall-area-calculator__message-section-wrapper">
          <div className="wall-area-calculator__message-section">
            {!showResult && !showError && introPane && (
              <div className="wall-area-calculator__message-section__home">
                {introPane}
              </div>
            )}
            {showError && errorMessage && (
              <span
                className="wall-area-calculator__error-text"
              >{errorMessage}
              </span>
            )}
            {showResult && (
              <div className="wall-area-calculator__results-section">
                <div className="amount-message">YOU WILL NEED</div>
                <div className="amount">{amount}</div>
                <div className="result-description">
                  Based on one coat of paint for a {width}&#8217; x {length}&#8217; room{' '}
                  with {doorsText} and {windowsText}
                </div>
              </div>
            )}
          </div>
          {showResult && disclaimer && (
            <div className="wall-area-calculator__footer-disclaimer">
              PLEASE NOTE: {disclaimer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

WallAreaCalculator.displayName = 'WallAreaCalculator';

WallAreaCalculator.propTypes = {
  className: string,
  introPane: node.isRequired,
  errorMessage: string.isRequired,
  disclaimer: string
};

WallAreaCalculator.defaultProps = {
  className: '',
  disclaimer: ''
};
