import React, { useEffect, useContext, useState } from 'react';
import { useStore } from '@thd-nucleus/experience-context';
import {
  useDataModel, QueryContext
} from '@thd-nucleus/data-sources';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import {
  bool, string, shape, arrayOf
} from 'prop-types';
import { generateEncodedSubGroup, generateTableNavParam, getSortParams } from '../utils/sub-groups-helpers';
import TableView from './tableView';
import { dataModel } from './dataModel';

const SubGroup = ({
  groupIds, subGroup, activeProductDetails, setActiveProductDetails, handleNRFStatus, categoryNavParam,
  tableMetaData, showGroupTitle, groupTitle, storeId, topSellerImageURLs, customerType, channel,
  serverDeliveryZip
}) => {

  const { isClientResolved } = useContext(QueryContext);
  const [deliveryZip, setDeliveryZip] = useState(serverDeliveryZip);
  const { subgroupIds } = subGroup;
  const { headerAttributes: columns = [], sortBy, sortOrder } = tableMetaData;
  const attributeIds = columns.map((header) => header.columnId);
  const isServer = typeof window === 'undefined';

  const encodedSubgroup = generateEncodedSubGroup(subgroupIds);
  const TableNavParam = generateTableNavParam(categoryNavParam, groupIds, encodedSubgroup);
  const additionalSearchParams = { deliveryZip };
  const primaryFilter = getSortParams(sortBy, sortOrder, columns);
  const { membershipInformation } = useStore();

  const { data, error, loading } = useDataModel('tableProductModel', {
    variables: {
      customerType,
      navParam: TableNavParam,
      storeId,
      attributeIds,
      sort: primaryFilter,
      additionalSearchParams,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership,
      channel: channel.toLocaleUpperCase()
    }
  });

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        const zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
        setDeliveryZip(zipDetails?.zipcode);
      }
    } catch (_error) {
      // eslint-disable-next-line no-console
      console.error('error in zipcode', _error);
    }
  }, [deliveryZip]);
  const isResolved = isClientResolved({ queryName: 'tableProductModel' });
  if (!data && loading) return <TextBlock rows={10} color="#E0E0E0" />;
  handleNRFStatus(data?.tableProductModel?.tableRow?.length > 0 ? 1 : 0);

  let tableRows = [];
  if (!isServer && isResolved) {
    tableRows = data?.tableProductModel?.tableRow.filter((row) => {
      return row?.pricing?.value;
    });
  } else {
    tableRows = data?.tableProductModel?.tableRow;
  }

  if (!tableRows || tableRows?.length === 0) return null;

  return (
    <TableView
      groupIds={groupIds}
      columns={columns}
      subGroup={subGroup}
      rows={tableRows}
      activeProductDetails={activeProductDetails}
      setActiveProductDetails={setActiveProductDetails}
      uniqueId={TableNavParam}
      showGroupTitle={showGroupTitle}
      groupTitle={groupTitle}
      topSellerImageURLs={topSellerImageURLs}
      storeId={storeId}
      customerType={customerType}
      primaryFilter={primaryFilter}
      isResolved={isResolved}
    />
  );
};

export default SubGroup;

SubGroup.displayName = 'SubGroup';
SubGroup.dataModel = { tableProductModel: dataModel.tableProductModel };
SubGroup.propTypes = {
  groupIds: arrayOf(string),
  subGroup: shape({
    subGroupTitle: string,
    id: arrayOf(string),
  }),
  activeProductDetails: shape({
    uniqueId: string
  }),
  setActiveProductDetails: () => {},
  handleNRFStatus: () => {},
  categoryNavParam: string,
  tableMetaData: shape({
    headerAttributes: arrayOf(shape({})),
    sortBy: arrayOf(string),
    sortOrder: arrayOf(string)
  }),
  showGroupTitle: bool,
  groupTitle: string,
  storeId: string,
  topSellerImageURLs: arrayOf(shape({
    url: string,
    size: string
  })),
  customerType: string,
  channel: string,
  serverDeliveryZip: string,
};

SubGroup.defaultProps = {
  groupIds: [],
  subGroup: { subGroupTitle: '', id: '' },
  activeProductDetails: [],
  setActiveProductDetails: () => {},
  categoryNavParam: '',
  tableMetaData: { headerAttributes: [], sortBy: [''], sortOrder: [''] },
  showGroupTitle: false,
  groupTitle: '',
  storeId: '',
  topSellerImageURLs: [{ url: '', size: '' }],
  customerType: '',
  handleNRFStatus: () => {},
  channel: 'DESKTOP',
  serverDeliveryZip: ''
};