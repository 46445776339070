/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';

/**
 * @group Legacy
 * Legacy address form
 */

const AddressEntryFormPrivacy = (props) => {
  const popupDefault = {
    display: 'none',
    top: '270px',
    left: '-45px',
    right: '10px',
  };
  return (
    <div className="address-entry-form">
      <div className="col-12-12 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_firstName">
          <span>*</span>First Name:
        </label>
        <div className="col-4-12 alpha omega">
          <span className="form-input">
            <input className="form-input__field" type="text" maxLength="30" name="mailing_firstName" id="mailing_firstName" />
          </span>
        </div>
      </div>

      <div className="col-12-12 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_lastName"><span>*</span>Last Name:</label>
        <div className="col-4-12 alpha omega">
          <span className="form-input">
            <input className="form-input__field" type="text" maxLength="30" name="mailing_lastName" id="mailing_lastName" />
          </span>
        </div>
      </div>

      <div className="col-12-12 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_address1"><span>*</span>Address 1:</label>
        <div className="col-4-12 alpha omega">
          <span className="form-input">
            <input className="form-input__field" type="text" name="mailing_address1" id="mailing_address1" />
          </span>
        </div>
      </div>

      <div className="col-12-12 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_address2">Address 2:</label>
        <div className="col-4-12 alpha omega">
          <span className="form-input">
            <input className="form-input__field" type="text" name="mailing_address2" id="mailing_address2" />
          </span>
        </div>
      </div>

      <div className="col-12-12 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_city"><span>*</span>City:</label>
        <div className="col-4-12 alpha omega">
          <span className="form-input">
            <input className="form-input__field" type="text" name="mailing_city" id="mailing_city" />
          </span>
        </div>
      </div>

      <div className="col-12-12 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_state"><span>*</span>State:</label>
        <div className="col-4-12 alpha omega">
          <span className="drop-down__title drop-down__title--select">
            <select className="drop-down__select" name="mailing_state" id="mailing_state">
              <option value="">Select a state</option>
            </select>
          </span>
        </div>
      </div>

      <div className="col-1-1 pad alpha omega">
        <label className="control-label col-2-12 alpha omega" htmlFor="mailing_zipCode"><span>*</span>ZIP Code:</label>
        <div className="col-4-12 alpha omega">
          <span className="form-input">
            <input className="form-input__field" type="text" name="mailing_zipCode" id="mailing_zipCode" />
            <input type="hidden" name="mailing_country" maxLength="10" value="US" />
          </span>
        </div>
      </div>

      <div className="popup-layer" id="express-checkout" style={popupDefault}>
        <div className="close-bar">
          <button
            type="button"
            href="#"
            className="popup-closer"
            aria-label="Close Popup"
            onClick={() => {
              return false;
            }}
          >Close Window
          </button>
        </div>
        <h1>Why do we need this?</h1>
        <div className="window-content">
          <p className="left-indent">The Home Depot requires a phone number where you can be contacted if we have questions regarding your order.</p>
        </div>
      </div>
    </div>
  );
};

AddressEntryFormPrivacy.propTypes = {
};

export default AddressEntryFormPrivacy;
