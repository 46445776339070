import React from 'react';
import PropTypes from 'prop-types';
import {
  Link
} from '@one-thd/sui-atomic-components';

const AnchorLink = ({ linkItem }) => {
  return (
    <div key={linkItem.label}>

      <Link /*eslint-disable-line*/
        key={linkItem.label}
        onClick={() => {
          window.location.href = linkItem.href;
        }}
        color="subtle"
        underline={linkItem.href ? 'always' : 'none'}
      >
        {linkItem.label}
      </Link>
    </div>
  );
};

AnchorLink.propTypes = {
  linkItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired
};

AnchorLink.displayName = 'AnchorLink';

export default AnchorLink;