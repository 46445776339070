import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { RectShape, TextBlock } from '@thd-olt-component-react/loading-placeholder';

export const ProductPodPlaceholder = ({ podsCount, podType }) => {
  if (podType === 'list') {
    return (
      <div className="grid">
        { [...Array(podsCount)].map((n, i) => (
          <Row className="placeholder-list show-loading-animation" key={`placholder-${i}`}>
            <Col className="placeholder-product-pod" lg="3" md="3" sm="3" xs="3">
              <RectShape style={{ width: '100%', height: '220px', marginBottom: '5px' }} color="#E0E0E0" />
            </Col>
            <Col className="placeholder-product-pod" lg="5" md="5" sm="5" xs="5">
              <TextBlock rows={7} color="#E0E0E0" />
            </Col>
            <Col className="placeholder-product-pod" lg="4" md="4" sm="4" xs="4">
              <TextBlock rows={7} color="#E0E0E0" />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
  return (
    <Row className="placeholder-list show-loading-animation">
      { [...Array(podsCount)].map((n, i) => (
        <Col className="placeholder-product-pod" key={`placholder-${i}`} lg="3" md="3" sm="3" xs="4">
          <RectShape style={{ width: '100%', height: '250px', marginBottom: '10px' }} color="#E0E0E0" />
          <TextBlock rows={7} color="#E0E0E0" />
        </Col>
      ))}
    </Row>
  );
};

ProductPodPlaceholder.propTypes = {
  podsCount: PropTypes.number,
  podType: PropTypes.string
};

ProductPodPlaceholder.defaultProps = {
  podsCount: 8,
  podType: null
};
