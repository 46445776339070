import React, { useContext } from 'react';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import { dynamicRecsDataModel } from '../dataModel';
import SearchViewedRecs from './SearchViewedRecs';

const SearchViewedWrapperComponent = (props) => {
  const { anchor, anchorId } = props;

  const { isServer } = useContext(ExperienceContext);

  if (!isServer) {
    return (
    /* eslint-disable react/jsx-props-no-spreading */
      <SearchViewedRecs {...props} anchor={anchor || anchorId} />
    );
  }
  return null;
};

SearchViewedWrapperComponent.displayName = 'SearchViewedWrapper';

const propTypes = {
  anchorId: string,
  schemaName: string.isRequired,
  anchor: string
};
const defaultProps = {
  anchorId: null,
  anchor: null
};

const dataModel = dynamicRecsDataModel;

SearchViewedWrapperComponent.propTypes = propTypes;
SearchViewedWrapperComponent.defaultProps = defaultProps;
SearchViewedWrapperComponent.dataModel = dataModel;

const HydratedSearchViewedRecs = withHydrator({
  delay: 1500,
  id: 'product-section-searchviewed',
  placeholder: (<LoadingPlaceholder />),
  preserveCtxVal: 'clientStore',
  scrollBuffer: 1200
}, SearchViewedWrapperComponent);

export const SearchViewedWrapper = withErrorBoundary(HydratedSearchViewedRecs);

SearchViewedWrapperComponent.propTypes = propTypes;
SearchViewedWrapperComponent.defaultProps = defaultProps;
SearchViewedWrapper.dataModel = dataModel;
