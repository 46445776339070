import React, { useContext } from 'react';
import { ProductPodContext } from '../../ProductPodContext';
import './product-hddc-message.scss';

export const ProductHddcMessage = () => {
  return (
    <div className="product-hddc-message">
      <p>Only available through The Home Depot Design Center in San Diego, CA or Rockville, MD </p>
    </div>
  );
};

ProductHddcMessage.displayName = 'ProductHddcMessage';