export const FIXED_COLUMN_ORDER = 1;

export const DEFAULT_ROWS_VISIBLE = 6;
export const NO_VALUE_INDICATOR = '---';

const NRF_RESULT_1 = 'Sorry, there are no products available online or in your local store.';
const NRF_RESULT_2 = 'Please use our Store Finder to select another local store.';
export const NRF_RESULT = NRF_RESULT_1 + NRF_RESULT_2;

export const cloneArray = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};

export const COLUMNAME = {
  Price: { key: 'Price', symbol: '$', id: 'PRICE' },
  INSTOREAVAILABILITY: { key: 'INSTOREAVAILABILITY' },
  ONLINEAVAILABILITY: { key: 'ONLINEAVAILABILITY' }
};

export const SORT_TYPE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc'
};

export const DEFAULT_ACCORDION_ICON = {
  position: 'left',
  size: 'sm'
};

export const CHANNEL = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
};

export const DISABLED_SORT_COLUMNS = [
  'INSTOREAVAILABILITY',
  'ONLINEAVAILABILITY',
  'ITEMDESC'
];

export const DEFAULTGROUPIMAGEURL = [{
  size: '75',
  url: 'https://images.thdstatic.com/productImages/e8aed81c-c3d8-4994-b504-31c1448f7a8f/svn/null-64_400.jpg'
}];