/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import {
  string as stringType,
  object,
  shape,
  bool,
} from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { extend } from '@thd-nucleus/data-sources';
import { withDynamicComponent, AppContext } from '@thd-nucleus/app-render';
import className from 'classnames';
import { CoreBuyItAgain } from './CoreBuyitagain';
import { dataModel } from './buyItAgainDataModel';
import { BuyItAgainCarousel } from './BuyItAgainCarousel';
import { BuyItAgainLoadingPlaceholder } from './BuyItAgainLoadingPlaceholder';
import { CarouselHeader } from '../core/CarouselHeader';

const BuyItAgainWrapperComponent = (props) => {
  const [mounted, setMounted] = useState(false);
  const { instance } = useContext(AppContext);
  const isLoggedIn = instance?.authHeaders['x-thd-customer-token'];
  const wrapperClasses = className(
    'sui-p-4',
    'buyitagain'
  );
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div
      id={props?.schemaName}
      className={wrapperClasses}
      data-type="container"
      data-component={props?.schemaName}
    >
      <meta data-prop="name" content={props?.schemaName} />
      {mounted && <CoreBuyItAgain {...props} />}

    </div>
  );
};

BuyItAgainWrapperComponent.displayName = 'BuyItAgain';
BuyItAgainWrapperComponent.dataModel = extend({}, dataModel, BuyItAgainCarousel);
BuyItAgainWrapperComponent.propTypes = {
  anchorId: stringType.isRequired,
  schemaName: stringType.isRequired,
  searchStoreId: stringType,
  keyword: stringType,
  // eslint-disable-next-line react/forbid-prop-types
  searchModelData: object,
  nValue: stringType,
  data: shape({}),
  loading: bool,
  zipCode: stringType,
};

BuyItAgainWrapperComponent.defaultProps = {
  searchStoreId: '',
  keyword: '',
  nValue: '',
  searchModelData: null,
  data: null,
  loading: false,
  zipCode: undefined,
};

const HydratedBuyitAgain = withHydrator({
  delay: 0,
  placeholder: (<BuyItAgainLoadingPlaceholder />),
  preserveCtxVal: 'clientStore'
}, BuyItAgainWrapperComponent);

const BuyitAgainWrapper = withErrorBoundary(withDynamicComponent(HydratedBuyitAgain));

export { BuyitAgainWrapper as BuyItAgain };