import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductImage } from '../product-image/ProductImage';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductQuickView } from '../product-see-product-quick-view/ProductQuickView';
import './product-horizontal-simple.style.scss';
import { ProductPodGroupContext } from '../../ProductPodGroupContext';

export const ProductHorizontalSimple = () => {

  const [mounted, setMounted] = useState(false);
  const { showQuickView } = useContext(ProductPodContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    handleProductQuickView
  } = useContext(ProductPodGroupContext);

  const quickViewEnabled = showQuickView && handleProductQuickView;

  return (
    <div className="horizontal-simple__product-pod">
      <Col xs="2" className="image--wrapper">
        <ProductImage className="product-image" />
      </Col>
      <Col xs="6">
        <Row className="title-rating">
          <ProductHeader brand="above" collection="hidden" />
          <ProductRating />
          <ProductPrice large={false} hideBulkPrice hideLimitPerOrder />
        </Row>
        <Row className="checkout-section">
          {mounted && <ProductATC checkGeneric outline className="product-pod__add-to-cart" />}
        </Row>
      </Col>
      { quickViewEnabled && <ProductQuickView /> }
    </div>
  );
};

ProductHorizontalSimple.displayName = 'ProductHorizontalSimple';
