import {
  params,
  shape as shapeType,
  arrayOf as arrayType,
  string as stringType,
  extend,
  shape,
  client,
  bool,
  string,
  arrayOf,
  number,
  customType
} from '@thd-nucleus/data-sources';

const inventory = shape({
  isOutOfStock: bool(),
  isInStock: bool(),
  isLimitedQuantity: bool(),
  isUnavailable: bool(),
  quantity: number(),
  maxAllowedBopisQty: string(),
  minAllowedBopisQty: string()
});

const locations = arrayOf(shape({
  curbsidePickupFlag: string(),
  isBuyInStoreCheckNearBy: string(),
  distance: string(),
  inventory,
  isAnchor: bool(),
  locationId: string(),
  storeName: string(),
  type: string()
}));

const services = arrayOf(shape({
  type: string(),
  hasFreeShipping: bool(),
  freeDeliveryThreshold: string(),
  locations
}));

const fulfillmentOptions = arrayOf(shape({
  type: string(),
  fulfillable: bool(),
  services
}));

const fulfillment = client(params({ storeId: string(), zipCode: string() }).shape({
  backordered: bool(),
  backorderedShipDate: string(),
  seasonStatusEligible: string(),
  fulfillmentOptions
}));

const headerAttributes = arrayOf(shape({
  fixed: bool(),
  isNum: bool(),
  columnId: string(),
  columnName: string(),
  sortable: bool(),
  order: number()
}));

export const dataModel = {
  tableViewModel: params({
    navParam: string(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    customerType: customType('CustomerType').enum(['B2C', 'B2B'], 'B2C')
  }).shape({
    id: string(),
    tables: arrayOf(shape({
      groups: arrayOf(shape({
        title: string(),
        groupIds: arrayOf(string()),
        topSellerOmsId: string(),
        topSellerImageURLs: arrayOf(shape({
          size: string(),
          url: string(),
        })),
        subgroups: arrayOf(shape({
          title: string(),
          subgroupIds: arrayOf(string())
        })),
      })),
      headerAttributes,
      sortBy: arrayOf(string()),
      sortOrder: arrayOf(string())
    }))
  }),
  tableProductModel: params({
    navParam: string(),
    storeId: string(),
    customerType: customType('CustomerType').enum(['B2C', 'B2B'], 'B2C'),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    attributeIds: arrayOf(string()),
    sort: arrayOf(customType('SortAttribute').shape({
      sortBy: string(),
      sortOrder: string(),
      isNumber: bool()
    })),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL'),
    additionalSearchParams: customType('AdditionalParams').shape({
      mcvisId: string(),
      sponsored: bool(),
      svocID: string()
    }),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      programTiers: arrayOf(string()),
      svocID: string()
    }),

  })
    .shape({
      tableRow: arrayOf(
        shape({
          id: string(),
          previouslyPurchased: string(),
          brandName: string(),
          canonicalUrl: string(),
          productLabel: string(),
          attributes: arrayOf(
            shape({
              attributeValue: string(),
              attributeId: string()
            })
          ),
          pricing: client(shape({
            value: string(),
            preferredPriceFlag: bool(),
            unitOfMeasure: string(),
          })),
          fulfillment
        })
      )
    })
};
