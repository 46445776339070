const isInHiddenPromos = ({
  data, hiddenPromos
}) => {
  const experienceTag = data?.product?.pricing?.conditionalPromotions?.[0]?.experienceTag;
  const subExperienceTag = data?.product?.pricing?.conditionalPromotions?.[0]?.subExperienceTag;

  return hiddenPromos.some((hiddenPromo) => {
    if (experienceTag === 'BOGO') {
      return (hiddenPromo === subExperienceTag);
    }
    return (hiddenPromo === experienceTag);
  });
};

export const shouldDisplay = (({
  data,
  hiddenPromos,
  hideConditionalPromoDescription,
}) => {
  const shortDesc = data?.product?.pricing?.promotion?.description?.shortDesc;
  const preferredPriceFlag = data?.product?.pricing?.preferredPriceFlag;

  return (
    !preferredPriceFlag
    && !hideConditionalPromoDescription
    && shortDesc
    && !isInHiddenPromos({ data, hiddenPromos })
  );
});
