import React, { useState } from 'react';
import {
  func, string, arrayOf, shape, bool
} from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Row, Col } from '@thd-olt-component-react/core-ui';
import { publish } from '../../publisher';
import { FILTER_LABEL } from '../../product-results-helpers';
import { PrimaryFiltersDrawer } from './PrimaryFiltersDrawer';
import styles from '../../styles/primary-filters.scss';

const PrimaryFilters = ({
  dimensions,
  appliedDimensions,
  onChange,
  onRefinementChange,
  onApply,
  toggleSecondaryDrawer,
  baseUrl,
  canonicalUrl,
  primaryFilters,
  hideViewAll,
  isMobile
}) => {
  const cx = classNames.bind(styles);
  const [currentDimension, setDimension] = useState(dimensions?.length > 0 ? dimensions[0].label : '');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const primaryFiltersWrapperClasses = cx('primary-filters--background', {
    'primary-filters--no-pading-left': isMobile
  });

  const primaryFilterClassName = cx({
    'primary-filters__wrapper': !isMobile,
    'primary-filters__wrapper--mobile': isMobile
  });

  const dimensionsContainerClassName = cx('dimensions-container', {
    'dimensions-container--mobile': isMobile
  });

  if (!dimensions) {
    return null;
  }
  if (dimensions.length === 0) return null;

  const toggleDrawer = (isOpen, activeDimension) => {
    setIsDrawerOpen(isOpen);
    setDimension(activeDimension);
  };

  const getLabelByDimension = ({ dimensionId, label }) => {
    const colClassName = cx('dimension__col', {
      'dimension__col--bold': (currentDimension && label === currentDimension.label),
    });
    const caretClassName = cx('primary-filters__caret ', {
      'primary-filters__caret--open': (currentDimension && label === currentDimension.label),
    });
    const linkClassName = cx('primary-filters__link');

    return (
      <div
        key={dimensionId}
        className={colClassName}
      >
        <Button
          className={linkClassName}
          link
          tag="a"
          onClick={() => toggleDrawer(true, label)}
        >
          <span className={cx('primary-filters__label')}>
            {label}
          </span>
          {dimensionId
            && (
              <img
                alt="open arrow"
                className={caretClassName}
                src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
                height="15"
                width="9"
              />
            )}
        </Button>
      </div>
    );
  };

  const getLabels = (primaryFiltersDimensions) => {
    if (primaryFiltersDimensions.length === 0) return null;
    return (
      <div className={dimensionsContainerClassName}>
        {
          primaryFiltersDimensions.map((dimension) => {
            return getLabelByDimension(dimension);
          })
        }
      </div>
    );
  };

  return (
    <div className={cx('primary-filters')} data-component="PrimaryFilterDimensions">
      <Row className={primaryFilterClassName}>
        <Col className={primaryFiltersWrapperClasses} sm="12">
          {!hideViewAll && (
            <div
              key="viewAllFilters"
              className={cx('primary-filters__col')}
            >
              <button className={cx('view-all-button')} type="button" onClick={() => toggleSecondaryDrawer(false)}>
                {FILTER_LABEL}
              </button>
            </div>
          )}
          {getLabels(primaryFilters)}
        </Col>
      </Row>
      <PrimaryFiltersDrawer
        isOpen={isDrawerOpen}
        activeDimension={currentDimension}
        toggleDrawer={toggleDrawer}
        dimensions={dimensions}
        primaryFiltersDimensions={primaryFilters}
        appliedDimensions={appliedDimensions}
        onRefinementChange={onChange}
        onDimensionChange={onRefinementChange}
        onApply={onApply}
        canonicalUrl={canonicalUrl}
        baseUrl={baseUrl}
      />
    </div>
  );
};

PrimaryFilters.propTypes = {
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  dimensions: arrayOf(
    shape({
      label: string.isRequired
    })
  ).isRequired,
  primaryFilters: arrayOf(
    shape({
      label: string.isRequired
    })
  ).isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  onRefinementChange: func.isRequired,
  onChange: func.isRequired,
  onApply: func.isRequired,
  toggleSecondaryDrawer: func.isRequired,
  hideViewAll: bool,
  isMobile: bool
};

PrimaryFilters.defaultProps = {
  hideViewAll: false,
  isMobile: false
};

PrimaryFilters.displayName = 'PrimaryFilters';

export { PrimaryFilters };
