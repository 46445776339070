import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { ProductPodContext } from '../../ProductPodContext';
import { DUAL_PATH_IDENTIFIER } from '../../constants';
import styles from './product-service-add-ons.module.scss';

const cx = classNames.bind(styles);

export const ProductServiceAddOns = () => {
  const { product } = useContext(ProductPodContext);
  const isDualPathSku = product?.info?.productSubType?.name === DUAL_PATH_IDENTIFIER;

  return isDualPathSku ? (
    <div className={cx('product-service-adds-on__service-message')}>
      Expert Installation Available
    </div>
  ) : null;
};
