import {
  arrayOf as arrayType,
  bool as boolType,
  shape as shapeType,
  string as stringType,
  number as numberType,
  extend,
  alias,
  customType,
  params
} from '@thd-nucleus/data-sources';
import { CustomProductPod } from '../components/CustomProductPod';

const Product = {
  itemId: stringType(),
  dataSources: stringType(),
  info: shapeType({
    hidePrice: boolType()
  }),
  identifiers: shapeType({
    itemId: stringType()
  }),
  fulfillment: params({ storeId: stringType() }).shape({
    fulfillmentOptions: arrayType(shapeType({
      type: stringType(),
      fulfillable: boolType(),
      services: arrayType(shapeType({
        type: stringType(),
        locations: arrayType(shapeType({
          inventory: shapeType({
            isInStock: boolType(),
            isOutOfStock: boolType(),
            isLimitedQuantity: boolType()
          }),
          isAnchor: boolType()
        }))
      }))
    }))
  }),
  availabilityType: shapeType({
    type: stringType(),
    discontinued: boolType()
  })
};

const wrappedProduct = extend(Product, CustomProductPod.dataModel.product);

export const dataModel = {
  // TODO: can we just change this to productShelfModel? eventually?
  // YES WE CAN. alias things can be anything we want.
  // simply change dpdSearchModel here and update all references
  dpdSearchModel: alias('searchModel').params({
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    navParam: stringType(),
    keyword: stringType(),
    itemIds: arrayType(stringType()),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL'),
    storeId: stringType(),
    additionalSearchParams: customType('AdditionalParams').shape({
      callback: stringType()
    })
  }).shape({
    id: stringType(),
    products: params({
      pageSize: numberType(),
      startIndex: numberType(),
      orderBy: customType('ProductSort').shape({ field: customType('ProductSortField')
        .enum(['TOP_SELLERS', 'MOST_POPULAR', 'TOP_RATED', 'NEW_ITEMS', 'BEST_MATCH', 'PRODUCT_NAME', 'BRAND_NAME', 'PRICE'], 'TOP_SELLERS'),
      order: customType('SortOrder').enum(['ASC', 'DESC'], 'ASC') })
    }).arrayOf(shapeType(wrappedProduct)),
    searchReport: shapeType({
      totalProducts: numberType(),
      sortBy: stringType(),
    }),
    metadata: shapeType({
      canonicalUrl: stringType()
    }),
    taxonomy: shapeType({
      breadCrumbs: arrayType(shapeType({
        dimensionName: stringType(),
        label: stringType(),
        refinementKey: stringType()
      }))
    })
  })
};
