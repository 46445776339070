import Base from './Base';
import * as Helpers from '../Helpers';

class CategoryCards extends Base {

  get validCategoryCardsList() {
    const guides = this.get('universalSearchArray', []);

    const validCategoryCardsList = [];
    guides.forEach((guide) => {
      const { info = {} } = guide;
      const { title = '' } = info;
      if (title) {
        const cardTitle = Helpers.getCardType(title);
        if (cardTitle) {
          validCategoryCardsList.push(guide);
        }
      }
    });
    return validCategoryCardsList;
  }

  get majorCard() {

    const categoryCards = this.validCategoryCardsList;

    if (categoryCards.length > 0) {
      return categoryCards[0];
    }
    return categoryCards;
  }

  get majorHeader() {
    const major = this.majorCard;
    if (major.length === 0) return null;

    const { info = {}, pods = [] } = major;

    const header = {
      title: info.title,
      description: pods[0] && pods[0].title
    };

    return header;
  }

  get majorDetails() {
    const major = this.majorCard;
    if (major.length === 0) return null;

    const { pods = [] } = major;

    const details = {
      imageUrl: pods[0] && pods[0].imageUrl,
      description: pods[0] && pods[0].description,
      link: pods[0] && pods[0].link,
      recordType: pods[0] && pods[0].recordType,
    };

    return details;
  }

  get minorHeader() {
    const minor = this.minorCard;
    if (minor.length === 0) return null;

    const { info = {}, pods = [] } = minor;

    const header = {
      title: info.title,
      description: pods[0] && pods[0].title
    };

    return header;
  }

  get minorDetails() {
    const minor = this.minorCard;
    if (minor.length === 0) return null;

    const { pods = [] } = minor;

    const details = {
      imageUrl: pods[0] && pods[0].imageUrl,
      description: pods[0] && pods[0].description,
      link: pods[0] && pods[0].link,
      recordType: pods[0] && pods[0].recordType,
    };

    return details;
  }

  get minorCard() {
    const categoryCards = this.validCategoryCardsList;

    if (categoryCards.length > 1) {
      return categoryCards[1];
    }
    return [];
  }

  get productTypes() {
    return this.get('productTypes', []);
  }

  isFullCard(channel) {
    const categoryCards = this.validCategoryCardsList;
    if (categoryCards.length === 0) return null;

    if (channel === 'mobile') {
      if (categoryCards.length === 1) {
        const { pods } = categoryCards[0];
        return pods.length === 1;
      }
    }
    return categoryCards.length === 1;
  }
}

export default CategoryCards;
