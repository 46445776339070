import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import '../countdown-timer.style.scss';
import classNames from 'classnames';

const TimerInline = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg,
  showMessageOnRight,
  showOrangeTimerSvg
}) => {

  const timerClasses = classNames('countdown-timer__wrapper', {
    'countdown-timer__wrapper--verbose': shouldUseMessagingString
  });

  return (
    <span className={timerClasses}>
      {shouldIncludeSvg && (
        showOrangeTimerSvg ? (
          <Image src="https://assets.thdstatic.com/images/v1/clock.svg" alt="" width={1} height={1} />
        ) : (
          <Image src="https://assets.thdstatic.com/images/v1/clock-black.svg" alt="" width={1} height={1} />
        )

      )}
      {(timeStringAndMessaging.messagingString && shouldUseMessagingString && !showMessageOnRight) && (
        <span className={timerClasses}>
          {timeStringAndMessaging.messagingString}
        </span>
      )}
      {timeStringAndMessaging.timeString && (
        timeStringAndMessaging.timeString
      )}
      {(timeStringAndMessaging.messagingString && shouldUseMessagingString && showMessageOnRight) && (
        <span className={timerClasses}>
          {timeStringAndMessaging.messagingString}
        </span>
      )}
    </span>
  );
};

TimerInline.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show clock image
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show Message on right Side
   */
  showMessageOnRight: PropTypes.bool,
  /**
   * To show orage colour clock image
   */
  showOrangeTimerSvg: PropTypes.bool,
  /**
   * To show message and time
   */
  timeStringAndMessaging: PropTypes.oneOfType([
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.string
    }),
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.element
    })
  ])
};

TimerInline.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  showMessageOnRight: false,
  showOrangeTimerSvg: false,
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerInline;
