import React from 'react';
import { RectShape, TextRow } from '@thd-olt-component-react/loading-placeholder';
import { Row } from '@thd-olt-component-react/grid';
import '../../styles/filter-and-sort.scss';

const TopFiltersPlaceholder = () => {
  return (
    <div className="filter-and-sort">
      <Row style={{ display: 'flex', width: '100%' }}>
        <RectShape
          style={{
            width: '110px',
            height: '40px',
            marginTop: '5px',
            marginBotton: '5px',
            marginRight: '10px'
          }}
          color="#E0E0E0"
        />
        <RectShape
          style={{
            width: '90px',
            height: '40px',
            marginTop: '5px',
            marginBotton: '5px',
            marginRight: '2px'
          }}
          color="#E0E0E0"
        />
        <RectShape
          style={{
            width: '150px',
            height: '40px',
            marginTop: '5px',
            marginBotton: '5px',
            marginRight: '2px'
          }}
          color="#E0E0E0"
        />
      </Row>
    </div>
  );
};

const FilterAndSortPlaceholder = () => {
  return (
    <div className="filter-and-sort" data-component="FilterAndSortPlaceholder">
      <TopFiltersPlaceholder />
    </div>
  );
};

FilterAndSortPlaceholder.displayName = 'FilterAndSortPlaceholder';

export { FilterAndSortPlaceholder };