import { forEach } from '~/core-js/modules/_enum-bug-keys';
import { CHANNEL, COLUMNAME, DISABLED_SORT_COLUMNS, SORT_TYPE } from '../constants/appConstant';

export const isNumber = (str) => {
  if (typeof str !== 'string') return false;
  if (str === '') return false;
  return !Number.isNaN(Number(str));
};

export const convertFractionToDecimal = (str) => {
  if (str) {
    const rawValue = str.split(' ');
    const parts = rawValue[0].split('-');
    if (parts.length > 1) {
      const decParts = parts[1].split('/');
      return `${parseInt(parts[0], 10)
                + (parseInt(decParts[0], 10) / parseInt(decParts[1], 10))}`;
    }
    const decParts = parts[0].split('/');
    return `${(parseInt(decParts[0], 10) / parseInt(decParts[1], 10))}`;
  }
  return false;
};

export const valueMapper = (str) => {
  if (str) {
    const rawValue = str.split(' ');
    const digitValues = rawValue[0].split('/');
    const isFraction = digitValues.length === 2;
    if (isFraction) {
      return convertFractionToDecimal(str);
    }
    return str;
  }
  return str;
};

export const encodeTo36 = (refinementIds) => {
  return refinementIds.map((refinementId) => parseInt(refinementId, 10).toString(36));
};

const sortAlphaNumeric = (prev, next) => {
  // convert to strings and force lower
  const prevValue = typeof prev === 'string' ? prev.toLowerCase() : prev?.toString();
  const nextValue = typeof next === 'string' ? next.toLowerCase() : next?.toString();

  return prevValue.localeCompare(nextValue, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};

export const tableViewCustomSort = (sortType) => {
  return (prev, next) => {
    let prevValue;
    let nextValue;

    if (sortType.type === COLUMNAME.Price.id) {
      prevValue = valueMapper(
        prev?.pricing?.value?.toString() || null
      );
      nextValue = valueMapper(
        next?.pricing?.value?.toString() || null
      );
    } else if (sortType.type === COLUMNAME.INSTOREAVAILABILITY.key) {
      prevValue = prev.pickupText;
      nextValue = next.pickupText;
    } else if (sortType.type === COLUMNAME.ONLINEAVAILABILITY.key) {
      prevValue = prev.deliveryText;
      nextValue = next.deliveryText;
    } else {
      prevValue = valueMapper(
        prev.attributes.find((attr) => attr.attributeId === sortType.type)?.attributeValue
      );
      nextValue = valueMapper(
        next.attributes.find((attr) => attr.attributeId === sortType.type)?.attributeValue
      );
    }

    if (prevValue === null || prevValue === undefined) {
      return 1;
    }
    if (nextValue === null || nextValue === undefined) {
      return -1;
    }

    if (isNumber(prevValue) && isNumber(nextValue)) {
      if (sortType.order === SORT_TYPE.ASCENDING) {
        return prevValue - nextValue;
      }
      return nextValue - prevValue;

    }
    const sortValue = sortAlphaNumeric(prevValue, nextValue);
    return sortType.order === SORT_TYPE.ASCENDING ? sortValue : -sortValue;
  };
};

export const arrangeHeaderByColumnOrder = (tableColumns) => {
  let header = [];
  tableColumns.forEach((attr) => {
    header[parseInt(attr.order - 1, 10)] = attr;
  });
  return header;
};

export const isDesktopView = (channel) => {
  return channel === CHANNEL.DESKTOP;
};

export const disableSort = (id) => {
  return DISABLED_SORT_COLUMNS.find((col) => col === id);
};

export const defaultSortParams = (primaryCol) => {
  if (!primaryCol.length) return { type: '', order: '' };
  const { sortBy: type, sortOrder: order } = primaryCol[0];
  return { type, order: order.toLowerCase() };
};

export const getBaseNavParam = (navParam, groups) => {
  let navParamList = navParam.split('Z');
  const groupIds = groups.map((group) => group.groupIds).flat();

  // if there are only 2 values means there are no refinements applied
  if (navParamList.length === 2) return navParam;

  // remove default values
  let filters = navParamList.slice(2);

  // default navParm values
  const defaultNavParam = navParamList.slice(0, 2);

  // encode groupIds
  const encodedGroupIds = encodeTo36(groupIds);

  let index = 0;
  while (filters.length > 0 && index < filters.length) {
    if (encodedGroupIds.includes(filters[index])) {
      filters.splice(index, 1);
    } else {
      index += 1;
    }
  }
  // join any rermaining refiements to the default params
  return defaultNavParam.concat(filters).join('Z');
};