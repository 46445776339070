import React from 'react';
import {
  Skeleton,
  SkeletonContent,
  SkeletonBlock,
  SkeletonLine,
} from '@one-thd/sui-atomic-components';

const ContentSkeleton = () => {
  return (
    <>
      <Skeleton className="sui-w-full">
        <SkeletonLine variant="heading" />
      </Skeleton>
      <div className="sui-flex sui-flex-col">
        <Skeleton orientation="horizontal" className="sui-w-full">
          <SkeletonContent disablePadding initialSize="1/4" orientation="horizontal">
            <SkeletonBlock aspect="square" />
          </SkeletonContent>
          <SkeletonContent disablePadding initialSize="3/4">
            <SkeletonLine numberOfLines={6} />
          </SkeletonContent>
        </Skeleton>
      </div>
    </>
  );
};

export default ContentSkeleton;