import React, { useContext } from 'react';
import {
  shape as shapeType,
  string as stringType,
  alias,
  extend,
  QueryProvider,
  useDataModel,
  params
} from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Button } from '@thd-olt-component-react/button';
import styles from './track-order.module.scss';

const TrackOrder = ({ keyword }) => {

  const {
    channel
  } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const trackOrderButton = classNames({
    [styles['track-order__button']]: !isMobile,
    [styles['track-order__button--mobile']]: isMobile
  });

  const trackOrderTitle = classNames(styles['track-order__title'], {
    [styles['track-order__title--mobile']]: isMobile
  });

  const { data } = useDataModel('searchModel', {
    variables: {
      keyword
    },
    skip: !keyword
  });

  if (!keyword) {
    return null;
  }

  const orderNumber = data?.searchModel?.orangeGraph?.orderNumber;

  if (!orderNumber) {
    return null;
  }

  return (
    <div className={classNames(styles['track-order'])} data-component="TrackOrder">
      <div className={trackOrderTitle}>
        Checking your order status?
      </div>
      <Button
        tag="a"
        outline
        href={`/order/view/tracking?cm_mmc=${orderNumber}`}
        className={trackOrderButton}
      >
        Track Order
      </Button>
    </div>
  );
};

TrackOrder.propTypes = {
  keyword: PropTypes.string
};

TrackOrder.defaultProps = {
  keyword: ''
};

TrackOrder.displayName = 'TrackOrder';

TrackOrder.dataModel = extend({
  searchModel: params({ keyword: stringType() })
    .shape({
      orangeGraph: shapeType({
        orderNumber: stringType()
      })
    })
});

export { TrackOrder };
