import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { oneOf } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { ProductPodContext } from '../../ProductPodContext';
import { ProductImage } from '../product-image/ProductImage';
import { ProductFavorites } from '../product-favorites/ProductFavorites';
import { ProductHeader } from '../product-header/ProductHeader';
import { ProductRating } from '../product-rating/ProductRating';
import { ProductPrice } from '../product-price/ProductPrice';
import { ProductATC } from '../product-atc/ProductATC';
import { ProductQuantity } from '../product-quantity/ProductQuantity';
import { ProductQuoteCenter } from '../product-quote-center/ProductQuoteCenter';
import './product-pod-grouped.style.scss';
import { ProductIdentifier } from '../product-identifier/ProductIdentifier';

export const ProductPodGrouped = ({ channel }) => {
  const {
    itemId,
    onFavoriteChange,
    onChangeSuperSku,
    onHoverSuperSku,
    buyMoreSaveMore,
    previousItemId,
    isHovered,
    showModelNumber,
    showItemId,
    showSkuNumber,
    isHDQCSku
  } = useContext(ProductPodContext);

  const wrapperClasses = classNames('product-pod-grouped__wrapper', {
    'product-pod-grouped__wrapper--mobile': channel === 'mobile'
  });
  const superSkuItemId = isHovered ? previousItemId : itemId;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Col className="product-pod-grouped">
      <Col xs="11" className={wrapperClasses}>
        <div className="product-pod-grouped__product" data-testid="grouped-pod">
          <Row>
            <Col fallback="5" xs="12" nopadding>
              <ProductImage buyMoreSaveMore={buyMoreSaveMore} />
              <ProductFavorites showCircle position="topRight" onFavoriteChange={onFavoriteChange} />
            </Col>
            <Col fallback="7" xs="12" nopadding>
              <ProductHeader />
              <>
                {showItemId && <ProductIdentifier type="itemId" /> }
                {showModelNumber && <ProductIdentifier type="model" /> }
                {showSkuNumber && <ProductIdentifier type="sku" />}
              </>
              <ProductRating />
            </Col>
          </Row>
          {mounted && (
            <SuperSku
              itemId={superSkuItemId}
              onChange={onChangeSuperSku}
              onHover={onHoverSuperSku}
            />
          )}
        </div>
        { isHDQCSku ? (
          <ProductQuoteCenter />
        ) : (
          <div className="grouped-view__atc-wrapper">
            <ProductPrice large={false} className="product-pod__pricing" />
            <Col fallback="4" nopadding>
              <ProductQuantity nopadding />
            </Col>
            <Col fallback="8" nopadding className="grouped-view__add-to-cart">
              <ProductATC className="product-pod__add-to-cart" checkGeneric outline showReturnable />
            </Col>
          </div>
        )}
      </Col>
    </Col>
  );
};

ProductPodGrouped.displayName = 'ProductPodGrouped';

ProductPodGrouped.propTypes = {
  channel: oneOf(['desktop', 'mobile'])
};

ProductPodGrouped.defaultProps = {
  channel: 'desktop'
};
