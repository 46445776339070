import React, { useContext, useState } from 'react';
import { Col, Image } from '@thd-olt-component-react/core-ui';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductQuickView = () => {

  const {
    onQuickViewClick
  } = useContext(ProductPodContext);

  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  // corresponds to product-pod context value for handleProductQuickView
  const handleQuickViewOpen = () => onQuickViewClick({
    isQuickViewOpen,
    setIsQuickViewOpen,
  });

  return (
    <Col xs="1" className="quick-view__caret">
      <button
        onClick={() => {
          setIsQuickViewOpen(true);
          handleQuickViewOpen();
        }}
        type="button"
        data-testid="quick-view-caret"
      >
        <Image
          alt="caret-icon"
          src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
          height="15"
          width="9"
        />
      </button>
    </Col>
  );
};

ProductQuickView.displayName = 'ProductQuickView';
