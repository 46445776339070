import {
  APPLIANCE_DEPARTMENT,
  FULFILLMENT_METHODS,
  MAJOR_APPLIANCE,
  MERCHANDISE,
  NON_MAJOR_APPLIANCE_CLASS
} from '../constants';
import { getStateName } from './StateCodes';

export const formatDate = (date, isLongMonthName) => {
  if (!date) {
    return date;
  }
  let formattedDate;
  if (typeof date === 'object') {
    formattedDate = date;
  } else if (typeof date === 'string' && date.indexOf('T') > -1) {
    formattedDate = new Date(date);
  } else {
    const dateparts = date.split('-');
    formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  }

  if (formattedDate.toString() === 'Invalid Date') {
    return null;
  }
  const longMonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (isLongMonthName) {
    return longMonthNames[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
  }
  return shortMonthNames[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
};

export const formatWeek = (date, isLongDayName) => {
  if (!date) {
    return date;
  }
  let formattedDate;
  if (typeof date === 'object') {
    formattedDate = date;
  } else if (typeof date === 'string' && date.indexOf('T') > -1) {
    formattedDate = new Date(date);
  } else {
    const dateparts = date.split('-');
    formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  }
  if (formattedDate.toString() === 'Invalid Date') {
    return null;
  }
  const longWeekNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const shortWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  if (isLongDayName) {
    return longWeekNames[formattedDate.getDay()];
  }
  return shortWeekNames[formattedDate.getDay()];
};

export const safelyGet = (input, fallback) => {
  try {
    return input();
  } catch (exception) {
    return fallback;
  }
};

export const showTodayOrTomorrow = (date, isLongDayName = false) => {
  if (!date) {
    return date;
  }
  const dateparts = date.split('-');
  const estimatShippingMonth = parseInt(dateparts[1], 10);
  const estimatShippingDay = parseInt(dateparts[2], 10);

  const todaysDate = new Date();
  const today = todaysDate.getDate();
  const todayMonth = todaysDate.getMonth() + 1;

  const tomorrowDate = new Date(todaysDate.setDate(todaysDate.getDate() + 1));
  const tomorrow = tomorrowDate.getDate();
  const tomorrowMonth = tomorrowDate.getMonth() + 1;

  if (today === estimatShippingDay && todayMonth === estimatShippingMonth) {
    return 'Today';
  }
  if (tomorrow === estimatShippingDay && tomorrowMonth === estimatShippingMonth) {
    return 'Tomorrow';
  }
  return formatWeek(date, isLongDayName);
};

export const getParsedFulfillment = (fulfillment, shippingServiceModel) => {
  if (shippingServiceModel) {
    const {
      dynamicETA: { totalHours, totalMinutes },
      endDate,
      startDate,
      freeShippingThreshold,
      deliveryTimeline,
      totalCharge,
      isBodfsShipMode,
      hasFreeShipping,
      locations
    } = shippingServiceModel || {};
    return {
      ...fulfillment,
      deliveryTimeline,
      deliveryCharge: totalCharge,
      isBodfsShipMode,
      freeDeliveryThreshold: freeShippingThreshold,
      hasFreeShipping,
      locations,
      deliveryDates: {
        ...fulfillment.deliveryDates,
        startDate,
        endDate
      },
      dynamicEta: {
        ...fulfillment.dynamicETA,
        hours: totalHours,
        minutes: Number.isNaN(totalMinutes) ? '' : totalMinutes
      }
    };
  }
  return fulfillment;
};

export const isStoreOnlyWithClearance = (fulfillment, type) => {
  return fulfillment?.anchorStoreStatusType?.toUpperCase() === 'CLEARANCE' && type === 'Store Only';
};

export const isOutOfStockOnline = (fulfillment) => {
  const { locations = [] } = fulfillment;
  if (locations.length) {
    return locations[0]?.inventory?.isOutOfStock;
  }
  return false;
};

export const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    return locations.find((location) => !location.isAnchor);
  }
  return null;
};
export const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  return locations.find((location) => location.isAnchor);
};

export const getOnlineLocation = (fulfillment) => {
  return (fulfillment?.locations || []).find((location) => location.type === 'online');
};

export const isBOSSBackordered = (fulfillment) => {
  return fulfillment?.backordered;
};

export const isBOSSOutOfStock = (fulfillment) => {
  const bossLocation = (fulfillment?.locations || []).find((location) => location.type === 'online');
  return bossLocation?.inventory?.isOutOfStock;
};

export const isBopisOutOfStock = (localStoreLocation, nearByStoreLocation) => {
  const localStoreOOS = localStoreLocation?.inventory?.isOutOfStock;
  const nearByStoreOOS = nearByStoreLocation?.inventory?.isOutOfStock;
  return !!(localStoreOOS || (localStoreOOS && nearByStoreOOS));
};

export const isBOPISUnavailable = (localStoreLocation, nearByStoreLocation) => {
  const localStoreUnavailable = localStoreLocation?.inventory?.isUnavailable;
  const nearByStoreUnavailable = nearByStoreLocation?.inventory?.isUnavailable;
  if (localStoreUnavailable || (localStoreUnavailable && nearByStoreUnavailable)) {
    return true;
  }
  return localStoreUnavailable;
};

export const isBOSSDominant = (bossFulfillment, location, quantity) => {
  return bossFulfillment
    && location?.inventory?.quantity > 0
    && location.inventory.quantity < quantity;
};

export const isCovidAlertEnabled = (covidAlert, bopisHolidayTiming) => {
  return covidAlert && new Date().getHours() >= bopisHolidayTiming;
};

export const getExcludedStateName = (stateCode) => {
  return getStateName(stateCode) || stateCode;
};

export const isLocationInExcludedStates = (stateCode, excludedStates = '') => {
  if (excludedStates && excludedStates.split) {
    const excludedStatesArray = excludedStates.split(',');
    return (stateCode && stateCode.length === 2 && excludedStatesArray.includes(stateCode));
  }
  return false;
};

export const getFulfillmentLabel = (fulfillment) => {
  if (fulfillment.method === FULFILLMENT_METHODS.STH) {
    return 'Ship to Home';
  }
  if (fulfillment.method === FULFILLMENT_METHODS.BOPIS) {
    return 'Store Pickup';
  }
  if (fulfillment.method === FULFILLMENT_METHODS.STORE) {
    return 'Ship to Store';
  }
  if (fulfillment.method === FULFILLMENT_METHODS.BODFS) {
    return 'Express Delivery';
  }
  return null;
};

export const getLiveGoodsLocations = (fulfillment) => {
  return safelyGet(() => (fulfillment.fulfillmentOptions)
    .find((option) => (option.type === 'pickup' && option.fulfillable === false
      && option.services)).services
    .find((services) => (services.type === 'bopis' && services.locations)).locations);
};

export const getLiveGoodsStock = (fulfillment) => {
  if (fulfillment?.seasonStatusEligible == null) {
    return false;
  }

  const bopisLocations = getLiveGoodsLocations(fulfillment);

  if (bopisLocations) {
    return safelyGet(() => bopisLocations
      .find((location) => (location.isAnchor)).inventory.quantity, 0) || 0;
  }
  return false;
};

export const isQuantityLimitExceed = ({ method, storeObj, shippingObj, expressObj }) => {
  if (method === FULFILLMENT_METHODS.BOPIS || method === FULFILLMENT_METHODS.STORE) {
    return storeObj.quantityLimitExceed;
  }
  if (method === FULFILLMENT_METHODS.STH) {
    return shippingObj.quantityLimitExceed;
  }
  if (method === FULFILLMENT_METHODS.BODFS) {
    return expressObj.quantityLimitExceed;
  }
  return false;
};

export const isStoreOnlyOOS = (fulfillment, type) => {
  const pickupService = (fulfillment?.fulfillmentOptions || [])
    .find((option) => option.type === 'pickup' && !option.fulfillable);
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

  const localStoreLocation = getLocalStore(bopisFulfillment);
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  return ((isBopisOutOfStock(localStoreLocation, nearByStoreLocation)
    || isBOPISUnavailable(localStoreLocation, nearByStoreLocation))
      && type === 'Store Only');
};

export const bopis = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  return bopisFulfillment || null;
};
export const sth = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const sthFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  return sthFulfillment || null;
};
export const boss = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return bossFulfillment || null;
};

export const isMajorApplianceProductType = (productType) => {
  return productType === MAJOR_APPLIANCE;
};

export const isMerchandiseProductType = (productType) => {
  return productType === MERCHANDISE;
};

export const isNonMajorAppliance = (productType, info) => {
  const departmentCheck = info?.productDepartment === APPLIANCE_DEPARTMENT;
  const classCheck = NON_MAJOR_APPLIANCE_CLASS.find((classNumberValue) => classNumberValue === info?.classNumber);
  return isMerchandiseProductType(productType)
    && departmentCheck
    && classCheck;
};

export const isNoBOPISForAppliance = (productType, fulfillment) => {
  const bopisFulfillment = bopis(fulfillment);
  if (!bopisFulfillment && isMajorApplianceProductType(productType)) {
    return true;
  }
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return isBopisOutOfStock(localStoreLocation) && isMajorApplianceProductType(productType);
};

export const hasNoDirectDeliveryAvailable = ({ productType, fulfillment, checkAvailabilityModel }) => {
  const noBOPIS = isNoBOPISForAppliance(productType, fulfillment);
  return (checkAvailabilityModel?.hasOutOfStock || checkAvailabilityModel?.isUnavailable) && noBOPIS;
};

export const getDeliveryZip = () => {
  let zipCode = '';
  try {
    if (typeof window !== 'undefined') {
      const zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
      zipCode = zipDetails?.zipcode;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return zipCode;
};
export const setDeliveryZip = (deliveryZipCode) => {
  try {
    if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer && deliveryZipCode) {
      window.THD_LOCALIZER_AUTO_INIT.Localizer.updateDeliveryZipcodeDetails(deliveryZipCode, 'USER');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const formatInventory = (inventoryValue) => {
  let inventorySplit = inventoryValue.toLocaleString();
  if (!inventoryValue) {
    return 0;
  }
  return inventorySplit;
};

export const getLiveGoodsStoreName = (product) => {
  const bopisLocations = getLiveGoodsLocations(product?.fulfillment);

  if (bopisLocations) {
    return safelyGet(() => bopisLocations
      .find((location) => (location.isAnchor)).storeName) || false;
  }
  return false;
};

export const getCurrentFulfillment = ({ selectedFulfillment, fulfillmentModels }) => {
  if (selectedFulfillment === FULFILLMENT_METHODS.BOPIS || selectedFulfillment === FULFILLMENT_METHODS.BOSS) {
    return fulfillmentModels.find(
      (option) => option.method === FULFILLMENT_METHODS.BOPIS || option.method === FULFILLMENT_METHODS.BOSS
    );
  }
  return fulfillmentModels.find((option) => option.method === selectedFulfillment);
};

export const isMobile = (channel) => {
  return channel?.toLowerCase() === 'mobile';
};
