import React, { useContext } from 'react';
import classNames from 'classnames';
import { node } from 'prop-types';
import { Paper } from '@thd-olt-component-react/core-ui';
import styles from './drawer-footer.module.scss';
import { DrawerContext } from '../Drawer';
/**
 * Footer in Drawer
 * @param {*} param0
 */
export const DrawerFooter = ({
  children
}) => {
  const { isFlex } = useContext(DrawerContext);
  const footerClasses = classNames(
    styles['paper--elevation-2-override'],
    styles['drawer-footer'],
    styles['sticky-nav'],
    styles['sticky-nav--bottom'], {
      [styles['sticky-nav--flex']]: isFlex
    }
  );

  return (
    <Paper elevation={2} sharp className={footerClasses}>
      {children}
    </Paper>
  );
};

DrawerFooter.displayName = 'DrawerFooter';

DrawerFooter.propTypes = {
  children: node.isRequired
};