import { I_DONT_KNOW } from '../constants';

export const getDefaultSelectedOptions = (optionsList = []) => {
  let defaultOptions = {};

  optionsList.forEach(({ id, value }) => {
    defaultOptions = {
      ...defaultOptions,
      [id?.toUpperCase()]: {
        id: '',
        displayValue: `Pick Your ${value}`,
        option: id
      }
    };
  });

  return defaultOptions;
};

export const formatCarDetailsToPath = (vehicleSelection = {}) => {
  const { YEAR, MAKE, MODEL } = vehicleSelection;
  const displayValues = [YEAR, MAKE, MODEL];

  let definedValues = [];
  displayValues.forEach((displayValue) => {
    const value = displayValue?.displayValue || undefined;
    if (value) {
      definedValues.push(value);
    }
  });

  return definedValues.length ? definedValues.join('-').replace(/ /g, '-') : '';
};

export const getVehiclePickerHistory = () => {
  let prevVehicleSelection;
  let currentVehicleSelection;

  if (typeof window !== 'undefined') {
    const vehiclePickerHistory = JSON.parse(window.localStorage?.getItem('vehiclePickerHistory'));
    prevVehicleSelection = vehiclePickerHistory?.prevSelection;
    currentVehicleSelection = vehiclePickerHistory?.newSelection;
  }

  return { prevVehicleSelection, currentVehicleSelection };
};

const removePrevVehicleIds = (filters = [], urlParts = [], nValueIndex) => {
  // remove old vehicle encoded ids
  const { prevVehicleSelection, currentVehicleSelection } = getVehiclePickerHistory();

  if (prevVehicleSelection && currentVehicleSelection) {
    const outdatedCarDetails = formatCarDetailsToPath(prevVehicleSelection);
    const updatedCarDetails = formatCarDetailsToPath(currentVehicleSelection);

    if (JSON.stringify(prevVehicleSelection) !== JSON.stringify(currentVehicleSelection)) {
      Object.values(prevVehicleSelection).forEach(({ id }) => {
        const index = filters.indexOf(id);
        if (index > -1) {
          filters.splice(index, 1);
        }
      });
    }

    const index = urlParts.indexOf(outdatedCarDetails);
    if (index !== -1 && outdatedCarDetails.length) {
      urlParts.splice(index, 1, updatedCarDetails);
    } else {
      urlParts.splice(nValueIndex, 0, updatedCarDetails);
    }

    // Remove previous dynamic options text from path i.e. /b/.../AWD/Sedan/...
    let dynamicOptions = Object.values(prevVehicleSelection)
      .filter(({ option }) => !['year', 'make', 'model'].includes(option))
      .filter(({ displayValue }) => displayValue !== I_DONT_KNOW)
      .map(({ displayValue }) => displayValue?.replace(/ /g, '-').replace('.', ''));

    dynamicOptions.forEach((option) => {
      const indexOf = urlParts.indexOf(option);
      if (indexOf !== -1) {
        urlParts.splice(indexOf, 1);
      }
    });
  }

  return [filters, urlParts];
};

export const updateCarSelectionParams = (path = '', encodedIds = []) => {
  let urlParts = path.split('/');
  let nValue;
  let nValueIndex;

  urlParts.forEach((piece, index) => {
    if (/^N-/.test(piece)) {
      nValue = piece;
      nValueIndex = index;
    }
  });

  if (!nValue) {
    // No nValue so return path
    return path;
  }

  // Keyword edge case ex: /b/.../N-5yc1vZc8ns/Ntk-EnrichedProductInfo/Ntt-wiper?NCNI-5
  const containsKeyword = nValueIndex !== urlParts.length - 1;
  let params;
  let query;
  let getItFast = '';

  if (!containsKeyword) {
    // Still need to find correct nValue to update
    [params, query = ''] = nValue.split('?');

    if (!query.length) {
      // 'Get It Fast' edge case ex: /b/.../N-5yc1vZc8ssZ1z175a5&storeSelection=6553,6839,504,6808,6892
      getItFast = params.split('&');

      if (getItFast.length > 1) {
        nValue = getItFast[0];
        getItFast = getItFast.slice(1);
      } else {
        // Path contains no queries, ex: /b/.../N-5yc1vZc8ss --> reset so it does not append to path
        getItFast = '';
      }
    } else {
      nValue = params;
    }
  }

  let filters = nValue.split('Z');

  [filters, urlParts] = removePrevVehicleIds(filters, urlParts, nValueIndex);

  encodedIds.forEach((id) => {
    if (filters.indexOf(id) === -1) {
      filters.push(id);
    }
  });

  let nValueUpdate = [...filters].join('Z');

  urlParts.forEach((piece, index) => {
    if (/^N-/.test(piece)) {
      nValueIndex = index;
    }
  });

  if (query?.length > 0) {
    nValueUpdate = `${nValueUpdate}?${query}`;
  }

  if (getItFast?.length > 0) {
    nValueUpdate = `${nValueUpdate}&${getItFast.join('&')}`;
  }

  urlParts.splice(nValueIndex, 1, nValueUpdate);

  return `${urlParts.join('/')}`;
};

export const savePersistedState = (newVehicleSelection) => {
  const prevVehicleSelection = JSON.parse(localStorage?.getItem('vehiclePickerHistory'));
  let vehiclePickerHistory = prevVehicleSelection || {};

  if (!prevVehicleSelection) {
    vehiclePickerHistory = {
      prevSelection: newVehicleSelection,
      newSelection: newVehicleSelection
    };
  } else {
    vehiclePickerHistory = {
      prevSelection: vehiclePickerHistory.newSelection,
      newSelection: newVehicleSelection
    };
  }
  const serializedState = JSON.stringify(vehiclePickerHistory);
  localStorage.setItem('vehiclePickerHistory', serializedState);
};

export const getDataModelErrorState = (data, loading, error) => {
  return !data?.fitmentOptionsV1 || loading || (error && !data?.fitmentOptionsV1);
};

export const verifyPIPFitment = (pipFitmentRequest, itemIds = [], pipFilter = {}) => {
  pipFitmentRequest({
    variables: {
      fitmentType: 'AUTOMOTIVE',
      itemIds,
      filter: pipFilter
    }
  });
};

export const getAlertBannerDetails = (pageType, item = {}) => {
  const fitType = item?.fitType || null;
  const doesFit = item?.doesFit || null;
  const pipPage = pageType === 'pip';

  let message = pipPage ? 'This part may fit your ' : 'Please select your vehicle';
  let type = 'warning';

  if (fitType === 'universal') {
    message = 'This fits most vehicles';
    type = 'information';
  } else if (doesFit === 'no') {
    message = `Unfortunately this won't fit ${pipPage ? 'your ' : ''}`;
    type = 'danger';
  } else if (doesFit === 'yes') {
    message = pipPage ? 'This will fit your ' : 'This fits your vehicle';
    type = 'success';
  }

  return { message, type };
};

export const getIndex = (id, arr = []) => {
  return arr?.findIndex((item) => item?.id === id);
};

export const getUniversalUrl = (dimensions) => {
  if (dimensions) {
    const dimensionIndex = dimensions.findIndex((dimension) => dimension?.label === 'Automotive Fit Type');

    if (dimensionIndex !== -1) {
      const fitTypeDimension = dimensions[dimensionIndex];
      const refinementIndex = fitTypeDimension?.refinements?.findIndex(
        (refinement) => refinement?.label === 'Universal'
      );

      if (refinementIndex !== -1) {
        return fitTypeDimension?.refinements[refinementIndex]?.url;
      }
    }
  }
  return undefined;
};
