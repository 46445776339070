import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';

import { Heading, Image } from '@thd-olt-component-react/core-ui';

import paintModals from '../../../data/paint-modals.json';
import sharedStyles from '../paint-modal-content.module.scss';
import styles from './Sheen.module.scss';

const { sheens } = paintModals;
const cx = classNames.bind({ ...styles, ...sharedStyles });

const FINISH_PERFORMANCE_LABEL = 'FINISH PERFORMANCE';
const BEST_USES_LABEL = 'BEST USES';
const BEST_SUITED_LOCATIONS_LABEL = 'BEST SUITED LOCATIONS';

export const Sheen = ({ colorHex }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('brands.ready'); }, []);

  return (
    <div className={cx('sheen-modal', 'paint-modal-content')} data-component="PaintModalContentSheen">
      <Heading
        underline
        className={cx('sheen-modal__header')}
        title="Sheen Options"
      />
      <p className={cx('sheen-modal__description')}>
        Take the guesswork out of finding the perfect paint sheen for your next paint project.
      </p>

      <div className={cx('table')}>
        <div className={cx('table__attributes')}>
          <div className={cx('table__row', 'table__row--performance', 'sheen__performance')}>
            {FINISH_PERFORMANCE_LABEL}
          </div>
          <div className={cx('table__row')}>{BEST_USES_LABEL}</div>
          <div className={cx('table__row', 'table__row--locations', 'sheen__locations')}>
            {BEST_SUITED_LOCATIONS_LABEL}
          </div>
        </div>
        <div className={cx('table__body')}>
          <div className={cx('table__row', 'table__row--image')}>
            {sheens.map((sheen) => {
              const { image, name } = sheen;
              return (
                <Image
                  className={cx('sheen__image', 'table__data')}
                  src={image}
                  alt={name}
                  style={{ backgroundColor: colorHex }}
                  key={name}
                  width={147}
                  height={84}
                />
              );
            })}
          </div>
          <div className={cx('table__row')}>
            {sheens.map((sheen) => {
              const { name } = sheen;
              return (
                <div className={cx('sheen__name', 'table__data')} key={name}>{ name }</div>
              );
            })}
          </div>
          <div className={cx('table__row', 'table__row--performance', 'table__row--shaded')}>
            {sheens.map((sheen) => {
              const { name, finishPerformance } = sheen;
              return (
                <div className={cx('table__data')} key={name}>{ finishPerformance }</div>
              );
            })}
          </div>
          <div className={cx('table__row')}>
            {sheens.map((sheen) => {
              const { name, bestUses } = sheen;
              return (
                <div className={cx('sheen__uses', 'table__data')} key={name}>{ bestUses }</div>
              );
            })}
          </div>
          <div className={cx('table__row', 'table__row--locations', 'table__row--shaded')}>
            {sheens.map((sheen) => {
              return (
                <div className={cx('sheen__locations', 'table__data')} key={sheen.name}>
                  {
                    sheen.bestSuitedLocations.map((location) => {
                      return (<div className={cx('sheen__location')} key={location}>{location}</div>);
                    })
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={cx('table__row')}>
        <div className={cx('arrow')}> </div>
      </div>
      <div className={cx('table__row')}>
        <div className={cx('stain-resistance')}>LESS STAIN RESISTANCE</div>
        <div className={cx('stain-resistance')}>SUPERIOR STAIN RESISTANCE</div>
      </div>
    </div>
  );
};

Sheen.propTypes = {
  colorHex: string.isRequired,
};

Sheen.displayName = 'PaintModalContent.Sheen';
