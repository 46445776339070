import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { bool, number, string } from 'prop-types';
import {
  Skeleton, SkeletonLine, SkeletonBlock, SkeletonContent
} from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { CarouselHeader } from '../core/CarouselHeader';

const BuyItAgainLoadingPlaceholder = ({
  hideTitle, title, txtPlaceholderLines,
}) => {

  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  let numOfPlaceholders = isMobile ? 1 : 3;

  const placeholderGridClass = classNames('sui-grid', 'loading-placeholder',
    { 'sui-grid sui-gap-1 sui-grid-cols-1 xl:sui-grid-cols-1': isMobile,
      'sui-grid sui-gap-1 sui-grid-cols-3 xl:sui-grid-cols-3': !isMobile });

  const RenderPlaceholders = () => {
    let placeholder = [];
    for (let i = 0; i < numOfPlaceholders; i += 1) {
      placeholder.push(
        <Skeleton disablePadding="y" orientation="horizontal">
          <SkeletonContent density="loose" disablePadding="x" initialSize="2/4">
            <SkeletonBlock aspect="wide" />
          </SkeletonContent>
          <SkeletonContent density="loose" disablePadding="x" initialSize="2/4">
            <SkeletonLine numberOfLines={txtPlaceholderLines} />
          </SkeletonContent>
        </Skeleton>);
    }
    return placeholder;
  };

  return (
    <>
      {!hideTitle && <CarouselHeader key={title} title={title} />}
      <div className={placeholderGridClass} key="placeholder-row">
        <RenderPlaceholders />
      </div>
    </>
  );
};

BuyItAgainLoadingPlaceholder.propTypes = {
  hideTitle: bool,
  title: string,
  txtPlaceholderLines: number
};

BuyItAgainLoadingPlaceholder.defaultProps = {
  hideTitle: false,
  txtPlaceholderLines: 3,
  title: '',
};
export { BuyItAgainLoadingPlaceholder };