import React from 'react';
import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { ProductPod, ProductPodMobile } from '@thd-olt-component-react/product-pod';
import { useStore } from '@thd-nucleus/experience-context';
import { CHANNEL } from '../constants/appConstant';

const ProductGrid = ({ itemId, storeId, channel, customerType }) => {

  const { membershipInformation } = useStore();

  const defaultVariables = {
    skipInstallServices: true,
    skipSpecificationGroup: false,
    skipKPF: true,
    skipSubscribeAndSave: true,
    loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership,
    storeId
  };

  const renderProductByChannel = (deviceType) => {
    if (CHANNEL.DESKTOP === deviceType) {
      return (
        <QueryProvider defaultVariables={defaultVariables} cacheKey="productpod-overview">
          <ProductPod
            itemId={itemId}
            storeId={storeId}
            swatchCount={1}
            podType="list"
            hideCompareBox
            showItemId
            showModelNumber
            showSkuNumber
            showStoreInfo
            showPreferredPricingBadge={customerType === 'B2B'}
          />
        </QueryProvider>
      );
    }
    return (
      <QueryProvider defaultVariables={defaultVariables} cacheKey="productpod-overview">
        <ProductPodMobile
          itemId={itemId}
          storeId={storeId}
          swatchCount={1}
          mobilePodType="singleColumn"
          hideCompareBox
          showModelNumber
          showSkuNumber
          showStoreInfo
          showPreferredPricingBadge={customerType === 'B2B'}
        />
      </QueryProvider>
    );
  };
  return (
    renderProductByChannel(channel)
  );
};

ProductGrid.displayName = 'ProductGrid';
ProductGrid.dataModel = extend({}, ProductPod);

export default ProductGrid;