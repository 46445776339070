import {
  arrayOf, bool, number, shape, string
} from 'prop-types';

import {
  CUSTOMER_TYPES,
  FULFILLMENT_METHODS,
  INVENTORY_SEARCH_ARRAY
} from '../../components/constants';
import {
  bopis,
  boss,
  getDeliveryZip,
  getExcludedStateName,
  isLocationInExcludedStates,
  safelyGet,
  sth
} from '../../components/helper/utils';
import { getStateFromZipcode } from './zipcode-helper';

export const productShape = shape({
  details: shape({
    collection: shape({
      name: string,
      url: string,
      type: string
    })
  }),
  media: {
    images: arrayOf(shape({
      subType: string,
      url: string,
    })),
  },
  identifiers: shape({
    parentId: string,
    itemId: string,
    productLabel: string,
    canonicalUrl: string,
    productUrl: string
  }),
  availabilityType: shape({
    discontinued: bool
  }),
  fulfillment: shape({
    bossExcludedShipStates: string,
    excludedShipStates: string,
  }),
  pricing: shape({
    unitOfMeasure: string,
    alternatePriceDisplay: bool,
    specialBuy: number,
    promotion: shape({
      dollarOff: number,
      percentageOff: number,
      // lowerPrice: bool
    }),
    alternate: shape({
      unit: shape({
        unitsPerCase: number,
        unitsOriginalPrice: number,
      })
    }),
  }),
  reviews: shape({
    ratingsReviews: shape({
      averageRating: string,
      totalReviews: string
    }),
  })
});

// This method is used to determine if we re-render the FulfillmentPodStore/Shipping component,
// or just pull from React.memo() cache.
export const isGraphQlDataEqual = (prevProps, nextProps) => {
  try {
    return !!((prevProps?.product?.itemId === nextProps?.product?.itemId)
    // eslint-disable-next-line max-len
    && (prevProps?.product?.fulfillment?.fulfillmentOptions?.length === nextProps?.product?.fulfillment?.fulfillmentOptions?.length));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return false;
  }
};

export const padWithZeros = (n) => {
  if (n
    && n.length
    && n.length === 4) {
    return n;
  }
  let storeNumber = '' + n;
  while (storeNumber.length < 4) {
    storeNumber = '0' + storeNumber;
  }
  return storeNumber;
};

export const isPickupService = (fulfillmentOption) => {
  return fulfillmentOption?.type === 'pickup';
};

export const getPickupService = (product) => {
  return (product?.fulfillment?.fulfillmentOptions || []).find(isPickupService);
};

export const isDeliveryService = (fulfillmentOption) => {
  return fulfillmentOption?.type === 'delivery';
};

export const getDeliveryService = (product) => {
  return (product?.fulfillment?.fulfillmentOptions || []).find(isDeliveryService);
};

export const getLocations = (service) => {
  return service?.locations || [];
};

export const getInventory = (service) => {
  return service?.locations[0]?.inventory;
};

const getQuantity = (service) => {
  return getInventory(service)?.quantity || 0;
};

// BOPIS
export const getBOPISFulfillment = (product) => {
  return (getPickupService(product)?.services || []).find((service) => service.type === 'bopis');
};

export const isBOPIS = (product) => {
  return !!getBOPISFulfillment(product);
};

export const isBuyInStore = (product) => {
  return !getPickupService(product)?.fulfillable;
};

export const isInStockYourStore = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));

  return !!(locations.find(
    (location) => location.isAnchor && location.inventory?.isInStock)
  );
};

export const isInStockNearby = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return !!(locations.find(
    (location) => !location.isAnchor && location.inventory?.isInStock)
  );
};

export const isLimitedStock = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return !!(locations.find(
    (location) => location.isAnchor && location.inventory?.isLimitedQuantity)
  );
};

export const isLimitedStockNearby = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return !!(locations.find(
    (location) => !location.isAnchor && location.inventory?.isLimitedQuantity)
  );
};

export const isOutOfStockYourStore = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return !!(locations.find(
    (location) => location.isAnchor && location.inventory?.isOutOfStock)
  );
};

export const getNearbyStoreWithLimitedStock = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return locations.find(
    (location) => !location.isAnchor && location.inventory?.isLimitedQuantity
  );
};

export const getNearbyStoreInStock = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return locations.find(
    (location) => !location.isAnchor && location.inventory?.isInStock
  );
};

export const isShowBuyInStoreNearBy = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  return !locations || locations.find(
    (location) => location?.isAnchor && location?.isBuyInStoreCheckNearBy
  );
};

export const getSellableQuantityYourStore = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  const store = locations.find(
    (location) => location.isAnchor
  );

  return store?.inventory?.quantity || 0;
};

export const getSellableQuantityNearbyStore = (product) => {
  const locations = getLocations(getBOPISFulfillment(product));
  const store = locations.find(
    (location) => !location.isAnchor && location.inventory?.isInStock
  );

  return store?.inventory?.quantity || 0;
};

// BOSS
export const getBOSSFulfillment = (product) => {
  return (getPickupService(product)?.services || []).find((service) => service.type === 'boss');
};

export const isBOSS = (product) => {
  return !!getBOSSFulfillment(product);
};

export const isOutOfStockBoss = (product) => {
  const locations = getLocations(getBOSSFulfillment(product));
  return !!(locations.find(
    (location) => location.inventory?.isOutOfStock)
  );
};

// STH
export const getSTHFulfillment = (product) => {
  return (getDeliveryService(product)?.services || []).find((service) => service.type === 'sth');
};

export const isSTH = (product) => {
  return !!getSTHFulfillment(product);
};

export const isSthInStock = (product) => {
  return getInventory(getSTHFulfillment(product))?.isInStock;
};

export const isSthOutOfStock = (product) => {
  return getInventory(getSTHFulfillment(product))?.isOutOfStock;
};

export const hasFreeShipping = (product) => {
  return getSTHFulfillment(product)?.hasFreeShipping;
};

export const getShippingThreshold = (product) => {
  return getSTHFulfillment(product)?.freeDeliveryThreshold;
};

// BODFS
export const getBODFSFulfillment = (product) => {
  return (getDeliveryService(product)?.services || []).find((service) => service.type === 'express delivery');
};

export const isBODFS = (product) => {
  return !!getBODFSFulfillment(product);
};

export const isBodfsWithQuantityAvailable = (product) => {
  return getQuantity(getBODFSFulfillment(product));
};

export const isBodfsWithQuantityUnknown = (product) => {
  return getInventory(getBODFSFulfillment(product)) === null;
};

export const isSameDayDelivery = (product) => {
  return getBODFSFulfillment(product)?.deliveryTimeline === 'TODAY';
};

export const isNextDayDelivery = (product) => {
  return getBODFSFulfillment(product)?.deliveryTimeline === 'TOMORROW';
};

export const isBrowseOnly = (product) => {
  const fulfillable = !!((product?.fulfillment?.fulfillmentOptions || []).find((option) => option.fulfillable));
  const availableOnline = product?.availabilityType?.type === 'Browse Only';

  return availableOnline && !fulfillable;
};

export const isCurrentlyUnavailable = (product) => {
  return !((product?.fulfillment?.fulfillmentOptions || [])
    .find((option) => (option.services || [])
      .find((service) => (service.locations || [])
        .find((location) => {
          let isInStock = location.inventory?.isInStock;
          let quantity = location.inventory?.quantity;
          return (isInStock || quantity)
            && !(isBuyInStore(product) && service.type === 'bopis');
        }))));
};

export const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

export const isOutOfStockOnline = (product) => {
  const { dataSources, fulfillment, availabilityType } = product;
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find(isDeliveryService);
  const sthFulfillment = getSTHFulfillment(product);
  if (dataSources === 'catalog' && !fulfillment?.fulfillmentOptions && availabilityType?.type === 'Online') {
    return true;
  }
  if (sthFulfillment) {
    return getInventory(sthFulfillment)?.isOutOfStock && !deliveryService?.fulfillable;
  }
  return false;
};

export const isAppliance = (product) => {
  return (product?.identifiers?.productType?.toUpperCase() === 'MAJOR_APPLIANCE');
};

export const applianceDeliveryThreshold = () => {
  return 396;
};

export const meetsApplianceDeliveryThreshold = (product) => {
  return product?.pricing?.original >= applianceDeliveryThreshold();
};

export const isAvailableAppliance = (product) => {

  const productInventory = getInventory(getSTHFulfillment(product));

  if (!productInventory) return true;

  const {
    quantity,
    isInStock
  } = productInventory;
  return quantity > 0 && isInStock;
};

export const isLiveGoods = (product) => {
  return !!(product?.info?.isLiveGoodsProduct);
};

export const isFulfillable = (product) => {
  return !!(product?.fulfillment?.fulfillmentOptions || []).find(
    (option) => option.fulfillable || isBodfsWithQuantityAvailable(product)
  );
};

export const isFulfillableIRGSku = (product) => {
  const {
    buyable, discontinued, status
  } = product?.availabilityType || {};

  return buyable && status && !discontinued;
};

// update bundling-product-tabs component to use isSthInStock(product) instead
export const isInStock = (product) => {
  const deliveryService = (product?.fulfillment?.fulfillmentOptions || []).find(isDeliveryService);
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');

  const locations = shippingFulfillment?.locations;

  if (locations?.length) {
    return !!(locations[0]?.inventory?.isInStock);
  }

  return false;
};

// ExcludedState functions
export const getDeliveryZipCookie = () => {
  return getDeliveryZip();
};

const getStateCode = (ffm, ffmType) => {
  if (ffmType === 'sth') {
    return getStateFromZipcode(getDeliveryZipCookie());
  }

  if (ffm && ffm.locations && ffm.locations[0]) {
    return ffm.locations[0].state;
  }

  return null;
};

const checkForExcludedState = (product, ffm) => {
  let excludedState = null;
  let ffmType = ffm?.type;
  let state = getStateCode(ffm, ffmType);
  const fulfillment = product?.fulfillment;

  if (state && fulfillment) {
    excludedState = (ffmType === 'boss')
      ? fulfillment.bossExcludedShipStates : fulfillment.excludedShipStates;

    if (excludedState && isLocationInExcludedStates(state, excludedState)) {
      excludedState = getExcludedStateName(state);
    } else {
      excludedState = '';
    }
  }

  return excludedState;
};

export const getExcludedStateBopis = (product) => {
  return checkForExcludedState(product, getBOPISFulfillment(product));
};

export const getExcludedStateBoss = (product) => {
  return checkForExcludedState(product, getBOSSFulfillment(product));
};

export const getExcludedStateSth = (product) => {
  return checkForExcludedState(product, getSTHFulfillment(product));
};

export const getExcludedStateBodfs = (product) => {
  return checkForExcludedState(product, getBODFSFulfillment(product));
};

export const getFulfillmentMethod = (product, hasInStoreFilter = false) => {
  if (hasInStoreFilter && (isInStockYourStore(product) || isInStockNearby(product)) && !isBuyInStore(product)) {
    return FULFILLMENT_METHODS.BOPIS;
  }
  if (isAppliance(product)) {
    return FULFILLMENT_METHODS.APPLIANCE;
  }
  if (isSTH(product) && !getExcludedStateSth(product) && !getInventory(getSTHFulfillment(product))?.isOutOfStock) {
    return FULFILLMENT_METHODS.STH;
  }
  if (
    isBOPIS(product)
    && !isShowBuyInStoreNearBy(product)
    && !isLimitedStockNearby(product)
    && !isLimitedStock(product)
    && (!isOutOfStockYourStore(product) || isInStockNearby(product))
    && !isBuyInStore(product)) {
    return FULFILLMENT_METHODS.BOPIS;
  }
  if (isBOSS(product) && !getExcludedStateBoss(product)) {
    return FULFILLMENT_METHODS.STORE;
  }
  if (isBODFS(product)) {
    return FULFILLMENT_METHODS.BODFS;
  }
  return FULFILLMENT_METHODS.STH;
};

export const formatDistance = (distance = 0) => {
  return distance.toFixed(1);
};

export const shouldShowShippingThreshold = (product, threshold) => {
  return product?.pricing?.value < threshold;
};

export const isBackordered = (product) => {
  return product?.fulfillment?.backordered;
};

export const isBackorderedWithDate = (product) => {
  return isBackordered(product) && product?.fulfillment?.backorderedShipDate;
};

const getLocationFromProduct = (product, ffmMethod) => {
  const ffmTypes = INVENTORY_SEARCH_ARRAY[ffmMethod];
  return safelyGet(() => (product.fulfillment.fulfillmentOptions)
    .find((option) => (option.type === ffmTypes[0] && option.services)).services
    .find((services) => (services.type === ffmTypes[1] && services.locations)).locations);
};

export const getAllFulfillmentInventories = (product) => {
  const fulfillmentMethods = {
    [FULFILLMENT_METHODS.STH]: 0,
    [FULFILLMENT_METHODS.BOPIS]: 0,
    [FULFILLMENT_METHODS.STORE]: 0,
  };

  if (isSTH(product)) {
    fulfillmentMethods[FULFILLMENT_METHODS.STH] += getQuantity(sth(product?.fulfillment));
  }

  if (isBOPIS(product)) {
    fulfillmentMethods[FULFILLMENT_METHODS.BOPIS] += getQuantity(bopis(product?.fulfillment));
  }

  if (isBOSS(product)) {
    fulfillmentMethods[FULFILLMENT_METHODS.STORE] += getQuantity(boss(product?.fulfillment));
  }

  return fulfillmentMethods;
};

export const getDisplayableInventory = (product, customer, ffmMethod) => {
  const eligibleFulfillments = [
    FULFILLMENT_METHODS.STH,
    FULFILLMENT_METHODS.BODFS,
    FULFILLMENT_METHODS.BOSS
  ];

  const eligibleCustomerType = [
    CUSTOMER_TYPES.B2B
  ];

  const validFulfillment = eligibleFulfillments.includes(ffmMethod);
  const validCustomerType = eligibleCustomerType.includes(customer?.type?.toUpperCase());
  const locationObject = getLocationFromProduct(product, ffmMethod);

  const displayableQty = validFulfillment && validCustomerType && !isBackordered(product)
  && locationObject?.[0]?.inventory?.quantity;

  return (displayableQty) || 0;
};
