import React, { useContext } from 'react';
import { number } from 'prop-types';
import { ProductPodContext } from '../../ProductPodContext';
import './ProductSponsored.style.scss';

export const ProductSponsored = ({ position }) => {
  const {
    sponsoredValues
  } = useContext(ProductPodContext);

  if (!sponsoredValues?.isSponsored) return null;

  return (
    <div
      className="product-pod__sponsored"
      id={'plp_pod_sponsored_' + position}
      data-onview-beacon={sponsoredValues?.sponsoredBeacon?.onViewBeacon}
      data-onclick-beacon={sponsoredValues?.sponsoredBeacon?.onClickBeacon}
    ><span className="sponsored-message">Sponsored</span>
    </div>
  );
};

ProductSponsored.displayName = 'ProductSponsored';

ProductSponsored.propTypes = {
  position: number
};

ProductSponsored.defaultProps = {
  position: null
};
