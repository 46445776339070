import React from 'react';
import PropTypes from 'prop-types';
import { Link, ListItem, Typography } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

const NavigationItem = ({
  componentId, componentPosition, link, title, heading
}) => {
  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'SideNavigation',
      component: 'SideNavigation',
      position: componentPosition,
      type: 'content'
    }
  });

  return (
    <ListItem
      key={componentId}
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
      // eslint-disable-next-line tailwindcss/no-arbitrary-value,tailwindcss/no-custom-classname
      className="side-navigation__li sui-flex sui-flex-row sui-items-start
              sui-pt-2 sui-px-[2px] sui-pb-1 sui-static sui-bg-primary sui-self-stretch sui-m-0"
    >
      <Typography variant={heading ? 'h5' : null} color={heading ? 'primary' : null}>
        {heading ? title : <Link href={link} color="subtle" underline="hover">{title}</Link>}
      </Typography>
    </ListItem>
  );
};

NavigationItem.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentPosition: PropTypes.number,
  link: PropTypes.string,
  title: PropTypes.string,
  heading: PropTypes.bool
};

NavigationItem.defaultProps = {
  componentPosition: 1,
  link: '',
  title: '',
  heading: false
};

NavigationItem.displayName = 'NavigationItem';

export { NavigationItem };
