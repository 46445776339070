import React from 'react';
import {
  params,
  shape as shapeType,
  string,
  useDataModel,
  arrayOf as arrayType,
} from '@thd-nucleus/data-sources';
import { string as stringType, number } from 'prop-types';
import { Typography, Pill, Card } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { track } from './related-search-analytics';

const PopularCategories = ({ popCatId, componentId }) => {
  const {
    data,
    loading,
    error
  } = useDataModel('emtPopularCategories', {
    variables: {
      id: popCatId.length < 4 ? `0${popCatId}` : popCatId,
    }
  });

  const navigationList = data?.emtPopularCategories?.popularCategoryList;

  if (!data || loading || error || !navigationList?.length) {
    return null;
  }

  return (
    <div className="popular-categories sui-w-full" data-component="PopularCategories">
      <Card
        className="sui-h-full sui-leading-none"
        style={{ background: 'transparent', borderStyle: 'none' }}
      >
        <Typography variant="h2">Popular Categories</Typography>
        <div
          className="popular-categories__links sui-w-full sui-flex
          sui-flex-row sui-flex-wrap sui-leading-tight sui-gap-4"
        >
          {navigationList?.map((popCat, index) => (
            <PopularCategory
              key={`popcat-${popCat?.title}${index}`}
              componentId={componentId}
              url={popCat.paramLink}
              copyText={popCat?.title}
              index={index}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

const PopularCategory = ({
  copyText,
  url,
  componentId,
  index
}) => {
  const handlePopularCategoryClick = () => {
    if (window?.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('popular-category.UPDATE', { updatedSearchTerm: '' });
    }
    track(copyText);
  };

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'PopularCategory',
      component: 'PopularCategory',
      position: index + 1,
      type: 'content',
      category: copyText?.replace(/\W+/g, '')
    },
  });

  return (
    <div
      className="popular-categories__elements sui-bg-primary sui-rounded-full"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <Pill
        label={copyText}
        component="a"
        href={url}
        onClick={() => handlePopularCategoryClick(copyText)}
        clickable
      />
    </div>
  );
};

PopularCategories.displayName = 'PopularCategories';

PopularCategories.propTypes = {
  popCatId: stringType.isRequired,
  componentId: stringType
};

PopularCategories.defaultProps = {
  componentId: ''
};

PopularCategory.propTypes = {
  copyText: stringType.isRequired,
  url: stringType.isRequired,
  componentId: stringType.isRequired,
  index: number.isRequired
};

PopularCategories.dataModel = {
  emtPopularCategories: params({ id: string().isRequired() }).shape({
    popularCategoryList: arrayType(shapeType({
      title: string(),
      link: string(),
      paramLink: string()
    }))
  })
};

export { PopularCategories };
