import React from 'react';
import { string, bool } from 'prop-types';
import { getShipModeDetails } from '../../helpers/utils';

const getDeliveryDatePrice = ({ isDeliveryTimelineTodayOrTomorrow, deliveryDateAvailability }) => {
  let displayDeliveryDatePrice = '';
  if (isDeliveryTimelineTodayOrTomorrow) {
    displayDeliveryDatePrice = `As soon as ${deliveryDateAvailability.toLowerCase()}`;
  } else {
    displayDeliveryDatePrice = 'Get it faster';
  }

  return displayDeliveryDatePrice;
};

const getPriceToShow = ({ shipCharge }) => {
  if (shipCharge === '$0.00' || shipCharge.toUpperCase() === 'FREE') {
    return (<span className="free_shipping">FREE</span>);
  }
  return `${shipCharge}/order`;
};

const DeliverFromStore = ({
  isDeliveryTimelineTodayOrTomorrow,
  deliveryDateAvailability,
  shipCharge
}) => (
  <>
    <div className="datePriceRow">
      <span className="left date dots">
        {getDeliveryDatePrice({
          isDeliveryTimelineTodayOrTomorrow,
          deliveryDateAvailability
        })}
      </span>

      <span className="right price">
        <span className="" />
        {getPriceToShow({ shipCharge })}
      </span>

      <div className="shipModeRow">
        <div className="shipmentModeDescription">
          {getShipModeDetails({
            isDeliveryTimelineTodayOrTomorrow,
            deliveryDateAvailability
          })}
        </div>
      </div>

    </div>
  </>
);

DeliverFromStore.propTypes = {
  deliveryDateAvailability: string,
  isDeliveryTimelineTodayOrTomorrow: bool,
  shipCharge: string
};

DeliverFromStore.defaultProps = {
  deliveryDateAvailability: null,
  isDeliveryTimelineTodayOrTomorrow: null,
  shipCharge: null
};

export { DeliverFromStore };
