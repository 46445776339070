import React, { useContext } from 'react';
import { Button } from '@thd-olt-component-react/core-ui';
import { ProductPodContext } from '../../ProductPodContext';

export const ProductRemove = () => {
  const { product, onRemove } = useContext(ProductPodContext);
  const { itemId } = product || {};

  return (
    <>
      <div className="product-pod__remove-action">
        <Button onClick={() => { onRemove(itemId); }} remove link>
          Remove
        </Button>
      </div>
    </>
  );
};

ProductRemove.displayName = 'ProductRemove';
