import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '@thd-olt-component-react/core-ui';
import { extend } from '@thd-nucleus/data-sources';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import {
  PodSection,
  PodSpacer,
  PodSticker,
  ProductATC,
  ProductBadge,
  ProductCustomOptions,
  ProductIdentifier,
  ProductImage,
  ProductRating,
  ProductSample,
  ProductPod
} from '@thd-olt-component-react/product-pod';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';

const CustomMobileProductPod = ({
  index, itemId, showFavoritesCount, storeId, storeZip
}) => {
  return (
    <ProductPod
      analyticsData={{
        parent: 'DPD', position: index
      }}
      itemId={itemId}
      storeId={storeId}
      render={(pod) => {
        return (
          <>
            <PodSection noPadding>
              <Col xs={5}>
                <PodSticker position="top-left">
                  <ProductBadge itemId={pod.itemId} storeId={storeId} />
                </PodSticker>
                <PodSticker position="top-right">
                  <AddToList itemId={pod.itemId} showCount={showFavoritesCount} showIconButton />
                </PodSticker>
                <ProductImage
                  itemId={pod.itemId}
                  showSecondaryImage={pod.showSecondaryImage}
                />
                <ProductSample itemId={pod.itemId} />
                <ProductCustomOptions itemId={pod.itemId} />
              </Col>
              <Col xs={7}>
                <PodSpacer padding={['ALL']}>
                  <ProductHeader
                    brand="above"
                    itemId={pod.itemId}
                    brandTitleMaxLine={4}
                    xSmall
                  />
                </PodSpacer>
                <SuperSkuMiniSwatches
                  itemId={pod.itemId}
                  storeId={storeId}
                  onChange={pod.onChange}
                  onHover={pod.onSSKUHover}
                  numberOfMiniSwatches={3}
                />
                <PodSpacer padding={['LEFT', 'RIGHT', 'TOP']}>
                  <ProductRating itemId={pod.itemId} />
                </PodSpacer>
                <Price
                  itemId={pod.itemId}
                  large={false}
                  storeId={pod.storeId}
                  displayEachUom={false}
                  onSavingsCenterToggle={pod.onSavingsCenterToggle}
                />
              </Col>
            </PodSection>
          </>
        );
      }}
    />
  );
};

CustomMobileProductPod.dataModel = extend(
  AddToList,
  Price,
  ProductATC,
  ProductBadge,
  ProductCustomOptions,
  ProductHeader,
  ProductIdentifier,
  ProductImage,
  ProductPod,
  ProductRating,
  ProductSample,
  SuperSkuMiniSwatches,
  FulfillmentPodShipping,
  FulfillmentPodStore
);

CustomMobileProductPod.propTypes = {
  index: PropTypes.number,
  itemId: PropTypes.string.isRequired,
  showFavoritesCount: PropTypes.bool,
  storeId: PropTypes.string,
  storeZip: PropTypes.string
};

CustomMobileProductPod.defaultProps = {
  index: 1,
  showFavoritesCount: false,
  storeId: '121',
  storeZip: undefined
};

export { CustomMobileProductPod };
