import React, { useContext } from 'react';
import { Col, Image } from '@thd-olt-component-react/core-ui';

import { ProductPodContext } from '../../ProductPodContext';
import './ProductSample.style.scss';

export const ProductSample = () => {
  const {
    channel,
    product
  } = useContext(ProductPodContext);

  const {
    identifiers,
    info
  } = product || {};

  const {
    canonicalUrl = '',
  } = identifiers || {};

  const sampleAvailable = info?.samplesAvailable;
  const target = channel === 'mobile' ? '_self' : '_blank';

  if (!sampleAvailable) return null;

  return (
    <Col nopadding>
      <a
        className="sampleWrapper"
        href={canonicalUrl}
        target={target}
      >
        <div className="sampleWrapper__icon">
          <Image
            src="https://assets.thdstatic.com/images/v1/more-options.svg"
            alt="more-options-icon"
            stretchy
            width={42}
            height={31}
          />
        </div>
        <span className="sampleWrapper__title">Samples Available</span>
      </a>
    </Col>
  );
};

ProductSample.displayName = 'ProductSample';

export default ProductSample;
