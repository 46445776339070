/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useEffect } from 'react';
import { number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf,
  customType,
  typename,
  params,
  shape,
  namedFragment,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { withImpressions } from '../hocs/withImpressions';
import { MedioComposableBannerRenderer } from './MedioComposableBannerRenderer';

const MedioComposableBanner = withImpressions(
  ({ componentClass, componentId, componentPosition }) => {
    const { ref, clickID } = useImpression({
      data: {
        id: componentId,
        name: MedioComposableBanner.displayName,
        component: MedioComposableBanner.displayName,
        position: componentPosition,
        type: 'content',
      },
    });

    useEffect(() => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('medio-composable-banner.ready');
    }, []);

    const { data, loading, error } = useDataModel('component', {
      variables: {
        componentClass,
        id: componentId,
      },
    });

    if (!data || loading || error) {
      return null;
    }

    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        data-component-id={componentId}
        data-component-name="MedioComposableBanner"
        data-component-position={componentPosition}
        data-component="MedioComposableBanner"
        id={`${componentClass}-${componentPosition}-${componentId}`}
      >
        <MedioComposableBannerRenderer data={data.component} />
      </div>
    );
  });

MedioComposableBanner.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
};

MedioComposableBanner.defaultProps = {
  componentClass: '',
  componentPosition: 1,
};

MedioComposableBanner.displayName = 'MedioComposableBanner';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1',
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: string(),
      })
    ),
  }),
  damDownloadedContent: shape({
    url: string(),
  }),
  __typename: typename('DamMedia'),
});

MedioComposableBanner.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['MedioComposableBanner']).isRequired(),
  }).shape({
    MedioComposableBanner: namedFragment({
      inline: true,
      fragmentType: 'MedioComposableBanner',
    }).shape({
      componentName: string(),
      id: string(),
      image: DamMediaFragment,
      altText: string(),
      smallMedia: DamMediaFragment,
      altTextSmallMedia: string(),
      title: string(),
      subtitle: string(),
      cta: string(),
      destinationUrl: string(),
      legalCopy: shape({
        legalText: string(),
        legalUrl: string(),
      }),
    }),
  }),
};

export { MedioComposableBanner };
