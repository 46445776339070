import React from 'react';
import classNames from 'classnames/bind';
import paintModals from '../../../../data/paint-modals.json';
import styles from './Footer.module.scss';

const { calculateSize } = paintModals;
const cx = classNames.bind(styles);
export const Footer = () => {

  return (
    <div className={cx('footer-disclaimer')}>
      PLEASE NOTE: {calculateSize.disclaimer}
    </div>
  );
};

Footer.displayName = 'Footer';
