export const PROJECT_GUIDE = 'Project Guide';
export const BUYING_GUIDE = 'Buying Guide';
export const INSTALLATION_SERVICES = 'Installation Services';
export const TOOL_RENTAL = 'Tool Rental';
export const FULL_CARD = 'full';
export const RENTAL_CARD = 'rental';

export function getCardType(type) {
  switch (type) {
  case PROJECT_GUIDE:
    return 'guide';
  case BUYING_GUIDE:
    return 'guide';
  case INSTALLATION_SERVICES:
    return 'install';
  case TOOL_RENTAL:
    return 'rental';
  default:
    return null;
  }
}
