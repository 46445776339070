import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ListItem = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const classes = classNames(props.className);
  // eslint-disable-next-line react/destructuring-assignment
  return <li className={classes}>{props.children}</li>;
};

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  className: PropTypes.string
};

ListItem.defaultProps = {
  className: null
};

export default ListItem;
