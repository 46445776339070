import { useDataModel } from '@thd-nucleus/data-sources';

export const useCategoryCard = ({
  clientStore,
  store,
  storeId,
  keyword,
  navParam,
  propLoading,
  propData,
  propError,
  ssr
}) => {
  const { storeId: defaultStoreId } = clientStore || store;
  const variables = {
    storeId: storeId || defaultStoreId,
  };
  if (keyword) {
    variables.keyword = keyword;
  }
  if (navParam) {
    variables.navParam = navParam;
  }
  const isPropDriven = typeof propLoading !== 'undefined';
  const skip = (propData || propLoading || propError) || (!keyword && !navParam && !isPropDriven);
  let { data, loading, error } = useDataModel('searchModel', {
    variables,
    skip,
    ssr
  });

  if (propData || propLoading || propError) {
    data = propData;
    loading = propLoading;
    error = propError;
  }
  return { data, loading, error };
};
