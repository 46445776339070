/* eslint-disable react/no-unknown-property */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import { ProductPodContext } from './ProductPodContext';
import { ProductPodGroupContext } from './ProductPodGroupContext';
import { ProductPodPlaceholder } from './ProductPodPlaceholder';

import { productPodClasses } from './utils';
import { ProductDefault } from './subcomponents/product-default/ProductDefault';
import { ProductHorizontalMobile } from './subcomponents/product-horizontal/product-horizontal-mobile';
import { ProductList } from './subcomponents/product-list/ProductList';
import { ProductMini } from './subcomponents/product-mini/ProductMini';
import { ProductMobileSingleColumn } from './subcomponents/product-mobile/ProductMobileSingleColumn';
import { ProductPodGrouped } from './subcomponents/product-pod-grouped/ProductPodGrouped';
import { ProductSideBySide } from './subcomponents/product-side-by-side/ProductSideBySide';
import { ProductSimple } from './subcomponents/product-simple/ProductSimple';

import {
  ProductPodPropTypes,
  ProductPodDefaultProps,
  getProductPodContextValue
} from './Helper';
import { publish } from '../analytics';
import { PRODUCT_POD_LOAD } from './constants';
import { dataModel } from './dataModel';
import { usePreFetchTimer } from './usePreFetchTimer';
import './product-pod.scss';

export const ProductPodMobile = (props) => {
  const {
    channelFromTest,
    className,
    hover,
    hoverDelay,
    installationServices,
    isEtchOne,
    itemId,
    keyword,
    miniOptions,
    mobilePodType,
    onError,
    onHover,
    onHoverExit,
    podType,
    preFetchProductData,
    showDpdPod,
    showExactPriceOnLoad,
    showSubscribeAndSave,
    startingQuantity,
    storeId,
    testId,
    zipCode,
    showKPF,
    ignoreAnalytics,
    hideCompareBox,
    position
  } = props;

  const { channel, hosts, dataType } = useContext(ExperienceContext);
  const { handleProductPodExpansion, setOverlayState } = useContext(ProductPodGroupContext);

  const [state, setState] = useState({
    buyMoreSaveMore: false,
    itemId,
    isHovered: false,
    podInteractionNumber: 1,
    selectedItemId: null,
    showExactPrice: showExactPriceOnLoad,
    previousItemId: null,
    itemIdForSubComponents: itemId,
    canonicalUrl: null,
    quantity: startingQuantity,
    sponsoredValues: null,
    isTableView: dataType === 'expanded-product-data'
  });

  const productRef = useRef(null);

  const itemIdProp = state.selectedItemId || itemId;

  const { data, loading, error } = useDataModel('product', {
    variables: {
      itemId: itemIdProp,
      storeId,
      zipCode,
      skipInstallServices: !installationServices,
      skipKPF: !showKPF,
      skipSubscribeAndSave: !showSubscribeAndSave
    },
    skip: !itemIdProp,
    fetchPolicy: state.fetchPolicy
  });

  if (data) {
    productRef.current = data;
  }

  useEffect(() => {
    return () => {
      // On Component un mounting close see similar item window.
      if (setOverlayState) {
        setOverlayState(false);
      }
    };
  }, []);

  useEffect(() => {
    if (data?.product) {
      if (!data.product.itemId && onError) {
        onError({ data, loading, error });
      }
      if (data.product.info?.isSponsored) {
        publish(PRODUCT_POD_LOAD, { sponsoredProduct: data.product.info.isSponsored });
      }
      setState({
        ...state,
        itemIdForSubComponents: data.product?.itemId,
        canonicalUrl: data.product.identifiers?.canonicalUrl,
        sponsoredValues: {
          isSponsored: state.sponsoredValues?.isSponsored || data.product.info?.isSponsored,
          sponsoredBeacon: state.sponsoredValues?.sponsoredBeacon || data.product.info?.sponsoredBeacon,
          sponsoredMetadata: state.sponsoredValues?.sponsoredMetadata || data.product.info?.sponsoredMetadata
        }
      });
    }
  }, [data?.product]);

  useEffect(() => {
    if (error && onError) {
      onError({ data, loading, error });
    }
  }, [error]);

  const impressionContextData = useImpression({ data: {
    id: itemId,
    name: 'ProductPod',
    component: 'ProductPod',
    position: position + 1,
    type: 'product'
  } });
  const productPodRef = impressionContextData.ref;

  if (mobilePodType === 'singleColumn' && loading && !data) return <ProductPodPlaceholder podsCount={1} />;
  if ((error || loading) && !data && !productRef?.current) return null;

  const { product } = data || productRef?.current || {};
  if (!product) return null;
  if (!product.itemId) return null;

  const {
    disabled,
    onAction,
    enableATC,
    silentATC,
    onlyShowIfFulfillable,
    inCartQuantity,
    callToActionSection,
    showBadge,
    showBundleDiscount,
    ctaLocation = 'bottom',
    hideFulfillment,
    hideCaret,
    ctaNoPadding
  } = miniOptions || {};

  const context = getProductPodContextValue({
    props,
    channel,
    state,
    hosts,
    productRef,
    productPodRef,
    handleProductPodExpansion,
    product,
    setState,
    setOverlayState
  });

  const { handleTouchEnd, handleTouchStart } = context;

  return (
    <div
      className={productPodClasses({
        channel: 'mobile',
        className,
        hover,
        podType,
        mobilePodType
      })}
      data-testid={testId}
      data-automation-id="podnode"
      ref={productPodRef}
      clickid={impressionContextData.clickID}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      data-type="product"
    >
      <meta data-prop="productID" content={itemId} />
      <ProductPodContext.Provider value={context}>
        {podType === 'horizontal' && <ProductHorizontalMobile channelFromTest={channelFromTest} />}
        {podType === 'simple' && <ProductSimple />}

        {podType === 'list' && (
          <ProductList
            channelFromTest={channelFromTest}
            keyword={keyword}
            hideCompareBox={hideCompareBox}
          />
        )}
        {podType === 'grouped' && <ProductPodGrouped channel={channel} />}
        {podType === 'mini' && (
          <ProductMini
            disabled={disabled}
            onAction={onAction}
            enableATC={enableATC}
            silentATC={silentATC}
            onlyShowIfFulfillable={onlyShowIfFulfillable}
            inCartQuantity={inCartQuantity}
            callToActionSection={callToActionSection}
            showBadge={showBadge}
            showBundleDiscount={showBundleDiscount}
            showKPF={showKPF}
            ctaLocation={ctaLocation}
            hideFulfillment={hideFulfillment}
            hideCaret={hideCaret}
            ctaNoPadding={ctaNoPadding}
          />
        )}
        {podType === 'sidebyside' && <ProductSideBySide />}
        {mobilePodType === 'singleColumn' ? (
          <ProductMobileSingleColumn
            isEtchOne={isEtchOne}
            showDpdPod={showDpdPod}
            showKPF={showKPF}
          />
        ) : (podType === 'default' && <ProductDefault hideCompareBox={hideCompareBox} />)}
      </ProductPodContext.Provider>
    </div>
  );
};

ProductPodMobile.propTypes = ProductPodPropTypes;

ProductPodMobile.defaultProps = ProductPodDefaultProps;

ProductPodMobile.dataModel = dataModel;

ProductPodMobile.displayName = 'ProductPodMobile';
