import React from 'react';
import { ListItem } from '@thd-olt-component-react/list';
import { node, string } from 'prop-types';
import classNames from 'classnames';

const SalientListItem = (props) => {
  const { children, className, point } = props;
  const content = point || children;
  const classes = classNames(className, 'list__item');

  return (
    <ListItem className={classes}>
      {content}
    </ListItem>
  );
};

SalientListItem.displayName = 'SalientListItem';

SalientListItem.propTypes = {
  // Additional salient points apart from productHighlights
  children: node,
  // Additional class names
  className: string,
  // A salient point featuring productHighlights
  point: string
};

SalientListItem.defaultProps = {
  children: null,
  className: null,
  point: null
};

export { SalientListItem };
