import React, { useEffect, useState, useContext, useCallback } from 'react';
import PropTypes, {
  bool, string, func, number, arrayOf
} from 'prop-types';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import Script from 'react-load-script';
import classNames from 'classnames';
import { useDataModel } from '@thd-nucleus/data-sources';
import { SubscribeAndSaveDefault } from './SubscribeAndSaveDefault';
import { SubscribeAndSaveLite } from './SubscribeAndSaveLite';
import { Message } from './message/Message';
import { Frequency } from './frequency/Frequency';
import { Events } from './Events';
import { dataModel } from './dataModel';
import {
  update,
  init,
  setOgFrequency,
  handleError
} from '../order-groove/order-groove';
import './subscribe-and-save.scss';

const SubscribeAndSave = ({
  itemId, quantity, fulfillment, onChange, lite, hideForProductTypes
}) => {
  const DEFAULT_FREQUENCY = '6';
  const DEFAULT_DISCOUNT = '5';
  const FULFILLMENT_METHOD_STH = 'ShipToHome';
  const [frequency, setFrequency] = useState('6_3');
  const [newFrequency, setNewFrequency] = useState('');
  const [iQuantity, setIQuantity] = useState(quantity);
  const [optIn, setOptIn] = useState(false);
  const { channel, hosts } = useContext(ExperienceContext);
  const { orderGroove } = hosts || {};
  const storeId = useStoreId();
  const ogRef = useCallback((node) => {
    if (node !== null) {
      if (node.querySelector('.og-widget')) setOgFrequency({ itemId, frequency });
    }
  });
  const isDisabled = fulfillment !== FULFILLMENT_METHOD_STH;
  const classes = classNames('subscribe-and-save', {
    'subscribe-and-save--disabled': isDisabled,
    'subscribe-and-save--lite': lite
  });

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('subscribe-and-save.ready'); }, []);

  const isShipToHome = (product) => {
    const deliveryService = (product?.fulfillment?.fulfillmentOptions || [])
      .find((option) => option.type === 'delivery');
    const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');

    return !!shippingFulfillment;
  };

  const scriptErrorHandler = () => {
    handleError(new Error('OG script failed to load.'));
  };

  const scriptLoadHandler = () => {
    init({ itemId, quantity: iQuantity });
  };

  const handleQuantityChange = (qty) => {
    setIQuantity(qty);
  };

  const { data, loading, error } = useDataModel('product', {
    ssr: false,
    variables: {
      itemId,
      storeId
    }
  },
  );

  if (!data || loading || (error && !data)) {
    return null;
  }

  const { product } = data;

  if (!product || (hideForProductTypes.includes(product?.info?.productSubType?.name))) return null;

  const { defaultfrequency, discountPercentage, subscriptionEnabled } = product?.subscription || {};
  const { value } = product?.pricing || {};
  const shipToHome = isShipToHome(product);

  if (!subscriptionEnabled || !shipToHome || !value) return null;

  const getOgFrequencyFromValue = (ogValue) => {
    const NO_FREQUENCY = '0_0';
    const frequencyMap = {
      1: '1_3',
      2: '2_3',
      3: '3_3',
      4: '4_3',
      5: '5_3',
      6: '6_3',
      9: '9_3',
      12: '12_3'
    };

    return ogValue ? frequencyMap[ogValue] : NO_FREQUENCY;
  };

  const handleFrequencyChange = (frequencyValue) => {
    setFrequency(frequencyValue);
    update({ itemId, quantity: iQuantity, frequency: frequencyValue });
    setOptIn(true);
    onChange({ frequency: frequencyValue, isOptIn: true });
  };

  const handleOptionChange = (optionsValue) => {
    const ogFrequency = getOgFrequencyFromValue(defaultfrequency);

    if (!newFrequency) {
      setNewFrequency(ogFrequency);
      setFrequency(ogFrequency);
      setOptIn(optionsValue);
      onChange({ frequency: ogFrequency, isOptIn: optionsValue });

    } else {
      setOptIn(optionsValue);
      onChange({ frequency, isOptIn: optionsValue });
    }
  };

  const discountedPrice = () => {
    const discount = discountPercentage || DEFAULT_DISCOUNT;
    return (((100 - discount) / 100) * value).toFixed(2);
  };

  return (
    <>
      <Events itemId={itemId} subscribed={optIn} frequency={frequency} onQuantityChange={handleQuantityChange} />
      <div className={classes} data-testid="subscribe-and-save">
        <div id="subscribe-and-save-options">
          {lite
            ? (
              <SubscribeAndSaveLite
                disabled={isDisabled}
                discount={discountPercentage || DEFAULT_DISCOUNT}
                itemId={itemId}
                subscribed={optIn}
                onChange={handleOptionChange}
              />
            )
            : (
              <SubscribeAndSaveDefault
                disabled={isDisabled}
                discount={discountPercentage || DEFAULT_DISCOUNT}
                discountPrice={discountedPrice()}
                itemId={itemId}
                subscribed={optIn}
                onChange={handleOptionChange}
              />
            )}
        </div>
        {(!lite || isDisabled) && (
          <div id="subscribe-and-save-message">
            <Message
              channel={channel}
              discount={discountPercentage || DEFAULT_DISCOUNT}
              disabled={isDisabled}
            />
          </div>
        )}
        {!lite && (
          <div id="subscribe-and-save-frequency">
            <Frequency
              defaultFrequency={defaultfrequency || DEFAULT_FREQUENCY}
              onChange={handleFrequencyChange}
              disabled={isDisabled}
            />
          </div>
        )}
      </div>
      <div className="og-offer" data-og-module="pdp_nocontent" data-og-product="*" ref={ogRef} />
      <Script
        url={`${orderGroove}/379dc8f09a4311e7806bbc764e106cf4/main.js`}
        onError={scriptErrorHandler}
        onLoad={scriptLoadHandler}
      />
    </>
  );
};

SubscribeAndSave.displayName = 'SubscribeAndSave';

SubscribeAndSave.propTypes = {
  /** The itemId for the subscription */
  itemId: string.isRequired,
  /** onChange callback for the frequency and optIn selection changes */
  onChange: func.isRequired,
  /** currently selected fulfillment option */
  fulfillment: string.isRequired,
  /** currently selected quantity */
  quantity: number,
  /** hide for Product Types (i.e. HDQC) */
  hideForProductTypes: arrayOf(PropTypes.string),
  /** To show SubscribeAndSaveLite component */
  lite: bool
};

SubscribeAndSave.defaultProps = {
  quantity: 1,
  lite: false,
  hideForProductTypes: []
};

SubscribeAndSave.dataModel = dataModel;

export { SubscribeAndSave };
