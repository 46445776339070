import React from 'react';
import PropTypes from 'prop-types';

import { extend } from '@thd-nucleus/data-sources';
import { Col, Row } from '@thd-olt-component-react/grid';

import { StandardDimension } from '../Dimensions/StandardDimension';

export const handleRecordCount = (dimension, isStoreDisplay) => {
  const { refinements = [] } = dimension || {};
  if (!refinements.length) return null;

  const singleRefinement = { ...dimension, refinements: [dimension.refinements[0]] };
  const recordCount = parseInt(singleRefinement.refinements[0]?.recordCount, 10) || 0;

  if (recordCount === 0 && !isStoreDisplay) return null;

  return singleRefinement;
};

export const MainFilter = ({
  dimensions,
  enableMultiStore,
  multiStoreSelection,
  onChange,
  onDisplayData,
  onMultiStoreSelectionChange
}) => {

  const dimensionArray = dimensions.filter((dimension) => dimension.label.toUpperCase() === 'GET IT FAST');

  const [dimension] = dimensionArray;

  const singleRefinement = handleRecordCount(dimension, Boolean(onDisplayData?.isStoreDisplay));
  if (!singleRefinement) return null;
  if (onDisplayData?.isStoreDisplay === 'true') return null;

  return (
    <div className="main-filter" data-testid="main-filter">
      <Row className="filter-and-sort--top">
        <Col nopadding className="filter-and-sort--top__col">
          <StandardDimension
            dimension={singleRefinement}
            enableMultiStore={enableMultiStore}
            key={dimension.dimensionId}
            mini
            multiStoreSelection={multiStoreSelection}
            onChange={onChange}
            onDisplayData={onDisplayData}
            onMultiStoreSelectionChange={onMultiStoreSelectionChange}
          />
        </Col>
      </Row>
    </div>
  );
};

MainFilter.propTypes = {
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    isStoreDisplay: PropTypes.string,
    itemCount: PropTypes.string,
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func
  }),
  onMultiStoreSelectionChange: PropTypes.func,
};

MainFilter.defaultProps = {
  enableMultiStore: false,
  multiStoreSelection: null,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  onMultiStoreSelectionChange: null
};

MainFilter.displayName = 'MainFilter';

MainFilter.dataModel = extend(StandardDimension);
