import React, { useContext } from 'react';
import {
  string, arrayOf, shape, bool, func
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Drawer, DrawerPosition
} from '@thd-olt-component-react/drawer';
import { PrimaryFilterDrawerDimension } from './PrimaryFilterDrawerDimension';

const PrimaryFiltersDrawer = ({
  primaryFiltersDimensions,
  appliedDimensions,
  isOpen,
  activeDimension,
  toggleDrawer,
  onRefinementChange,
  onDimensionChange,
  onApply,
  canonicalUrl,
  baseUrl
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const cancel = () => {
    toggleDrawer(false, activeDimension);
  };

  const handleOnApply = () => {
    onApply();
    toggleDrawer(false, activeDimension);
  };

  const handleDimensionChange = ({ refinement, dimension }) => {
    onDimensionChange({ refinement, dimension });
    toggleDrawer(false, activeDimension);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={cancel}
      initialItem={activeDimension}
      position={DrawerPosition.LEFT}
      maxSize={isMobile ? 100 : 420}
      isMobile={isMobile}
      isFlex
      zIndex={107159}
    >
      {(primaryFiltersDimensions || []).map((dimension) => {
        return (
          <PrimaryFilterDrawerDimension
            name={dimension.label}
            key={`primary-filter-item${dimension.label}`}
            dimension={dimension}
            appliedDimensions={appliedDimensions}
            isMobile={isMobile}
            onCancel={cancel}
            onApply={handleOnApply}
            onDimensionChange={handleDimensionChange}
            handleOnRefinementChange={onRefinementChange}
            canonicalUrl={canonicalUrl}
            baseUrl={baseUrl}
          />
        );
      })}
    </Drawer>
  );
};

PrimaryFiltersDrawer.displayName = 'PrimaryFiltersDrawer';
PrimaryFiltersDrawer.propTypes = {
  baseUrl: string.isRequired,
  canonicalUrl: string.isRequired,
  primaryFiltersDimensions: arrayOf(shape({
    label: string
  })).isRequired,
  appliedDimensions: arrayOf(shape({})).isRequired,
  isOpen: bool.isRequired,
  activeDimension: string.isRequired,
  toggleDrawer: func.isRequired,
  onRefinementChange: func.isRequired,
  onDimensionChange: func.isRequired,
  onApply: func.isRequired
};
export { PrimaryFiltersDrawer };