import React from 'react';
import PropTypes from 'prop-types';
import {
  Link
} from '@one-thd/sui-atomic-components';

const HrefLink = ({ linkItem }) => {
  return (
    <div key={linkItem.label}>
      <Link
        key={linkItem.label}
        href={linkItem.href}
        target="_blank"
        color="subtle"
        underline={linkItem.href ? 'always' : 'none'}
      >
        {linkItem.label}
      </Link>
      <br />
    </div>
  );
};

HrefLink.propTypes = {
  linkItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
  }).isRequired
};

HrefLink.displayName = 'HrefLink';

export default HrefLink;