import React from 'react';
import './delivery-options-drawer.scss';
import { Drawer, DrawerItem, DrawerNav } from '@thd-olt-component-react/drawer';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import PropTypes from 'prop-types';

export const DeliveryContentDrawerLoader = ({ channel, setIsQuickViewOpen }) => {

  return (
    <Drawer
      open
      onClose={() => setIsQuickViewOpen(false)}
      initialItem="delivery-quick-view"
      position="right"
      maxSize={channel === 'Mobile' ? 100 : 500}
      isMobile={channel === 'Mobile'}
    >
      <DrawerItem name="delivery-quick-view">
        <div className={`DeliveryDrawer__wrapper ${channel}`}>
          <DrawerNav>
            <Placeholder type="rect" height="20px" width="235px" />
          </DrawerNav>
          <div className={`DeliveryDrawer__divider ${channel}`} />
          <div className={`DeliveryDrawer__body ${channel}`} style={{ overflowX: 'hidden' }}>
            <div className={`DeliveryDrawer__deliveryType ${channel}`} style={{ marginBottom: 10 }}>
              <Placeholder type="text" className="custom-text-row" height="30px" />
              <div className={`DeliveryDrawer__cardsContainer ${channel}`}>
                <Placeholder
                  type="text"
                  className="custom-text-row"
                  height="20px"
                  width="235px"
                />
                <Placeholder type="text" className="custom-text-block" height="20px" />
                <Placeholder type="text" className="custom-text-block" height="20px" />
              </div>
            </div>

            <hr />

            <div className={`DeliveryDrawer__deliveryType ${channel}`} style={{ marginBottom: 10 }}>
              <Placeholder type="text" className="custom-text-row" height="30px" />
              <div className={`DeliveryDrawer__cardsContainer ${channel}`}>
                <Placeholder
                  type="text"
                  className="custom-text-row"
                  height="20px"
                  width="235px"
                />
                <Placeholder type="text" className="custom-text-block" height="20px" />
                <Placeholder type="text" className="custom-text-block" height="20px" />
              </div>
            </div>
          </div>
        </div>
      </DrawerItem>
    </Drawer>
  );
};

export default DeliveryContentDrawerLoader;

DeliveryContentDrawerLoader.propTypes = {
  channel: PropTypes.string,
  setIsQuickViewOpen: PropTypes.func
};

DeliveryContentDrawerLoader.defaultProps = {
  channel: null,
  setIsQuickViewOpen: () => {},
};
