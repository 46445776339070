import React from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { string } from 'prop-types';
import './dropdown.scss';

export const DropdownItem = (props) => {
  const {
    attributeValue,
    swatchGuid
  } = props;

  return (
    <>
      {swatchGuid && (
        <span className="swatch__image">
          <Image
            alt={attributeValue}
            src={swatchGuid}
            title={attributeValue}
            width="30"
            height="30"
          />
        </span>
      )}
      {attributeValue}
    </>
  );
};

DropdownItem.displayName = 'DropdownItem';

DropdownItem.propTypes = {
  attributeValue: string,
  swatchGuid: string
};

DropdownItem.defaultProps = {
  attributeValue: null,
  swatchGuid: null
};
