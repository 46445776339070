import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources/react/dataModel/prop-types';
import { params, alias } from '@thd-nucleus/data-sources';

const Service = shapeType({
  deliveryTimeline: stringType(),
  isDefault: boolType(),
  deliveryDates: shapeType({
    startDate: stringType(),
    endDate: stringType(),
  }),
  deliveryCharge: stringType(),
  dynamicEta: shapeType({
    hours: stringType(),
    minutes: stringType()
  }),
  freeDeliveryThreshold: numberType({ float: true }),
  hasFreeShipping: boolType(),
  locations: arrayOfType(shapeType({
    distance: numberType({ float: true }),
    inventory: shapeType({
      isOutOfStock: boolType(),
      isInStock: boolType(),
      isLimitedQuantity: boolType(),
      isUnavailable: boolType(),
      quantity: numberType(),
    }),
    isAnchor: boolType(),
    locationId: stringType(),
    storeName: stringType(),
    storePhone: stringType(),
    type: stringType(),
  })),
  type: stringType(),
  totalCharge: numberType({ float: true }),
  mode: shapeType({
    code: stringType(),
    desc: stringType(),
    group: stringType(),
    longDesc: stringType()
  })
});

export const dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType({ float: true })
    })
  }),
  shipping: params({
    itemId: stringType().isRequired(),
    storeId: stringType(),
    zipCode: stringType(),
    quantity: numberType().isRequired(),
    price: numberType({ float: true }).isRequired()
  }).shape({
    itemId: stringType(),
    state: stringType(),
    excludedShipStates: stringType(),
    zipCode: stringType(),
    services: arrayOfType(Service)
  })
};
