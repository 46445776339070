import { useRef } from 'react';
import { useLazyDataModel } from '@thd-nucleus/data-sources';

export const usePreFetchTimer = ({
  onHover,
  onHoverExit,
  hoverDelay,
  preFetchProductData,
  itemId,
  storeId,
  zipCode
}) => {
  const preFetchTimerRef = useRef(null);

  const [prefetchReq, prefetchRes] = useLazyDataModel('product', {
    variables: {
      itemId,
      storeId,
      zipCode,
    }
  });

  const onProductPodHover = (hoveredItemId) => {
    if (onHover) {
      preFetchTimerRef.current = setTimeout(() => {
        onHover(hoveredItemId);
      }, hoverDelay);
    }
    if (preFetchProductData) {
      preFetchTimerRef.current = setTimeout(() => {
        prefetchReq({
          variables: {
            itemId: hoveredItemId,
            zipCode: '30080'
          }
        });
      }, 200);
    }
  };

  const onProductPodHoverExit = () => {
    if (onHoverExit) {
      onHoverExit();
    }
    clearInterval(preFetchTimerRef.current);
  };
  return [onProductPodHover, onProductPodHoverExit];
};
